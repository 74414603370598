import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../TemplateUtils/Loading';
import RecordSectionRequired from '../TemplateUtils/RecordSectionRequired';
import RecordSectionOptional from '../TemplateUtils/RecordSectionOptional';
import { Form, Button } from 'react-bootstrap';
import { UseAppContext } from '../../AppContextProvider';
import RecordNavControls from '../TemplateUtils/RecordNavControls';
import RecordIdentifier from '../TemplateUtils/RecordIdentifier';
import RecordCaption2 from '../TemplateUtils/RecordCaption2';
import DOI from '../TemplateUtils/DOI';
import TypeAhead from '../TemplateUtils/TypeAhead';
import RecordError from '../TemplateUtils/RecordError';
import RecordOldversion from '../TemplateUtils/RecordOldversion';
import RecordHelp from '../TemplateUtils/RecordHelp';
import MEditor from '../TemplateUtils/MEditor';
import { gotoDOI, parseDOI, checkDOI, clearData, getKey, Log, getOldVersion, RecordSectionPurposeEdit, getRecordSection, scrollIntoView, showError, cleanHTML, getEditorChecks, checkTempateItem, buildpreview } from '../TemplateUtils/utils.js';
import RecordTimebomb from '../TemplateUtils/RecordTimebomb';
import { defs } from '../../api/siteDefinitions';


const fname = "TemplateReviewPublication";
const TemplateReviewPublication = props => {
    // ========================================================================
    // the context data store
    // ========================================================================
    const {
        recordData: {
            record
        }
    } = UseAppContext();


    // ========================================================================
    // state variables
    // ========================================================================
    const [data, setData] = useState();                         // the actual section data
    const [showpreview, setShowPreview] = useState(false);      // toggle show/hide the preview
    const [dirty, setDirty] = useState(false);                  // true if anything has changed
    const [error, setError] = useState(null);                   // true if there is an error
    const [checked, setCheck] = useState({});                   // result of checking the content locally (overridden on the server)
    const [showerrors, setShowErrors] = useState(false);        // toggle showing any errors reported by check()
    const [loading, setLoading] = useState(true);               // true if loding data
    const [rerender, setRerender] = useState(0);                // helper to force a rerender
    const [oldversion, setOldversion] = useState(null);             // placeholder for any version that has not been saved 
    const [helpitem, setHelpItem] = useState(null);
    const [temp, setTemp] = useState({ doi: "", citation: {}, doichecked: "" });


    // ========================================================================
    // Helper function to get the data for this section from the server
    // ========================================================================
    const getData = async (recordversion, fname) => {
        await getRecordSection(recordversion, fname, RecordSectionPurposeEdit)
            .then((resp) => {
                Log(resp);
                if (resp.status === "error") {
                    setError(resp);
                    setLoading(false);
                }
                else {
                    if (resp.FieldStatus < 100) {
                        buildpreview(resp);
                    }
                    setData(resp);
                    setCheck(checkTempateItem(resp));
                    setLoading(false);
                }
            });
    }


    // ========================================================================
    // Initialsise on first call
    // ========================================================================
    useEffect(() => {
        getOldVersion(record.recordversion, fname);
        getData(record.recordversion, fname);
    }, []);
    // ========================================================================
    // Handle the browser back button
    // ========================================================================

    useEffect(() => {
        const beforeUnLoad = (e) => {
            if (dirty) {
                e.preventDefault();
                e.stopPropagation();
                e.returnValue = "Are you sure you want to leave? Changes will not be saved";
                return "Are you sure you want to leave? Changes will not be saved";
            }
        }


        window.addEventListener('beforeunload', beforeUnLoad);
        return () => {
            window.removeEventListener("beforeunload", beforeUnLoad);
        }
    }, [dirty]);


    // ========================================================================
    // helper function to load or create the data
    // ========================================================================
    useEffect(() => {
        if (!loading) {
            setDirty(true);
        }
        else {
            setTimeout(() => {
                var topOfElement = 0;
                let elem = document.getElementById('scrollto_' + fname);
                if (elem !== null) {
                    topOfElement = elem.offsetTop;
                    window.scroll({ top: topOfElement, behavior: "smooth" });
                }
                //scrollIntoView("scrollto_" + fname);
            }, 100);
        }
        setCheck(checkTempateItem(data));
    }, [rerender]);


    // ========================================================================
    // Handle going back without saving changes
    // =======================================================================
    const back = () => {
        setDirty(false);
        props.back("list");
        setTimeout(() => {
            window.history.back();
        }, 100)
    }


    // ========================================================================
    // callback from the editor onblur
    // ========================================================================
    const notifytext = (editorname, editor) => {
        var state = data;
        const clean = cleanHTML(editor.datahtml);
        let content = state.content;
        let c0 = content[0];
        if (c0.editors[editorname].datahtml === clean) {
            return;
        }
        state.preview = clean;
        editor.datahtml = clean;
        c0.editors[editorname] = editor;
        content[0] = c0;
        state.content = content;

        setDirty(true);
        buildpreview(state);
        setData(state);
        setRerender(rerender + 1);
    }


    const notify = (selected) => {
        var state = data;
        let content = state.content;
        content[0].lists.list1 = selected;

        state.content = content;
        setDirty(true);
        buildpreview(state);
        setData(state);
        setRerender(rerender + 1);

    }


    // ========================================================================
    // get the editor data for the editor
    // ========================================================================
    const getEditorData = (editorname) => {
        if (data != undefined && data !== null) {
            return data.content[0].editors[editorname];
        }
        return null;
    }

    // ========================================================================
    // handle already editing data
    // ========================================================================
    const useoldversion = (option) => {
        if (option) {
            let tempdata = JSON.parse(oldversion);
            tempdata.EditingToken = data.EditingToken;
            setOldversion(null);
            setData(tempdata);
        }
        else {
            setOldversion(null);
        }
    }


    // ========================================================================
    // get the value of an object element
    // ========================================================================
    const getTagValue = (tag) => {
        let tempdata = data;
        let tags = tempdata.content[0].tags;
        return tags[tag];
    }


    // ========================================================================
    // set the value of an object element
    // ========================================================================
    const setTagValue = (e) => {
        var tempdata = data;
        let content = tempdata.content;

        let name = e.currentTarget.name;
        let value = e.currentTarget.value;

        // ========================================================================
        // if we are changing a top-level selection, cleaqr all data
        // ========================================================================
        if (name === "currentstatus") {
            tempdata = clearData(tempdata);
        }


        // ========================================================================
        // grab the elements we are interested in
        // ========================================================================
        var mytags = tempdata.content[0].tags;

        mytags[name] = value;
        content[0].tags = mytags;
        tempdata.content = content;
        buildpreview(tempdata);
        setData(tempdata);
        setRerender(rerender + 1);
    }


    // ========================================================================
    // set the value of an object element
    // ========================================================================
    const setCheckValue = (e) => {
        var tempdata = data;
        let name = e.currentTarget.name;
        let value = e.currentTarget.value;
        let checked = e.currentTarget.checked;

        let content = tempdata.content[0];
        var mytags = tempdata.content[0].tags;

        mytags[name] = checked ? value : '';
        content.tags = mytags;

        buildpreview(tempdata);
        setData(tempdata);
        setRerender(rerender + 1);

    }




    // ========================================================================
    // Check if an object element has a particular value
    // ========================================================================
    const isSelected = (key, item) => {
        let tempdata = data;
        let tags = tempdata.content[0].tags;
        if (tags[key] === item) {
            return "checked";
        }
        return null;
    }


    const savecitation = (citation) => {
        data.content[0].tags.preprintdoicitation = JSON.parse(JSON.stringify(citation));
        data.content[0].tags.preprintdoichecked = "checked"
        buildpreview(data);
        setRerender(rerender + 1);
    }

    const savedoi = (doilink) => {
        data.content[0].tags.preprintdoi = doilink;
        buildpreview(data);
        setRerender(rerender + 1);
    }


    const savepubcitation = (citation) => {
        data.content[0].tags.publisheddoicitation = JSON.parse(JSON.stringify(citation));
        data.content[0].tags.publisheddoichecked = "checked"
        buildpreview(data);
        setRerender(rerender + 1);
    }

    const savepubdoi = (doilink) => {
        data.content[0].tags.publisheddoi = doilink;
        buildpreview(data);
        setRerender(rerender + 1);
    }


    // ========================================================================
    // return the content
    // ========================================================================
    return (<>
        {loading && <LoadingSpinner />}
        {error !== null && <RecordError errormessage={error} />}
        {!showpreview && !loading && (<div className="page-inner-restrict pb-3 recordfields">
            {error === null && oldversion === null && (<>
                <RecordIdentifier variant={data.RecordTemplateVariant} />
                <RecordCaption2
                    caption={data.caption}
                    variant={data.RecordTemplateVariant}
                    fname={fname}
                />


                <div className={"radiobox"}>
                    <h2>Is publication of results planned? <RecordSectionRequired /></h2>
                    <Form.Group>
                        <Form.Check type="radio" id={getKey()} >
                            <Form.Check.Input type="radio" name="willpublish" checked={isSelected("willpublish", "willpublishn") == null ? false : true} defaultValue="willpublishn" onChange={(e) => setTagValue(e)} />
                            <Form.Check.Label className={isSelected("willpublish", "willpublishn") == null ? "labelnotselected" : "labelselected"}
                            >We do not plan on publishing the results of this review</Form.Check.Label>
                        </Form.Check>
                        <Form.Check type="radio" id={getKey()} >
                            <Form.Check.Input type="radio" name="willpublish" checked={isSelected("willpublish", "willpublishy") == null ? false : true} defaultValue="willpublishy" onChange={(e) => setTagValue(e)} />
                            <Form.Check.Label className={isSelected("willpublish", "willpublishy") == null ? "labelnotselected" : "labelselected"}
                            >We plan to publish the results of this review</Form.Check.Label>
                        </Form.Check>
                    </Form.Group>
                </div>

                {isSelected("willpublish", "willpublishy") &&
                    <>
                        <div className={"radiobox"}>
                            <h2>The review will be published in the following languages <RecordSectionOptional /></h2>
                            <div className="mt-3">
                                <TypeAhead
                                    id={"inputid-2"}
                                    baseurl={defs.prosperoURL + "record/languagesearch?count=500&q="}
                                    ismultiple={true}
                                    notify={notify}
                                    selectdata={data.content[0].lists.list1}
                                    inputProps={{ className: "notranslate" }}
                                    className={"notranslate"}
                                />
                            </div>
                            {showError(data.section + "_musthavecontent", checked, showerrors) && <div id={data.section + "_musthavecontent"} className="formerror">You must select at least one language</div>}
                        </div>
                        {data.ReviewStatus === "completed" && <>
                            <h2 className="mt-5">Publications <RecordSectionOptional /></h2>
                            <div className={"radiobox"}>
                                <h2>Preprint publication</h2>
                                <Form.Check type="checkbox" id={getKey()} >
                                    <Form.Check.Input type="checkbox" name="preprint" checked={isSelected("preprint", "preprinty") == null ? false : true} defaultValue="preprinty" onChange={(e) => setCheckValue(e)} />
                                    <Form.Check.Label className={isSelected("preprint", "preprinty") == null ? "labelnotselected" : "labelselected"}>The review is published on a preprint server
                                    </Form.Check.Label>
                                </Form.Check>

                                {isSelected("preprint", "preprinty") && <>
                                    <DOI className="mb-3" doi={getTagValue("preprintdoi")} citation={getTagValue("preprintdoicitation")} postdoi={savedoi} postcitation={savecitation} />
                                    {showError(fname + "_doichecked", checked, showerrors) && <div className="formerror">You must check that the DOI link is valid</div>}
                                    {showError(fname + "_preprintdoi", checked, showerrors) && <div className="formerror">You must provide a valid DOI here (valid DOIs begin with "10.")</div>}

                                </>}
                            </div>


                            <div className={"radiobox"}>
                                <h2>Journal publication</h2>
                                <Form.Check type="radio" id={getKey()} >
                                    <Form.Check.Input type="radio" name="published" checked={isSelected("published", "publishedn") == null ? false : true} defaultValue="publishedn" onChange={(e) => setTagValue(e)} />
                                    <Form.Check.Label className={isSelected("published", "publishedn") == null ? "labelnotselected" : "labelselected"}
                                    >Not yet published in a journal but will be in future</Form.Check.Label>
                                </Form.Check>
                                <Form.Check type="radio" id={getKey()} >
                                    <Form.Check.Input type="radio" name="published" checked={isSelected("published", "publishedy") == null ? false : true} defaultValue="publishedy" onChange={(e) => setTagValue(e)} />
                                    <Form.Check.Label className={isSelected("published", "publishedy") == null ? "labelnotselected" : "labelselected"}
                                    >Published in an academic journal</Form.Check.Label>
                                </Form.Check>
                            </div>

                            {isSelected("published", "publishedy") && <>
                                <div className={"radiobox"}>
                                    <h2>Journal publication details</h2>
                                    <DOI className="mb-3" doi={getTagValue("publisheddoi")} citation={getTagValue("publisheddoicitation")} postdoi={savepubdoi} postcitation={savepubcitation} />
                                    {showError(fname + "_doichecked", checked, showerrors) && <div className="formerror">You must check that the DOI link is valid</div>}
                                    {showError(fname + "_publisheddoi", checked, showerrors) && <div className="formerror">You must provide a valid DOI here (valid DOIs begin with "10.")</div>}
                                </div>
                            </>}
                        </>
                        }

                    </>
                }

                {showError(fname + "_musthchoose1", checked, showerrors) && <div className="formerror">You must choose an option.</div>}



                {isSelected("currentstatus", "discontinued") === "checked" && <>
                    <h3 className="ml-5">Why was the review discontinued? <RecordSectionRequired /></h3>
                    <p className="helptextinline">e.g. GIVE EAMPLE REASONS HERE</p>
                    <div className="radiobox selected pt-3 pb-3">
                        <MEditor editorname="editor1" key={`editor1`} className={"visible"} notify={notifytext} editordata={getEditorData("editor1")} editorchecks={() => getEditorChecks(data.section, "editor1")} />
                        {showError(fname + "_musthavecontent1", checked, showerrors) && <div className="formerror">You must honour the word count.</div>}
                    </div>



                </>
                }
            </>)
            }
            {isSelected("willpublish", "willpublishn") && <>
                <div className="radiobox">
                    <h2>Describe why the review will not be published <RecordSectionRequired /></h2>
                    <MEditor editorname="editor1" key={`editor`} className={"visible"} notify={notifytext} editordata={getEditorData("editor1")} editorchecks={() => getEditorChecks(data.section, "editor1")} />
                    {showError(data.section + "_musthavecontent", checked, showerrors) && <div id={data.section + "_musthavecontent"} className="formerror">You must honour the word count</div>}
                </div>

            </>

            }
  
            {data !== undefined && data.content[0].editors.editor2.datahtml !== undefined && data.content[0].editors.editor2.datahtml !== "" && <>
                <div className="radiobox">
                    <h2>Legacy record publication details <RecordSectionOptional /></h2>
                    <MEditor editorname="editor2" key={`editor2`} className={"visible"} notify={notifytext} editordata={getEditorData("editor2")} editorchecks={() => getEditorChecks(data.section, "editor2")} />
                </div>

            </>

            }
            {oldversion !== null && <RecordOldversion oldversion={oldversion} newversion={data} useoldversion={useoldversion} />}
            <RecordNavControls data={data} back={back} preview={setShowPreview} dirty={dirty} cancomplete={checked} setshowerrors={setShowErrors} showerrors={showerrors} error={error === null && oldversion === null ? null : "error"} />
        </div >)
        }
        {
            showpreview && (<div className="page-inner-restrict pb-3 publicdocument">
                <h1>{data.caption}</h1>
                <div dangerouslySetInnerHTML={{ __html: data.preview }} />
                <div><p><br /></p>
                    <p><br /></p>
                </div>
                <Button variant="primary" onClick={() => setShowPreview(false)}>Close preview</Button>
            </div>)
        }


    </>
    )

}


export default TemplateReviewPublication;

