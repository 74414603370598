import React, { useState, useEffect } from 'react';
import LoadingSpinner from "../TemplateUtils/Loading";
import RecordSectionRequired from '../TemplateUtils/RecordSectionRequired';
import RecordSectionOptional from '../TemplateUtils/RecordSectionOptional';
import { Form, Button } from 'react-bootstrap';
import { UseAppContext } from '../../AppContextProvider';
import RecordNavControls from '../TemplateUtils/RecordNavControls';
import RecordIdentifier from '../TemplateUtils/RecordIdentifier';
import RecordCaption2 from '../TemplateUtils/RecordCaption2';
import RecordError from '../TemplateUtils/RecordError';
import RecordOldversion from '../TemplateUtils/RecordOldversion';
import MEditor from '../TemplateUtils/MEditor';
import { getKey, Log, getOldVersion, RecordSectionPurposeEdit, getRecordSection, scrollIntoView, showError, cleanHTML, getEditorChecks, checkTempateItem, buildpreview, clearData } from '../TemplateUtils/utils.js';
import Funder from '../TemplateUtils/Funder';
//import { prosperoClient } from '../../api/axios';

const fname = "TemplateFunding";
const TemplateFunding = props => {
    // ========================================================================
    // the context data store
    // ========================================================================
    const {
        recordData: {
            record
        }
    } = UseAppContext();

    // ========================================================================
    // state variables
    // ========================================================================
    const [data, setData] = useState();                         // the actual section data
    const [showpreview, setShowPreview] = useState(false);      // toggle show/hide the preview
    const [dirty, setDirty] = useState(false);                  // true if anything has changed
    const [error, setError] = useState(null);                   // true if there is an error
    const [checked, setCheck] = useState({});                   // result of checking the content locally (overridden on the server)
    const [showerrors, setShowErrors] = useState(false);        // toggle showing any errors reported by check()
    const [loading, setLoading] = useState(true);               // true if loding data
    const [rerender, setRerender] = useState(0);                // helper to force a rerender
    const [oldversion, setOldversion] = useState(null);         // placeholder for any version that has not been saved 
    const [other, setOther] = useState(null);
    const [other2, setOther2] = useState(null);

    // ========================================================================
    // Help links and text. set to NULL if not required for this component
    // ========================================================================
    const pdfhelp = "https://www.crd.york.ac.uk/prospero/documents/Guide%20to%20searching%20v1.pdf";
    const videohelp = "https://www.youtube.com/watch?v=MltKLEGad9s";
    const shorthelp = <p>Give details of the individuals, organizations, groups or other legal entities who take responsibility for initiating, managing, sponsoring and/or financing the review. Include any unique identification numbers assigned to the review by the individuals or bodies listed.</p>;
    const longhelp = <><p>Give details of the individuals, organizations, groups or other legal entities who take responsibility for initiating, managing, sponsoring and/or financing the review. Include any unique identification numbers assigned to the review by the individuals or bodies listed.</p>
    </>;
    // ========================================================================
    // Helper function to get the data for this section from the server
    // ========================================================================
    const getData = async (recordversion, fname) => {
        await getRecordSection(recordversion, fname, RecordSectionPurposeEdit)
            .then((resp) => {
                Log(resp);
                if (resp.status === "error") {
                    setError(resp);
                    setLoading(false);
                }
                else {
                    if (resp.FieldStatus < 100) {
                        buildpreview(resp);
                    }
                    setData(resp);
                    setCheck(checkTempateItem(resp));
                    setLoading(false);
                }
            });
    }


    // ========================================================================
    // Initialsise on first call
    // ========================================================================
    useEffect(() => {
        getOldVersion(record.recordversion, fname);
        getData(record.recordversion, fname);
        //        getDBs();
    }, []);




    // ========================================================================
    // Handle the browser back button
    // ========================================================================

    useEffect(() => {
        const beforeUnLoad = (e) => {
            if (dirty) {
                e.preventDefault();
                e.stopPropagation();
                e.returnValue = "Are you sure you want to leave? Changes will not be saved";
                return "Are you sure you want to leave? Changes will not be saved";
            }
        }


        window.addEventListener('beforeunload', beforeUnLoad);
        return () => {
            window.removeEventListener("beforeunload", beforeUnLoad);
        }
    }, [dirty]);



    // ========================================================================
    // helper function to load or create the data
    // ========================================================================
    useEffect(() => {
        if (!loading) {
            setDirty(true);
        }
        setCheck(checkTempateItem(data));
    }, [rerender]);


    // ========================================================================
    // Handle going back without saving changes
    // =======================================================================
    const back = () => {
        setDirty(false);
        props.back("list");
        setTimeout(() => {
            window.history.back();
        }, 100)
    }

    // ========================================================================
    // callback from the editor onblur
    // ========================================================================
    const notifytext = (editorname, editor) => {
        var state = data;
        let content = state.content;
        let c0 = content[0];

        const clean = cleanHTML(editor.datahtml);
        state.preview = clean;
        editor.datahtml = clean;
        c0.editors[editorname] = editor;
        content[0] = c0;
        state.content = content;

        setDirty(true);
        buildpreview(state);
        setData(state);
        setRerender(rerender + 1);
    }

    // ========================================================================
    // get the editor data for the editor
    // ========================================================================
    const getEditorData = (editorname) => {
        if (data != undefined && data !== null) {
            return data.content[0].editors[editorname];
        }
        return null;
    }

    // ========================================================================
    // get the value of an object element
    // ========================================================================
    const getTagValue = (tag) => {
        let tempdata = data;
        let tags = tempdata.content[0].tags;
        return tags[tag];
    }


    // ========================================================================
    // set the value of an object element
    // ========================================================================
    const setTagValue = (e) => {
        var tempdata = data;
        let name = e.currentTarget.name;
        let value = e.currentTarget.value;

        // ========================================================================
        // if we are changing a top-level selection, cleaqr all data
        // ========================================================================
        if (name === "fundingtype") {
            tempdata = clearData(tempdata);
        }


        // ========================================================================
        // grab the elements we are interested in
        // ========================================================================
        var mytags = tempdata.content[0].tags;


        mytags[name] = value;
        let content = tempdata.content;
        content[0].tags = mytags;
        tempdata.content = content;
        buildpreview(tempdata);
        setData(tempdata);
        setRerender(rerender + 1);
    }



    // ========================================================================
    // handle already editing data
    // ========================================================================
    const useoldversion = (option) => {
        if (option) {
            let tempdata = JSON.parse(oldversion);
            tempdata.EditingToken = data.EditingToken;
            setOldversion(null);
            setData(tempdata);
        }
        else {
            setOldversion(null);
        }
    }

    // ========================================================================
    // set the value of an object element
    // NOTE list name (e.g. list1, list2) is passed in in this case)
    // ========================================================================
    const setCheckValue = (selected, list) => {
        var tempdata = data;
        tempdata.content[0].lists[list] = selected;
        buildpreview(tempdata);
        setData(tempdata);
        setRerender(rerender + 1);
    }


    const setOtherValue = (list) => {
        let value;

        if (list === "list1" && other === null)
            return;
        else if (list === "list2" && other2 === null)
            return;

        if (list === "list1")
            value = other.trim();
        else if (list === "list2")
            value = other2.trim();


        if (value !== '') {
            let source = data.content[0].lists[list];
            for (let i = 0; i < source.length; i++) {
                if (source[i].toLowerCase() === value.toLowerCase()) {
                    return;
                }
            }


            data.content[0].lists[list] = [...data.content[0].lists[list], value];
            setOther("");
            setOther2("");
            buildpreview(data);
            setData(data);
            setRerender(rerender + 1);
        }
    }


    // ========================================================================
    // Check if an object element has a particular value
    // ========================================================================
    const isSelected = (key, item) => {
        let tempdata = data;
        let tags = tempdata.content[0].tags;
        if (tags[key] === item) {
            return "checked";
        }
        return null;
    }

    const isOther = (list) => {
        const selected = data.content[0].lists[list];

        for (let i = 0; i < selected.length; i++) {
            if (selected[i] === "Other") {
                return true;
            }
        }
        return false;
    }

    // ========================================================================
    // return the content
    // ========================================================================
    return (<>
        {loading && <LoadingSpinner />}
        {error !== null && <RecordError errormessage={error} />}
        {!showpreview && !loading && (<div className="page-inner-restrict pb-3  recordfields">
            {error === null && oldversion === null && (<>
                <RecordIdentifier variant={data.RecordTemplateVariant} />
                <RecordCaption2
                    caption={data.caption}
                    variant={data.RecordTemplateVariant}
                    fname={fname}
                />
                <div className={"radiobox"}>
                    <h2>Please select from the options below <RecordSectionRequired /></h2>
                    <Form.Group>
                        <Form.Check type="radio" id={getKey()} >
                            <Form.Check.Input type="radio" name="fundingtype" checked={isSelected("fundingtype", "fundingtypesup") == null ? false : true} defaultValue="fundingtypesup" onChange={(e) => setTagValue(e)} />
                            <Form.Check.Label className={isSelected("fundingtype", "fundingtypesup") == null ? "labelnotselected" : "labelselected"}
                            >Review has no specific/external funding but is supported by guarantor/review team (non-commercial) institutions
                            </Form.Check.Label>
                        </Form.Check>
                        <Form.Check type="radio" id={getKey()} >
                            <Form.Check.Input type="radio" name="fundingtype" checked={isSelected("fundingtype", "fundingtypeno") == null ? false : true} defaultValue="fundingtypeno" onChange={(e) => setTagValue(e)} />
                            <Form.Check.Label className={isSelected("fundingtype", "fundingtypeno") == null ? "labelnotselected" : "labelselected"}
                            >Review has no funding and no agreed support from an academic institution and is done in authors’ own time
                            </Form.Check.Label>
                        </Form.Check>
                        <Form.Check type="radio" id={getKey()} >
                            <Form.Check.Input type="radio" name="fundingtype" checked={isSelected("fundingtype", "fundingtypenoncom") == null ? false : true} defaultValue="fundingtypenoncom" onChange={(e) => setTagValue(e)} />
                            <Form.Check.Label className={isSelected("fundingtype", "fundingtypenoncom") == null ? "labelnotselected" : "labelselected"}
                            >Review is fully or partially funded</Form.Check.Label>
                        </Form.Check>
                    </Form.Group>
                    {showError(data.section + "_mustchoose", checked, showerrors) && <div id={data.section + "_mustchoose"} className="formerror">You must select an item.</div>}
                </div>




                {isSelected("fundingtype", "fundingtypenoncom") === "checked" &&
                    <>
                        <div className={"radiobox"}>
                            <h2>Major <b>non-commercial</b> funders <RecordSectionRequired /></h2>

                            <p className="helptextinline">Give only the overarching funders e.g. a review funded by the NIHR HTA Programme should simply be listed as NIHR (National Institute for Health and Care Research). Further details may be added in the additional details field below if desired.</p>
                            <Funder type={"noncommercial"} selectdata={data.content[0].lists.list1} onChange={(e) => setCheckValue(e, "list1")} />
                            {showError(data.section + "_funder", checked, showerrors) && <div id={data.section + "_funder"} className="formerror pt-3">You must select at least one funder.</div>}

                            <h3>Other major <b>non-commercial</b> funder not listed above</h3>
                            <MEditor key={"editor1"} editorname={"editor1"} className={"visible"} notify={notifytext} editordata={getEditorData("editor1")} editorchecks={() => getEditorChecks(data.section, "editor1")} />
                        </div>
                        <div className={"radiobox"}>
                            <h2>Major <b>commercial</b> funders <RecordSectionRequired /></h2>

                            <p className="helptextinline">Give only the overarching funders e.g. a review funded by the Wellcome Foundation should simply be listed as NIHR (National Institute for Health and Care Research). Further details may be added in the additional details field below if desired.</p>
                            <Funder type={"commercial"} selectdata={data.content[0].lists.list2} onChange={(e) => setCheckValue(e, "list2")} />
                            {showError(data.section + "_funder", checked, showerrors) && <div id={data.section + "_funder"} className="formerror pt-3">You must select at least one funder.</div>}
                            <h3 >Other major <b>commercial</b> funder not listed above</h3>
                            <p className="helptextinline"></p>
                            <MEditor key={"editor2"} editorname={"editor2"} className={"visible"} notify={notifytext} editordata={getEditorData("editor2")} editorchecks={() => getEditorChecks(data.section, "editor2")} />
                        </div>


                        <div className={"radiobox"}>
                            <h2>Grant number <RecordSectionRequired /></h2>
                            <Form.Group className={"mb-3"}>
                                <Form.Check type="radio" id={getKey()} >
                                    <Form.Check.Input type="radio" name="grantyn" checked={isSelected("grantyn", "nogrant") == null ? false : true} defaultValue="nogrant" onChange={(e) => setTagValue(e)} />
                                    <Form.Check.Label className={isSelected("grantyn", "nogrant") == null ? "labelnotselected" : "labelselected"}
                                    >I do not have a grant/award number </Form.Check.Label>
                                </Form.Check>
                                <Form.Check type="radio" id={getKey()} >
                                    <Form.Check.Input type="radio" name="grantyn" checked={isSelected("grantyn", "grant") == null ? false : true} defaultValue="grant" onChange={(e) => setTagValue(e)} />
                                    <Form.Check.Label className={isSelected("grantyn", "grant") == null ? "labelnotselected" : "labelselected"}
                                    >I have a grant/award number </Form.Check.Label>
                                </Form.Check>
                                {getTagValue("grantyn") === "grant" &&
                                    <div>
                                        <p className="helptextinline"></p>
                                        <Form.Control
                                            type="text"
                                            name="grantnumber"
                                            aria-describedby={"inputid_help"}
                                            onBlur={(e) => setTagValue(e)}
                                            defaultValue={getTagValue("grantnumber")}
                                            placeholder="Please give the grant number"
                                            className="doiwidth"
                                            maxLength="50"
                                        />
                                        {showError(fname + "_grantnumber", checked, showerrors) && <div className="formerror">You must provide a grant number here</div>}
                                    </div>
                                }
                            </Form.Group>
                            {showError(data.section + "_grantnumberchoose", checked, showerrors) && <div id={data.section + "_grantnumberchoose"} className="formerror pt-3">You must select an item.</div>}
                        </div>


                    </>


                }
                <div className={"radiobox"}>
                    <h2>Additional details about funding <RecordSectionOptional /></h2>
                    <MEditor key={"editor3"} editorname={"editor3"} className={"visible"} notify={notifytext} editordata={getEditorData("editor3")} editorchecks={() => getEditorChecks(data.section, "editor3")} />
                    {showError(fname + "_musthavecontent", checked, showerrors) && <div className="formerror">This field is not mandatory but if used you must honour the word count.</div>}
                </div>
            </>)}
            {oldversion !== null && <RecordOldversion oldversion={oldversion} newversion={data} useoldversion={useoldversion} />}
            <RecordNavControls data={data} back={back} preview={setShowPreview} dirty={dirty} cancomplete={checked} setshowerrors={setShowErrors} showerrors={showerrors} error={error === null && oldversion === null ? null : "error"} />
        </div>)
        }
        {showpreview && (<div className="page-inner-restrict publicdocument">
            <h1>{data.caption}</h1>
            <div dangerouslySetInnerHTML={{ __html: data.preview }} />
            <div><p><br /></p>
                <p><br /></p>
            </div>
            <Button variant="primary" onClick={() => setShowPreview(false)}>Close preview</Button>
        </div>)}


    </>
    )
}


export default TemplateFunding;