import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../TemplateUtils/Loading';
import RecordSectionRequired from '../TemplateUtils/RecordSectionRequired';
import { Form, Button } from 'react-bootstrap';
import { UseAppContext } from '../../AppContextProvider';
import RecordNavControls from '../TemplateUtils/RecordNavControls';
import RecordIdentifier from '../TemplateUtils/RecordIdentifier';
import RecordCaption2 from '../TemplateUtils/RecordCaption2';
import RecordError from '../TemplateUtils/RecordError';
import RecordOldversion from '../TemplateUtils/RecordOldversion';
import MEditor from '../TemplateUtils/MEditor';
import ModalBox from '../TemplateUtils/Modal';
import RecordHelp from '../TemplateUtils/RecordHelp';
import { getKey, clearData, Log, getOldVersion, RecordSectionPurposeEdit, getRecordSection, scrollIntoView, showError, cleanHTML, getEditorChecks, checkDOI, gotoDOI, gotoPDF, checkTempateItem, buildpreview } from '../TemplateUtils/utils.js';
import RecordTimebomb from '../TemplateUtils/RecordTimebomb';


const fname = "TemplateScreening";
const TemplateScreening = props => {
    // ========================================================================
    // the context data store
    // ========================================================================
    const {
        recordData: {
            record
        }
    } = UseAppContext();


    // ========================================================================
    // state variables
    // ========================================================================
    const [data, setData] = useState();                         // the actual section data
    const [showpreview, setShowPreview] = useState(false);      // toggle show/hide the preview
    const [dirty, setDirty] = useState(false);                  // true if anything has changed
    const [error, setError] = useState(null);                   // true if there is an error
    const [checked, setCheck] = useState({});                   // result of checking the content locally (overridden on the server)
    const [showerrors, setShowErrors] = useState(false);        // toggle showing any errors reported by check()
    const [loading, setLoading] = useState(true);               // true if loding data
    const [rerender, setRerender] = useState(0);                // helper to force a rerender
    const [oldversion, setOldversion] = useState(null);         // placeholder for any version that has not been saved 
    const [remove, setRemove] = useState(false);                // flag for removing a PDF
    const [file, setFile] = useState(null);                     // file to deal woith
    const [doi, setDoi] = useState(null);                       // doi to deal woith
    const [showModal, setShowModal] = useState(false);                       // doi to deal woith
    const [modalHeading, setModalHeading] = useState(false);                       // doi to deal woith
    const [modalBody, setModalBody] = useState(false);                       // doi to deal woith


    const error1 = <><p>Screening by a single person is not recommended.</p>
        <p>Single screening of the titles and abstracts of studies misses substantially more studies than other methods and increases the risk of bias.</p>
        <RecordHelp className="smalltext" fname={fname} helpsection="help1" label="What does this mean?" />

    </>

    // ========================================================================
    // Helper function to get the data for this section from the server
    // ========================================================================
    const getData = async (recordversion, fname) => {
        await getRecordSection(recordversion, fname, RecordSectionPurposeEdit)
            .then((resp) => {
                Log(resp);
                if (resp.status === "error") {
                    setError(resp);
                    setLoading(false);
                }
                else {
                    if(resp.FieldStatus < 100) {
                        buildpreview(resp);
                    }
                    setData(resp);
                    setCheck(checkTempateItem(resp));
                    setLoading(false);
                }
            });
    }


    // ========================================================================
    // Initialsise on first call
    // ========================================================================
    useEffect(() => {
        getOldVersion(record.recordversion, fname);
        getData(record.recordversion, fname);
    }, []);


    // ========================================================================
    // Handle the browser back button
    // ========================================================================
    useEffect(() => {
        const beforeUnLoad = (e) => {
            if (dirty) {
                e.preventDefault();
                e.stopPropagation();
                e.returnValue = "Are you sure you want to leave? Changes will not be saved";
                return "Are you sure you want to leave? Changes will not be saved";
            }
        }


        window.addEventListener('beforeunload', beforeUnLoad);
        return () => {
            window.removeEventListener("beforeunload", beforeUnLoad);
        }
    }, [dirty]);

    // ========================================================================
    // helper function to load or create the data, and perform initial check
    // ========================================================================
    useEffect(() => {
        if (!loading) {
            setDirty(true);
        }
        setCheck(checkTempateItem(data));
    }, [rerender]);


    // ========================================================================
    // Handle going back without saving changes
    // =======================================================================
    const back = () => {
        setDirty(false);
        props.back("list");
        setTimeout(() => {
            window.history.back();
        }, 100)
    }


    // ========================================================================
    // callback from the editor onblur
    // ========================================================================
    const notifytext = (editorname, editor) => {
        var state = data;
        let content = state.content;
        let c0 = content[0];

        const clean = cleanHTML(editor.datahtml);
        if (c0.editors[editorname].datahtml === clean) {
            return;
        }
        state.preview = clean;
        editor.datahtml = clean;
        c0.editors[editorname] = editor;
        content[0] = c0;
        state.content = content;

        setDirty(true);
        buildpreview(state);
        setData(state);
        setRerender(rerender + 1);
    }


    // ========================================================================
    // get the editor data for the editor
    // ========================================================================
    const getEditorData = (editorname) => {
        if (data != undefined && data !== null) {
            return data.content[0].editors[editorname];
        }
        return null;
    }

    // ========================================================================
    // handle already editing data
    // ========================================================================
    const useoldversion = (option) => {
        if (option) {
            let tempdata = JSON.parse(oldversion);
            tempdata.EditingToken = data.EditingToken;
            setOldversion(null);
            setData(tempdata);
        }
        else {
            setOldversion(null);
        }
    }

    // ========================================================================
    // get the value of an object element
    // ========================================================================
    const getTagValue = (tag) => {
        let tempdata = data;
        let tags = tempdata.content[0].tags;
        return tags[tag];
    }

    // ========================================================================
    // set the value of an object element
    // ========================================================================
    const setTagValue = (e) => {
        var tempdata = data;
        let name = e.currentTarget.name;
        let value = e.currentTarget.value;

        // ========================================================================
        // if we are changing a top-level selection, cleaqr all data
        // ========================================================================
        if (value !== "screeningmethodother") {
            tempdata = clearData(tempdata);
        }


        // ========================================================================
        // grab the elements we are interested in
        // ========================================================================
        var mytags = tempdata.content[0].tags;


        mytags[name] = value;
        let content = tempdata.content;
        content[0].tags = mytags;
        tempdata.content = content;
        buildpreview(tempdata);
        setData(tempdata);
        setRerender(rerender + 1);
    }


    // ========================================================================
    // Check if an object element has a particular value
    // ========================================================================
    const isSelected = (key, item) => {
        let tempdata = data;
        let tags = tempdata.content[0].tags;
        if (tags[key] === item) {
            return "checked";
        }
        return null;
    }

    // ========================================================================
    // return the content
    // ========================================================================
    return (<>
        {loading && <LoadingSpinner />}
        {error !== null && <RecordError errormessage={error} />}
        {!showpreview && !loading && (<div id={"scrollto_" + fname} className="page-inner-restrict pb-3 recordfields">
            {error === null && oldversion === null && (
                <><RecordIdentifier variant={data.RecordTemplateVariant} />
                    <RecordCaption2
                        caption={data.caption}
                        variant={data.RecordTemplateVariant}
                        fname={fname}
                    />

                    <div className={"radiobox"}>
                        <h2>Please select a method of screening <RecordSectionRequired /></h2>
                        <Form.Group>
                            <Form.Check type="radio" id={getKey()} >
                                <Form.Check.Input type="radio" name="screeningmethod" checked={isSelected("screeningmethod", "screeningmethod1") == null ? false : true} defaultValue="screeningmethod1" onChange={(e) => setTagValue(e)} />
                                <Form.Check.Label className={isSelected("screeningmethod", "screeningmethod1") == null ? "labelnotselected" : "labelselected"}
                                >Studies screened by one person (or machine) only</Form.Check.Label>
                            </Form.Check>
                            <Form.Check type="radio" id={getKey()} >
                                <Form.Check.Input type="radio" name="screeningmethod" checked={isSelected("screeningmethod", "screeningmethod2") == null ? false : true} defaultValue="screeningmethod2" onChange={(e) => setTagValue(e)} />
                                <Form.Check.Label className={isSelected("screeningmethod", "screeningmethod2") == null ? "labelnotselected" : "labelselected"}
                                >Studies screened independently by at least 2 people (or person/machine combination) with a process to resolve differences</Form.Check.Label>
                            </Form.Check>
                            <Form.Check type="radio" id={getKey()} >
                                <Form.Check.Input type="radio" name="screeningmethod" checked={isSelected("screeningmethod", "screeningmethod1m") == null ? false : true} defaultValue="screeningmethod1m" onChange={(e) => setTagValue(e)} />
                                <Form.Check.Label className={isSelected("screeningmethod", "screeningmethod1m") == null ? "labelnotselected" : "labelselected"}
                                >Studies screened by one person (or a machine) and checked by at least one other person (or machine)</Form.Check.Label>
                            </Form.Check>
                            <Form.Check type="radio" id={getKey()} >
                                <Form.Check.Input type="radio" name="screeningmethod" checked={isSelected("screeningmethod", "screeningmethodother") == null ? false : true} defaultValue="screeningmethodother" onChange={(e) => setTagValue(e)} />
                                <Form.Check.Label className={isSelected("screeningmethod", "screeningmethodother") == null ? "labelnotselected" : "labelselected"}
                                >Other screening method</Form.Check.Label>
                            </Form.Check>

                        </Form.Group>
                    </div>


                    {isSelected("screeningmethod", "screeningmethod1") === "checked" &&
                        <div className={"radiobox"}>
                            <RecordTimebomb html={error1} />
                        </div>}
                    {isSelected("screeningmethod", "screeningmethodother") === "checked" &&
                        <div className={"radiobox"}>
                            <h2>Brief details of other searching and screening method.</h2>
                            <MEditor editorname="editor1" key={`editor`} className={"visible"} notify={notifytext} editordata={getEditorData("editor1")} editorchecks={() => getEditorChecks(data.section, "editor1")} mode="title" />
                            {showError(fname + "_musthavecontent", checked, showerrors) && <div className="formerror">You must honour the word count.</div>}
                        </div>
                    }

                </>)}
            {oldversion !== null && <RecordOldversion oldversion={oldversion} newversion={data} useoldversion={useoldversion} />}
            <RecordNavControls data={data} back={back} preview={setShowPreview} dirty={dirty} cancomplete={checked} setshowerrors={setShowErrors} showerrors={showerrors} error={error === null && oldversion === null ? null : "error"} />
        </div>)
        }
        {showpreview && (<div className="page-inner-restrict publicdocument">
            <h1>{data.caption}</h1>
            <div dangerouslySetInnerHTML={{ __html: data.preview }} />
            <div><p><br /></p>
                <p><br /></p>
            </div>
            <Button variant="primary" onClick={() => setShowPreview(false)}>Close preview</Button>
        </div>)}


    </>
    )

}


export default TemplateScreening;