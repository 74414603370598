import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'
import LoadingSpinner from '../TemplateUtils/Loading';
import RecordSectionRequired from '../TemplateUtils/RecordSectionRequired';
import { Form, Button } from 'react-bootstrap';
import { UseAppContext } from '../../AppContextProvider';
import RecordNavControls from '../TemplateUtils/RecordNavControls';
import RecordIdentifier from '../TemplateUtils/RecordIdentifier';
import RecordCaption2 from '../TemplateUtils/RecordCaption2';
import RecordError from '../TemplateUtils/RecordError';
import RecordOldversion from '../TemplateUtils/RecordOldversion';
import RecordHelp from '../TemplateUtils/RecordHelp';
import { getKey, Log, getOldVersion, RecordSectionPurposeEdit, getRecordSection, scrollIntoView, showError, stripHTML, getEditorChecks, checkTempateItem, buildpreview } from '../TemplateUtils/utils.js';



const fname = "TemplateReviewTimeline";
const TemplateReviewTimeline = props => {
    // ========================================================================
    // the context data store
    // ========================================================================
    const {
        recordData: {
            record
        }
    } = UseAppContext();


    // ========================================================================
    // state variables
    // ========================================================================
    const [data, setData] = useState();                         // the actual section data
    const [showpreview, setShowPreview] = useState(false);      // toggle show/hide the preview
    const [dirty, setDirty] = useState(false);                  // true if anything has changed
    const [error, setError] = useState(null);                   // true if there is an error
    const [checked, setCheck] = useState({});                   // result of checking the content locally (overridden on the server)
    const [showerrors, setShowErrors] = useState(false);        // toggle showing any errors reported by check()
    const [loading, setLoading] = useState(true);               // true if loding data
    const [rerender, setRerender] = useState(0);                // helper to force a rerender
    const [oldversion, setOldversion] = useState(null);             // placeholder for any version that has not been saved 
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [datewarning1, setDateWarning1] = useState(null);
    const [datewarning2, setDateWarning2] = useState(null);

    // ========================================================================
    // Helper function to get the data for this section from the server
    // ========================================================================
    const getData = async (recordversion, fname) => {
        await getRecordSection(recordversion, fname, RecordSectionPurposeEdit)
            .then((resp) => {
                Log(resp);
                if (resp.status === "error") {
                    setError(resp);
                    setLoading(false);
                }
                else {
                    if(resp.FieldStatus < 100) {
                        buildpreview(resp);
                    }
                    setData(resp);

                    if(resp.content[0].tags.startdate === "") {
                        resp.content[0].tags.startdate = new Date();
                    }
                    if(resp.content[0].tags.enddate === "") {
                        resp.content[0].tags.enddate = new Date();
                    }
                    let storedstartdate = new Date(resp.content[0].tags.startdate);
                    if (storedstartdate !== null) {
                        setStartDate(storedstartdate);
                    }
                    let storedenddate = new Date(resp.content[0].tags.enddate);
                    if (storedenddate !== null) {
                        setEndDate(storedenddate);
                    }
                    setCheck(checkTempateItem(resp));
                    checkDates(resp);
                    setLoading(false);
                }
            });
    }


    // ========================================================================
    // Initialsise on first call
    // ========================================================================
    useEffect(() => {
        getOldVersion(record.recordversion, fname);
        getData(record.recordversion, fname);
    }, []);


    // ========================================================================
    // Handle the browser back button
    // ========================================================================
    useEffect(() => {
        const beforeUnLoad = (e) => {
            if (dirty) {
                e.preventDefault();
                e.stopPropagation();
                e.returnValue = "Are you sure you want to leave? Changes will not be saved";
                return "Are you sure you want to leave? Changes will not be saved";
            }
        }


        window.addEventListener('beforeunload', beforeUnLoad);
        return () => {
            window.removeEventListener("beforeunload", beforeUnLoad);
        }
    }, [dirty]);

    // ========================================================================
    // helper function to load or create the data, and perform initial check
    // ========================================================================
    useEffect(() => {
        if (!loading) {
            setDirty(true);
        }
        setCheck(checkTempateItem(data));
        checkDates(data);
    }, [rerender]);


    // ========================================================================
    // Handle the browser back button
    // ========================================================================
    const setDates = (name, value) => {
        let mytags = data.content[0].tags;

        mytags[name] = value;

        if (name === 'startdate') {
            setStartDate(value);
        }
        else if (name === 'enddate') {
            setEndDate(value);
        }
        buildpreview(data);
        setRerender(rerender + 1);
    }



    // ========================================================================
    // Handle going back without saving changes
    // =======================================================================
    const back = () => {
        setDirty(false);
        props.back("list");
        setTimeout(() => {
            window.history.back();
        }, 100)
    }


    // ========================================================================
    // handle already editing data
    // ========================================================================
    const useoldversion = (option) => {
        if (option) {
            let tempdata = JSON.parse(oldversion);
            tempdata.EditingToken = data.EditingToken;
            setOldversion(null);
            setData(tempdata);
        }
        else {
            setOldversion(null);
        }
    }


    // ========================================================================
    // get the value of an object element
    // ========================================================================
    const getTagValue = (tag) => {
        let tempdata = data;
        let tags = tempdata.content[0].tags;
        return tags[tag];
    }


    // ========================================================================
    // set the value of an object element
    // ========================================================================
    const setTagValue = (e) => {
        var tempdata = data;
        var mytags = tempdata.content[0].tags;
        let name = e.currentTarget.name;
        let value = e.currentTarget.value;

        // ========================================================================
        // element-sepcific transformations
        // ========================================================================
        /*
        if (name === "enddate") {
                        // if the review end date changes, we reset the published protocol end date also
                        let reviewenddate = new Date(value);
                        try {
                            if (temprecordfields.TemplatePublishedProtocol.content[0].tags.enddate != "") {
                                let publishedenddate = new Date(temprecordfields.TemplatePublishedProtocol.content[0].tags.enddate);
                                if (publishedenddate != "" && publishedenddate > reviewenddate) {
                                    temprecordfields.TemplatePublishedProtocol.content[0].tags.enddate = reviewenddate.toISOString().split('T')[0];
                                    buildpreview(data);
                                }
                            }
                        }
                        catch (e) {
                        }
                    }
            */
        mytags[name] = value;
        let content = tempdata.content;
        content[0].tags = mytags;
        tempdata.content = content;
        buildpreview(tempdata);
        setData(tempdata);
        setRerender(rerender + 1);
    }


    const checkDates = (dat) => {
        if(dat === undefined) {
            return;
        }

        setDateWarning1(false);
        setDateWarning2(false);


        const content = dat.content[0];

        // first check the tags
        const tags = content.tags;
        if (tags.startdate !== "" && tags.enddate !== "") {
            const end = new Date(tags.enddate);
            let start = new Date(tags.startdate);
            let now = new Date();
            let ancient = new Date(now.setMonth(now.getMonth() - 9));
            if (start < ancient) {
                setDateWarning1(true);
            }

            start = new Date(start.setDate(start.getDate() + 28));
            if (end < start) {
                setDateWarning2(true);
            }
        }

    }

    return (<>
        {loading && <LoadingSpinner />}
        {error !== null && <RecordError errormessage={error} />}
        {!showpreview && !loading && (<div id={"scrollto_" + fname} className="page-inner-restrict pb-3 recordfields">
            {error === null && oldversion === null && (<><RecordIdentifier variant={data.RecordTemplateVariant} />
                <RecordCaption2
                    caption={data.caption}
                    variant={data.RecordTemplateVariant}
                    fname={fname}
                    helpmedecide={"help1"}
                />
                <div className="radiobox">
                    <Form.Group>
                        <h2>Start date: date the review will start (or started) <RecordSectionRequired /></h2>
                        <p className="helptextinline">The start date is any point after the review protocol is completed but before data extraction begins.</p>
                        <p><RecordHelp className="smalltext" fname={fname} helpsection="help1" label="More information..." /></p>

                        <DatePicker className="mt-3"
                            selected={startDate}
                            onChange={(date) => setDates('startdate', date)}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            dateFormat="dd MMMM yyyy"
                        />
                    </Form.Group>

                    {showError(fname + "_startdate", checked, showerrors) && <div id={fname + "_startdate"} className="formerror">You must provide a date here</div>}
                    {datewarning1 && <div id={fname + "_incorrectdates1"} className="formerror mt-3">The start date is a long time ago - are you sure it is correct?</div>}
                </div>
                <div className="radiobox">


                    <Form.Group>
                        <h2>End date: date the review is expected to finish <RecordSectionRequired /></h2>
                        <p className="helptextinline">If the project has a contractual end date use this, otherwise give a realistic expected date for completion.</p>
                        <p><RecordHelp className="smalltext" fname={fname} helpsection="help1" label="More information..." /></p>

                        <DatePicker className="mt-3"
                            selected={endDate}
                            onChange={(date) => setDates('enddate', date)}
                            selectsEnd
                            startDate={startDate}
                            dateFormat="dd MMMM yyyy"
                            endDate={endDate}
                            minDate={startDate}
                        />

                        {showError(fname + "_enddate", checked, showerrors) && <div id={fname + "_enddate"} className="formerror mt-3">You must provide a date here</div>}
                    </Form.Group>
                </div>
                {datewarning2 && <div id={fname + "_incorrectdates2"} className="formerror mt-3">The end date should be at least 28 days after the start date</div>}
            </>)}
            {oldversion !== null && <RecordOldversion oldversion={oldversion} newversion={data} useoldversion={useoldversion} />}
            <RecordNavControls data={data} back={back} preview={setShowPreview} dirty={dirty} cancomplete={checked} setshowerrors={setShowErrors} showerrors={showerrors} error={error === null && oldversion === null ? null : "error"} />
        </div>)}
        {showpreview && (<div className="page-inner-restrict publicdocument">
            <h1>{data.caption}</h1>
            <div dangerouslySetInnerHTML={{ __html: data.preview }} />
            <div><p><br /></p>
                <p><br /></p>
            </div>
            <Button variant="primary" onClick={() => setShowPreview(false)}>Close preview</Button>
        </div>)}


    </>
    )

}



export default TemplateReviewTimeline;