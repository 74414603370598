import React, { createContext, useContext, useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { getAuthenticationDetails } from './api/login.api';
import useToken from './hooks/useToken';
import { Navigate } from "react-router-dom";

// create context
const AppContext = createContext();

// Hook to provide access to context object
const UseAppContext = () => {
    return useContext(AppContext);
};

const AppContextProvider = ({ children }) => {
    // GD 2024-03-13 store recordversion in localstorage
    let recordversion = {"recordversion" : localStorage.getItem("currentrecordversion")};

    const { token, setToken } = useToken();
    // the value that will be given to the context
    const [user, setUser] = useState(null);

    const [isOpen, setIsOpen] = useState(true);

    // records page search preferences
    const [recordsSearch, setRecordsSearch] = useState({ "page": "1", "perPage": "20", "sort": [], "term": "", "field": "" });
    const [recordsCheck, setRecordsCheck] = useState({ "page": "1", "perPage": "10", "sort": [] });

    // GD messages
    const [messages, setMessages] = useState([]);
    const [showinstructions, setShowInstructions] = useState(true);

    const [lastmessage, setLastMessage] = useState();
    const [messageinterval, setMessageInterval] = useState(0);


    // GD record  preferences
    //    const [record, setRecord] = useState({"recordversion" : "D88F59F5-79CD-44DE-9A2C-4A1195361A1E", "recordid": 368969, "versionid": 0, "purpose": "admin", templateid: 7});
    //const [record, setRecord] = useState({"recordversion" : "D88F59F5-79CD-44DE-9A2C-4A1195361A1E"});
    const [record, setRecord] = useState(recordversion);
    const [recordfields, setRecordFields] = useState({});
    const [recordtitle, setRecordTitle] = useState("No title defined");
    const [recordsave, setRecordSave] = useState(1);
    const [recordsavestatus, setRecordSaveStatus] = useState(true);
    const [recordsavedate, setRecordSaveDate] = useState(0);
    const [recordaccordionkeys, setRecordAccordionKeys] = useState(["0"]);
    const [recordLinkedFields, setRecordLinkedFields] = useState(1);
    const [listingtab, setListingTab] = useState(1);
    const [page, setPage] = useState(1);


    // user page preferences
    const [userSearch, setUserSearch] = useState({ "tab": "simple", "resultsTab": "list" });
    const [userDetails, setUserDetails] = useState({});

    // email template preferences
    const [emailDetails, setEmailDetails] = useState({});

    const [reportDetails, setReportDetails] = useState({});

    // set the login token details
    const setLogin = (data) => {
        // set authentication token
        setToken(data.token);
    }

    // logout of the application
    const setLogout = () => {
        // remove the session token
        sessionStorage.removeItem('token');
        setToken(null);
        setUser(null);
    }

    // Assign React state and constants to context object
    const AppContextObject = {
        userData: {
            user, setUser
        },
        helpPanel: {
            isOpen, setIsOpen
        },
        recordsSearchData: {
            recordsSearch, setRecordsSearch
        },
        recordsCheckData: {
            recordsCheck, setRecordsCheck
        },
        messageData: {
            lastmessage, setLastMessage,
            messageinterval, setMessageInterval
        },
        recordData: {
            record, setRecord,
            recordfields, setRecordFields,
            recordtitle, setRecordTitle,
            recordLinkedFields, setRecordLinkedFields,
            recordsave, setRecordSave,
            recordsavestatus, setRecordSaveStatus,
            recordsavedate, setRecordSaveDate,
            recordaccordionkeys, setRecordAccordionKeys,
            showinstructions, setShowInstructions,
            listingtab, setListingTab,
            page, setPage
        },
        recordLink: {
            recordLinkedFields, setRecordLinkedFields
        },
        userSearchData: {
            userSearch, setUserSearch
        },
        userDetailsData: {
            userDetails, setUserDetails
        },
        emailDetailsData: {
            emailDetails, setEmailDetails
        },
        reportDetailsData: {
            reportDetails, setReportDetails
        },
        contextmessage: {
            messages, setMessages
        },

        authToken: {
            token, setToken
        },
        setLogin,
        setLogout

    };

    // get the user details when refreshed
    useEffect(() => {
        getAuthDetails();
    }, []);

    const getAuthDetails = async () => {
        // attempt to authenticate on server
        try {
            const data = await getAuthenticationDetails();
            if (data !== undefined ) { // store the user details             
                if(data.status !== undefined && data.status === 500) {
                    alert(data.errormessage);
                    Navigate("/login");
                }
                setUser(data);
            }
        } catch (err) {
            console.log(err);
            // remove the token if failed and go to the login screen
        }
    }

    return (
        // the Provider gives access to the context to its children
        <AppContext.Provider value={AppContextObject}>
            {children}
        </AppContext.Provider>
    );
};


export { AppContext, AppContextProvider, UseAppContext };

AppContextProvider.propTypes = {
    children: PropTypes.element
};