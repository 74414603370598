import React, { useState, useEffect } from 'react';


const HelpInline = props => {        

    if(props.view === "short") {
        return <div className={"helptextinline"}>{props.shorthelp}</div>;
    }
    else if(props.view === "long") {
        return <div className={"helptextinline"}>{props.longhelp}</div>;
    }
    else  {
        return <>
            <div className={"helptextinline"}>{props.shorthelp}</div>
            <div className={"helptextinline"}>{props.longhelp}</div>   
        </>;
    }

}


export default HelpInline;