import React, { useEffect, useState } from 'react';
import { Table, Button, Col, Container, Form, Row, InputGroup, FormControl } from "react-bootstrap";
import { UseAppContext } from '../AppContextProvider';
import { postSearch } from './SearchUtils';

import { useNavigate } from 'react-router-dom';
import SearchResultsTable from './SearchResultsTable';
import SearchFilters from './SearchFilters';
import { prosperoClient } from '../api/axios';
import { axiosoptions } from '../Template/TemplateUtils/utils';
import { baseURL,  stripHTML} from '../Template/TemplateUtils/utils.js';



const SearchResults = () => {
    const [testData, setTestData] = useState({});    
    const [selected, setSelected] = useState([]);    
    const [grandTotal, setGrandTotal] = useState(0);
    const [results, setResults] = useState(null);
    
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    
    const baseterm = {bool : {
        must : [],
        must_not : [],
        should : [],
    }};

    const [term, setTerm] = useState("");
    const [filter, setFilter] = useState(baseterm);    

    const {
        userSearchData: {
            userSearch, setUserSearch
        }
    } = UseAppContext();
  
    // ========================================================================
    // helper function to load or create the data
    // ========================================================================
    useEffect(()=>{        
        setResults(userSearch.results);
        setGrandTotal(userSearch.total);        
    },[userSearch.results]);      

    // ========================================================================
    // helper function to load or create the data
    // ========================================================================
    useEffect(()=>{              
        doFilter();
    },[userSearch.filter]);

    const updateSelectedTab = (key) => {
        setUserSearch({...userSearch, "resultsTab": key});
    }

    const doFilter = async () => {
        if(userSearch.filter !== undefined && userSearch.filter.current !== undefined) {
            try {
                const myterm = userSearch.filter.current;
                console.log("FILTER", myterm);
                const resp = await postSearch(userSearch.search, myterm);

                // update the results
                setUserSearch({...userSearch, "results": resp.data.results});
            } catch(err) {
                console.error("FILTER SEARCH ERROR", err);
            }
        }
    }
  
    const Body = (props) => {
        const [URLbase, setURLbase] = useState("https://www.crd.york.ac.uk/prospero/display_record.php?RecordID=")

        let keyCount = 0;
        const getKey = (root) => {
            return root + "_" + keyCount++;
        }
    
        const getRecord = (recordid) => {    
            window.open(URLbase + recordid, "PROSPERO_RECORD_" + recordid);
        }


        let ret = []; 
        if(results != null) {
           for(var i = 0; i < props.results.hits.length; i++) {
                let hit = props.results.hits[i]._source;
                let row = <tr key={getKey("`results-tr-")} >
                    <td  key={getKey("`results-td-")} ><span>{stripHTML(hit.title)}</span> [ <span className="link" onClick={() => getRecord(hit.recordid)}>CRD{hit.accessionnumber}</span> ]</td>
                    <td key={getKey("`results-td-")}>{hit.recordtype}</td>
                </tr>         
                ret.push(row);   
            }
        }
        return ret;
    }
    
    if(results != undefined && results.hits != undefined)
        return (<>
                <Row>
                    <div>{`Results for: ${userSearch.search.term}`}</div>
                    <div>{`${results.hits.total.value} results shown (${grandTotal} total)`}</div>
                </Row>
                <div>
                    <SearchFilters  results={results} />
                    <SearchResultsTable userSearch={userSearch} updateSelectedTab={updateSelectedTab} />
                </div>
                </>)
    else return <></>;
             
}

export default SearchResults;