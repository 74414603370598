import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { UseAppContext } from '../AppContextProvider';
import { getPreview } from '../Template/TemplateUtils/utils.js';


const fname = "Preview";
const Preview = props => {
    // ========================================================================
    // the context data store
    // ========================================================================
    const {
        recordData: {
            record
        }
    } = UseAppContext();


    const getLivePreview = async () => {
        const data = await getPreview(record.recordversion);
        setPreviewData(data);
    }

    // ========================================================================
    // Initialsise on first call
    // ========================================================================
    useEffect(() => {
        getLivePreview();
    }, []);


    // ========================================================================
    // state variables
    // ========================================================================
    const [previewdata, setPreviewData] = useState(null);
    const [error, setError] = useState(null);                   // true if there is an error
    const [loading, setLoading] = useState(true);               // true if loding data


    // ========================================================================
    // Handle going back without saving changes
    // =======================================================================
    const back = () => {
        setTimeout(() => {
            window.history.back();
        }, 100)
    }

    // ========================================================================
    // return the content
    // ========================================================================
    return (<>
        <h1>Record preview</h1>
        <div className="page-inner-restrict pb-3 publicdocument">
            {previewdata !== null && <div dangerouslySetInnerHTML={{ __html: previewdata }}></div>}
        </div>
        <div><p><br /></p>
            <Button variant="primary" onClick={() => back()}>Close preview</Button>
            <p><br /></p>
        </div>

    </>
    )

}


export default Preview;