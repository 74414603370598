import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../TemplateUtils/Loading';
import RecordSectionRequired from '../TemplateUtils/RecordSectionRequired';
import RecordSectionOptional from '../TemplateUtils/RecordSectionOptional';
import { Form, Button } from 'react-bootstrap';
import { UseAppContext } from '../../AppContextProvider';
import RecordNavControls from '../TemplateUtils/RecordNavControls';
import RecordIdentifier from '../TemplateUtils/RecordIdentifier';
import RecordCaption2 from '../TemplateUtils/RecordCaption2';
import RecordError from '../TemplateUtils/RecordError';
import RecordOldversion from '../TemplateUtils/RecordOldversion';
import MEditor from '../TemplateUtils/MEditor';
import ModalBox from '../TemplateUtils/Modal';
import RecordHelp from '../TemplateUtils/RecordHelp';
import { getKey, clearData, Log, getOldVersion, RecordSectionPurposeEdit, getRecordSection, scrollIntoView, showError, cleanHTML, getEditorChecks, checkDOI, gotoDOI, gotoPDF, checkTempateItem, buildpreview } from '../TemplateUtils/utils.js';
import RecordTimebomb from '../TemplateUtils/RecordTimebomb';

const fname = "TemplateDataExtraction";
const TemplateDataExtraction = props => {
    // ========================================================================
    // the context data store
    // ========================================================================
    const {
        recordData: {
            record
        }
    } = UseAppContext();


    // ========================================================================
    // state variables
    // ========================================================================
    const [data, setData] = useState();                         // the actual section data
    const [showpreview, setShowPreview] = useState(false);      // toggle show/hide the preview
    const [dirty, setDirty] = useState(false);                  // true if anything has changed
    const [error, setError] = useState(null);                   // true if there is an error
    const [checked, setCheck] = useState({});                   // result of checking the content locally (overridden on the server)
    const [showerrors, setShowErrors] = useState(false);        // toggle showing any errors reported by check()
    const [loading, setLoading] = useState(true);               // true if loding data
    const [rerender, setRerender] = useState(0);                // helper to force a rerender
    const [oldversion, setOldversion] = useState(null);         // placeholder for any version that has not been saved 
    const [remove, setRemove] = useState(false);                // flag for removing a PDF
    const [file, setFile] = useState(null);                     // file to deal woith
    const [doi, setDoi] = useState(null);                       // doi to deal woith
    const [showModal, setShowModal] = useState(false);                       // doi to deal woith
    const [modalHeading, setModalHeading] = useState(false);                       // doi to deal woith
    const [modalBody, setModalBody] = useState(false);                       // doi to deal woith



    const error1 = <><p>Data extraction by a single person is not recommended.</p>
        <p>Systematic reviews following best practice will have at least two people (or a person and machine) independently extract data and then check that extracted values are the same. Having one or more people/machines check extracted values is also considered good practice.</p>
    </>

    // ===========================================
    // Helper function to get the data for this section from the server
    // ========================================================================
    const getData = async (recordversion, fname) => {
        await getRecordSection(recordversion, fname, RecordSectionPurposeEdit)
            .then((resp) => {
                Log(resp);
                if (resp.status === "error") {
                    setError(resp);
                    setLoading(false);
                }
                else {
                    if (resp.FieldStatus < 100) {
                        buildpreview(resp);
                    }
                    setData(resp);
                    setCheck(checkTempateItem(resp));
                    setLoading(false);
                }
            });
    }


    // ========================================================================
    // Initialsise on first call
    // ========================================================================
    useEffect(() => {
        getOldVersion(record.recordversion, fname);
        getData(record.recordversion, fname);
    }, []);


    // ========================================================================
    // Handle the browser back button
    // ========================================================================
    useEffect(() => {
        const beforeUnLoad = (e) => {
            if (dirty) {
                e.preventDefault();
                e.stopPropagation();
                e.returnValue = "Are you sure you want to leave? Changes will not be saved";
                return "Are you sure you want to leave? Changes will not be saved";
            }
        }


        window.addEventListener('beforeunload', beforeUnLoad);
        return () => {
            window.removeEventListener("beforeunload", beforeUnLoad);
        }
    }, [dirty]);

    // ========================================================================
    // helper function to load or create the data, and perform initial check
    // ========================================================================
    useEffect(() => {
        if (!loading) {
            setDirty(true);
        }
        else {
            setTimeout(() => {
                var topOfElement = 0;
                let elem = document.getElementById('scrollto_' + fname);
                if (elem !== null) {
                    topOfElement = elem.offsetTop;
                    window.scroll({ top: topOfElement, behavior: "smooth" });
                }
                //scrollIntoView("scrollto_" + fname);
            }, 100);
        }
        setCheck(checkTempateItem(data));
    }, [rerender]);


    // ========================================================================
    // Handle going back without saving changes
    // =======================================================================
    const back = () => {
        setDirty(false);
        props.back("list");
        setTimeout(() => {
            window.history.back();
        }, 100)
    }


    // ========================================================================
    // callback from the editor onblur
    // ========================================================================
    const notifytext = (editorname, editor) => {
        var state = data;
        let content = state.content;
        let c0 = content[0];

        const clean = cleanHTML(editor.datahtml);
        if (c0.editors[editorname].datahtml === clean) {
            return;
        }
        state.preview = clean;
        editor.datahtml = clean;
        c0.editors[editorname] = editor;
        content[0] = c0;
        state.content = content;

        setDirty(true);
        buildpreview(state);
        setData(state);
        setRerender(rerender + 1);
    }


    // ========================================================================
    // get the editor data for the editor
    // ========================================================================
    const getEditorData = (editorname) => {
        if (data != undefined && data !== null) {
            return data.content[0].editors[editorname];
        }
        return null;
    }

    // ========================================================================
    // handle already editing data
    // ========================================================================
    const useoldversion = (option) => {
        if (option) {
            let tempdata = JSON.parse(oldversion);
            tempdata.EditingToken = data.EditingToken;
            setOldversion(null);
            setData(tempdata);
        }
        else {
            setOldversion(null);
        }
    }

    // ========================================================================
    // get the value of an object element
    // ========================================================================
    const getTagValue = (tag) => {
        let tempdata = data;
        let tags = tempdata.content[0].tags;
        return tags[tag];
    }


    // ========================================================================
    // set the value of an object element
    // ========================================================================
    const setCheckValue = (e) => {
        var tempdata = data;
        let name = e.currentTarget.name;
        let value = e.currentTarget.value;
        let checked = e.currentTarget.checked;

        let content = tempdata.content[0];
        var mytags = tempdata.content[0].tags;

        mytags[name] = checked ? value : '';
        content.tags = mytags;

        buildpreview(tempdata);
        setData(tempdata);
        setRerender(rerender + 1);

    }



    // ========================================================================
    // set the value of an object element
    // ========================================================================
    const setTagValue = (e) => {
        var tempdata = data;
        let name = e.currentTarget.name;
        let value = e.currentTarget.value;
        let content = tempdata.content[0];

        // ========================================================================
        // if we are changing a top-level selection, cleaqr all data
        // ========================================================================
        if (name == "extractionmethod" && value !== "extractionmethodother") {
            content.editors.editor1 = {};
        }
        if (name == "extractionmethod" && value === "extractionmethodno") {
            content.tags.extractionadditional = "";
        }


        // ========================================================================
        // grab the elements we are interested in
        // ========================================================================
        var mytags = tempdata.content[0].tags;


        mytags[name] = value;
        content.tags = mytags;
        tempdata.content[0] = content;
        buildpreview(tempdata);
        setData(tempdata);
        setRerender(rerender + 1);
    }


    // ========================================================================
    // Check if an object element has a particular value
    // ========================================================================
    const isEmpty = (key) => {
        let tempdata = data;
        let tags = tempdata.content[0].tags;
        if (tags[key] === "") {
            return true;
        }
        return false;
    }

    // ========================================================================
    // Check if an object element has a particular value
    // ========================================================================
    const isSelected = (key, item) => {
        let tempdata = data;
        let tags = tempdata.content[0].tags;
        if (tags[key] === item) {
            return "checked";
        }
        return null;
    }

    // ========================================================================
    // return the content
    // ========================================================================
    return (<>
        {loading && <LoadingSpinner />}
        {error !== null && <RecordError errormessage={error} />}
        {!showpreview && !loading && (<div id={"scrollto_" + fname} className="page-inner-restrict pb-3 recordfields">
            {error === null && oldversion === null && (
                <><RecordIdentifier variant={data.RecordTemplateVariant} />
                    <RecordCaption2
                        caption={data.caption}
                        variant={data.RecordTemplateVariant}
                        fname={fname}
                    />
                    {isSelected("extractionmethod", "extractionmethod1") === "checked" &&
                        <div className="radiobox">
                            <RecordTimebomb html={error1} />
                        </div>
                    }
                    <div className="radiobox">
                        <h2 >Extracting data from publications or reports <RecordSectionRequired /></h2>
                        <Form.Group>
                            <Form.Check type="radio" id={getKey()} >
                                <Form.Check.Input type="radio" name="extractionmethod" checked={isSelected("extractionmethod", "extractionmethodno") == null ? false : true} defaultValue="extractionmethodno" onChange={(e) => setTagValue(e)} />
                                <Form.Check.Label className={isSelected("extractionmethod", "extractionmethodno") == null ? "labelnotselected" : "labelselected"}
                                >Data will not be extracted from publications or reports</Form.Check.Label>
                            </Form.Check>
                            <Form.Check type="radio" id={getKey()} >
                                <Form.Check.Input type="radio" name="extractionmethod" checked={isSelected("extractionmethod", "extractionmethod1") == null ? false : true} defaultValue="extractionmethod1" onChange={(e) => setTagValue(e)} />
                                <Form.Check.Label className={isSelected("extractionmethod", "extractionmethod1") == null ? "labelnotselected" : "labelselected"}
                                >Data will be extracted by one person (or machine) only</Form.Check.Label>
                            </Form.Check>
                            <Form.Check type="radio" id={getKey()} >
                                <Form.Check.Input type="radio" name="extractionmethod" checked={isSelected("extractionmethod", "extractionmethod2") == null ? false : true} defaultValue="extractionmethod2" onChange={(e) => setTagValue(e)} />
                                <Form.Check.Label className={isSelected("extractionmethod", "extractionmethod2") == null ? "labelnotselected" : "labelselected"}
                                >Data will be extracted independently by at least two people (or person/machine combination) with a process to resolve differences</Form.Check.Label>
                            </Form.Check>
                            <Form.Check type="radio" id={getKey()} >
                                <Form.Check.Input type="radio" name="extractionmethod" checked={isSelected("extractionmethod", "extractionmethod1m") == null ? false : true} defaultValue="extractionmethod1m" onChange={(e) => setTagValue(e)} />
                                <Form.Check.Label className={isSelected("extractionmethod", "extractionmethod1m") == null ? "labelnotselected" : "labelselected"}
                                >Data will be extracted by one person (or a machine) and checked by at least one other person (or machine)</Form.Check.Label>
                            </Form.Check>
                            <Form.Check type="radio" id={getKey()} >
                                <Form.Check.Input type="radio" name="extractionmethod" checked={isSelected("extractionmethod", "extractionmethodother") == null ? false : true} defaultValue="extractionmethodother" onChange={(e) => setTagValue(e)} />
                                <Form.Check.Label className={isSelected("extractionmethod", "extractionmethodother") == null ? "labelnotselected" : "labelselected"}
                                >Other data extraction method</Form.Check.Label>
                            </Form.Check>

                        </Form.Group>
                        {showError(fname + "_mustchoose2", checked, showerrors) && <div className="formerror">You must select an option.</div>}

                    </div>

                    {isSelected("extractionmethod", "extractionmethodother") === "checked" &&
                        <div className={"radiobox"}>
                            <h2>Give brief details of other data extraction methods.</h2>
                            <MEditor editorname="editor1" key={`editor`} className={"visible"} notify={notifytext} editordata={getEditorData("editor1")} editorchecks={() => getEditorChecks(data.section, "editor1")} />
                            {showError(fname + "_musthavecontent", checked, showerrors) && <div className="formerror">You must honour the word count.</div>}
                        </div>
                    }



                    {isSelected("extractionmethod", "extractionmethodno") !== "checked" && !isEmpty("extractionmethod") &&
                        <div className={"radiobox"}>
                            <><h2>Will study authors be contacted for further information?</h2>
                                <Form.Group >
                                    <Form.Check type="radio" id={getKey()} >
                                        <Form.Check.Input type="radio" name="extractionadditional" checked={isSelected("extractionadditional", "extractionadditionalno") == null ? false : true} defaultValue="extractionadditionalno" onChange={(e) => setTagValue(e)} />
                                        <Form.Check.Label className={isSelected("extractionadditional", "extractionadditionalno") == null ? "labelnotselected" : "labelselected"}
                                        >No, authors will not be contacted </Form.Check.Label>
                                    </Form.Check>
                                    <Form.Check type="radio" id={getKey()} >
                                        <Form.Check.Input type="radio" name="extractionadditional" checked={isSelected("extractionadditional", "extractionadditional") == null ? false : true} defaultValue="extractionadditional" onChange={(e) => setTagValue(e)} />
                                        <Form.Check.Label className={isSelected("extractionadditional", "extractionadditional") == null ? "labelnotselected" : "labelselected"}
                                        >Yes, authors will be asked to provide any required data not available in published reports </Form.Check.Label>
                                    </Form.Check>
                                    {showError(fname + "_mustchoose3", checked, showerrors) && <div className="formerror">You must select an option.</div>}
                                </Form.Group>
                            </>
                        </div>

                    }

                    <div className={"radiobox"}>
                        <h2>Obtaining study datasets/individual participant data (IPD)</h2>
                        <RecordHelp className="smalltext" fname={fname} helpsection="help1" label="More information..." />

                        <Form.Group >
                            <Form.Check type="radio" id={getKey()} >
                                <Form.Check.Input type="radio" name="extractiontype" checked={isSelected("extractiontype", "extractionno") == null ? false : true} defaultValue="extractionno" onChange={(e) => setTagValue(e)} />
                                <Form.Check.Label className={isSelected("extractiontype", "extractionadditional") == null ? "labelnotselected" : "labelselected"}
                                >No - IPD will not be sought</Form.Check.Label>
                            </Form.Check>
                            <Form.Check type="radio" id={getKey()} >
                                <Form.Check.Input type="radio" name="extractiontype" checked={isSelected("extractiontype", "extractionipd") == null ? false : true} defaultValue="extractionipd" onChange={(e) => setTagValue(e)} />
                                <Form.Check.Label className={isSelected("extractiontype", "extractionipd") == null ? "labelnotselected" : "labelselected"}
                                >Yes - study datasets/IPD will be obtained from study investigators or via a data repository</Form.Check.Label>
                            </Form.Check>
                            {showError(fname + "_mustchoose3", checked, showerrors) && <div className="formerror">You must select an option.</div>}
                        </Form.Group>

                        {isSelected("extractiontype", "extractionipd") !== null &&
                            <div className={"radiobox"}>
                                <h2>Details of how IPD will be obtained  <RecordSectionOptional /></h2>
                                <MEditor editorname="editor2" key={`editor`} className={"visible"} notify={notifytext} editordata={getEditorData("editor2")} editorchecks={() => getEditorChecks(data.section, "editor2")} />
                                {showError(data.section + "_musthavecontent2", checked, showerrors) && <div id={data.section + "_musthavecontent1"} className="formerror">You must honour the word count</div>}
                            </div>
                        }
                    </div>

                </>)}
            {oldversion !== null && <RecordOldversion oldversion={oldversion} newversion={data} useoldversion={useoldversion} />}
            <RecordNavControls data={data} back={back} preview={setShowPreview} dirty={dirty} cancomplete={checked} setshowerrors={setShowErrors} showerrors={showerrors} error={error === null && oldversion === null ? null : "error"} />
        </div>)
        }
        {showpreview && (<div className="page-inner-restrict publicdocument">
            <h1>{data.caption}</h1>
            <div dangerouslySetInnerHTML={{ __html: data.preview }} />
            <div><p><br /></p>
                <p><br /></p>
            </div>
            <Button variant="primary" onClick={() => setShowPreview(false)}>Close preview</Button>
        </div>)}


    </>
    )

}


export default TemplateDataExtraction;

