import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useNavigate, Link } from 'react-router-dom';

import { UseAppContext } from '../AppContextProvider';

const ProsperoNavbar = () => {
  const nav = useNavigate();

  const {
    userData: {
      user
    },
    setLogout
  } = UseAppContext();

  const getName = () => {
    if (user.firstname !== undefined && user.lastname !== undefined)
      return user.firstname + ' ' + user.lastname;
    else if (user.firstname !== undefined)
      return user.firstname;
    else
      return "Unknown user";

  }

  const logoutUser = () => {

    setLogout()
    goToPage("/home");

  }

  const goToPage = (page) => {
    nav(page, {replace:false});
  }
  return (
    <>
      <Navbar className="no-print" bg="dark" variant="dark">
        <Container>
          <Nav className="me-auto">
            <div className="navbar-left nav-link" onClick={() => goToPage("/home")}>Home</div>
            <NavDropdown title="About PROSPERO" id="basic-nav-dropdown">
              <NavDropdown.Item onClick={() => goToPage("/help/aboutprospero")}>About PROSPERO</NavDropdown.Item>
              <NavDropdown.Item onClick={() => goToPage("/help/eligibility")}>Eligibility for inclusion</NavDropdown.Item>
              <NavDropdown.Item onClick={() => goToPage("/help/register")}>How to register a systematic review in PROSPERO</NavDropdown.Item>
              <NavDropdown.Item onClick={() => goToPage("/help/dataintegrity")}>Data integrity</NavDropdown.Item>
              <NavDropdown.Item onClick={() => goToPage("/help/advisoryboard")}>Advisory board</NavDropdown.Item>
              <NavDropdown.Item onClick={() => goToPage("/help/resources2")}>Resources</NavDropdown.Item>
              <NavDropdown.Item onClick={() => goToPage("/help/terms")}>Terms and conditions of use</NavDropdown.Item>
              <NavDropdown.Item onClick={() => goToPage("/help/privacy")}>Privacy policy</NavDropdown.Item>

            </NavDropdown>
            <Nav.Link className="nav-link-search navbar-left nav-link"  as={Link} to="../search">Search</Nav.Link>
            {(user?.firstname !== undefined)
              ? <>
                <Nav.Link className="navbar-left nav-link"  as={Link} to="../myprospero">My PROSPERO</Nav.Link>
                <Nav.Link className="nav-link"><span onClick={() => { logoutUser() }}>{`Logout: ${getName()}`}</span></Nav.Link>
              </>
              : <>
                <Nav.Link className="navbar-left nav-link" onClick={() => { goToPage("/login") }}>Login</Nav.Link>
                <Nav.Link onClick={() => { goToPage("/join") }} className="nav-link">Join us</Nav.Link>
              </>
            }
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}


export default ProsperoNavbar;
