import React, { useState, useEffect } from 'react';
import { ListGroup, LitItem, Accordion } from 'react-bootstrap';
import { prosperoClient } from '../../api/axios';
import { Form, Button, Row, Col, InputGroup, Check } from 'react-bootstrap';
import RecordSectionRequired from '../TemplateUtils/RecordSectionRequired';
import { UseAppContext } from '../../AppContextProvider';
import TypeAhead from '../TemplateUtils/TypeAhead';
import RecordNavControls from '../TemplateUtils/RecordNavControls';
import RecordOldversion from '../TemplateUtils/RecordOldversion';
import RecordIdentifier from '../TemplateUtils/RecordIdentifier';
import RecordCaption2 from '../TemplateUtils/RecordCaption2';
import MEditor from '../TemplateUtils/MEditor';
import sanitizeHtml from 'sanitize-html';
import LoadingSpinner from "../TemplateUtils/Loading";
import RecordError from '../TemplateUtils/RecordError';
import { makeURL, getOldVersion, Log, RecordSectionPurposeEdit, getRecordSection, formatDate, createMarkup, axiosoptions, getPreviewPlainText, getKey, showError, cleanHTML, stripHTML, getEditorChecks, checkTempateItem, buildpreview } from '../TemplateUtils/utils.js';
// import { isDocument } from '@testing-library/user-event/dist/utils';


const fname = "TemplateCheckSimilar";
const TemplateCheckSimilar = props => {
    // ========================================================================
    // state variables
    // ========================================================================

    const [data, setData] = useState();
    const [showpreview, setShowPreview] = useState(false);
    const [error, setError] = useState(null);
    const [URLbase, setURLbase] = useState("https://www.crd.york.ac.uk/prospero/display_record.php?RecordID=")
    const [results, setResults] = useState();
    const [rerender, setRerender] = useState(0);
    const [activeKey, setActiveKey] = useState();
    const [activeKeys, setActiveKeys] = useState();
    const [dirty, setDirty] = useState(false);
    const [showerrors, setShowErrors] = useState(false);
    const [checked, setCheck] = useState({});
    const [showcount, setShowcount] = useState(3);
    const [oldversion, setOldversion] = useState(null);         // placeholder for any version that has not been saved 
    const [activerecord, setActiveRecord] = useState();
    const [recordview, setRecordView] = useState(null);
    const [titleerror, showTitleError] = useState();


    //    const [selected, setSelected] = useState(null);
    const [loading, setLoading] = useState(true);

    // ========================================================================
    // the context data store
    // ========================================================================
    const {
        recordData: {
            record
        }
    } = UseAppContext();


    // ========================================================================
    // handle already editing data
    // ========================================================================
    const useoldversion = (option) => {
        if (option) {
            let tempdata = JSON.parse(oldversion);
            tempdata.EditingToken = data.EditingToken;
            setOldversion(null);
            setData(tempdata);
        }
        else {
            setOldversion(null);
        }
    }



    // ========================================================================
    // Helper function to get the data for this section from the server
    // ========================================================================
    const getData = async (recordversion, fname) => {
        await getRecordSection(recordversion, fname, RecordSectionPurposeEdit)
            .then((resp) => {
                Log(resp);
                if (resp.status === "error") {
                    setError(resp);
                    setLoading(false);
                }
                else {
                    if (resp.FieldStatus < 100) {
                        buildpreview(resp);
                    }
                    setData(resp);
                    setCheck(checkTempateItem(resp));
                    setLoading(false);
                }
            });
    }


    // ========================================================================
    // Initialsise on first call
    // ========================================================================
    useEffect(() => {
        getOldVersion(record.recordversion, fname);
        getData(record.recordversion, fname);
    }, []);



    useEffect(() => {
        if (data !== undefined) {
            let max = showcount;
            let list = data.content[0].lists.list1;
            for (var i = 0; i < list.length; i++) {
                let temprecord = list[i];

                // for top level selections clear the whole record
                if (temprecord.select1 !== '') {
                    max = i;
                }
            }
            if (max < 3) {
                setShowcount(3);
            }
            else if (max !== showcount) {
                setShowcount(max + 1);
            }
            checkString();
        }
    }, [data]);


    // ========================================================================
    // Handle the browser back button
    // ========================================================================

    useEffect(() => {
        const beforeUnLoad = (e) => {
            if (dirty) {
                e.preventDefault();
                e.stopPropagation();
                e.returnValue = "Are you sure you want to leave? Changes will not be saved";
                return "Are you sure you want to leave? Changes will not be saved";
            }
        }


        window.addEventListener('beforeunload', beforeUnLoad);
        return () => {
            window.removeEventListener("beforeunload", beforeUnLoad);
        }
    }, [dirty]);



    // ========================================================================
    // helper function to load or create the data
    // ========================================================================
    useEffect(() => {
        if (!loading) {
            setDirty(true);
        }
        // check the status of everything after wach rerender
        setCheck(checkTempateItem(data));

        if (data !== undefined) {
            let max = showcount;
            let list = data.content[0].lists.list1;
            for (var i = 0; i < list.length; i++) {
                let temprecord = list[i];

                // for top level selections clear the whole record
                if (temprecord.select1 !== '') {
                    max = i;
                }
            }
            if (max < 3) {
                setShowcount(3);
            }
            else if (max !== showcount) {
                setShowcount(max + 1);
            }
        }

    }, [rerender]);


    // ========================================================================
    // Handle going back without saving changes
    // =======================================================================
    const back = () => {
        setDirty(false);
        props.back("list");
        setTimeout(() => {
            window.history.back();
        }, 100)
    }



    const checkString = async () => {
        if (data === undefined || data.content[0].lists.list1.length > 0) {
            return;
        }
        let title = getPreviewPlainText(data.content, "TemplateTitle");
        let rq = getPreviewPlainText(data.content, "TemplateReviewQuestion");
        let condition = getPreviewPlainText(data.content, "TemplateCondition");
        let intervention = getPreviewPlainText(data.content, "TemplateIntervention");
        let comparator = getPreviewPlainText(data.content, "TemplateComparator");
        let outcomes = getPreviewPlainText(data.content, "TemplateOutcomesMain");

        if (title === "" || rq === "") {
            showTitleError("There needs to be at least a Title and Review Question to perform this check.");
            return;
        }

        if (record !== undefined && record.recordversion !== undefined && title !== undefined) {
            try {
                const params = {
                    recordversion: record.recordversion,
                    title: title,
                    rq: rq,
                    condition: condition,
                    intervention: intervention,
                    comparator: comparator,
                    outcomes: outcomes,
                    threshold: 0.4
                };


                const ret = await prosperoClient.post('record/checksimilarrecords', params, axiosoptions)
                    .then(res => {
                        setLoading(false);
                        setResults(res.data);

                        let tempdata = data;

                        let content = tempdata.content[0].lists.list1;

                        // for each result, check whether we already have it
                        res.data.results.map((i, index) => {
                            let addme = true;
                            content.forEach(element => {
                                if (element.recordid === i.RecordID) {
                                    addme = false;
                                }
                            });
                            if (addme) {
                                let obj = {};
                                obj.recordid = i.RecordID;
                                obj.title = i.Title;
                                obj.reviewq = i.ReviewQ;
                                obj.accessionnumber = i.AccessionNumber;
                                obj.datefirstpublished = i.DateFirstPublished;
                                obj.score = i.percent;
                                obj.select1 = "";
                                obj.select2 = "";
                                obj.select3 = [];
                                obj.select3other = "";
                                obj.select4 = [];
                                obj.select4other = "";
                                content.push(obj);
                            }
                        });

                        content.sort((a, b) => {
                            return b.score - a.score;
                        })
                        tempdata.content[0].lists.list1 = content;


                    })
                    .catch(error => {
                        setLoading(false);
                        console.log(error);
                        setError(error);
                    });
                setLoading(false);
            } catch (err) {
                setLoading(false);
                setError(err);

            }
        }
    }



    const getRecord = (recordid) => {
        window.open(URLbase + recordid, "PROSPERO_RECORD_" + recordid);
    }

    const showPreview = (show) => {
        setShowPreview(true);
    }


    const select1 = (event, recordid) => {
        const val = event.target.value;
        let tempdata = data;
        let list = tempdata.content[0].lists.list1;
        for (var i = 0; i < list.length; i++) {
            let temprecord = list[i];

            // for top level selections clear the whole record
            if (temprecord.recordid === recordid) {
                temprecord.select1 = "";
                temprecord.select2 = "";
                temprecord.select3 = [];
                temprecord.select3other = "";
                temprecord.select4 = [];
                temprecord.select4other = "";



                temprecord.select1 = val;
                list[i] = temprecord;
                setData(tempdata);
                setRerender(rerender + 1);


                break;
            }
        }
        buildpreview(tempdata);
    };
    const select2 = (event, recordid) => {
        const val = event.target.value;
        let tempdata = data;
        let list = tempdata.content[0].lists.list1;
        for (var i = 0; i < list.length; i++) {
            let temprecord = list[i];
            if (temprecord.recordid === recordid) {
                temprecord.select2 = val;
                list[i] = temprecord;
                setData(tempdata);
                setRerender(rerender + 1);
                break;
            }
        }
        buildpreview(tempdata);

    };
    const select3 = (event, recordid) => {
        const val = event.target.value;
        let tempdata = data;
        let list = tempdata.content[0].lists.list1;
        for (var i = 0; i < list.length; i++) {
            let temprecord = list[i];
            if (temprecord.recordid === recordid) {
                let select3 = temprecord.select3;
                const index = select3.indexOf(val);
                if (index > -1) { // only splice array when item is found
                    select3.splice(index, 1); // 2nd parameter means remove one item only
                }
                if (event.currentTarget.checked === true) {
                    select3.push(val);
                }

                temprecord.select3 = select3;
                list[i] = temprecord;
                setData(tempdata);
                setRerender(rerender + 1);
                break;
            }
        }
        buildpreview(tempdata);

    };
    const select3other = (event, recordid) => {
        const val = event.target.value;
        let tempdata = data;
        let list = tempdata.content[0].lists.list1;
        for (var i = 0; i < list.length; i++) {
            let temprecord = list[i];
            if (temprecord.recordid === recordid) {
                temprecord.select3other = val;
                list[i] = temprecord;
                setData(tempdata);
                setRerender(rerender + 1);
                break;
            }
        }
        buildpreview(tempdata);
    };

    const select4 = (event, recordid) => {
        const val = event.target.value;
        let tempdata = data;
        let list = tempdata.content[0].lists.list1;
        for (var i = 0; i < list.length; i++) {
            let temprecord = list[i];
            if (temprecord.recordid === recordid) {
                let select4 = temprecord.select4;
                const index = select4.indexOf(val);
                if (index > -1) { // only splice array when item is found
                    select4.splice(index, 1); // 2nd parameter means remove one item only
                }
                if (event.currentTarget.checked === true) {
                    select4.push(val);
                }

                temprecord.select4 = select4;
                list[i] = temprecord;
                setData(tempdata);
                setRerender(rerender + 1);
                break;
            }
        }
        buildpreview(tempdata);

    };
    const select4other = (event, recordid) => {
        const val = event.target.value;
        let tempdata = data;
        let list = tempdata.content[0].lists.list1;
        for (var i = 0; i < list.length; i++) {
            let temprecord = list[i];
            if (temprecord.recordid === recordid) {
                temprecord.select4other = val;
                list[i] = temprecord;
                setData(tempdata);
                setRerender(rerender + 1);
                break;
            }
        }
        buildpreview(tempdata);
    };

    const handleSubmit = () => {
        return true;
    }


    const countDone = () => {
        let max = data.content[0].lists.list1.length;
        let count = 0;
        let list = data.content[0].lists.list1;
        for (var i = 0; i < max; i++) {
            let temprecord = list[i];
            if (temprecord.select1 !== "") {
                count++;
            }
        }
        if (count === max) {
            return 100;
        }
        else if (count > 0) {
            return 50;
        }
        else {
            return 0;
        }

    }
    /*
        const saveRecord = () => {
            var temp = data;
            buildpreview(temp);
            temp.status = countDone();
            var tempdata = recordfields;
            tempdata.TemplateCheckSimilar = temp;
            setRecordFields(tempdata);
            setTimeout(() => {
                setRecordSave(recordsave + 1);
            }, 100)
    
            // and move on
            let currentkey = activeKeys;
            currentkey = currentkey.substring(10);
            currentkey = parseInt(currentkey);
            currentkey = currentkey + 16;
            currentkey = "accordion_" + currentkey;
            setActiveKeys(currentkey);
    
        }
    */

    // ========================================================================
    // Check if an object element has a particular value
    // ========================================================================
    const isSelected = (key, item) => {
        let tempdata = data;
        let tags = tempdata.content[0].tags;
        if (tags[key] === item) {
            return "checked";
        }
        return null;
    }

    // ========================================================================
    // set the value of an object element
    // ========================================================================
    const setTagValue = (e) => {
        var tempdata = data;
        let name = e.currentTarget.name;
        let value = e.currentTarget.value;
        let content = tempdata.content;

        // ========================================================================
        // grab the elements we are interested in
        // ========================================================================
        var mytags = tempdata.content[0].tags;

        if(name === "checksimilar" && value === "notsimilar") {
            content[0].lists.list1 = [];
        }
        else {
            checkStringNow();
        }

        mytags[name] = value;
        content[0].tags = mytags;
        tempdata.content = content;
        buildpreview(tempdata);
        setData(tempdata);
        setRerender(rerender + 1);
    }

    // ========================================================================
    // callback from the editor onblur
    // ========================================================================
    const notifytext = (editorname, editor) => {
        var state = data;
        let content = state.content;
        let c0 = content[0];

        const clean = cleanHTML(editor.datahtml);
        state.preview = clean;
        editor.datahtml = clean;
        c0.editors[editorname] = editor;
        content[0] = c0;
        state.content = content;

        setDirty(true);
        buildpreview(state);
        setData(state);
        setRerender(rerender + 1);
    }


    // ========================================================================
    // get the editor data for the editor
    // ========================================================================
    const getEditorData = (editorname) => {
        if (data != undefined && data !== null) {
            return data.content[0].editors[editorname];
        }
        return null;
    }

    const exportReferences = () => {
        // marshal the recoferences 
        let tempdata = data;
        if (tempdata.content[0].lists.list1 === undefined) {
            return null;
        }

        //+ "] score: " + i.score
        let array = tempdata.content[0].lists.list1.map((i, index) => {

            // set the data packet
            if (index < showcount) {
                let tableclass = "";
                if (i.select1 === "bogus") {
                    tableclass = "itemdone";
                }
                else if (i.select1 === "similar" && i.select2 === "exit") {
                    tableclass = "itemdone";
                }
                else if (i.select1 === "similar" && i.select2 === "continue" && (i.select3.length > 0 || i.selectother.trim() !== "")) {
                    tableclass = "itemdone";
                }

                return "<div><p><b>" + stripHTML(i.title) + "</b> [published " + formatDate(i.datefirstpublished) + "]" + "<br />" + i.reviewq +  "<br />" + "<a href='" + makeURL("view/" + i.accessionnumber) +  "'>"+ makeURL("view/" + i.accessionnumber) + "</a></div>";
            }
        })

        let output = array.join("") ;
        console.log("<html><head></head><body>"+ output + "</body></html>");    
        const newWindow = window.open();
        newWindow.document.write(output)
    }



    const checkStringNow = () => {
        checkString();
    }


    const handleSelect = (eventKey) => {
        setActiveKeys(eventKey);
    }


    const setActiveRecordNumber = (number, match) => {
        setActiveRecord(number);
        setRecordView(match)
    }


    const populateList = () => {
        let tempdata = data;
        if (tempdata.content[0].lists.list1 === undefined) {
            return null;
        }

        //+ "] score: " + i.score
        return tempdata.content[0].lists.list1.map((i, index) => {

            // set the data packet
            if (index < showcount) {
                let tableclass = "";
                if (i.select1 === "bogus") {
                    tableclass = "itemdone";
                }
                else if (i.select1 === "similar" && i.select2 === "exit") {
                    tableclass = "itemdone";
                }
                else if (i.select1 === "similar" && i.select2 === "continue" && (i.select3.length > 0 || i.selectother.trim() !== "")) {
                    tableclass = "itemdone";
                }

                return <ListGroup.Item className={tableclass} key={index} eventKey={index} onClick={(e) => setActiveRecordNumber(index, i)}>
                    {stripHTML(i.title) + " [published " + formatDate(i.datefirstpublished) + "]" + (tableclass === "itemdone" ? " CHECKED" : "")}

                </ListGroup.Item>
            }
        })
        /*
        <ListItem key={i.RecordID} eventKey={getKey("accordion")}>
            <Accordion.Header className={isDone(i.RecordID) + " "} >{i.Title + " [published " + formatDate(i.DateFirstPublished) + "] score: " + i.percent} </Accordion.Header>
            <Accordion.Body className="radiobox selected">
                <h3>Review question</h3>
                <div dangerouslySetInnerHTML={createMarkup(i.ReviewQ.replaceAll("< div>", "</div>").replaceAll("< p>", "</p>").replaceAll("< b>", "</b>"))} />
                <div>&nbsp;</div>
                */

    }

    const isDone = (recordid) => {
        let tempdata = data;
        let list = tempdata.content[0].lists.list1;
        for (var i = 0; i < list.length; i++) {
            let temprecord = list[i];
            if (temprecord.recordid === recordid && temprecord.select1 !== "") {
                return "isdone";
            }
        }

        return "";

    }

    const isSimilar = (similar, recordid) => {
        let tempdata = data;
        let list = tempdata.content[0].lists.list1;
        for (var i = 0; i < list.length; i++) {
            let temprecord = list[i];
            if (temprecord.recordid === recordid && temprecord.select1 === similar) {
                return "";
            }
        }

        return "hidden";

    }

    const isSimilarDetail = (similar, recordid) => {
        let tempdata = data;
        let list = tempdata.content[0].lists.list1;
        for (var i = 0; i < list.length; i++) {
            let temprecord = list[i];
            if (temprecord.recordid === recordid && temprecord.select2 === similar) {
                return "";
            }
        }

        return "hidden";

    }


    const isSimilarProceed = (similar, recordid) => {
        let tempdata = data;
        let list = tempdata.content[0].lists.list1;
        for (var i = 0; i < list.length; i++) {
            let temprecord = list[i];
            if (temprecord.recordid === recordid) {
                let select3 = temprecord.select3;
                for (var j = 0; j < select3.length; j++) {
                    if (select3[j] === similar) {
                        return "";
                    }
                }
            }
        }
        return "hidden";
    }

    const isDifferentProceed = (similar, recordid) => {
        let tempdata = data;
        let list = tempdata.content[0].lists.list1;
        for (var i = 0; i < list.length; i++) {
            let temprecord = list[i];
            if (temprecord.recordid === recordid) {
                let select4 = temprecord.select4;
                for (var j = 0; j < select4.length; j++) {
                    if (select4[j] === similar) {
                        return "";
                    }
                }
            }
        }
        return "hidden";
    }

    const isSimilarProceedOther = (recordid) => {
        let tempdata = data;
        let list = tempdata.content[0].lists.list1;
        for (var i = 0; i < list.length; i++) {
            let temprecord = list[i];
            if (temprecord.recordid === recordid) {
                return temprecord.select3other;
            }
        }
        return "";
    }

    const isDifferentProceedOther = (recordid) => {
        let tempdata = data;
        let list = tempdata.content[0].lists.list1;
        for (var i = 0; i < list.length; i++) {
            let temprecord = list[i];
            if (temprecord.recordid === recordid) {
                return temprecord.select4other;
            }
        }
        return "";
    }

    const cancelDecision = () => {
        let tempdata = data;
        let list = tempdata.content[0].lists.list1;
        for (let i = 0; i < list.length; i++) {
            let temprecord = list[i];

            // for top level selections clear the whole record
            if (temprecord.recordid === recordview.RecordID) {
                temprecord.select1 = "";
                temprecord.select2 = "";
                temprecord.select3 = [];
                temprecord.select3other = "";
                temprecord.select4 = [];
                temprecord.select4other = "";
                list[i] = temprecord;
                setData(tempdata);
                setRerender(rerender + 1);
                break;
            }
        }
        buildpreview(tempdata);
        setRecordView(null);
    }
    setRecordView


    return (<>
        {loading && <LoadingSpinner />}
        {error !== null && <RecordError errormessage={error} />}

        {!showpreview && !loading && isSelected("checksimilar", "similar") === "checked" && recordview !== null &&
            <>
                <h1>Potential similar review in PROSPERO</h1>
                <div className={"radiobox"}>
                    <h2>Review title</h2>
                    <div className="pb-3" dangerouslySetInnerHTML={createMarkup(recordview.title.replaceAll("< div>", "</div>").replaceAll("< p>", "</p>").replaceAll("< b>", "</b>"))} />
                    <h2>First published</h2>
                    <div className="pb-3">{formatDate(recordview.datefirstpublished)}</div>
                    <h2>Review question</h2>
                    <div className="pb-3" dangerouslySetInnerHTML={createMarkup(recordview.reviewq.replaceAll("< div>", "</div>").replaceAll("< p>", "</p>").replaceAll("< b>", "</b>"))} />
                    <div>&nbsp;</div>
                    <Button className="mb-3" variant="primary" onClick={() => getRecord(recordview.recordid)}>View complete record in PROSPERO...</Button>
                </div>
                <div className={"radiobox"}>
                    <h2>Is this review similar to your review?</h2>
                    <Form.Group>
                        <Form.Check type={"radio"} id={getKey("`check-api-radio-")}>
                            <Form.Check.Input type={"radio"} defaultChecked={isSimilar("bogus", recordview.recordid) == "" ? "checked" : null} name="similarreview" value="bogus" onChange={(e) => select1(e, recordview.recordid)} />
                            <Form.Check.Label>This review is <b>different</b> to our review</Form.Check.Label>
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                        </Form.Check>
                        <Form.Check type={"radio"} id={getKey("`check-api-radio-")}  >
                            <Form.Check.Input type={"radio"} defaultChecked={isSimilar("similar", recordview.recordid) == "" ? "checked" : null} name="similarreview" value="similar" onChange={(e) => select1(e, recordview.recordid)} />
                            <Form.Check.Label>This review is <b>similar</b> to our review. It addresses a similar research question</Form.Check.Label>
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                        </Form.Check>
                    </Form.Group>
                </div>
                <div className={"radiobox " + isSimilar("similar", recordview.recordid)}>
                    <Form.Group >
                        <h2>What do you want to do?</h2>

                        <Form.Check type={"radio"} id={getKey("`check-api-radio-")} >
                            <Form.Check.Input type={"radio"} defaultChecked={isSimilarDetail("exit", recordview.recordid) == "" ? "checked" : null} name="similarreviewdetail" defaultValue="exit" onChange={(e) => select2(e, recordview.recordid)} />
                            <Form.Check.Label>I do not want to proceed with registration at this point</Form.Check.Label>
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>

                            <p className={"mt-3 " + isSimilarDetail("exit", recordview.recordid)}>Thank you for avoiding duplicating a review and for avoiding research waste. If the similar review is not yet completed you may wish to contact the review team to check that it is progressing as planned.</p>
                            <p className={"" + isSimilarDetail("exit", recordview.recordid)}><b>Your registration record has not been deleted at this point. If you no longer require it please delete it from your dashboard.</b></p>
                        </Form.Check>
                        <Form.Check type={"radio"} id={getKey("`check-api-radio-")} >
                            <Form.Check.Input type={"radio"} defaultChecked={isSimilarDetail("continue", recordview.recordid) == "" ? "checked" : null} name="similarreviewdetail" defaultValue="continue" onChange={(e) => select2(e, recordview.recordid)} />
                            <Form.Check.Label>I will proceed with my registration </Form.Check.Label>
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                        </Form.Check>
                    </Form.Group>
                </div>

                <Form.Group className={"" + isSimilarDetail("continue", recordview.recordid)}>
                    <div className={"radiobox"}>
                        <h2>Give your reasons for proceeding?</h2>
                        <p className="helptextinline">Tick all that apply.</p>

                        <Form.Check type={"checkbox"} id={getKey("`check-api-radio-")} >
                            <Form.Check.Input type={"checkbox"} defaultChecked={isSimilarProceed("continue-population", recordview.recordid) == "" ? "checked" : null} defaultValue="continue-population" onChange={(e) => select3(e, recordview.recordid)} />
                            <Form.Check.Label>There are differences in population</Form.Check.Label>
                        </Form.Check>
                        <Form.Check type={"checkbox"} id={getKey("`check-api-radio-")} >
                            <Form.Check.Input type={"checkbox"} defaultChecked={isSimilarProceed("continue-pico", recordview.recordid) == "" ? "checked" : null} defaultValue="continue-pico" onChange={(e) => select3(e, recordview.recordid)} />
                            <Form.Check.Label>There are differences in intervention or comparator </Form.Check.Label>
                        </Form.Check>
                        <Form.Check type={"checkbox"} id={getKey("`check-api-radio-")} >
                            <Form.Check.Input type={"checkbox"} defaultChecked={isSimilarProceed("continue-outcomes", recordview.recordid) == "" ? "checked" : null} defaultValue="continue-outcomes" onChange={(e) => select3(e, recordview.recordid)} />
                            <Form.Check.Label>Our review looks at additional or different outcomes</Form.Check.Label>
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                        </Form.Check>
                        <Form.Check type={"checkbox"} id={getKey("`check-api-radio-")} >
                            <Form.Check.Input type={"checkbox"} defaultChecked={isSimilarProceed("continue-uptodate", recordview.recordid) == "" ? "checked" : null} defaultValue="continue-uptodate" onChange={(e) => select3(e, recordview.recordid)} />
                            <Form.Check.Label>Our review will be more up to date</Form.Check.Label>
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                        </Form.Check>
                        <Form.Check type={"checkbox"} id={getKey("`check-api-radio-")} >
                            <Form.Check.Input type={"checkbox"} defaultChecked={isSimilarProceed("continue-methods", recordview.recordid) == "" ? "checked" : null} defaultValue="continue-methods" onChange={(e) => select3(e, recordview.recordid)} />
                            <Form.Check.Label>Our review uses improved methods</Form.Check.Label>
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                        </Form.Check>
                        <Form.Check type={"checkbox"} id={getKey("`check-api-radio-")} >
                            <Form.Check.Input type={"checkbox"} defaultChecked={isSimilarProceed("continue-conflict", recordview.recordid) == "" ? "checked" : null} defaultValue="continue-conflict" onChange={(e) => select3(e, recordview.recordid)} />
                            <Form.Check.Label>There are concerns about potential conflicts of interest in the other review</Form.Check.Label>
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                        </Form.Check>
                        <Form.Label>Other</Form.Label>
                        <Form.Control as="textarea" rows={3} onBlur={(e) => select3other(e, recordview.recordid)} defaultValue={isSimilarProceedOther(recordview.recordid)} />


                    </div>
                </Form.Group>

                <div className='mb-5 mt-5'>
                    <Button variant="primary" onClick={() => setRecordView(null)}>Save this decision</Button>
                    <Button variant="warning" onClick={() => cancelDecision()}>Cancel this decision</Button>
                </div>

            </>}




        {!showpreview && !loading && recordview === null &&
            <div className="page-inner-restrict pb-3  recordfields">



                {error === null && oldversion === null && <>
                    <div>
                        <RecordIdentifier variant={data.RecordTemplateVariant} />
                        <RecordCaption2
                            caption={data.caption}
                            variant={data.RecordTemplateVariant}
                            fname={fname}
                        />
                    </div>


                    <div className={"radiobox"}>
                        <h2 id={"scrollto_" + fname}>Check similar PROSPERO registrations?<RecordSectionRequired /></h2>
                        <Form.Group>
                            <Form.Check type="radio" id={getKey()} >
                                <Form.Check.Input type="radio" name="checksimilar" defaultChecked={isSelected("checksimilar", "notsimilar") == null ? false : true} defaultValue="notsimilar" onChange={(e) => setTagValue(e)} />
                                <Form.Check.Label className={isSelected("checksimilar", "notsimilar") == null ? "labelnotselected" : "labelselected"}
                                >I do not want to check ANY similar records</Form.Check.Label>
                            </Form.Check>

                            <Form.Check type="radio" id={getKey()} >
                                <Form.Check.Input type="radio" name="checksimilar" defaultChecked={isSelected("checksimilar", "similar") == null ? false : true} defaultValue="similar" onChange={(e) => setTagValue(e)} />
                                <Form.Check.Label className={isSelected("checksimilar", "similar") == null ? "labelnotselected" : "labelselected"}
                                >Show similar records</Form.Check.Label>
                            </Form.Check>
                        </Form.Group>

                        {showError(fname + "_shoulddecide", checked, showerrors) && <div className="formerror">You must chose an option.</div>}
                    </div>

                    {isSelected("checksimilar", "notsimilar") === "checked" &&
                        <div className={"radiobox"}>
                            <h2>Reason for not checking similar records</h2>
                            <p className="helptextinline">This information will be shown on your public PROSPERO record.</p>
                            <p className="helptextinline">PROSPERO monitors these responses to improve our services. If this option is selected we may check your record and contact you for further information.</p>
                            <MEditor key={"editor1"} editorname={"editor1"} className={"visible"} notify={notifytext} editordata={getEditorData("editor1")} editorchecks={() => getEditorChecks(data.section, "editor1")} />
                            {showError(fname + "_musthavecontent", checked, showerrors) && <div className="formerror">You must honour the word count.</div>}

                        </div>
                    }


                    {isSelected("checksimilar", "similar") === "checked" && recordview === null &&
                        <div className={"radiobox"}>
                            <h2>Similar registrations</h2>

                            {titleerror === undefined && <>
                                <p className="helptextinline mt-3">Click on the links to view each of these records and then enter if and how they are similar to or different from your review - and whether you want to proceed with your registration.</p>
                                <Button variant="primary" onClick={() => exportReferences(false)}>Export reference list</Button>
                                <ListGroup
                                    activeKey={activeKey}
                                    numbered
                                >
                                    {populateList()}
                                </ListGroup>
                                {showcount <= data.content[0].maxtocheck &&
                                    <p className="internal-link" onClick={() => setShowcount(showcount + 3)}>Show me more...</p>
                                }
                                {showError(fname + "_musthavecontent1", checked, showerrors) && <div className="formerror">You must review at least three records.</div>}

                            </>}
                            {titleerror !== undefined &&
                                <RecordError errormessage={titleerror} />
                            }
                        </div>
                    }


                </>

                }
                {oldversion !== null && <RecordOldversion oldversion={oldversion} newversion={data} useoldversion={useoldversion} />}
                <RecordNavControls data={data} back={back} preview={setShowPreview} dirty={dirty} cancomplete={checked} setshowerrors={setShowErrors} showerrors={showerrors} error={error === null && oldversion === null ? null : "error"} />
            </div>
        }

        {
            showpreview && (<div className="page-inner-restrict pb-3 publicdocument">
                <h1>{data.caption}</h1>
                <div dangerouslySetInnerHTML={{ __html: data.preview }} />
                <div><p><br /></p>
                    <p><br /></p>
                </div>
                <Button variant="primary" onClick={() => setShowPreview(false)}>Close preview</Button>
            </div>)


        }
    </>)
}
export default TemplateCheckSimilar;