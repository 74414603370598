import React, { useEffect, useState } from 'react';
import { Pagination, Button, Col, Row, Container, Badge, Stack } from 'react-bootstrap';
import { prosperoClient } from '../api/axios';
import { axiosoptions, formatDate } from '../Template/TemplateUtils/utils.js';
import { useNavigate } from 'react-router-dom';
import { UseAppContext } from '../AppContextProvider';
import RecordError from '../Template/TemplateUtils/RecordError';
import HomeBanner from '../home/homebanner';
import { useParams } from "react-router-dom"
import { SimpleTable, DeleteCell, ButtonCell } from '../table/table.js';

import { RiFileEditLine, RiFileEditFill } from "react-icons/ri";
import { MdSecurityUpdateGood } from 'react-icons/md';

const MyProspero = () => {
    const [edstat, setEdstat] = useState();
    const [pubstat, setPubstat] = useState();
    const [caption, setCaption] = useState();
    const [defaulttab, setDefaultTab] = useState(0);

    const [results, setResults] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const sections = [

        { pubstat: "Registered", edstat: "Referred", label: "Registered referred", caption: "Registered records referred for your attention" }
        , { pubstat: "Not yet registered", edstat: "Referred", label: "New referred", caption: "New records referred for your attention" }
        , { pubstat: "Registered", edstat: "Amending", label: "Amending", caption: "Registered records being amended" }
        , { pubstat: "Not yet registered", edstat: "Being assessed", label: "New publishing", caption: "New records awaiting publication" }
        , { pubstat: "Registered", edstat: "In process", label: "Updates", caption: "Registered records being updated" }
        , { pubstat: "Not yet registered", edstat: "In process", label: "New (active)", caption: "New records being worked on" }
        , { pubstat: "Registered", edstat: "Being assessed", label: "Registered publishing", caption: "Registered records updated and awaiting publication" }
        , { pubstat: "Registered", edstat: "Stable", label: "Registered", caption: "Registered records published and stable" }
        , { pubstat: "Not yet registered", edstat: "Stable", label: "New (dormant)", caption: "New records not being worked on<" }
        , { pubstat: "", edstat: "Rejected", label: "Rejected", caption: "Records that have been rejected" }
    ]


    const columns = [
        {
            Header: "Review",
            accessor: "Title",
            Cell: (props) => (
                <>{<div dangerouslySetInnerHTML={{ __html: props.value }} />}</>
            )

        },
        {
            Header: "Date created",
            accessor: "utcDateCreated",
            className: "table-md",
            Cell: (props) => (
                <>{formatDate(props.value)}</>
            )
        },
        {
            Header: "Last edited",
            accessor: "utcDateLastEdited",
            className: "table-md",
            Cell: (props) => (
                <>{formatDate(props.value)}</>
            )
        }/*,
        {
            Header: "Action",
            accessor: "RecordVersionID",
            className: "table-action",
            Cell: (props) => (
                <div><ActionButton props={props} /></div>
            )
        }*/
    ];


    // ========================================================================
    // the context data store
    // ========================================================================
    const {
        recordData: {
            record, setRecord, listingtab, setListingTab, page, setPage
        },
        userData: {
            user
        }
    } = UseAppContext();

    if (user === undefined || user === null || user.useruuid === undefined || user.useruuid === null) {
        navigate("/login");
        return <>Not logged in</>;
    }



    // ========================================================================
    // helper function to load or create the data
    // ========================================================================
    useEffect(() => {
        getList();
    }, []);

    const ActionButton = ({ props }) => {
        try {
            switch (props.row.values.EditingStatus) {
                case "In process":
                    return <>
                        <ButtonCell
                            label={<RiFileEditLine />}
                            title={"Show dashboard"}
                            id={props.value}
                            action={showDashboard}
                        />
                        <ButtonCell
                            label={<RiFileEditLine />}
                            title={"Edit record"}
                            id={props.value}
                            action={editVersion}
                        />
                    </>
                case "Awaiting approval":
                    return <ButtonCell
                        label={<RiFileEditLine />}
                        title={"Edit record"}
                        id={props.value}
                        action={editVersion}
                    />
                case "Stable":
                    return <ButtonCell
                        label={<RiFileEditFill />}
                        title={"Update record"}
                        id={props.value}
                        action={editVersion}
                    />
                default:
                    return <></>
            }
        } catch (err) {
            console.error("Registration record action", err);
            return <></>;
        }
    }


    // ========================================================================
    // helper function to generate object keys
    // ========================================================================

    const getList = async () => {
        try {
            const params = {

            };

            const ret = await prosperoClient.get('record/getuserrecordlist', { params }, axiosoptions)
                .then(res => {
                    setLoading(false);
                    if (res.data.status === "error") {
                        setError(res.data.errormessage);
                        setLoading(false);
                    }
                    else {
                        setResults(res.data);
                        chooseDefault(res.data);
                    }
                })
                .catch(error => {
                    setLoading(false);
                    console.log(error);
                    setError(error);
                });
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setError(err);
        }
    }


    const editVersion = (e) => {
        setRecord({ recordversion: e });
        localStorage.setitem("currentrecordversion", e)
        navigate("../register");
    }

    const showDashboard = (row) => {
        //        recordid = 368969;
        setRecord({ recordversion: row.original.RecordVersionID });
        localStorage.setItem("currentrecordversion", row.original.RecordVersionID)
        navigate("/recorddashboard", { replace: false });
        setTimeout(() => {
            //            window.location = "/recorddashboard/" + row.original.RecordVersionID;
        }, 1000)

    }


    const addList = (tab) => {
        const nperpage = 10;
        let pubstat = sections[tab].pubstat;
        let edstat = sections[tab].edstat;
        let caption = sections[tab].caption;

        let ret = [<h2 key={pubstat + edstat}>{caption}</h2>];
        let temp = results.results.flatMap((item, index) => {

            return pubstat === "" ?
                item.EditingStatus === edstat ? item : []
                :
                item.PublicationStatus === pubstat && item.EditingStatus === edstat ? item : []
        })

        let start = (page - 1) * nperpage;
        let end = start + nperpage;
        let total = temp.length;
        if (end > total)
            end = total;

        let npages = Math.floor((total / nperpage)) + (total % nperpage > 0 ? 1 : 0);

        let res = temp.flatMap((item, index) => {
            return index >= start && index < end ? item : []
        })

        if (res.length > 0) {
            if (npages > 1) {
                /* the algorithm is
                    1. if there are 9 or fewer pages, show them all
                    2. If there are > 9 pages show the current page in the middles with 4 either side, where possible
                */
                let active = page;
                let items = [];

                // we always have a label
                items.push(<div key={"div1"} className="pageslabel"> Pages </div>);

                // we always have a First link and a prev
                items.push(
                    <Pagination.First key={"pag-first"} onClick={() => setPage(1)} />
                )
                if (page == 1) {
                    items.push(<Pagination.Prev disabled key={"pag-prev"} onClick={() => setPage(page - 1)} />)
                }
                else {
                    items.push(<Pagination.Prev key={"pag-prev"} onClick={() => setPage(page - 1)} />)
                }

                // work out the starting page
                let startpage = 1;
                let endpage = startpage + 9 > npages ? npages : startpage + 9;
                if (page > 9) {
                    startpage = page - 4;
                    startpage = startpage + 9 >= npages ? npages - 9 : startpage;
                    endpage = startpage + 9 >= npages ? npages : startpage + 9;
                }

                // show the pages
                for (let number = startpage; number <= endpage; number++) {
                    items.push(
                        <Pagination.Item key={"pag" + number} active={number === active} onClick={() => setPage(number)}>
                            {number}
                        </Pagination.Item>
                    );
                }

                if (endpage < npages) {
                    items.push(
                        <Pagination.Ellipsis key={"pag-e1"} />
                    );

                    items.push(
                        <Pagination.Item key={"pag-end"} onClick={() => setPage(npages)}>
                            {npages}
                        </Pagination.Item>
                    );
                }

                if (page < npages) {
                    items.push(<Pagination.Next key={"pag-next"} onClick={() => setPage(page + 1)} />)
                }

                items.push(
                    <Pagination.Last key={"pag-last"} onClick={() => setPage(npages)} />
                );




                ret.push(
                    <Pagination key={"pagination"}  size="sm">{items}</Pagination>
                )
            }

            ret.push(<SimpleTable
                key={pubstat + edstat + "table"}
                data={res}
                columns={columns}
                disableSort={true}
                rowClick={showDashboard}
            />)
            return ret;
        }
        return null;
    }

    const countlist = (target, pubstat, edstat) => {
        let count = 0;
        for (let i = 0; i < target.length; i++) {
            const item = target[i];
            if (pubstat === item.PublicationStatus && item.EditingStatus === edstat) {
                count++;
            }

        }

        return count;
    }


    const selectList = (index) => {
        setListingTab(index);
        setPage(1);
        console.log(listingtab);
    }


    const makeStack = () => {
        let ret = sections.flatMap((item, index) => {
            return countlist(results.results, item.pubstat, item.edstat) > 0 ?
                <Button key={"burtton + " + index} onClick={() => selectList(index)} variant={listingtab === index ? "primary" : "secondary"}>{item.label} <Badge bg="dark">{countlist(results.results, item.pubstat, item.edstat)}</Badge>
                    <span className="visually-hidden">{item.caption}</span>
                </Button>
                : [];

        })
        return ret;
    }

    const chooseDefault = (temp) => {
        if (listingtab !== undefined) {
            setListingTab(listingtab);
            return;
        }
        let ret = sections.flatMap((item, index) => {
            return countlist(temp.results, item.pubstat, item.edstat) > 0 ?
                index
                : [];

        })
        setListingTab(ret.length ? ret[0] : '');
        //setDefaultTab(ret.length ? ret[0] : '');
    }


    if (loading)
        return <div>LOADING!!</div>
    else if (error !== null)
        return <RecordError errormessage={error} />
    else
        return <Container className="page-outer">

            <Row>
                <HomeBanner img="pexels-engin-akyurt-1435752.jpg" tag="My PROSPERO" />
            </Row>

            <Row xs={1} md={4} className="g-4 pb-2 pt-3">
                <Col key="1">
                    <Button variant="primary" onClick={() => navigate("/reviewcoversheet")}>Create a new PROSPERO record...</Button>
                </Col>
            </Row>

            <h1 className="mt-5">Your PROSPERO registration records {results != null && results.results != null && results.results.length > 0 && <span className="actvieCount">({results.results.length} record{results.results.length === 1 ? "" : "s"})</span>}</h1>
            <Row className="my-4">
                {(results !== null && results.results !== null) && results.results.length > 0 ?
                    <>
                        <Stack className="mb-5" direction="horizontal" gap={2}>
                            {makeStack()}
                        </Stack>

                        {listingtab !== undefined && <>
                            {addList(listingtab)}
                        </>
                        }


                    </>
                    :
                    <>
                        {results == null || results.results === undefined ?
                            <div>Loading your PROSPERO records...</div>
                            :
                            <div>You do not have any PROSPERO records yet.</div>
                        }
                    </>
                }
            </Row>


        </Container>
}

export default MyProspero;