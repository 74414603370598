import React from 'react';
import { Container } from 'react-bootstrap';



const Footer = () => {

    return <div className="footer">
        <div className="footerinner">
            <div className="floatleft">
                <p><a href="#contactuspage" className="ui-link">Contact us</a></p>
                <p><a href="https://www.york.ac.uk/about/legal-statements/#tab-1" target="legal" className="ui-link" rel="noreferrer">Disclaimer</a></p>
                <p><a href="https://www.york.ac.uk/about/legal-statements/accessibility/" target="legal" className="ui-link" rel="noreferrer">Accessibility</a></p>
                <p><a href="https://www.york.ac.uk/about/legal-statements/cookies/" target="legal" className="ui-link" rel="noreferrer">Cookies</a> and <a href="http://www.york.ac.uk/about/legal-statements/#tab-4" target="legal" className="ui-link" rel="noreferrer">Privacy</a></p>
            </div>
            <a className="floatcenter ui-link" href="https://www.york.ac.uk/crd/" target="_blank" rel="noreferrer"></a>
            <div className="floatright">
                <p>Centre for Reviews and Dissemination<br />
                    University of York<br />
                    York, UK<br />
                    YO10 5DD<br />
                </p>
            </div>
        </div>
    </div>
}

export default Footer;