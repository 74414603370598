
import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Row, Col, InputGroup, Check } from 'react-bootstrap';

const RecordOldversion = props => {

    return (
        <div className="texterror">
            <h2 className="color-red">**** There is a version of this section stored locally ****</h2>
            <p>The locally stored version is {props.oldversion.utcLastUpdated > props.newversion.utcLastUpdated ? "older" : "newer"} than the version on the server. What do you want to do?</p>
            <p>&nbsp;</p>
            <p><Button variant="outline-primary" onClick={() => props.useoldversion(false)}  >Discard the locally stored version and use the version on the server</Button></p>
            <p><Button variant="outline-primary" onClick={() => props.useoldversion(true)} >Discard the version on the server and use the locally stored version</Button></p>
        </div>


    )


}

export default RecordOldversion;