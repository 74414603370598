import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../TemplateUtils/Loading';
import RecordSectionRequired from '../TemplateUtils/RecordSectionRequired';
import { Form, Button } from 'react-bootstrap';
import { UseAppContext } from '../../AppContextProvider';
import RecordNavControls from '../TemplateUtils/RecordNavControls';
import RecordIdentifier from '../TemplateUtils/RecordIdentifier';
import RecordCaption2 from '../TemplateUtils/RecordCaption2';
import RecordError from '../TemplateUtils/RecordError';
import RecordOldversion from '../TemplateUtils/RecordOldversion';
import { getKey, Log, getOldVersion, RecordSectionPurposeEdit, getRecordSection, scrollIntoView, showError, checkTempateItem, buildpreview } from '../TemplateUtils/utils.js';

const fname = "TemplateUnpublishedStudies";
const TemplateUnpublishedStudies = props => {
    // ========================================================================
    // the context data store
    // ========================================================================
    const {
        recordData: {
            record
        }
    } = UseAppContext();


    // ========================================================================
    // state variables
    // ========================================================================
    const [data, setData] = useState();                         // the actual section data
    const [showpreview, setShowPreview] = useState(false);      // toggle show/hide the preview
    const [dirty, setDirty] = useState(false);                  // true if anything has changed
    const [error, setError] = useState(null);                   // true if there is an error
    const [checked, setCheck] = useState({});                   // result of checking the content locally (overridden on the server)
    const [showerrors, setShowErrors] = useState(false);        // toggle showing any errors reported by check()
    const [loading, setLoading] = useState(true);               // true if loding data
    const [rerender, setRerender] = useState(0);                // helper to force a rerender
    const [oldversion, setOldversion] = useState(null);         // placeholder for any version that has not been saved 


    // ========================================================================
    // Helper function to get the data for this section from the server
    // ========================================================================
    const getData = async (recordversion, fname) => {
        await getRecordSection(recordversion, fname, RecordSectionPurposeEdit)
            .then((resp) => {
                Log(resp);
                if (resp.status === "error") {
                    setError(resp);
                    setLoading(false);
                }
                else {
                    if(resp.FieldStatus < 100) {
                        buildpreview(resp);
                    }
                    setData(resp);
                    setCheck(checkTempateItem(resp));
                    setLoading(false);
                }
            });
    }


    // ========================================================================
    // Initialsise on first call
    // ========================================================================
    useEffect(() => {
        getOldVersion(record.recordversion, fname);
        getData(record.recordversion, fname);
    }, []);


    // ========================================================================
    // Handle the browser back button
    // ========================================================================
    useEffect(() => {
        const beforeUnLoad = (e) => {
            if (dirty) {
                e.preventDefault();
                e.stopPropagation();
                e.returnValue = "Are you sure you want to leave? Changes will not be saved";
                return "Are you sure you want to leave? Changes will not be saved";
            }
        }


        window.addEventListener('beforeunload', beforeUnLoad);
        return () => {
            window.removeEventListener("beforeunload", beforeUnLoad);
        }
    }, [dirty]);

    // ========================================================================
    // helper function to load or create the data, and perform initial check
    // ========================================================================
    useEffect(() => {
        if (!loading) {
            setDirty(true);
        }
        setCheck(checkTempateItem(data));
    }, [rerender]);


    // ========================================================================
    // Handle going back without saving changes
    // =======================================================================
    const back = () => {
        setDirty(false);
        props.back("list");
        setTimeout(() => {
            window.history.back();
        }, 100)
    }


    // ========================================================================
    // Check if an object element has a particular value
    // ========================================================================
    const isSelected = (item, value) => {
        let tempdata = data;
        let tags = tempdata.content[0].tags;
        if (tags[item] === value) {
            return "checked";
        }
        return null;
    }

    // ========================================================================
    // set the value of an object element
    // ========================================================================
    const setTagValue = (e) => {
        var tempdata = data;
        var mytags = tempdata.content[0].tags;
        let name = e.currentTarget.name;
        let value = e.currentTarget.value;

        if (e.currentTarget.checked) {
            mytags[name] = value;
        }
        else {
            mytags[name] = "";
        }

        let content = tempdata.content;
        content[0].tags = mytags;
        tempdata.content = content;
        buildpreview(tempdata);
        setData(tempdata);
        setRerender(rerender + 1);
    }

    // ========================================================================
    // handle already editing data
    // ========================================================================
    const useoldversion = (option) => {
        if (option) {
            let tempdata = JSON.parse(oldversion);
            tempdata.EditingToken = data.EditingToken;
            setOldversion(null);
            setData(tempdata);
        }
        else {
            setOldversion(null);
        }
    }


    // ========================================================================
    // return the content
    // ========================================================================
    return (<>
        {loading && <LoadingSpinner />}
        {error !== null && <RecordError errormessage={error} />}
        {!showpreview && !loading && (<div id={"scrollto_" + fname} className="page-inner-restrict pb-3 recordfields">
            {error === null && oldversion === null && (
                <><RecordIdentifier variant={data.RecordTemplateVariant} />
                    <RecordCaption2
                        caption={data.caption}
                        variant={data.RecordTemplateVariant}
                        fname={fname}
                    />
                    <div className={"radiobox"}>
                    <h2>Please select from the options below <RecordSectionRequired /></h2>
                        <Form.Group>
                            <Form.Check type={"checkbox"} id={getKey()} >
                                <Form.Check.Input type={"checkbox"} checked={isSelected("publishedstudies", "publishedstudies") == null ? false : true} name="publishedstudies" value="publishedstudies" onChange={(e) => setTagValue(e)} />
                                <Form.Check.Label>We will search for published studies</Form.Check.Label>
                            </Form.Check>

                            <Form.Check type={"checkbox"} id={getKey()} >
                                <Form.Check.Input type={"checkbox"} checked={isSelected("unpublishedstudies", "unpublishedstudies") == null ? false : true} name="unpublishedstudies" value="unpublishedstudies" onChange={(e) => setTagValue(e)} />
                                <Form.Check.Label>We will search for unpublished studies</Form.Check.Label>
                            </Form.Check>
                        </Form.Group>
                    </div>
                    {showError(fname + "_mustchoose", checked, showerrors) && <div id={fname + "_mustchoose"} className="formerror">You must select at least one option above</div>}



                </>)}
            {oldversion !== null && <RecordOldversion oldversion={oldversion} newversion={data} useoldversion={useoldversion} />}
            <RecordNavControls data={data} back={back} preview={setShowPreview} dirty={dirty} cancomplete={checked} setshowerrors={setShowErrors} showerrors={showerrors} error={error === null && oldversion === null ? null : "error"} />
        </div>)}
        {showpreview && (<div className="page-inner-restrict publicdocument">
            <h1>{data.caption}</h1>
            <div dangerouslySetInnerHTML={{ __html: data.preview }} />
            <div><p><br /></p>
                <p><br /></p>
            </div>
            <Button variant="primary" onClick={() => setShowPreview(false)}>Close preview</Button>
        </div>)}


    </>
    )

}

export default TemplateUnpublishedStudies;