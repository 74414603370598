import React, { useState } from 'react';
import { AppContextProvider } from "./AppContextProvider";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './header/header';
import { ErrorBoundary } from 'react-error-boundary';

import Home from './home/home';
import About from './home/about';
import Feedback from './home/feedback';
import HowToRegister from './home/howtoregister';
import Search from './search/search';
import Register from './record/register';
import Preview from './record/preview';
import Help from './help/help';
import MyProspero from './user/myprospero';
import RecordDashboard from './record/recorddashboard';
import Login from './login/login';
import UserDetails from './login/userdetails';

import Footer from './footer/footer';
import UpdatePassword from './login/updatePassword';
import ChooseReviewType from './record/choosereviewtype';
import ReviewCoverSheet from './record/reviewcoversheet';
import AuthorApproval from './record/authorapproval';
import ConfirmUser from './record/confirm';
import PROSPERORecord from './record/prosperorecord';
import {defs} from './api/siteDefinitions';
import { useParams } from "react-router-dom"


import './App.css';


function App() {
  const [recordid, setrecordID] = useState();
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [messageinterval, SetMessageInterval] = useState(0);

  const searchParams = new URLSearchParams(document.location.search)

  const helppage = "test";
  //const currentParams = Object.fromEntries([...searchParams]);
  //  console.log(currentParams); // get new values onchange

console.log(defs);

  // fallback on any unexpected error
  const errorFallback = ({ error }) => {
    console.log("Error fallback", error.message);
    //reload the page
    window.location.reload(false);
  }


  return (<>
    <AppContextProvider>

      {/* <BrowserRouter basename={'/PROSPEROv2'}> */}
      {/*<BrowserRouter basename={defs.default.baseURL}> */}
      <BrowserRouter basename={defs.baseURL}>

        {/* <Sidebar menuCollapse={menuCollapse} setMenuCollapse={setMenuCollapse} /> */}
        {/*<RecordFooter />*/}
        <div id="bodyOuter">
          {!window.location.pathname.startsWith("/view") && <Header menu={1}/>}
          
          <ErrorBoundary FallbackComponent={errorFallback}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/feedback" element={<Feedback />} />
              <Route path="/howtoregister" element={<HowToRegister />} />
              <Route path="/search" element={<Search />} />
              <Route path="/myprospero" element={<MyProspero />} />
              <Route path="/choosereviewtype" element={<ChooseReviewType />} />
              <Route path="/reviewcoversheet" element={<ReviewCoverSheet />} />
              <Route path="/register/:page/preview" element={<Preview />} />

              <Route path="/register" element={<Register />}>
                <Route path=":page" element={<Register />} />
              </Route>
              <Route path="/help" element={<Help />}>
                <Route path=":page" element={<Help />} />
              </Route>
              <Route path="/recorddashboard" element={<RecordDashboard />}>
                <Route path=":recordid" element={<RecordDashboard />} />
              </Route>
              <Route path="/authorapproval" element={<AuthorApproval />}>
                <Route path=":code" element={<AuthorApproval />} />
              </Route>
              <Route path="/confirm" element={<ConfirmUser />}>
                <Route path=":code" element={<ConfirmUser />} />
              </Route>
              <Route path="/login" element={<Login />} />
              <Route path="/join" element={<UserDetails />} />
              <Route path="/passwordreset" element={<UpdatePassword />}></Route>

              <Route path="/view" element={<PROSPERORecord />}>
                <Route path=":page" element={<PROSPERORecord />} >
                  <Route path=":major" element={<PROSPERORecord />} >
                    <Route path=":minor" element={<PROSPERORecord />} >
                    </Route>
                  </Route>
                </Route>
              </Route>

            </Routes>
          </ErrorBoundary>
          {!window.location.pathname.startsWith("/view") && <Footer  menu={1}/>}
        </div>
        {/* <Footer /> */}
      </BrowserRouter>

    </AppContextProvider>
  </>
  );
}

export default App;
