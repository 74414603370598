import React, { useEffect, useState } from 'react';
import { Table, Button, Col, Container, Form, Row, InputGroup, FormControl } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { baseURL,  stripHTML} from '../Template/TemplateUtils/utils.js';

const SearchResultsTable = (props) => {
    const Body = (props) => {
        const [URLbase, setURLbase] = useState("https://www.crd.york.ac.uk/prospero/display_record.php?RecordID=")

        let keyCount = 0;
        const getKey = (root) => {
            return root + "_" + keyCount++;
        }

        const getRecord = (recordid) => {
            window.open(URLbase + recordid, "PROSPERO_RECORD_" + recordid);
        }

        const addKeyword = (kw) => {
            /*
            const collection = document.getElementsByClassName("rbt-input-main");
            if (!navigator.clipboard) {
                var textArea = document.createElement("textarea");
                textArea.value = kw.item;

                // Avoid scrolling to bottom
                textArea.style.top = "0";
                textArea.style.left = "0";
                textArea.style.position = "fixed";

                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();

                try {
                    var successful = document.execCommand('copy');
                    var msg = successful ? 'successful' : 'unsuccessful';
                    console.log('Fallback: Copying text command was ' + msg);
                } catch (err) {
                    console.error('Fallback: Oops, unable to copy', err);
                }

                document.body.removeChild(textArea);
                collection[0].select();
                document.execCommand('paste');
            }
            else {
                navigator.clipboard.writeText(kw.item).then(function() {
                    console.log('Async: Copying to clipboard was successful!');
                    collection[0].select();
                    document.execCommand('paste');
    
                  }, function(err) {
                    console.error('Async: Could not copy text: ', err);
                  });                
            }
            */
        }

        const mapped = (array, highlights) => {
            // count source and target keywords and change style if all match
            const arcount = array.length;
            const hllength = highlights.length;
            let targetcount = 0;
            array.map((item, i) => {
                if (highlights.includes("<highlight>" + item + "</highlight>")) {
                    targetcount++;
                }
            });

            let cname = "highlight";

            if (arcount == hllength && arcount == targetcount) {
                cname = "highlight-all";
            }
            var html = array.map((item, i) => {
                if (highlights.includes("<highlight>" + item + "</highlight>")) {
                    return <span className={cname} key={getKey("hl-")}>{item}; </span>;
                }
                else
                    return <span className="keyword-add" onClick={() => addKeyword({ item })} key={getKey("hl-")}>{item}; </span>;
            });
            return html;

        }

        let ret = [];
        if (props.results != null && props.opts === "keywords") {
            for (var i = 0; i < props.results.hits.length; i++) {
                let hit = props.results.hits[i]._source;
                let highlight = (props.results.hits[i].highlight["keywords.keyword"] != undefined ? props.results.hits[i].highlight["keywords.keyword"] : stripHTML(hit.title));
                let kw = hit.keywords;
                let row = <tr key={getKey("`results-tr-")} >
                    <td key={getKey("`results-td-")} ><span>{stripHTML(hit.title)}</span> [<span className="link" onClick={() => getRecord(hit.recordid)}>CRD{hit.accessionnumber}</span>]<br /><i>Keywords: {mapped(kw, highlight)}</i></td>
                    {props.showtype && <td key={getKey("`results-td-")}>{hit.recordtype}</td>}
                </tr>
                ret.push(row);
            }
        }
        else if (props.results != null) {
            for (var s = 0; s < props.results.hits.length; s++) {
                let hit = props.results.hits[s]._source;
                let row = <tr key={getKey("`results-tr-")} >
                    <td key={getKey("`results-td-")} ><span>{stripHTML(hit.title)}</span> [ <span className="link" onClick={() => getRecord(hit.recordid)}>CRD{hit.accessionnumber}</span> ]</td>
                    {props.showtype && <td key={getKey("`results-td-")}>{hit.recordtype}</td>}
                </tr>
                ret.push(row);
            }
        }
        return ret;
    }

    return <div className={props.setclass}>
        <Table striped bordered hover responsive >
            <thead>
                <tr>
                    <th>Title</th>
                    {props.showtype && <th>Type</th>}
                </tr>
            </thead>
            <tbody>
                <Body results={props.results != null ? props.results : null} opts={props.opts} />
            </tbody>
        </Table>
    </div>

}

export default SearchResultsTable;