import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../TemplateUtils/Loading';
import RecordSectionRequired from '../TemplateUtils/RecordSectionRequired';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { UseAppContext } from '../../AppContextProvider';
import RecordNavControls from '../TemplateUtils/RecordNavControls';
import RecordIdentifier from '../TemplateUtils/RecordIdentifier';
import RecordCaption2 from '../TemplateUtils/RecordCaption2';
import RecordError from '../TemplateUtils/RecordError';
import RecordOldversion from '../TemplateUtils/RecordOldversion';
import RecordHelp from '../TemplateUtils/RecordHelp';
import MEditor from '../TemplateUtils/MEditor';
import { getROBTools, getKey, clearData, Log, getOldVersion, RecordSectionPurposeEdit, getRecordSection, scrollIntoView, showError, cleanHTML, getEditorChecks, checkTempateItem, buildpreview } from '../TemplateUtils/utils.js';
import RecordTimebomb from '../TemplateUtils/RecordTimebomb';


const fname = "TemplateRiskOfBias";
const TemplateRiskOfBias = props => {

    // ========================================================================
    // the context data store
    // ========================================================================
    const {
        recordData: {
            record
        }
    } = UseAppContext();


    // ========================================================================
    // state variables
    // ========================================================================
    const [data, setData] = useState();                         // the actual section data
    const [showpreview, setShowPreview] = useState(false);      // toggle show/hide the preview
    const [dirty, setDirty] = useState(false);                  // true if anything has changed
    const [error, setError] = useState(null);                   // true if there is an error
    const [checked, setCheck] = useState({});                   // result of checking the content locally (overridden on the server)
    const [showerrors, setShowErrors] = useState(false);        // toggle showing any errors reported by check()
    const [loading, setLoading] = useState(true);               // true if loding data
    const [rerender, setRerender] = useState(0);                // helper to force a rerender
    const [oldversion, setOldversion] = useState(null);             // placeholder for any version that has not been saved 
    const [helpitem, setHelpItem] = useState(null);
    const [tools, setTools] = useState(null);
    const [isother, setIsOther] = useState(false);

    //const [israndomized, setIsRandomised] = useState(getSectionTagValue(temprecordfields, "TemplateStudyDesign", "randomized"));
    // const [isnonrandomized, setIsNonRandomised] = useState(getSectionTagValue(temprecordfields, "TemplateStudyDesign", "nonrandomized"));



    const error1 = <><p>It is recommended that systematic reviews include assessment of risk of bias or study quality.</p>
        <p>Not doing so suggests that this might be a scoping review. Please consider whether your review is a systematic review or scoping review.</p>
        <RecordHelp className="smalltext" fname={fname} helpsection="help1" label="What is a scoping review?" />
    </>

    const error2 = <><p>Risk of bias assessment by a single person is not considered best practice.</p>
        <p>Risk of bias assessment blah blah blah.</p>
        <RecordHelp className="smalltext" fname={fname} helpsection="help2" label="What does this mean?" />

    </>

    // ========================================================================
    // Get the RoB tools appropriate for this review type
    // ========================================================================
    const getTools = async () => {
        await getROBTools(record.recordversion)
            .then((resp) => {
                if (resp.errormessage === undefined) {
                    setTools(resp);
                }
                else {
                    setError(resp);
                }

            });
    }



    // ========================================================================
    // Helper function to get the data for this section from the server
    // ========================================================================
    const getData = async (recordversion, fname) => {
        await getRecordSection(recordversion, fname, RecordSectionPurposeEdit)
            .then((resp) => {
                Log(resp);
                if (resp.status === "error") {
                    setError(resp);
                    setLoading(false);
                }
                else {
                    if (resp.FieldStatus < 100) {
                        buildpreview(resp);
                    }
                    setData(resp);
                    setCheck(checkTempateItem(resp));
                    setLoading(false);
                    setTimeout(() => {
                        getTools();
                    }, 400)

                }
            });
    }


    // ========================================================================
    // Initialsise on first call
    // ========================================================================
    useEffect(() => {
        getOldVersion(record.recordversion, fname);
        getData(record.recordversion, fname);
    }, []);


    // ========================================================================
    // Initialsise on first call
    // ========================================================================
    useEffect(() => {
        if (data === undefined) {
            return;
        }
        // set the corresponding rob data object to indicate whether rand or nonrand studies are included
        if (tools !== null && tools[0].Randomised !== undefined) {
            if (data.content[0].tags.robrandomised !== "robrandomised") {
                data.content[0].tags.robrandomised = "robrandomised";
                //                setDirty(true);
                buildpreview(data);
            }
        }
        else {
            if (data.content[0].tags.robrandomised !== "") {
                data.content[0].tags.robrandomised = "";
                data.content[0].lists.list1 = [];
                //              setDirty(true);
                buildpreview(data);
            }
        }

    }, [tools]);



    // ========================================================================
    // Handle the browser back button
    // ========================================================================

    useEffect(() => {
        const beforeUnLoad = (e) => {
            if (dirty) {
                e.preventDefault();
                e.stopPropagation();
                e.returnValue = "Are you sure you want to leave? Changes will not be saved";
                return "Are you sure you want to leave? Changes will not be saved";
            }
        }


        window.addEventListener('beforeunload', beforeUnLoad);
        return () => {
            window.removeEventListener("beforeunload", beforeUnLoad);
        }
    }, [dirty]);



    // ========================================================================
    // helper function to load or create the data
    // ========================================================================
    useEffect(() => {
        if (!loading) {
            setDirty(true);
        }
        // check the status of everything after wach rerender
        setCheck(checkTempateItem(data));
    }, [rerender]);


    // ========================================================================
    // Handle going back without saving changes
    // =======================================================================
    const back = () => {
        setDirty(false);
        props.back("list");
        setTimeout(() => {
            window.history.back();
        }, 100)
    }

    // ========================================================================
    // callback from the editor onblur
    // ========================================================================
    const notifytext = (editorname, editor) => {
        var state = data;
        let content = state.content;
        let c0 = content[0];

        const clean = cleanHTML(editor.datahtml);
        state.preview = clean;
        editor.datahtml = clean;
        c0.editors[editorname] = editor;
        content[0] = c0;
        state.content = content;

        setDirty(true);
        buildpreview(state);
        setData(state);
        setRerender(rerender + 1);
    }

    // ========================================================================
    // get the editor data for the editor
    // ========================================================================
    const getEditorData = (editorname) => {
        if (data != undefined && data !== null) {
            return data.content[0].editors[editorname];
        }
        return null;
    }


    // ========================================================================
    // handle already editing data
    // ========================================================================
    const useoldversion = (option) => {
        if (option) {
            let tempdata = JSON.parse(oldversion);
            tempdata.EditingToken = data.EditingToken;
            setOldversion(null);
            setData(tempdata);
        }
        else {
            setOldversion(null);
        }
    }



    // ========================================================================
    // Check if an object element has a particular value
    // ========================================================================
    const isListSelected = (item) => {
        let tempdata = data;
        let tags = tempdata.content[0].lists.list1;
        for (var i = 0; i < tags.length; i++) {
            if (tags[i] === item) {
                return "checked";
            }

        }
        return null;
    }

    // ========================================================================
    // set the value of an object element
    // ========================================================================
    const setListValue = (type, e) => {
        var tempdata = data;
        let content = tempdata.content[0];

        var list = content.lists.list1;
        let value = e.currentTarget.value;


        if (e.currentTarget.checked) {
            for (var i = 0; i < list.length; i++) {
                if (list[i] === value) {
                    return; // already there
                }
            }
            list.push(value);
            if (value === "Other") {
                setIsOther(true);
            }
        }
        else {
            for (var i = 0; i < list.length; i++) {
                if (list[i] === value) {
                    list.splice(i, 1);
                    if (value === "Other") {
                        setIsOther(false);
                        content.editors.editor1 = {};
                    }
                }
            }
        }

        // sort the array
        list.sort();

        buildpreview(tempdata);
        setData(tempdata);
        setRerender(rerender + 1);
    }



    // ========================================================================
    // set the value of an object element
    // ========================================================================
    const setTagValue = (e) => {
        var tempdata = data;
        let name = e.currentTarget.name;
        let value = e.currentTarget.value;
        let content = tempdata.content[0];
        var mytags = content.tags;

        // ========================================================================
        // if we are changing a top-level selection, cleaqr all data
        // ========================================================================
        if (name === "robtype" && value === "norobtype") {
            tempdata = clearData(tempdata);
        }


        // ========================================================================
        // grab the elements we are interested in
        // ========================================================================
        mytags[name] = value;
        content.tags = mytags;
        tempdata.content[0] = content;
        buildpreview(tempdata);
        setData(tempdata);
        setRerender(rerender + 1);
    }


    // ========================================================================
    // Check if an object element has a particular value
    // ========================================================================
    const isSelected = (key, item) => {
        let tempdata = data;
        let tags = tempdata.content[0].tags;
        if (tags[key] === item) {
            return "checked";
        }
        return null;
    }



    // ========================================================================
    // Check if an object element has a particular value
    // ========================================================================
    const isChecked = (type, item) => {
        let tempdata = data;
        let content = tempdata.content[0];

        var list = content.lists.list1;

        for (var i = 0; i < list.length; i++) {
            if (list[i] === item) {
                return true;
            }
        }
        return null;
    }



    const getlist = (type, relevance) => {
        if (tools !== null) {

            let mapping;
            mapping = tools[0].Randomised;
            let list = mapping.flatMap(function (item) {
                let ret = <Form.Check key={getKey()} type={"checkbox"} id={getKey()} >
                    <Form.Check.Input type={"checkbox"} checked={isChecked(type, item.ToolName + (item.Description !== undefined ? " - " + item.Description : ""), true) == null ? false : true} name="checkbox"
                        value={item.ToolName + (item.Description !== undefined ? " - " + item.Description : "")}
                        onChange={(e) => setListValue(type, e)} />
                    <Form.Check.Label>{item.ToolName + (item.Description !== undefined ? " - " + item.Description : "")}</Form.Check.Label>
                </Form.Check>

                return ret;
            });

            let ret = <Form.Check key={getKey()} type={"checkbox"} id={getKey()} >
                <Form.Check.Input type={"checkbox"} checked={isChecked(type, "Other", true) == null ? false : true} name="checkbox"
                    value={"Other"}
                    onChange={(e) => setListValue(type, e)} />
                <Form.Check.Label>Other</Form.Check.Label>
            </Form.Check>

            list.push(ret);

            if (isChecked("Randomised", "Other" + type, true) !== null && isother === false) {
                setIsOther(true);
            }

            // now turn the list into a grid
            let grid = [];
            for (let i = 0; i < list.length; i = i + 2) {
                if (i < list.length - 1) {
                    grid.push(<Row>
                        <Col>{list[i]}</Col>
                        <Col>{list[i+1]}</Col>
                    </Row>)
                }
                else {
                    grid.push(<Row>
                        <Col>{list[i]}</Col>
                    </Row>)
                }
            }

            return grid;
        }
        else {
            return [];
        }
    }



    // ========================================================================
    // return the content
    // ========================================================================
    return (<>
        {loading && <LoadingSpinner />}
        {error !== null && <RecordError errormessage={error} />}
        {!showpreview && !loading && (<div className="page-inner-restrict pb-3  recordfields">
            {error === null && oldversion === null && (<>
                <RecordIdentifier variant={data.RecordTemplateVariant} />
                <RecordCaption2
                    caption={data.caption}
                    variant={data.RecordTemplateVariant}
                    fname={fname}
                />
                {/*                <RecordHelp fname={fname} helpsection={data.RecordTemplateVariant} label="Click here for help" /> */}
                {isSelected("robtype", "norobtype") === "checked" &&
                    <div className={"radiobox"}>
                        <RecordTimebomb html={error1} />
                    </div>
                }
                <div className={"radiobox"}>
                    <h2>Will risk of bias be assessed? <RecordSectionRequired /></h2>
                    <Form.Group>
                        <Form.Check type="radio" id={getKey()} >
                            <Form.Check.Input type="radio" name="robtype" checked={isSelected("robtype", "robtype") == null ? false : true} defaultValue="robtype" onChange={(e) => setTagValue(e)} />
                            <Form.Check.Label className={isSelected("robtype", "robtype") == null ? "labelnotselected" : "labelselected"}
                            >Risk of bias/study quality will be assessed</Form.Check.Label>
                        </Form.Check>
                        <Form.Check type="radio" id={getKey()} >
                            <Form.Check.Input type="radio" name="robtype" checked={isSelected("robtype", "norobtype") == null ? false : true} defaultValue="norobtype" onChange={(e) => setTagValue(e)} />
                            <Form.Check.Label className={isSelected("robtype", "norobtype") == null ? "labelnotselected" : "labelnotselected"} >Risk of bias/study quality will not be assessed</Form.Check.Label>
                        </Form.Check>
                    </Form.Group>
                    {showError(fname + "_mustchoose2", checked, showerrors) && <div className="formerror">You must select an option.</div>}
                </div>

                {isSelected("robtype", "robtype") === "checked" && <>

                    {tools !== null && tools[0].Randomised !== undefined &&
                        <div className={"radiobox"}>
                            <h2>What tool(s) will you use to evaluate risk of bias or study quality? <RecordSectionRequired /></h2>
                            <RecordHelp className="smalltext" fname={fname} helpsection="help4" label="More information..." />
                            {getlist()}
                            {showError(fname + "_mustchoose5", checked, showerrors) && <div className="formerror">You must select an option.</div>}
                        </div>
                    }
                    {isother &&
                        <div className={"radiobox"}>
                            <h2>Give details of the other risk of bias tool(s) you will use.</h2>
                            <MEditor editorname="editor1" key={`editor`} className={"visible"} notify={notifytext} editordata={getEditorData("editor1")} editorchecks={() => getEditorChecks(data.section, "editor1")} />
                            {showError(fname + "_musthavecontent1", checked, showerrors) && <div className="formerror">You must honour the word count.</div>}
                        </div>
                    }

                    {isSelected("robmethod", "robmethod1") === "checked" &&
                        <div className={"radiobox"}>
                            <RecordTimebomb html={error2} />
                        </div>
                    }

                    <div className={"radiobox"}>
                        <h2>How many people will assess risk of bias or study quality? <RecordSectionRequired /></h2>
                        <Form.Check type="radio" id={getKey()} >
                            <Form.Check.Input type="radio" name="robmethod" checked={isSelected("robmethod", "robmethod2") == null ? false : true} defaultValue="robmethod2" onChange={(e) => setTagValue(e)} />
                            <Form.Check.Label className={isSelected("robmethod", "robmethod2") == null ? "labelnotselected" : "labelselected"}
                            >Studies will be assessed independently by at least two people (or person/machine combination) with a process to resolve differences</Form.Check.Label>
                        </Form.Check>
                        <Form.Check type="radio" id={getKey()} >
                            <Form.Check.Input type="radio" name="robmethod" checked={isSelected("robmethod", "robmethod1m") == null ? false : true} defaultValue="robmethod1m" onChange={(e) => setTagValue(e)} />
                            <Form.Check.Label className={isSelected("robmethod", "robmethod1m") == null ? "labelnotselected" : "labelselected"}
                            >Studies will be assessed by one person (or a machine) and checked by at least one other person (or machine)</Form.Check.Label>
                        </Form.Check>
                        <Form.Check type="radio" id={getKey()} >
                            <Form.Check.Input type="radio" name="robmethod" checked={isSelected("robmethod", "robmethod1") == null ? false : true} defaultValue="robmethod1" onChange={(e) => setTagValue(e)} />
                            <Form.Check.Label className={isSelected("robmethod", "robmethod1") == null ? "labelnotselected" : "labelselected"}
                            >Studies will be assessed by one person (or machine) only</Form.Check.Label>
                        </Form.Check>

                    </div>
                    {showError(fname + "_mustchoose4", checked, showerrors) && <div className="formerror">You must select an option.</div>}


                    <div className={"radiobox"}>
                        <Form.Group>
                            <h2>Will additional information be sought if required information is unclear or unavailable in the study publications/reports <RecordSectionRequired /></h2>
                            <Form.Check type="radio" id={getKey()} >
                                <Form.Check.Input type="radio" name="robadditional" checked={isSelected("robadditional", "robadditional") == null ? false : true} defaultValue="robadditional" onChange={(e) => setTagValue(e)} />
                                <Form.Check.Label className={isSelected("robadditional", "robadditional") == null ? "labelnotselected" : "labelselected"}
                                >Yes, additional information will be sought from study investigators</Form.Check.Label>
                            </Form.Check>
                            <Form.Check type="radio" id={getKey()} >
                                <Form.Check.Input type="radio" name="robadditional" checked={isSelected("robadditional", "norobadditional") == null ? false : true} defaultValue="norobadditional" onChange={(e) => setTagValue(e)} />
                                <Form.Check.Label className={isSelected("robadditional", "norobadditional") == null ? "labelnotselected" : "labelselected"}
                                >No, additional information will not be sought from study investigators</Form.Check.Label>
                            </Form.Check>
                        </Form.Group>
                        {showError(fname + "_mustchoose3", checked, showerrors) && <div className="formerror">You must select an option.</div>}

                    </div>


                </>
                }


            </>)}
            {oldversion !== null && <RecordOldversion oldversion={oldversion} newversion={data} useoldversion={useoldversion} />}
            <RecordNavControls data={data} back={back} preview={setShowPreview} dirty={dirty} cancomplete={checked} setshowerrors={setShowErrors} showerrors={showerrors} error={error === null && oldversion === null ? null : "error"} />
        </div>)
        }
        {
            showpreview && (<div className="page-inner-restrict pb-3 publicdocument">
                <h1>{data.caption}</h1>
                <div dangerouslySetInnerHTML={{ __html: data.preview }} />
                <div><p><br /></p>
                    <p><br /></p>
                </div>
                <Button variant="primary" onClick={() => setShowPreview(false)}>Close preview</Button>
            </div>)
        }


    </>
    )

}


export default TemplateRiskOfBias;