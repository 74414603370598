import React, { useState, useEffect, lazy } from 'react';
import SlidingPane from "react-sliding-pane";
import {helptext} from "../TemplateUtils/RecordHelpText";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { RiQuestionLine } from 'react-icons/ri';


const RecordHelp = props => {
    const [isOpen, setIsOpen] = useState(false);
    const [helpfile, setHelpFile] = useState(props.helpfile);
    const [fname, setFname] = useState(props.fname == undefined ? null : props.fname);
    const [helpsection, setHelpSection] = useState(props.helpsection);
    const [helphtml, setHelpHtml] = useState(null);

    // ========================================================================
    // if the suggested text != the existing text, dis[play]
    // ========================================================================    

    const openHelp = () => {
        if (fname === null) {
            fetch("/helpdocs/" + helpfile + ".html")
                .then((response) => response.text())
                .then((html) => {
                    var parser = new DOMParser();

                    // Parse the text
                    var doc = parser.parseFromString(html, "text/html");
                    //            setHelpHtml(<div dangerouslySetInnerHTML={{ __html: html }} />);
                    var docArticle = doc.querySelector('body').innerHTML
                    setHelpHtml(<div dangerouslySetInnerHTML={{ __html: docArticle }} />);
                    setIsOpen(true);
                })
                .catch((error) => { console.warn(error); });
        }
        else {
            const text = helptext[fname][helpsection].helphtml;
            setHelpHtml(text);
            setIsOpen(true);
        }
    }

    return <>
        <span className={"record-help-span " + (props.className != undefined ? props.className : "")}  onClick={() => openHelp()}>{props.label}</span>
        {isOpen &&
            <SlidingPane
                className="record-help-panel"
                closeIcon={<div className="closehelp">Close help</div>}
                overlayClassName="record-help-panel-overlay"
                isOpen={isOpen}
                width="30%"
                title={"Help"}
                //subtitle="Optional subtitle."
                onRequestClose={() => {
                    setIsOpen(false);
                }}
            >
                {helphtml}
            </SlidingPane>

        }
    </>
}

export default RecordHelp;