import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, InputGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from './Loading';
import { cleanHTML, getKey, showError, stripHTML, getEditorChecks, checkTempateSubItem, joinup } from './utils.js';
import { confirm } from 'react-bootstrap-confirmation';
import MEditor from '../TemplateUtils/MEditor';
import { RiMailSendLine } from 'react-icons/ri';
import { RiCheckLine } from 'react-icons/ri';
import { RiEqualLine } from 'react-icons/ri';
import { RiCloseLine } from 'react-icons/ri';
import Country from '../TemplateUtils/Country';
import RecordHelp from '../TemplateUtils/RecordHelp';
import RecordSectionRequired from '../TemplateUtils/RecordSectionRequired';
import RecordSectionOptional from '../TemplateUtils/RecordSectionOptional';
import { BsNutFill } from 'react-icons/bs';
import ModalBox from '../../Template/TemplateUtils/Modal';


// ========================================================================
// Module defs
// ========================================================================
const fname = "MyDetails";

const MyDetails = props => {
    const [itemid, setItemId] = useState(props.itemid);
    const [data, setData] = useState();
    const [error, setError] = useState();
    const [validation, setValidation] = useState({});
    const [loading, setLoading] = useState(true);
    const [rerender, setRerender] = useState(0);
    const [checked, setCheck] = useState(0);
    const [checkedsave, setCheckedSave] = useState(0);
    const [goBack, setGoBack] = useState(false);
    const [dirty, setDirty] = useState(false);
    const [deleteperson, setDeletePerson] = useState(false);
    const [showerrors, setShowErrors] = useState(true);
    const [notok, setNotOk] = useState(false);
    const [passwordmatch, setPasswordMatch] = useState(false);
    const [agreetoterms, setAgreeTerms] = useState(false);
    const [accountcreated, setAccountCreated] = useState(false);
    const [deletethisversion, setDeleteThisVersion] = useState(false);

    // ========================================================================
    // generate local keys (repeatable across renders)
    // ========================================================================

    const navigate = useNavigate();


    // ========================================================================
    // helper function to load or create the data
    // ========================================================================
    useEffect(() => {
        setData(props.data);
        setLoading(false);
        setCheckedSave(0);
    }, []);

    useEffect(() => {
        if (data != null) {
            setLoading(false);
        }
    }, [data]);


    // ========================================================================
    // helper function to set the dirty flag
    // ========================================================================
    const onChange = () => {
        setCheckedSave(0);
        setDirty(true);
    }


    // ========================================================================
    // helper function to set the dirty flag
    // ========================================================================
    const onChangeEmail = (e) => {
        onChange();
        if (!props.isemailok(e.currentTarget.value)) {
            setNotOk(true);
        }
        else {
            setNotOk(false);
        }
    }


    // ========================================================================
    // helper function to set the dirty flag
    // ========================================================================
    const onChangePassword = (e) => {
        onChange();
    }

    // ========================================================================
    // helper function to set the dirty flag
    // ========================================================================
    const onChangeRetypePassword = (e) => {
        onChange();
        if (data.password === e.currentTarget.value) {
            setPasswordMatch(true);
        }
        else {
            setPasswordMatch(false);
        }
    }


    const passwordok = (password) => {
        // GD 2023-02-15
        if (password === undefined)
            return true;

        let uc = /[A-Z]/;
        let lc = /[a-z]/;
        let num = /[0-9]/;
        let sc = /[#?!@$%^&*-\.]/;
        let len = /^.{8,16}$/
        let test = new Set([
            uc.test(password),
            lc.test(password),
            num.test(password),
            sc.test(password),
            len.test(password)
        ]);

        return !test.has(false);
    }
    // ========================================================================
    // Helper to handle the form data
    // ========================================================================  
    const handleSubmit1 = async () => {
        // grab the values
        let email = stripHTML(document.getElementById(itemid + "validationCustomUsername").value).trim();
        let orcid = stripHTML(document.getElementById(itemid + "validationCustomOrcid").value).trim();
        let title = stripHTML(document.getElementById(itemid + "validationCustomTitle").value).trim();
        let firstname = stripHTML(document.getElementById(itemid + "validationCustomFirstname").value).trim();
        let lastname = stripHTML(document.getElementById(itemid + "validationCustomLastname").value).trim();
        let org = stripHTML(document.getElementById(itemid + "validationCustom03").value).trim();
        let country = stripHTML(document.getElementById(itemid + "validationCustom04").value).trim();
        let password = 'Asdasdasd2.';
        let retypepassword = 'Asdasdasd2.';
        if (props.password !== undefined && props.password === 'yes') {
            password = stripHTML(document.getElementById(itemid + "validationPassword").value).trim();
            retypepassword = stripHTML(document.getElementById(itemid + "validationRetypePassword").value).trim();
        }

        // grab the data
        let state = data;

        // save the data
        state = { ...state, email: email, orcid: orcid, firstname: firstname, lastname: lastname, organisation: org, country: country, title: title, password: password, retypepassword: retypepassword };

        // grab the email check result
        let emailok = data.emailok;

        // if the email is different from the save email re-check it
        setLoading(true);
        if (email !== data.email || emailok === false) {
            emailok = await checkTempateSubItem("Email", email)
                .then((resp) => {
                    return resp;
                })
                .catch((response) => {
                    emailok = false;
                });
            // save the data and add a date the email was checked
            let date = new Date();
            state = { ...state, emailchecked: date, emailok: emailok };
        }
        setLoading(false);

        // check the content of this form locally for the UI
        let ret = [];
        ret = checkTempateSubItem("Contact", state);

        if (emailok !== undefined && !emailok) {
            ret.ok = false
            ret.formerrors.email = true;
        }
        if (ret.ok === false) {
            state.status = false;
        }
        else {
            state.status = true;
        }
        setCheck(ret);

        // add an error to the returned errors if the email was flagged incorrect above
        let ferrs = ret.formerrors;
        if (ferrs === undefined || ferrs === null) {
            ferrs = {};
        }

        if (emailok === false) {
            ferrs = { ...ferrs, email: !emailok };
        }

        state = { ...state, password: password };

        if (!passwordok(password)) {
            ferrs = { ...ferrs, password: true };
        }
        if (password !== undefined && password !== '' && password !== retypepassword) {
            ferrs = { ...ferrs, retypepassword: true };
        }


        // save everything
        setValidation(ferrs);
        setData({ ...state });

        if (Object.keys(ferrs).length > 0) {
            setCheckedSave(0);
            setDirty(true);
        }
        else if (agreetoterms && checkedsave !== 2) {
            setCheckedSave(2);
        }
        else if (checkedsave !== 3 && props.password !== undefined && props.password === 'no') {
            props.save(state);
        }
    };


    const joinnow = async () => {
        if (data === undefined || data.email === undefined) {
            setCheckedSave(0);
            setDirty(true);
            return;
        }

        let ret = await joinup(data)
            .then((resp) => {
                if (resp.status === "error") {
                    setError(resp);
                    setDeleteThisVersion(true);
                }
                else {
                    setAccountCreated(true);
                }
            })
            .catch((resp) => {
                setError(resp);
                setDeleteThisVersion(true);
            });
    }

    // ========================================================================
    // Check if an object element has a particular value
    // ========================================================================
    const isSelected = (key, item) => {
        if (data[key] === item) {
            return "checked";
        }
        return null;
    }


    // ========================================================================
    // set the value of an object element
    // ========================================================================
    const setTagValue = (e) => {
        let value = e.currentTarget.value;
        data.conflictyn = value;
        onChange();
        setRerender(rerender + 1);
    }

    // ========================================================================
    // ========================================================================
    // callback from the editor onblur
    // ========================================================================
    const notifytext = (editorname, editor) => {
        let c0 = data;

        const clean = cleanHTML(editor.datahtml);
        editor.datahtml = clean;
        c0.editors[editorname] = editor;
    }

    // ========================================================================
    // get the editor data for the editor
    // ========================================================================
    const getEditorData = (editor) => {
        if (data != undefined && data !== null) {
            return data.editors[editor];
        }
        return null;
    }


    if (props.display === "inline") {
        return <>
            <div className="contact_inline">
                {data.organisation === undefined ? "" : <span className="contact_org">{data.organisation}</span>}
                {data.country === undefined || data.country === "" ? "" : <span className="contact_country"> ({data.country})</span>}

            </div>
        </>
    }

    // ========================================================================
    // Render
    // ========================================================================
    return <>
        {loading && <LoadingSpinner text="Checking email..." />}
        {!loading && data != undefined && <>
            <h1 id={"scrollto_" + fname}>User details</h1>

            <div className={""}>
                <Form>
                    <Row >
                        <Form.Group key={"f100"} as={Col} md="3" controlId={itemid + "validationCustomTitle"}>
                            <Form.Label className="pt-3">Title <RecordSectionRequired /></Form.Label>
                            <Form.Control
                                key={"f101"}
                                as="select"
                                type="select"
                                name="title"
                                isInvalid={validation.title === undefined ? false : validation.title}
                                defaultValue={data.title === undefined ? "" : data.title}
                                onChange={onChange}
                            >
                                <option value="">None</option>
                                <option value="Mr">Mr</option>
                                <option value="Mrs">Mrs</option>
                                <option value="Miss">Miss</option>
                                <option value="Ms">Ms</option>
                                <option value="Dr">Dr</option>
                                <option value="Professor">Professor</option>
                                <option value="Assistant Professor">Assistant Professor</option>
                                <option value="Associate Professor">Associate Professor</option>
                                <option value="Mx">Mx</option>
                                <option value="Misc">Misc</option>
                                <option value="Ind">Ind</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">Please enter a title</Form.Control.Feedback>
                        </Form.Group>


                        <Form.Group key={"f110"} as={Col} md="3" controlId={itemid + "validationCustomFirstname"}>
                            <Form.Label className="pt-3">First name <RecordSectionRequired /></Form.Label>
                            <Form.Control
                                key={"f111"}
                                type="text"
                                placeholder="First name"
                                isInvalid={validation.firstname === undefined ? false : validation.firstname}
                                defaultValue={data.firstname === undefined ? "" : data.firstname}
                                onChange={onChange}

                            />
                            <Form.Control.Feedback type="invalid">Please enter a first name</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group key={"f120"} as={Col} md="3" controlId={itemid + "validationCustomLastname"}>
                            <Form.Label className="pt-3">Last name <RecordSectionRequired /></Form.Label>
                            <Form.Control
                                key={"f121"}
                                type="text"
                                placeholder="Last name"
                                isInvalid={validation.lastname === undefined ? false : validation.lastname}
                                defaultValue={data.lastname === undefined ? "" : data.lastname}
                                onChange={onChange}

                            />
                            <Form.Control.Feedback type="invalid">Please enter a lsat name</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group key={"f130"} as={Col} md="5" controlId={itemid + "validationCustomUsername"}>
                            <Form.Label className="pt-3">Email (use institutional email if possible) <RecordSectionRequired /></Form.Label>
                            {notok && <div className="texterror">This email address is already in use</div>}
                            <InputGroup hasValidation>
                                <Form.Control
                                    key={"f131"}
                                    type="email"
                                    autoComplete="new-password"
                                    autofill="nope"
                                    placeholder="email address"
                                    isInvalid={validation.email === undefined ? false : validation.email}
                                    defaultValue={data.email === undefined ? "" : data.email}
                                    onChange={onChangeEmail}


                                />
                                <Form.Control.Feedback type="invalid">
                                    Unable to verify this email address. Please check before saving.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>

                        <Form.Group key={"f140"} as={Col} md="3" controlId={itemid + "validationCustomOrcid"}>
                            <Form.Label className="pt-3">ORCID <RecordSectionOptional /></Form.Label>
                            <Form.Control
                                key={"f141"}
                                type="text"
                                placeholder="ORCID"
                                isInvalid={validation.orcid === undefined ? false : validation.orcid}
                                defaultValue={data.orcid === undefined ? "" : data.orcid}
                                onChange={onChange}

                            />
                            <Form.Control.Feedback type="invalid">That does not look like an ORCID. Please check.</Form.Control.Feedback>
                        </Form.Group>



                        <Form.Group key={"f150"} as={Col} md="6" controlId={itemid + "validationCustom03"}>
                            <Form.Label className="pt-3">Institution/Organisation <RecordSectionRequired /></Form.Label>
                            <Form.Control
                                key={"f151"}
                                type="text"
                                placeholder="Institution/Organisation"
                                isInvalid={validation.organisation === undefined ? false : validation.organisation}
                                defaultValue={data.organisation === undefined ? "" : data.organisation}
                                onChange={onChange}


                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide an Institution or Organisation.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group key={"f160"} as={Col} md="3" controlId={itemid + "validationCustom04"}>
                            <div className="pt-3">
                                <Country country={data.country} validation={validation} onChange={onchange} />
                            </div>
                        </Form.Group>

                        {props.password !== undefined && props.password === 'yes' && <>
                            <Form.Group key={"f170"} as={Col} md="6" controlId={itemid + "validationPassword"}>
                                <Form.Label className="pt-3">Password <RecordSectionRequired /></Form.Label>
                                <Form.Control
                                    key={"f161"}
                                    type="password"
                                    placeholder="password"
                                    isInvalid={validation.password === undefined ? false : validation.password}
                                    defaultValue={data.password === undefined ? "" : data.password}
                                    onChange={onChangePassword}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Password is invalid
                                </Form.Control.Feedback>

                            </Form.Group>

                            <Form.Group key={"f180"} as={Col} md="6" controlId={itemid + "validationRetypePassword"}>

                                <Form.Label className="pt-3">Retype password <RecordSectionRequired /></Form.Label>
                                <Form.Control
                                    key={"f171"}
                                    type="password"
                                    placeholder="password"
                                    autoComplete="new-password"
                                    isInvalid={validation.retypepassword === undefined ? false : validation.retypepassword}
                                    defaultValue={data.retypepassword === undefined ? "" : data.retypepassword}
                                    onChange={onChangeRetypePassword}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Passwords do not match
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group key={"f190"} as={Col} md="20" controlId={itemid + "validationPasswordText"}>
                                <p className="helptextinline mt-3">Passwords must be between 8 and 16 characters long, contain a mixture of upper- and lower-case characters, at least one number and at least one non-alphanumeric character</p>
                            </Form.Group>
                        </>}

                    </Row>
                    {props.password !== undefined && props.password === 'yes' && <>
                        <Row className="mt-5">
                            <Form.Group key={"f200"} as={Col} md="10" controlId={itemid + "validationTerms"}>
                                <Form.Check type={"checkbox"}  >
                                    <Form.Check.Input type={"checkbox"} name="terms"
                                        value={agreetoterms}
                                        checked={agreetoterms}
                                        onChange={(e) => setAgreeTerms(e.target.checked)}
                                    />
                                    <Form.Check.Label>I have read and understand the <a href="help/terms" target="terms">Terms and Conditions</a> and agree to abide by them</Form.Check.Label>
                                </Form.Check>
                            </Form.Group>
                        </Row>
                    </>}
                </Form>
            </div>

            <div className="recordnav mt-3">
                {notok === false && <div className="mt-3">
                    {checkedsave === 0 && <Button key={"f500"} type="button" variant="primary" onClick={handleSubmit1}>Check details</Button>}
                    {checkedsave === 2 && <Button key={"f700"} type="button" variant="primary" onClick={joinnow}>Details OK - join now?</Button>}
                    {props.password != undefined && props.password === "no" && <Button key={"f7u00"} type="button" variant="secondary" onClick={() => props.save()}>Cancel</Button>}
                </div>}
            </div>
            {deletethisversion &&
                <ModalBox show={deletethisversion} heading={"User creation error"} body={error.errormessage.errormessage} setclose={setDeleteThisVersion} />
            }
            {accountcreated &&
                <ModalBox show={accountcreated} heading={"User creation error"} body={"The account has been created and an email sent to the email address for confirmation. The account must be confirmed within 24 hours."} ok={() => navigate('/login')} setclose={setAccountCreated} />
            }

        </>}


    </>;

}


export default MyDetails;