import { prosperoClient } from '../api/axios';
import { axiosoptions } from '../Template/TemplateUtils/utils';

const getBaseTerm = () => {
    return {bool : {
        must : [],
        must_not : [],
        should : [],
    }};
}

// run a search on the server
export const postSearch = async (search, myterm) => {    
    // if no term passed in then create a base one
    if(myterm === undefined) {
        myterm = getBaseTerm();
    
        myterm.bool.must[0] = {
            query_string: {
                "query": (search.term === "" ? "*" : search.term),
                "fields": (search.field !== undefined) ? [...search.field] : ['title']
            }
        };    
    } else {
        let temp = myterm.bool.must;
        temp.push({
            query_string: {
                "query": (search.term === "" ? "*" : search.term),
                "fields": (search.field !== undefined) ? [...search.field] : ['title']
            }
        });

        myterm.bool.must = temp;
    }
                
    const params = {
        term : myterm
    };    

    console.log("SEARCH", params);
            
    return await prosperoClient.post('record/runsearch', params, axiosoptions);

}