import React, { useState } from 'react';
import { Row, Container, Col, Button, Form, FloatingLabel, Spinner } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';

import Header from '../header/header';
import ResetPassword from './resetPassword';

import { postLoginAuthentication } from '../api/login.api';
import { UseAppContext } from '../AppContextProvider';
import { defs } from '../api/siteDefinitions';

import './login.css';

const Login = () => {
    const [resetPassword, setResetPassword] = useState(false);

    return <Container className="page-outer">
        <Row className="mt-3">
            <Col md="6" sm="12" className="d-flex justify-content-center">
                <div className="login-outer">
                    {(resetPassword)
                        ? <ResetPassword setResetPassword={setResetPassword} />
                        : <UserLogin setResetPassword={setResetPassword} />
                    }
                </div>
            </Col>
            <Col>
                <img id="loginImg" src={defs.publicURL + "splashing-275950_1280.jpg"} alt={"Login"} />
            </Col>
        </Row>
    </Container>
}

const UserLogin = ({ setResetPassword }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [failed, setFailed] = useState("");
    const [authenticating, setAuthenticating] = useState(false);
    const [validated, setValidated] = useState(false);
    const navigate = useNavigate();

    const {
        userData: {
            setUser
        },
        setLogin
    } = UseAppContext();

    // check that the email address is valid
    const checkEmail = () => {
        const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        return email.match(isValidEmail);
    }

    // authenticates the user details entered on the server
    const handleSubmit = async e => {
        e.preventDefault();

        setFailed("");
        const form = e.currentTarget;
        if (form.checkValidity() === false || password === '') {
            // form details are invalid so show errors
            e.stopPropagation();
        } else {
            setAuthenticating(true);
            try {
                // attempt to authenticate on server
                const data = await postLoginAuthentication({
                    email,
                    password
                });

                if (data.status === 500) {
                    setFailed(data.errormessage);

                }
                else {

                    // store the user details                   
                    setLogin(data);
                    // set the user login details
                    setUser(data);

                    //return to the home screen
                    navigate('/home');
                }
            } catch (err) {
                if (err.response !== undefined && err.response.data !== undefined)
                    setFailed(err.response.data.error);
            }
            setAuthenticating(false);
        }
        setValidated(true);
    }


    // check for error authenticating 
    const Failed = () => {
        if (failed === '')
            return <></>;

        switch (failed) {
            case "PASSWORD": // incorrect credentials
                return <div aria-live="assertive" className="login-error" >The user credentials are in correct. Please try again.</div>;
            case "USERLOCKED": // incorrect credentials
                return <div aria-live="assertive" className="login-error" >The account has been locked as too many incorrect login attempts have been entered. Please try again in 5 minutes.</div>;
            default:
                return <div aria-live="assertive" className="login-error" >We have been unable to authenticate your credentials. Please try again.</div>;
        }
    }

    return <>
        <h2>Welcome back to PROSPERO</h2>
        <p>Please enter your details</p>

        <div className="py-3 px-5">
            <Button id="loginOrcid" onClick={() => { alert("Link to the ORCID authentication") }}>
                <img height="22" width="22" src={defs.publicURL + "ORCID.png"} alt={"ORCID"} />
                <span className="ms-2">Log in with ORCID</span>
            </Button>

        </div>

        <div className="login-break"><span>or</span></div>

        <div className="pt-3">
            <Form noValidate onSubmit={handleSubmit}>
                <Form.Group className="login-form-group">
                    <FloatingLabel
                        controlId="loginEmail"
                        label="Email"
                        className="mb-3"
                    >
                        <Form.Control
                            type="email"
                            placeholder="name@example.com"
                            className="login-input"
                            onInput={e => setEmail(e.target.value)}
                            isInvalid={validated && !checkEmail()}
                            required />
                        <Form.Control.Feedback type="invalid">Please provide a valid email.</Form.Control.Feedback>
                    </FloatingLabel>

                </Form.Group>

                <Form.Group className="login-form-group">
                    <FloatingLabel controlId="loginPassword" label="Password">
                        <Form.Control
                            type="password"
                            placeholder="Password"
                            className="login-input"
                            onInput={e => setPassword(e.target.value)}
                            isInvalid={validated && password === ''}
                            required />
                        <Form.Control.Feedback type="invalid">Please enter a password.</Form.Control.Feedback>
                    </FloatingLabel>
                </Form.Group>

                <Button id="forgottenPassword" onClick={() => { setResetPassword(true) }}>Forgotten password?</Button>

                <div className="form-btn-outer">
                    <Button type="submit" className="full-width" disabled={authenticating}>
                        <>Log in</>
                        {(authenticating)
                            ? <Spinner animation="grow" />
                            : <></>
                        }
                    </Button>
                    <Failed />
                </div>

                <div className="mt-3">
                    <p className="text-center">Don't have an account? <Link to="/join">Join us today</Link></p>
                </div>
            </Form>
        </div>
    </>
}

export default Login