
import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Row, Col, InputGroup, Check } from 'react-bootstrap';

const RecordError = props => {
    let initialstate;
    let initalcode = 0;
    let more;
    if (props.errormessage !== undefined && props.errormessage.code !== undefined) {
        switch (props.errormessage.code) {
            case 'ERR_NETWORK':
                initialstate = "Network error";
                break;
            case 'ECONNABORTED':
                initialstate = "Request timed out";
                break;
            default:
                //                initialstate = props.errormessage.response.data.message;
                initialstate = props.errormessage.message;
                initalcode = props.errormessage.code;
        }

    }
    else if (props.errormessage !== undefined && props.errormessage.errorcode !== undefined) {
        switch (props.errormessage.errorcode) {
            case 'ERR_NETWORK':
                initialstate = "Network error"; break;
            case 'ECONNABORTED':
                initialstate = "Request timed out"; break;
            default:
                initialstate = props.errormessage.errormessage;
                initalcode = props.errormessage.errorcode;

        }

    }
    else {
        initialstate = props.errormessage;

    }

    if (props.errormessage !== undefined && props.errormessage.data !== undefined) {
        more = props.errormessage.data;
    }
    const [message, setMessage] = useState(initialstate);
    const [code, setCode] = useState(initalcode);
    const [showmore, setShowMore] = useState(false);

    useEffect(() => {
        // Update the document title using the browser API

        let nodes = document.getElementsByClassName("resolve");
        for (let i = 0; i < nodes.length; i++) {
            //create pointer outside of the onclick event allowing closure
            let href = nodes[i].getAttribute('resolve')

            nodes[i].addEventListener('click', (e) => {
                alert("Effect");
            })

            //remove the custom attribute to cleanup
            nodes[i].removeAttribute('resolve')
        }
    }, []);

    const f1 = () => {
        props.f1();
    }

    return (
        <div className={" texterror "}>
            <div className="texterrorheader">{message + " [" + initalcode + "]"}
                {more ? showmore ? <span className="smallclick" onClick={() => setShowMore(false)}> show less...</span> : <span className="smallclick" onClick={() => setShowMore(true)}> show more...</span> : null}
            </div>
            {(showmore && more) &&
                <div className="texterrormore">
                    <div dangerouslySetInnerHTML={{ __html: more }} />
                    {props.errormessage.type === 'yes' && (
                        <Button variant="outline-primary" onClick={() => f1()} >Yes</Button>
                    )}
                    {/*}
                <p>A error has occurred that is preventing the current operation from completing. Here are some things you can do to resolve the problem.</p>
                    <ul>
                    <li>Check your network...</li>
                    <li>Do something else...</li>
                    <li>Do something else again...</li>
                    </ul>
                <p>If all else fails, please send an email to <a href="mailto://blah@blah.com">blah@blah.com</a> quoting the error ID in the message above.</p>
            */}
                </div>
            }
        </div>

    )


}

export default RecordError;