import React, { useEffect, useState } from 'react';
import { Row, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Header from '../header/header';
import HomeBanner from '../home/homebanner';
import RecordTriage from './recordtriage';
import { useParams } from "react-router-dom"

const HowToRegister = () => {   
    const [data, setData] = useState({});
    const [testData, setTestData] = useState({});

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    
    let params = useParams()

    
  
    if (loading) 
        return <div>LOADING!!</div>    
    else if(error)
        return <div>ERROR</div>
    else    
        return <Container className="page-outer"> 

            <Row>
                <HomeBanner img="4k-gf58fb1b71_1920.jpg" tag="Register a review"/>
            </Row>
            <RecordTriage params={params}/>
        </Container>
}

export default HowToRegister;