import React, { useEffect, useState } from 'react';
import { Row, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Header from '../header/header';

const About = () => {   
    const [data, setData] = useState({});
    const [testData, setTestData] = useState({});

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    
    const navigate = useNavigate();
  
    if (loading) 
    return <div>LOADING!!</div>    
else if(error)
    return <div>ERROR</div>
else
    return <Container className="page-outer"> 

            <Row>
            </Row>
            <Row>
            <div> About </div>
            </Row>
        </Container>
}

export default About;