import { fetchoptions, wordCount, checkEditorContent } from './utils.js';
import {
    EditorState,
    convertFromRaw,
} from 'draft-js';
import isTaxID from 'validator/lib/isTaxID.js';
import { defs } from '../../api/siteDefinitions';


// ============================================================
// Class to do the checking, one clause per section
// ============================================================
class Check {

    constructor(definedwordcounts) {
        this.definedwordcounts = definedwordcounts;
    }

    // ============================================================
    // TemplateTitle
    // ============================================================
    TemplateTitle(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // now check the word count
        // this field is not mandatory, but if completed min and max wordcounts apply
        let editor = content.editors.editor1;
        if (!checkEditorContent(editor, "editor1", templatesection)) {
            ret.ok = false;
            ret.errors.push(templatesection + "_musthavecontent1");
        }
        return ret;
    }


    // ============================================================
    // TemplateOriginalTitle
    // ============================================================
    TemplateOriginalTitle(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // now check the word count
        // this field is not mandatory, but if completed min and max wordcounts apply
        let editor = content.editors.editor1;
        if (!checkEditorContent(editor, "editor1", templatesection)) {
            ret.ok = false;
            ret.errors.push(templatesection + "_musthavecontent1");
        }
        return ret;
    }


    // ============================================================
    // TemplatereviewQuestion
    // ============================================================
    TemplateReviewQuestion(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // now check the word count
        // this field is not mandatory, but if completed min and max wordcounts apply
        let editor = content.editors.editor1;
        if (!checkEditorContent(editor, "editor1", templatesection)) {
            ret.ok = false;
            ret.errors.push(templatesection + "_musthavecontent1");
        }
        return ret;
    }

    // ============================================================
    // TemplateSameAuthor
    // ============================================================
    TemplateSameAuthor(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // now check the word count
        // this field is not mandatory, but if completed min and max wordcounts apply
        let editor = content.editors.editor1;
        if (!checkEditorContent(editor, "editor1", templatesection)) {
            ret.ok = false;
            ret.errors.push(templatesection + "_musthavecontent1");
        }
        return ret;
    }


        // ============================================================
    // TemplateOtherRegDetails
    // ============================================================
    TemplateOtherRegDetails(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // now check the word count
        // this field is not mandatory, but if completed min and max wordcounts apply
        let editor = content.editors.editor1;
        if (!checkEditorContent(editor, "editor1", templatesection)) {
            ret.ok = false;
            ret.errors.push(templatesection + "_musthavecontent1");
        }
        return ret;
    }


    // ============================================================
    // TemplateExtractionLegacy
    // ============================================================
    TemplateDataExtractionLegacy(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // now check the word count
        // this field is not mandatory, but if completed min and max wordcounts apply
        let editor = content.editors.editor1;
        if (!checkEditorContent(editor, "editor1", templatesection)) {
            ret.ok = false;
            ret.errors.push(templatesection + "_musthavecontent1");
        }
        return ret;
    }
    // ============================================================
    // TemplateContext
    // ============================================================
    TemplateContext(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // now check the word count
        // this field is not mandatory, but if completed min and max wordcounts apply
        let editor = content.editors.editor1;
        if (!checkEditorContent(editor, "editor1", templatesection)) {
            ret.ok = false;
            ret.errors.push(templatesection + "_musthavecontent1");
        }
        return ret;
    }
    // ============================================================
    // TemplateNamedContact
    // ============================================================
    TemplateNamedContact(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];
        const tags = content.tags;

        if (tags.title === "" || tags.firstname === "" || tags.lastname === "" || tags.country === "" || tags.email === "" || tags.organisation === "") {
            ret.ok = false;
            ret.errors.push(templatesection + "_mustchoose");
        }

        return ret;
    }
    // ============================================================
    // TemplateSubgroups
    // ============================================================
    TemplateSubgroups(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // now check the word count
        // this field is not mandatory, but if completed min and max wordcounts apply
        let editor = content.editors.editor1;
        if (!checkEditorContent(editor, "editor1", templatesection)) {
            ret.ok = false;
            ret.errors.push(templatesection + "_musthavecontent1");
        }
        return ret;
    }
    // ============================================================
    // TemplateDataSynthesisLegacy
    // ============================================================
    TemplateDataSynthesisLegacy(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // now check the word count
        // this field is not mandatory, but if completed min and max wordcounts apply
        let editor = content.editors.editor1;
        if (!checkEditorContent(editor, "editor1", templatesection)) {
            ret.ok = false;
            ret.errors.push(templatesection + "_musthavecontent1");
        }
        return ret;
    }

    // ============================================================
    // TemplateRiskOfBiasLegacy
    // ============================================================
    TemplateRiskOfBiasLegacy(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // now check the word count
        // this field is not mandatory, but if completed min and max wordcounts apply
        let editor = content.editors.editor1;
        if (!checkEditorContent(editor, "editor1", templatesection)) {
            ret.ok = false;
            ret.errors.push(templatesection + "_musthavecontent1");
        }
        return ret;
    }




    // ============================================================
    // TemplateSearch
    // ============================================================
    TemplateSearches(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // now check the word count
        // this field is not mandatory, but if completed min and max wordcounts apply
        let editor = content.editors.editor1;
        if (!checkEditorContent(editor, "editor1", templatesection)) {
            ret.ok = false;
            ret.errors.push(templatesection + "_musthavecontent1");
        }
        return ret;
    }

    // ============================================================
    // TemplateRevisionNote
    // ============================================================
    TemplateRevisionNote(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // now check the word count
        // this field is not mandatory, but if completed min and max wordcounts apply
        let editor = content.editors.editor1;
        if (!checkEditorContent(editor, "editor1", templatesection)) {
            ret.ok = false;
            ret.errors.push(templatesection + "_musthavecontent1");
        }
        return ret;
    }

    // ============================================================
    // TemplateRationale
    // ============================================================
    TemplateRationale(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // now check the word count
        // this field is not mandatory, but if completed min and max wordcounts apply
        let editor = content.editors.editor1;
        if (!checkEditorContent(editor, "editor1", templatesection)) {
            ret.ok = false;
            ret.errors.push(templatesection + "_musthavecontent1");
        }
        return ret;
    }


    // ============================================================
    // TemplateReportingBias
    // ============================================================
    TemplateReportingBias(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];
        const tags = content.tags;

        if (tags.biastype === "") {
            ret.ok = false;
            ret.errors.push(templatesection + "_mustchoose");
        }

        return ret;
    }


    // ============================================================
    // TemplateReportingBias
    // ============================================================
    TemplateCertainty(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];
        const tags = content.tags;

        if (tags.certaintytype === "") {
            ret.ok = false;
            ret.errors.push(templatesection + "_mustchoose");
        }
        else if (tags.certaintytype === "certaintytype") {
            let editor = content.editors.editor1;
            if (!checkEditorContent(editor, "editor1", templatesection)) {
                ret.ok = false;
                ret.errors.push(templatesection + "_musthavecontent1");
            }
        }

        return ret;
    }


    // ============================================================
    // TemplateOutcomesMain
    // ============================================================
    TemplateOutcomesMain(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // now check the word count
        // this field is not mandatory, but if completed min and max wordcounts apply
        let editor = content.editors.editor1;
        if (!checkEditorContent(editor, "editor1", templatesection)) {
            ret.ok = false;
            ret.errors.push(templatesection + "_musthavecontent");
        }
        return ret;
    }


    // ============================================================
    // TemplateOutcomesAdditional
    // ============================================================
    TemplateOutcomesAdditional(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // now check the word count
        // this field is not mandatory, but if completed min and max wordcounts apply
        let editor = content.editors.editor1;
        if (!checkEditorContent(editor, "editor1", templatesection)) {
            ret.ok = false;
            ret.errors.push(templatesection + "_musthavecontent1");
        }
        return ret;
    }


    // ============================================================
    // TemplateOutcomesAdditional
    // ============================================================
    TemplateMeSH(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];
        const list = content.lists.list1;

        const count = list.length;
        if (count < 1) {
            ret.ok = false;
            ret.errors.push(templatesection + "_mustchoose");
        }
        return ret;
    }


    // ============================================================
    // TemplateKeywords
    // ============================================================
    TemplateKeywords(data) {
        const minkeywords = 3;
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        const list = content.lists.list1;



        const count = list.length;

        if (count < minkeywords) {
            ret.ok = false;
            ret.errors.push(templatesection + "_mustchoose");
        }

        return ret;
    }

    // ============================================================
    // TemplatePopulation
    // ============================================================
    TemplatePopulation(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        let editor = content.editors.editor1;
        if (!checkEditorContent(editor, "editor1", templatesection)) {
            ret.ok = false;
            ret.errors.push(templatesection + "_musthavecontent1");
        }
        editor = content.editors.editor2;
        if (!checkEditorContent(editor, "editor2", templatesection)) {
            ret.ok = false;
            ret.errors.push(templatesection + "_musthavecontent2");
        }
        return ret;
    }

    // ============================================================
    // TemplateCondition
    // ============================================================
    TemplateCondition(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // check the included
        const list = content.lists.list1;
        const editor = content.editors.editor1;

        // inclusion critera are mandatory, exclusion are not
        // must have EITHER one or more PICO tags OR some text in the editor
        if (list.length == 0 && !checkEditorContent(editor, "editor1", templatesection, true)) { // NOTE usealtmin for editor check on htis item
            ret.ok = false;
            ret.errors.push(templatesection + "_musthavecontent1");
        }



        return ret;
    }

    // ============================================================
    // TemplateIntervention
    // ============================================================
    TemplateIntervention(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // check the included
        const list = content.lists.list1;
        const editor = content.editors.editor1;

        // inclusion critera are mandatory, exclusion are not
        // must have EITHER one or more PICO tags OR some text in the editor
        if (list.length === 0 && !checkEditorContent(editor, "editor1", templatesection, templatesection, true)) { // NOTE usealtmin for editor check on htis item
            ret.ok = false;
            ret.errors.push(templatesection + "_musthavecontent1");
        }


        // if there is text then it must honour the wordcount
        const editor2 = content.editors.editor2;
        if (!checkEditorContent(editor2, "editor2", templatesection)) {
            ret.ok = false;
            ret.errors.push(templatesection + "_musthavecontent2");
        }

        return ret;
    }


    // ============================================================
    // TemplateComparator
    // ============================================================
    TemplateComparator(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // check the included
        const tags = content.tags;
        const list = content.lists.list1;
        const editor = content.editors.editor1;

        if (tags.comparatoryn === "comparatorn") {
            return ret;
        }
        else if (tags.comparatoryn !== "comparator" && tags.comparatoryn !== "comparators") {
            ret.ok = false;
            ret.errors.push(templatesection + "_shoulddecide");
            return ret;
        }

        // inclusion critera are mandatory, exclusion are not
        // must have EITHER one or more PICO tags OR some text in the editor
        if (list.length >= 0 && !checkEditorContent(editor, "editor1", templatesection, templatesection, true)) { // NOTE usealtmin for editor check on htis item
            ret.errors.push(templatesection + "_musthavecontent1");
        }


        // if there is text then it must honour the wordcount
        const editor2 = content.editors.editor2;
        if (!checkEditorContent(editor2, "editor2", templatesection)) {
            ret.ok = false;
            ret.errors.push(templatesection + "_musthavecontent2");
        }

        return ret;
    }

    // ============================================================
    // TemplateCountry
    // ============================================================
    TemplateCountry(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // check the included
        const list = content.lists.list1;

        if (list.length === 0) {
            ret.ok = false;
            ret.errors.push(templatesection + "_mustchoose1");
        }

        return ret;
    }

    // ============================================================
    // TemplateReviewStatusNew
    // ============================================================
    TemplateReviewStatusNew(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // check the included
        const tags = content.tags;
        const editor = content.editors.editor1;

        if (tags.currentstatus !== "ongoing") {
            ret.ok = false;
            ret.errors.push(templatesection + "_mustchoose1");
        }

        return ret;
    }

    // ============================================================
    // TemplateReviewStage
    // ============================================================
    TemplateReviewStage(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // check the included
        const tags = content.tags;

        if (
            tags.pilot === "" ||
            tags.searching === "" ||
            tags.screening === "" ||
            tags.extraction === "" ||
            tags.rob === "" ||
            tags.synthesis === ""
        ) {
            ret.ok = false;
            ret.errors.push(templatesection + "_mustchoose1");

        }


        if (tags.pilot === "") {
            ret.ok = false;
            ret.errors.push(templatesection + "_pilot");
        }
        if (tags.searching === "") {
            ret.ok = false;
            ret.errors.push(templatesection + "_searching");
        }
        if (tags.screening === "") {
            ret.ok = false;
            ret.errors.push(templatesection + "_screening");
        }
        if (tags.extraction === "") {
            ret.ok = false;
            ret.errors.push(templatesection + "_extraction");
        }
        if (tags.rob === "") {
            ret.ok = false;
            ret.errors.push(templatesection + "_rob");
        }
        if (tags.synthesis === "") {
            ret.ok = false;
            ret.errors.push(templatesection + "_synthesis");
        }

                // don't allow these
            if(data.PublicationStatus === "Not yet registered") {
                if (tags.extraction !== "Not started" && tags.extraction !== "") {
                    ret.ok = false;
                    ret.errors.push(templatesection + "_wrongstage");
                }
                if (tags.rob !== "Not started" && tags.rob !== "") {
                    ret.ok = false;
                    ret.errors.push(templatesection + "_wrongstage");
                }
                if (tags.synthesis !== "Not started" && tags.synthesis !== "") {
                    ret.ok = false;
                    ret.errors.push(templatesection + "_wrongstage");
                }
            }

        return ret;
    }


    // ============================================================
    // TemplateReviewStage
    // ============================================================
    TemplateReviewStageNew(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // check the included
        const tags = content.tags;

        if (
            tags.pilot === "" ||
            tags.searching === "" ||
            tags.screening === "" ||
            tags.extraction === "" ||
            tags.rob === "" ||
            tags.synthesis === ""
        ) {
            ret.ok = false;
            ret.errors.push(templatesection + "_mustchoose1");

        }


        if (tags.pilot === "") {
            ret.ok = false;
            ret.errors.push(templatesection + "_pilot");
        }
        if (tags.searching === "") {
            ret.ok = false;
            ret.errors.push(templatesection + "_searching");
        }
        if (tags.screening === "") {
            ret.ok = false;
            ret.errors.push(templatesection + "_screening");
        }
        if (tags.extraction === "") {
            ret.ok = false;
            ret.errors.push(templatesection + "_extraction");
        }
        if (tags.rob === "") {
            ret.ok = false;
            ret.errors.push(templatesection + "_rob");
        }
        if (tags.synthesis === "") {
            ret.ok = false;
            ret.errors.push(templatesection + "_synthesis");
        }

        // don't allow these
        if (tags.extraction !== "Not started" && tags.extraction !== "") {
            ret.ok = false;
            ret.errors.push(templatesection + "_wrongstage");
        }
        if (tags.rob !== "Not started" && tags.rob !== "") {
            ret.ok = false;
            ret.errors.push(templatesection + "_wrongstage");
        }
        if (tags.synthesis !== "Not started" && tags.synthesis !== "") {
            ret.ok = false;
            ret.errors.push(templatesection + "_wrongstage");
        }



        return ret;
    }


    // ============================================================
    // TemplateReviewStatus
    // ============================================================
    TemplateReviewStatus(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // check the included
        const tags = content.tags;
        const editor = content.editors.editor1;

        if (tags.currentstatus === "discontinued") {
            if (!checkEditorContent(editor, "editor1", templatesection)) {
                ret.ok = false;
                ret.errors.push(templatesection + "_musthavecontent1");
            }
        }

        return ret;
    }


    // ============================================================
    // TemplateStudyDesign
    // There must be at least one check box checked
    // ============================================================
    TemplateStudyDesign(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // first check the tags
        const tags = content.tags;
        if (tags.randomized === "" && tags.nonrandomized === "") {
            ret.ok = false;
            ret.errors.push(templatesection + "_mustchoose");
        }

        // now check the word count
        // this field is not mandatory, but if completed min and max wordcounts apply
        let editor = content.editors.editor1;
        if (!checkEditorContent(editor, "editor1", templatesection)) {
            ret.ok = false;
            ret.errors.push(templatesection + "_musthavecontent1");
        }
        editor = content.editors.editor2;
        if (!checkEditorContent(editor, "editor2", templatesection)) {
            ret.ok = false;
            ret.errors.push(templatesection + "_musthavecontent2");
        }
        return ret;
    }

    // ============================================================
    // TemplateReviewTimeline
    // ============================================================
    TemplateReviewTimeline(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // first check the tags
        const tags = content.tags;
        if (tags.startdate === "") {
            ret.ok = false;
            ret.errors.push(templatesection + "_startdate");
        }
        if (tags.enddate === "") {
            ret.ok = false;
            ret.errors.push(templatesection + "_enddate");
        }
        /*
        if (tags.startdate !== "" && tags.enddate !== "") {
            const end = new Date(tags.enddate);
            let start = new Date(tags.startdate);
            start = new Date(start.setMonth(start.getMonth() + 3));
            if (end < start) {
                ret.ok = false;
                ret.errors.push(templatesection + "_incorrectdates");
            }
        }
        */
        return ret;
    }


    // ============================================================
    // TemplateStudyDateRestrictions
    // ============================================================
    TemplatePublishedProtocol(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // 
        const tags = content.tags;

        if (tags.protocolwritten === "noprotocolwritten") {
            ret.ok = true;
        }
        else if (tags.protocolwritten === "protocolpublished") {
            if (tags.doi !== "" && tags.doichecked !== "checked") {
                ret.ok = false;
                ret.errors.push(templatesection + "_doichecked");
            }
            else if (tags.doi !== "") {
                if (tags.doi.startsWith("10.")) {
                    ret.ok = true;
                }
                else {
                    ret.ok = false;
                    ret.errors.push(templatesection + "_doi");
                }
            }
            else {
                ret.ok = false;
                ret.errors.push(templatesection + "_doi");
            }
        }
        else if (tags.protocolwritten === "protocolupload") {
            if (tags.pdf === "") {
                ret.ok = false;
                ret.errors.push(templatesection + "_pdf");
            }

            if (tags.protocolpermission === "") {
                ret.ok = false;
                ret.errors.push(templatesection + "_permission");
            }

            if (tags.protocolpermission === "protocolpermission" && tags.pdf === "") {
                ret.ok = false;
                ret.errors.push(templatesection + "_pdf");
            }

            /*            if (tags.protocolpermission === "noprotocolpermission" && tags.enddate === "") {
                            ret.ok = false;
                            ret.errors.push(templatesection + "_enddate");
                        }
                        */
        }
        else {
            const editor = content.editors.editor1;
            if (!checkEditorContent(editor, "editor1", templatesection)) {
                ret.ok = false;
                ret.errors.push(templatesection + "_musthavecontent");
            }
        }
        return ret;
    }


    // ============================================================
    // TemplateSearchDatabases
    // ============================================================
    TemplateSearchDatabases(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // check the tags
        const list = content.lists.list1;
        if (list.length === 0) {
            ret.ok = false;
            ret.errors.push(templatesection + "_mustchoose");
        }
        else {
            for (let i = 0; i < list.length; i++) {
                if (list[i] === "Other") {
                    let editor = content.editors.editor1;
                    if (!checkEditorContent(editor, "editor1", templatesection)) {
                        ret.ok = false;
                        ret.errors.push(templatesection + "_musthavecontent1");
                    }
                    break;
                }
            }
        }

        return ret;
    }


    // ============================================================
    // TemplateAdditionalInfo
    // ============================================================
    TemplateAdditionalInfo(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // now check the word count
        // this field is not mandatory, but if completed min and max wordcounts apply
        const editor = content.editors.editor1;
        if (!checkEditorContent(editor, "editor1", templatesection)) {
            ret.ok = false;
            ret.errors.push(templatesection + "_musthavecontent1");
        }
        return ret;
    }


    // ============================================================
    // TemplateFunding
    // ============================================================
    TemplatePeerReview(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        const tags = content.tags;

        const type = tags.peerreviewed;
        if (type === "") {
            ret.ok = false;
            ret.errors.push(templatesection + "_mustchoose");
        }
        else {
            if (type === "peerreviewed") {

                // now check the word count
                // this field is not mandatory, but if completed min and max wordcounts apply
                const editor1 = content.editors.editor1;
                if (!checkEditorContent(editor1, "editor1", templatesection)) {
                    ret.ok = false;
                    ret.errors.push(templatesection + "_musthavecontent1");
                }
            }
        }

        return ret;
    }




    // ============================================================
    // TemplateReviewPublication
    // ============================================================
    TemplateReviewPublication(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        // ============================================================
        // Commercial funders
        // ============================================================
        let status = "ongoing";
        if (data.content.length > 1) {
            const subs = data.content[1];
            for (let i = 0; i < subs.length; i++) {
                let sub = subs[i];
                if (sub.section !== undefined && sub.section.TemplateSection !== undefined && sub.section.TemplateSection === "TemplateReviewStatus") {
                    status = sub.section.tags.currentstatus;
                    break;
                }
            }

        }

        const content = data.content[0];
        const tags = content.tags;

        const type = tags.willpublish;
        if (type === "") {
            ret.ok = false;
            ret.errors.push(templatesection + "_mustchoose");
        }
        else if (type === "willpublishn") {
            const editor1 = content.editors.editor1;
            if (!checkEditorContent(editor1, "editor1", templatesection)) {
                ret.ok = false;
                ret.errors.push(templatesection + "_musthavecontent");
            }

        }
        else if (type === "willpublishy") {
            if (status === 'ongoing') {
            }
            else if (status === 'completed') {
                if (tags.preprint === "preprinty" && Object.keys(tags.preprintdoicitation).length === 0) {
                    ret.ok = false;
                    ret.errors.push(templatesection + "_preprintdoi");
                }
                if (tags.published === "publishedy" && Object.keys(tags.publisheddoicitation).length === 0) {
                    ret.ok = false;
                    ret.errors.push(templatesection + "_publisheddoi");
                }
            }
            /*
            
                        const editor1 = content.editors.editor1;
                        if (!checkEditorContent(editor1, "editor1", templatesection)) {
                            ret.ok = false;
                            ret.errors.push(templatesection + "_musthavecontent1");
                        }
                        */
        }


        return ret;
    }

    // ============================================================
    // TemplateFunding
    // ============================================================
    TemplateConflict(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        const tags = content.tags;

        const type = tags.conflict;
        if (type === "") {
            ret.ok = false;
            ret.errors.push(templatesection + "_mustchoose");
        }
        else {
            if (type === "conflicty") {

                // now check the word count
                // this field is not mandatory, but if completed min and max wordcounts apply
                const editor1 = content.editors.editor1;
                if (!checkEditorContent(editor1, "editor1", templatesection)) {
                    ret.ok = false;
                    ret.errors.push(templatesection + "_musthavecontent1");
                }
            }
        }

        return ret;
    }

    // ============================================================
    // TemplateFunding
    // ============================================================
    TemplateFunding(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];
        const tags = content.tags;
        const list = [...content.lists.list1, ...content.lists.list2];

        const editor1 = content.editors.editor1;
        const editor2 = content.editors.editor2;
        const editor3 = content.editors.editor3;

        const type = tags.fundingtype;
        if (type === "") {
            ret.ok = false;
            ret.errors.push(templatesection + "_mustchoose");
        }
        else {
            if (type === "fundingtypenoncom") {
                if (list.length === 0) {
                    if (!checkEditorContent(editor1, "editor1", templatesection, true) && !checkEditorContent(editor2, "editor2", templatesection, true)) {
                        ret.ok = false;
                        ret.errors.push(templatesection + "_funder");
                    }
                }

                if (tags.grantyn === "") {
                    ret.ok = false;
                    ret.errors.push(templatesection + "_grantnumberchoose");
                }
                else if (tags.grantyn === "grant") {
                    if (tags.grantnumber === "") {
                        ret.ok = false;
                        ret.errors.push(templatesection + "_grantnumber");
                    }
                }
            }
        }

        // now check the word count
        // this field is not mandatory, but if completed min and max wordcounts apply
        return ret;
    }



    // ============================================================
    // TemplateCheckSimilar
    // ============================================================
    TemplateCheckSimilar(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // check the tags
        const tags = content.tags;
        const list = content.lists.list1;

        if (tags.checksimilar === "") {
            ret.ok = false;
            ret.errors.push(templatesection + "_shoulddecide");
        }
        else if (tags.checksimilar === "notsimilar") {
            // check the word count
            // this field is not mandatory, but if completed min and max wordcounts apply
            const editor1 = content.editors.editor1;
            if (!checkEditorContent(editor1, "editor1", templatesection)) {
                ret.ok = false;
                ret.errors.push(templatesection + "_musthavecontent");
            }
        }
        else if (tags.checksimilar === "similar") {
            if (list.length < content.mintocheck) {
                ret.ok = false;
                ret.errors.push(templatesection + "_musthavecontent1");
            }
            else {
                let count = 0;
                for (let i = 0; i < list.length; i++) {
                    if (list[i].select1 !== "") {
                        count++;
                    }
                }
                if (count < content.mintocheck) {
                    ret.ok = false;
                    ret.errors.push(templatesection + "_musthavecontent1");
                }

            }
        }

        return ret;
    }

    // ============================================================
    // TemplateDataExtraction
    // ============================================================
    TemplateDataExtraction(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // check the tags
        const tags = content.tags;
        if (tags.extractionmethod === undefined || tags.extractionmethod === "") {
            ret.ok = false;
            ret.errors.push(templatesection + "_mustchoose2");
        }
        else if (tags.extractionmethod === "extractionmethodother") {
            const editor1 = content.editors.editor1;
            if (!checkEditorContent(editor1, "editor1", templatesection)) {
                ret.ok = false;
                ret.errors.push(templatesection + "_musthavecontent");
            }
        }

        if (tags.extractionmethod !== "extractionmethodno") {
            if (tags.extractionadditional === "") {
                ret.ok = false;
                ret.errors.push(templatesection + "_mustchoose3");
            }
            if (tags.extractiontype === "") {
                ret.ok = false;
                ret.errors.push(templatesection + "_mustchoose3");
            }
            else {
                const editor2 = content.editors.editor2;
                if (!checkEditorContent(editor2, "editor2", templatesection)) {
                    ret.ok = false;
                    ret.errors.push(templatesection + "_musthavecontent2");
                }

            }
        }

        return ret;
    }

    // ============================================================
    // TemplateRiskOfBias
    // ============================================================
    TemplateRiskOfBias(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // check the tags
        const tags = content.tags;
        const list1 = content.lists.list1;
        const list2 = content.lists.list2;
        const editor1 = content.editors.editor1;
        const editor2 = content.editors.editor2;

        if (tags.robtype === undefined || tags.robtype === "") {
            ret.ok = false;
            ret.errors.push(templatesection + "_mustchoose2");
        }
        else if (tags.robtype === "robtype") { // if we are doing RoB
            if (tags.robmethod === "") {
                ret.ok = false;
                ret.errors.push(templatesection + "_mustchoose4");
            }
            if (tags.robadditional === "") {
                ret.ok = false;
                ret.errors.push(templatesection + "_mustchoose3");
            }

            // deal with randomised or nonrandomised selections
            if (tags.robrandomised !== "") {
                if (list1.length === 0) {
                    ret.ok = false;
                    ret.errors.push(templatesection + "_mustchoose5");
                }
                else {
                    for (var i = 0; i < list1.length; i++) {
                        if (list1[i] === "OtherRandomised") {
                            if (!checkEditorContent(editor1, "editor1", templatesection)) {
                                ret.ok = false;
                                ret.errors.push(templatesection + "_musthavecontent1");
                            }
                        }
                    }
                }
            }
            if (tags.robnonrandomised !== "") {
                if (list2.length === 0) {
                    ret.ok = false;
                    ret.errors.push(templatesection + "_mustchoose6");
                }
                else if (list2.length > 0) {
                    for (var i = 0; i < list2.length; i++) {
                        if (list2[i] === "OtherNonrandomised") {
                            if (!checkEditorContent(editor2, "editor2", templatesection)) {
                                ret.ok = false;
                                ret.errors.push(templatesection + "_musthavecontent2");
                            }
                        }
                    }
                }
            }
        }
        return ret;
    }


    // ============================================================
    // TemplateDataSynthesisQuantitative
    // ============================================================
    TemplateDataSynthesisQuantitativeOLD(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // check the tags
        const tags = content.tags;
        const editor1 = content.editors.editor1;
        const editor2 = content.editors.editor2;
        const editor3 = content.editors.editor3;
        const editor4 = content.editors.editor4;
        const editor5 = content.editors.editor5;
        const editor6 = content.editors.editor6;

        if (tags.synthesisyn === undefined || tags.synthesisyn === "") {
            ret.ok = false;
            ret.errors.push(templatesection + "_mustchoose1");
        }
        else if (tags.synthesisyn === "synthesisy") { // if we are here
            if (!checkEditorContent(editor1, "editor1", templatesection)) {
                ret.ok = false;
                ret.errors.push(templatesection + "_musthavecontent1");
            }
            if (!checkEditorContent(editor2, "editor2", templatesection)) {
                ret.ok = false;
                ret.errors.push(templatesection + "_musthavecontent2");
            }
            if (!checkEditorContent(editor3, "editor3", templatesection)) {
                ret.ok = false;
                ret.errors.push(templatesection + "_musthavecontent3");
            }


            if (tags.covariatesyn === undefined || tags.covariatesyn === "") {
                ret.ok = false;
                ret.errors.push(templatesection + "_mustchoose2");
            }
            else if (tags.covariatesyn === "covariatesy") {
                if (!checkEditorContent(editor4, "editor4", templatesection)) {
                    ret.ok = false;
                    ret.errors.push(templatesection + "_musthavecontent4");
                }
            }

            if (tags.heterogeneityyn === undefined || tags.heterogeneityyn === "") {
                ret.ok = false;
                ret.errors.push(templatesection + "_mustchoose3");
            }
            else if (tags.heterogeneityyn === "heterogeneityy") {
                if (!checkEditorContent(editor5, "editor5", templatesection)) {
                    ret.ok = false;
                    ret.errors.push(templatesection + "_musthavecontent5");
                }
            }

            if (tags.sensitivityyn === undefined || tags.sensitivityyn === "") {
                ret.ok = false;
                ret.errors.push(templatesection + "_mustchoose4");
            }
            else if (tags.sensitivityyn === "sensitivityy") {
                if (!checkEditorContent(editor6, "editor6", templatesection)) {
                    ret.ok = false;
                    ret.errors.push(templatesection + "_musthavecontent6");
                }
            }
        }
        return ret;
    }



    // ============================================================
    // TemplateDataSynthesisQuantitative
    // ============================================================
    TemplateDataSynthesisQualitativeNOTUSED(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // check the tags
        const tags = content.tags;
        const editor1 = content.editors.editor1;
        const editor2 = content.editors.editor2;
        const editor3 = content.editors.editor3;
        const editor4 = content.editors.editor4;

        if (tags.synthesisyn === undefined || tags.synthesisyn === "") {
            ret.ok = false;
            ret.errors.push(templatesection + "_mustchoose1");
        }
        else if (tags.synthesisyn === "synthesisy") { // if we are here
            if (!checkEditorContent(editor1, "editor1", templatesection)) {
                ret.ok = false;
                ret.errors.push(templatesection + "_musthavecontent1");
            }
            if (!checkEditorContent(editor2, "editor2", templatesection)) {
                ret.ok = false;
                ret.errors.push(templatesection + "_musthavecontent2");
            }
            if (!checkEditorContent(editor3, "editor3", templatesection)) {
                ret.ok = false;
                ret.errors.push(templatesection + "_musthavecontent3");
            }
            if (!checkEditorContent(editor4, "editor4", templatesection)) {
                ret.ok = false;
                ret.errors.push(templatesection + "_musthavecontent4");
            }

        }
        return ret;
    }



    // ============================================================
    // TemplateDataSynthesis
    // ============================================================
    TemplateDataSynthesis(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // check the tags
        const tags = content.tags;
        const editor1 = content.editors.editor1;
        const editor2 = content.editors.editor2;

        if (tags.synthesisyn === undefined || tags.synthesisyn === "") {
            ret.ok = false;
            ret.errors.push(templatesection + "_mustchoose1");
        }

        if (tags.synthesisyn === "synthesisy") { // if we are here
            if (!checkEditorContent(editor1, "editor1", templatesection)) {
                ret.ok = false;
                ret.errors.push(templatesection + "_musthavecontent1");
            }
        }

        return ret;
    }




    // ============================================================
    // TemplateReviewTeam
    // must have at least two people and must have both a guarantor and contact
    // ============================================================
    TemplateReviewTeam(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];
        const people = content.lists.list1;
        const tags = content.tags;

        // check all the people are correct
        for (var i = 0; i < people.length; i++) {
            const temp = this.Contact(people[i]);
            if (!temp.ok) {
                ret.ok = false;
                ret.errors.push(templatesection + "_mustbecorrect");
                break;
            }
        }

        // check there is a contact and a guanator
        if (tags.guarantor === "") {
            ret.ok = false;
            ret.errors.push(templatesection + "_musthavecontent");
        }
        /*if (tags.contact === "") {
            ret.ok = false;
            ret.errors.push(templatesection + "_musthavecontent");
        }
        */

        if (people.length < 2) {
            ret.ok = false;
            ret.errors.push(templatesection + "_musthave2");
        }


        // check that the review conflict of interest is selected and populated
        /*
                const cofi = content.conflictyn;
                if (cofi === undefined || cofi === "") {
                    ret.ok = false;
                    ret.errors.push(templatesection + "_mustchoose2");
                }
                else if (cofi === "Conflict") {
                    // check the word count
                    // this field is not mandatory, but if completed min and max wordcounts apply
                    const editor = content.editor;
                    let wordcount = 0;
                    if (editor.dataraw !== undefined && editor.dataraw !== null) {
                        const estate = convertFromRaw(editor.dataraw);
                        const ed = EditorState.createWithContent(estate);
        
                        const currentcontent = ed.getCurrentContent().getPlainText().trim();
                        if (currentcontent === "") {
                            ret.ok = (ret.ok === false ? false : true);
                        }
        
                        wordcount = wordCount(editor.dataraw)
                    }
                    const alllimits = this.definedwordcounts[templatesection];
                    const limits = alllimits.editor1;
                    if (wordcount < limits.minwords || wordcount > limits.maxwords) {
                        ret.ok = false;
                        ret.errors.push(templatesection + "_musthavecontent2");
                    }
                }
        
        */

        return ret;
    }




    // ============================================================
    // TemplateScreeningAdditional
    // ============================================================
    TemplateScreeningAdditional(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        const editor = content.editors.editor1;
        if (!checkEditorContent(editor, "editor1", templatesection)) {
            ret.ok = false;
            ret.errors.push(templatesection + "_musthavecontent1");
        }
        return ret;
    }






    // ============================================================
    // TemplateOrgAffil
    // ============================================================
    TemplateOrgAffil(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // now check the word count
        // this field is not mandatory, but if completed min and max wordcounts apply
        const editor = content.editors.editor1;
        if (!checkEditorContent(editor, "editor1", templatesection)) {
            ret.ok = false;
            ret.errors.push(templatesection + "_musthavecontent1");
        }
        return ret;
    }




    // ============================================================
    // TemplateScreening
    // ============================================================
    TemplateScreening(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // check the tags
        const tags = content.tags;
        if (tags.screeningmethod === undefined || tags.screeningmethod === "") {
            ret.ok = false;
            ret.errors.push(templatesection + "_mustchoose");
        }
        else if (tags.screeningmethod === "screeningmethodother") {
            // check the word count
            // this field is not mandatory, but if completed min and max wordcounts apply
            const editor = content.editors.editor1;
            if (!checkEditorContent(editor, "editor1", templatesection)) {
                ret.ok = false;
                ret.errors.push(templatesection + "_musthavecontent1");
            }
        }

        return ret;
    }


    // ============================================================
    // TemplatePublishedSearch
    // ============================================================
    TemplatePublishedSearch(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // 
        const tags = content.tags;

        if (tags.searchstrategyavailable === "nosearchstrategyavailable") {
            ret.ok = true;
        }
        else if (tags.searchstrategyavailable === "searchstrategyavailableupload") {
            if (tags.pdf === "") {
                ret.ok = false;
                ret.errors.push(templatesection + "_pdf");
            }
        }

        return ret;
    }


    // ============================================================
    // TemplateSearchLanguage
    // ============================================================
    TemplateSearchLanguage(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        const tags = content.tags;
        if (tags.searchlanguagerestriction === "") {
            ret.ok = false;
            ret.errors.push(templatesection + "_mustchoose");
        }


        // check the list
        const list = content.lists.list1;
        if (list.length === 0 && tags.searchlanguagerestriction === "searchlanguagerestriction") {
            ret.ok = false;
            ret.errors.push(templatesection + "_musthavecontent");
        }


        return ret;
    }


    // ============================================================
    // TemplateOtherStudyIDMethods
    // ============================================================
    TemplateOtherStudyIDMethods(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // check the word count
        // this field is not mandatory, but if completed min and max wordcounts apply
        let editor = content.editors.editor1;
        if (!checkEditorContent(editor, "editor1", templatesection)) {
            ret.ok = false;
            ret.errors.push(templatesection + "_musthavecontent1");
        }

        return ret;
    }


    // ============================================================
    // TemplateUnpublishedStudies
    // There must be at least one check box checked
    // ============================================================
    TemplateUnpublishedStudies(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // first check the tags
        const tags = content.tags;
        if (tags.publishedstudies === "" && tags.unpublishedstudies === "") {
            ret.ok = false;
            ret.errors.push(templatesection + "_mustchoose");
        }

        return ret;
    }


    // ============================================================
    // TemplateStudyDateRestrictions
    // ============================================================
    TemplateStudyDateRestrictions(data) {
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // first check the tags
        const tags = content.tags;
        if (tags.daterestrictions === "nodaterestrictions") {
            return ret;
        }

        if (tags.startdaterestrictions === "nostartdaterestrictions" && tags.enddaterestrictions === "noenddaterestrictions" && tags.startdate === "" && tags.enddate === "") {
            ret.ok = false;
            ret.errors.push(templatesection + "_bothselected");
        }



        if (tags.startdaterestrictions === "startdaterestrictions" && tags.startdate === "") {
            ret.ok = false;
            ret.errors.push(templatesection + "_startdate");
        }
        if (tags.enddaterestrictions === "enddaterestrictions" && tags.enddate === "") {
            ret.ok = false;
            ret.errors.push(templatesection + "_enddate");
        }

        if (tags.enddaterestrictions === "enddaterestrictions" && tags.startdaterestrictions === "startdaterestrictions") {
            if (new Date(tags.enddate) < new Date(tags.startdate)) {
                ret.ok = false;
                ret.errors.push(templatesection + "_incorrectdates");
            }
        }

        return ret;
    }



    // ========================================================================
    // Helper to check email
    // ========================================================================  
    async Email(email) {
        const pattern = /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;
        if (!pattern.test(email)) {
            return false;
        }

        let ret = false;
        await fetch(defs.prosperoURL + "record/checkemail?email=" + email, fetchoptions())
            .then((resp) => {
                return resp.json()
            }
            )
            .then((obj) => {
                if (obj.ok) {
                    ret = true;
                }
                else {
                    //alert(obj.err);
                    ret = false;
                }
            })
            .catch((response) => {
                ret = false;
                console.log(response);
            });
        return ret;
    }

    // ============================================================
    // COntact
    // ============================================================
    Contact(data) {
        let templatesection = "Contact";
        let ret = { ok: true, formerrors: {}, errors: [] };

        const content = data;

        // ============================================================
        // check the orcid is valid
        // ============================================================
        let pattern = /^[0-9]{4}-[0-9]{4}-[0-9]{4}-[0-9]{4}$/i;
        if (content.orcid != "" && !pattern.test(content.orcid)) {
            ret.ok = false;
            ret.formerrors.orcid = true;
        }

        // ============================================================
        // check the name
        // ============================================================
        let firstname = content.firstname.trim();
        if (firstname === "") {
            ret.ok = false;
            ret.formerrors.firstname = true;
        }

        // ============================================================
        // check the name
        // ============================================================
        let lastname = content.lastname.trim();
        if (lastname === "") {
            ret.ok = false;
            ret.formerrors.lastname = true;
        }

        // ============================================================
        // check the organization
        // ============================================================
        let org = content.organisation.trim();
        let spaces = org.length - org.replaceAll(" ", "").length;
        if (org === "" || spaces > 19) {
            ret.ok = false;
            ret.formerrors.organisation = true;
        }

        // ============================================================
        // check the organization
        // ============================================================
        let country = content.country;
        if (country === "") {
            ret.ok = false;
            ret.formerrors.country = true;
        }


        if (content.role === "") {
            ret.ok = false;
            ret.formerrors.role = true;
            ret.errors.push(templatesection + "_mustchoose3");
        }

        if (content.team === "") {
            ret.ok = false;
            ret.formerrors.team = true;
            ret.errors.push(templatesection + "_mustchoose3");
        }


        // this field is not mandatory, but if completed min and max wordcounts apply
        if (content.conflictyn === "") {
            ret.ok = false;
            ret.formerrors.cofi = true;
            ret.errors.push(templatesection + "_mustchoose2");
        }
        else if (content.conflictyn === "cofi") {
            let editor = content.editors.editor1;
            if (!checkEditorContent(editor, "editor1", templatesection)) {
                ret.ok = false;
                ret.errors.push(templatesection + "_musthavecontent1");
                ret.formerrors.editor = true;
            }
        }




        // ============================================================
        // check the email
        // ============================================================
        let email = content.email;
        pattern = /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;
        if (!pattern.test(content.email)) {
            ret.ok = false;
            ret.formerrors.email = true;
        }



        // ============================================================
        // check the email
        // ============================================================
        /* now only doing this in the client
        let email = content.email;
        if (content.emailchecked === "check") {
            let tempret = await this.Email(email)
                .then((resp) => {
                    return resp;
                })
                .catch((response) => {
                    tempret = false;
                });

            if (!tempret) {
                ret.ok = false;
                ret.formerrors.editor = true;
            }
        }
        */

        /*
        if (ret.ok) {
            content.status = "ok"
        }
        else {
            content.status = "error"
        }
        */
        return ret;
    }




}



export default Check;