import React, { useState, useEffect } from 'react';
import LoadingSpinner from "../TemplateUtils/Loading";
import RecordSectionRequired from '../TemplateUtils/RecordSectionRequired';
import RecordSectionOptional from '../TemplateUtils/RecordSectionOptional';
import { Form, Button } from 'react-bootstrap';
import { UseAppContext } from '../../AppContextProvider';
import TypeAhead from '../TemplateUtils/TypeAhead';
import RecordNavControls from '../TemplateUtils/RecordNavControls';
import RecordIdentifier from '../TemplateUtils/RecordIdentifier';
import RecordCaption2 from '../TemplateUtils/RecordCaption2';
import RecordError from '../TemplateUtils/RecordError';
import RecordOldversion from '../TemplateUtils/RecordOldversion';
import MEditor from '../TemplateUtils/MEditor';
import RecordHelp from '../TemplateUtils/RecordHelp';
import { getKey, Log, getOldVersion, RecordSectionPurposeEdit, getRecordSection, scrollIntoView, showError, cleanHTML, getEditorChecks, checkTempateItem, buildpreview } from '../TemplateUtils/utils.js';

const fname = "TemplateConflict";
const TemplateConflict = props => {
    // ========================================================================
    // the context data store
    // ========================================================================
    const {
        recordData: {
            record
        }
    } = UseAppContext();

    // ========================================================================
    // state variables
    // ========================================================================
    const [data, setData] = useState();                         // the actual section data
    const [showpreview, setShowPreview] = useState(false);      // toggle show/hide the preview
    const [dirty, setDirty] = useState(false);                  // true if anything has changed
    const [error, setError] = useState(null);                   // true if there is an error
    const [checked, setCheck] = useState({});                   // result of checking the content locally (overridden on the server)
    const [showerrors, setShowErrors] = useState(false);        // toggle showing any errors reported by check()
    const [loading, setLoading] = useState(true);               // true if loding data
    const [rerender, setRerender] = useState(0);                // helper to force a rerender
    const [oldversion, setOldversion] = useState(null);         // placeholder for any version that has not been saved 

    // ========================================================================
    // Help links and text. set to NULL if not required for this component
    // ========================================================================
    const pdfhelp = "https://www.crd.york.ac.uk/prospero/documents/Guide%20to%20searching%20v1.pdf";
    const videohelp = "https://www.youtube.com/watch?v=MltKLEGad9s";
    const shorthelp = <p>Has your full review protocol and/or funding application been peer reviewed?</p>;
    const longhelp = <><p>Has your full review protocol and/or funding application been peer reviewed?</p>
    </>;


    // ========================================================================
    // Helper function to get the data for this section from the server
    // ========================================================================
    const getData = async (recordversion, fname) => {
        await getRecordSection(recordversion, fname, RecordSectionPurposeEdit)
            .then((resp) => {
                Log(resp);
                if (resp.status === "error") {
                    setError(resp);
                    setLoading(false);
                }
                else {
                    if(resp.FieldStatus < 100) {
                        buildpreview(resp);
                    }
                    if(resp.FieldStatus < 100) {
                        buildpreview(resp);
                    }
                    setData(resp);
                    setCheck(checkTempateItem(resp));
                    setLoading(false);
                }
            });
    }


    // ========================================================================
    // Initialsise on first call
    // ========================================================================
    useEffect(() => {
        getOldVersion(record.recordversion, fname);
        getData(record.recordversion, fname);
    }, []);



    // ========================================================================
    // Handle the browser back button
    // ========================================================================

    useEffect(() => {
        const beforeUnLoad = (e) => {
            if (dirty) {
                e.preventDefault();
                e.stopPropagation();
                e.returnValue = "Are you sure you want to leave? Changes will not be saved";
                return "Are you sure you want to leave? Changes will not be saved";
            }
        }


        window.addEventListener('beforeunload', beforeUnLoad);
        return () => {
            window.removeEventListener("beforeunload", beforeUnLoad);
        }
    }, [dirty]);



    // ========================================================================
    // helper function to load or create the data
    // ========================================================================
    useEffect(() => {
        if (!loading) {
            setDirty(true);
        }
        setCheck(checkTempateItem(data));
    }, [rerender]);


    // ========================================================================
    // Handle going back without saving changes
    // =======================================================================
    const back = () => {
        setDirty(false);
        props.back("list");
        setTimeout(() => {
            window.history.back();
        }, 100)
    }

    // ========================================================================
    // callback from the editor onblur
    // ========================================================================
    const notifytext = (editorname, editor) => {
        var state = data;
        let content = state.content;
        let c0 = content[0];

        const clean = cleanHTML(editor.datahtml);
        state.preview = clean;
        editor.datahtml = clean;
        c0.editors[editorname] = editor;
        content[0] = c0;
        state.content = content;

        setDirty(true);
        buildpreview(state);
        setData(state);
        setRerender(rerender + 1);
    }

    // ========================================================================
    // get the editor data for the editor
    // ========================================================================
    const getEditorData = (editorname) => {
        if (data != undefined && data !== null) {
            return data.content[0].editors[editorname];
        }
        return null;
    }


    // ========================================================================
    // get the new selection list
    // ========================================================================

    const notify = (selected, list) => {
        let content = data.content;
        let c0 = content[0];
        c0.lists[list] = selected;
        content[0] = c0;
        buildpreview(data);
        setData({ ...data, content: content });
        setRerender(rerender + 1);
    }


    // ========================================================================
    // handle already editing data
    // ========================================================================
    const useoldversion = (option) => {
        if (option) {
            let tempdata = JSON.parse(oldversion);
            tempdata.EditingToken = data.EditingToken;
            setOldversion(null);
            setData(tempdata);
        }
        else {
            setOldversion(null);
        }
    }


    // ========================================================================
    // Check if an object element has a particular value
    // ========================================================================
    const isSelected = (key, item) => {
        let tempdata = data;
        let tags = tempdata.content[0].tags;
        if (tags[key] === item) {
            return "checked";
        }
        return null;
    }

    // ========================================================================
    // set the value of an object element
    // ========================================================================
    const setTagValue = (e) => {
        var tempdata = data;
        let name = e.currentTarget.name;
        let value = e.currentTarget.value;
        let content = tempdata.content[0];


        // ========================================================================
        // grab the elements we are interested in
        // ========================================================================
        var mytags = tempdata.content[0].tags;

        // ========================================================================
        // if we are changing a top-level selection, cleaqr all data
        // ========================================================================
        if (name === "comparatoryn") {
            content.editors.editor1 = {};
            content.editors.editor2 = {};
            content.lists.list1 = [];
            content.lists.list2 = [];
        }



        mytags[name] = value;
        content.tags = mytags;
        tempdata.content[0] = content;
        buildpreview(tempdata);
        setData(tempdata);
        setRerender(rerender + 1);
    }




    return (<>
        {loading && <LoadingSpinner />}
        {error !== null && <RecordError errormessage={error} />}
        {!showpreview && !loading && (<div className="page-inner-restrict pb-3  recordfields">
            {error === null && oldversion === null && (<>
                <RecordIdentifier variant={data.RecordTemplateVariant} />
                <RecordCaption2
                    caption={data.caption}
                    variant={data.RecordTemplateVariant}
                    fname={fname}
                />
                <div className={"radiobox"}>
                    <h2>Additional potential conflicts of interest to declare for this review<RecordSectionRequired /></h2>
                    <Form.Group>
                        <Form.Check type="radio" id={getKey()} >
                            <Form.Check.Input type="radio" name="conflict" checked={isSelected("conflict", "conflictn") == null ? false : true} defaultValue="conflictn" onChange={(e) => setTagValue(e)} />
                            <Form.Check.Label className={isSelected("conflict", "conflictn") == null ? "labelnotselected" : "labelselected"}
                            >No other potential conflicts of interest to declare for this review</Form.Check.Label>
                        </Form.Check>
                        <Form.Check type="radio" id={getKey()} >
                            <Form.Check.Input type="radio" name="conflict" checked={isSelected("conflict", "conflicty") == null ? false : true} defaultValue="conflicty" onChange={(e) => setTagValue(e)} />
                            <Form.Check.Label className={isSelected("conflict", "conflicty") == null ? "labelnotselected" : "labelselected"}
                            >Other potential conflicts of interest to declare for this review</Form.Check.Label>
                        </Form.Check>
                    </Form.Group>
                    {showError(fname + "_mustchoose", checked, showerrors) && <div className="formerror">You must select an item..</div>}
                </div>
                {isSelected("conflict", "conflicty") === "checked" &&
                    <div className={"radiobox"}>
                        <h2>Brief details of the potential conflicts of interest for this review  <RecordSectionRequired /></h2>
                        <MEditor key={"editor1"} editorname={"editor1"} className={"visible"} notify={notifytext} editordata={getEditorData("editor1")} editorchecks={() => getEditorChecks(data.section, "editor1")} />
                        {showError(fname + "_musthavecontent1", checked, showerrors) && <div className="formerror">You must honour the word count.</div>}
                    </div>
                }

            </>)}
            {oldversion !== null && <RecordOldversion oldversion={oldversion} newversion={data} useoldversion={useoldversion} />}
            <RecordNavControls data={data} back={back} preview={setShowPreview} dirty={dirty} cancomplete={checked} setshowerrors={setShowErrors} showerrors={showerrors} error={error === null && oldversion === null ? null : "error"} />
        </div>)
        }
        {showpreview && (<div className="page-inner-restrict publicdocument">
            <h1>{data.caption}</h1>
            <div dangerouslySetInnerHTML={{ __html: data.preview }} />
            <div><p><br /></p>
                <p><br /></p>
            </div>
            <Button variant="primary" onClick={() => setShowPreview(false)}>Close preview</Button>
        </div>)}


    </>
    )

}


export default TemplateConflict;

