import React, { useEffect, useState,  useRef } from 'react';
import RecordNotEnglish  from './RecordNotEnglish';
import RecordAllCaps  from './RecordAllCaps';
import RecordInvalidText  from './RecordInvalidText';
import {toSentenceCase, isEnglish, stripHTML,cleanHTML} from './utils.js';
import { ProgressBar } from 'react-bootstrap';
import {BsPatchMinusFill, BsTypeBold,BsTypeItalic} from 'react-icons/bs';
import {AiOutlineUnorderedList,AiOutlineOrderedList} from 'react-icons/ai';
import {RiListUnordered, RiListOrdered, RiItalic, RiBold} from 'react-icons/ri';


//import { Form, Button, Row, Col } from 'react-bootstrap';
//import { UseAppContext } from '../../AppContextProvider';

import {convertToHTML} from 'draft-convert'; 
import {Editor,
    EditorState,
    RichUtils,
    getDefaultKeyBinding,
    convertToRaw,
    ContentState,
    convertFromRaw ,
    Modifier
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import { stateFromHTML } from 'draft-js-import-html'
 
// OR
// const lngDetector = new (require('languagedetect'));
 



const MEditor = props => {            
    const [loading, setLoading] = useState(true);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [isFocus,setIsFocus] = useState('visible');
    const [wordcount, setWordCount] = useState(0);
    const [timeoutref, setTimeoutRef] = useState(null);
    const [currentBlockKey, setCurrentBlockKey] = useState(null);
    const [currentFocusOffset, setCurrentFocusOffset] = useState(null);
    const [editorchecks, setEditorChecks] = useState(props.editorchecks);
    const [errortext, setErrorText] = useState(null);
    const [englishPercent, setEnglishPercent] = useState(0.0);
    const [englishThreshold, setEnglishThreshold] = useState(1.0);
    
    const editorRef = useRef();

    // ========================================================================
    // load the data 
    // ========================================================================    
    useEffect(() => {
        loadDataOnlyOnce();
        setEditorChecks(props.editorchecks);
      }, []);

      useEffect(() => {
        setEditorChecks(props.editorchecks);
      }, [props.rerender]);

    // ========================================================================
    // load the data 
    // ========================================================================    
    useEffect(() => {
        const content = editorState.getCurrentContent().getPlainText().trim();
        setEnglishPercent(isEnglish(content));
  
        }, [editorState]);

   

    // ========================================================================
    // load the data 
    // ========================================================================    
/*    const loadDataOnlyOnce = () => {
        if(props.editordata != null && props.editordata.dataraw != null && props.editordata.dataraw != "") {
            const estate = convertFrom(props.editordata.dataraw);
            setEditorState( EditorState.createWithContent(estate));
            setWordCount(wordCount(props.editordata.dataraw));
    
        }
        if(props.editorchecks != null) {
            setEditorChecks(props.editorchecks);
        }
        setLoading(false);
    }
*/
    // ========================================================================
    // load the data 
    // ========================================================================    
    const loadDataOnlyOnce = () => {
        if(props.editordata != null && props.editordata.datahtml != null && props.editordata.datahtml != "") {
            setEditorState( EditorState.createWithContent(stateFromHTML(props.editordata.datahtml)));
            setWordCount(wordCount(props.editordata.datahtml));
        }
        if(props.editorchecks != null) { 
            setEditorChecks(props.editorchecks);
        }
        setLoading(false);
    }


    


    // ========================================================================
    // count the number of words in the editor
    // GD 2024-05-28 changed this to use the html
    // ========================================================================    
    const wordCountOLD = (raw) => {
        if(raw === undefined || raw.blocks === undefined )
            return 0;

        // iterate all blocks
        var count = 0;
        for(var i = 0; i < raw.blocks.length; i++) {
            var block = raw.blocks[i];
            count = count + block.text.split(' ').filter(function(str){return str!="";}).length;
        }
        return count;
    }

    // ========================================================================
    // count the number of words in the editor
    // ========================================================================    
    const wordCount = (html) => {
        if(html === undefined  )
            return 0;

        const text = stripHTML(html);
        let count = text.split(' ').filter(function(str){return str!="";}).length;
        
        return count;
    }

    // ========================================================================
    // Apply the checks to the content
    // ========================================================================    
    const checkInput = () => {
        setErrorText(null);
        // get the content

        const content = editorState.getCurrentContent().getPlainText().trim();

        // check for excluded terms
        if(editorchecks.exclude != undefined) {
            for(var i = 0;  i <  editorchecks.exclude.length; i++)        {
                const check = editorchecks.exclude[i];
                if(content.toUpperCase() === check.term.toUpperCase() ) {
                    setErrorText(check.report);
                    return false;
                }
            }
        }

        return true;

    }

    // ========================================================================
    // Check whether content is English (not very reliable at the moment so not used)
    // ========================================================================    
    const checkLanguage = () => {
        const la = editorState.getCurrentContent().getPlainText('\u0001');
    //            console.log(cld.detect(la));
    }

    // ========================================================================
    // fired when content changes
    // ========================================================================    
    const onChange = (e) => {
        e = setSelectionState(e);
        setEditorState(e);        
        setWordCount(wordCount(convertToHTML(e.getCurrentContent())));
        const content = editorState.getCurrentContent().getPlainText().trim();
        setEnglishPercent(isEnglish(content));

        // deal with empty P tags
        let html = convertToHTML(e.getCurrentContent());
        html = html.replaceAll("<p></p>", "<p><br></p>");

        const obj = {   dataraw: convertToRaw(e.getCurrentContent()),
                                                datahtml: html,
                                                datatext: editorState.getCurrentContent().getPlainText() 
        }                    
        //setEditorDirty(true);
                props.notify(props.editorname,obj);

    }


    // ========================================================================
    // handle keystrokes
    // ========================================================================    
    const handleKeyCommand = (command, editorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
    
        if (newState) {
          onChange(newState);
          return 'handled';
        }
    
        return 'not-handled';
      }

    // ========================================================================
    // turn selection into bullet points (or off)
    // ========================================================================    
    const toggleBulletPoints = () => { 
        clearTimeout(timeoutref);
        setTimeoutRef(null);
        setEditorState(RichUtils.toggleBlockType(
            editorState,
            'unordered-list-item'
        ));
        setTimeoutRef(setTimeout(() => {
            editorRef.current.focus();
        }, 100));
    }

    // ========================================================================
    // turn selection into numbered list (or off)
    // ========================================================================    
    const toggleNumberedList = () => { 
        clearTimeout(timeoutref);
        setTimeoutRef(null);
        setEditorState(RichUtils.toggleBlockType(
            editorState,
            'ordered-list-item'
        ));
        setTimeoutRef(setTimeout(() => {
            editorRef.current.focus();
        }, 100));
    }

    // ========================================================================
    // allow tab to indent lists
    // ========================================================================    
    const mapKeyToEditorCommand = (e) => {
        if (e.keyCode === 9 /* TAB */) {
          const newEditorState = RichUtils.onTab(e, editorState, 4 /* maxDepth */)
          if (newEditorState !== editorState) {
            onChange(newEditorState)
          }
          return null
        }
        return getDefaultKeyBinding(e)
      }


    // ========================================================================
    // turn selection into bullet points (or off)
    // ========================================================================    
    const toggleInlineStyle = (inlineStyle) => {
        onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
/*        clearTimeout(timeoutref);
        setTimeoutRef(null);
        setTimeoutRef(setTimeout(() => {
//            editorRef.current.focus();
            //setEditorState(editorState);
           
        }, 500));
        */
      }

    // ========================================================================
    // set the current cursor position on focus
    // ========================================================================    
    const onFocus = () => {
        setIsFocus('visible');      
        var selectionState = editorState.getSelection();
        selectionState=selectionState.merge({anchorKey:currentBlockKey, focusOffset:1}) 

    }
    
    
    // ========================================================================
    // hide the toolbar  on blur and save the current cursor location
    // ========================================================================    
    const onBlur = (e) => {
        const  bk = editorState.getSelection().getStartKey();
        const  fo = editorState.getSelection().getFocusOffset();
        setCurrentBlockKey(bk);
        setCurrentFocusOffset(fo);
        const currentBlockIndex = editorState.getCurrentContent().getBlockMap()
            .keySeq().findIndex(k => k === bk);
        

        const obj = {   dataraw: convertToRaw(editorState.getCurrentContent()),
                                                datahtml: convertToHTML(editorState.getCurrentContent()),
                                                datatext: editorState.getCurrentContent().getPlainText() 
        }                    
        
//        checkInput();
//        checkLanguage();
            console.log("Save editor content 2");
            props.notify(props.editorname,obj);
/*
        setTimeoutRef(setTimeout(() => {
            setIsFocus('hidden');
    },300));*/
    };
    


    // ========================================================================
    // reinstate the cursor position
    // ========================================================================    
    const setSelectionState = (e) => {
        if(currentBlockKey === null)
            return e;
        const selectionState = e.getSelection();
        const newSelectionState = selectionState.merge({
          anchorOffset: currentFocusOffset,
          focusOffset: currentFocusOffset,
          anchorKey: currentBlockKey,
          focusKey: currentBlockKey
        });
        const newEditorState = EditorState.forceSelection(
          e,
          newSelectionState
        );
        setCurrentBlockKey(null);
        setCurrentFocusOffset(null);
        return newEditorState;
        //onChange(newEditorState);
      };


    // ========================================================================
    // handle pasted text (not currently used)
    // ========================================================================    
    const handlePastedText = (text, html) => {
        // if they try to paste something they shouldn't let's handle it

        let pasted = stripHTML(text);

        if(props.mode === "title") {
            pasted = text.replaceAll("\r","").replaceAll("\n"," ");
        }

        const pastedBlocks = ContentState.createFromText(pasted).blockMap;

        const newState = Modifier.replaceWithFragment(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            pastedBlocks,
        );
        const newEditorState = EditorState.push(editorState, newState, "insert-fragment");
        
        onChange(newEditorState); 
        return "handled";  

    }

    const fixme = (raw) => {
        setEditorState(EditorState.createWithContent(convertFromRaw(raw)));
    }

    // ========================================================================
    // render the component
    // ========================================================================      
    return (<div className="meditor"> 
                <div>


                    <div className={ ["editorbuttonbar", isFocus].join(' ')}>
                        <button onClick={() => toggleInlineStyle('BOLD')}><RiBold className="editorbuttonicon" /></button>
                        <button onClick={() => toggleInlineStyle('ITALIC')}><RiItalic className="editorbuttonicon" /></button>
                        <button onClick={() => toggleBulletPoints()}><RiListUnordered className="editorbuttonicon" /></button>
                        <button onClick={() => toggleNumberedList()}><RiListOrdered className="editorbuttonicon" /></button>
                    </div>
                    <div className={(props.className + '  editor')}>
                    <Editor className={(props.className + '  editor')}
                        ref={editorRef}    
                        editorState={editorState} 
                        onChange={(event) => onChange(event, editorState)} 
                        handleKeyCommand={(command) => handleKeyCommand(command, editorState)} 
                        keyBindingFn={(e) => mapKeyToEditorCommand(e)}
                        placeholder={"Enter your text here"}
                        spellCheck={true}
                        onFocus={() => onFocus()}
                        onBlur={(e) => onBlur(e)}
                        onInput={() => {checkInput();}}
                        handleReturn ={() => {return props.mode === "title" ? 'handled' : "nothandled"}}
                        handlePastedText={(text,html) => handlePastedText(text,html)} 
                    />
                    </div>
                </div>
            
        <div className={"texterror " + (errortext === null ? "hidden" : "visible")}>{errortext}</div>

        <div className="wordcount">
            <span className={wordcount < editorchecks.minwords ? "words-too-few" : wordcount > editorchecks.maxwords ? "words-too-many" : "words-just-right"}>{wordcount} word{wordcount === 1 ? '' : 's'} - </span><span>minimum {editorchecks.minwords}, maximum {editorchecks.maxwords}</span>

                {wordcount > 0 && wordcount < editorchecks.minwords && <ProgressBar><ProgressBar variant="danger" now={(wordcount / editorchecks.maxwords) * 100} key={1} /></ProgressBar>}
                {(wordcount > 0 && wordcount >= editorchecks.minwords && wordcount <= editorchecks.maxwords) && <ProgressBar>
                    <ProgressBar variant="success" now={(wordcount / editorchecks.maxwords) * 100} key={1} />
                    </ProgressBar>
                }
                {wordcount > 0 && wordcount > editorchecks.maxwords  && <ProgressBar>
                    <ProgressBar variant="danger" now={(wordcount / editorchecks.maxwords) * 100} key={1} />
                    </ProgressBar>
                }
        </div>
        <RecordInvalidText text={props.editordata.dataraw} invalidtext={editorchecks.invalidtext}/>
        <RecordNotEnglish percent={englishPercent} threshold={englishThreshold} />
        <RecordAllCaps fixme={fixme} raw={convertToRaw(editorState.getCurrentContent())} skipme={editorchecks.allowallcaps === undefined ? false : true}/>
    </div>  )   
}



export default MEditor;    