import React, {useEffect} from 'react'
import {useTable, useFilters, useSortBy, usePagination} from 'react-table';
import {Table, Button} from 'react-bootstrap';
import { MdDeleteForever } from "react-icons/md";

import {BsFillCaretDownSquareFill, BsFillCaretUpSquareFill} from 'react-icons/bs';
import './table.css';

// return a simple table with not additional items. Disabling sort can also be included
const SimpleTable = ({data, columns, rowClick, disableSort}) => {

    const {
      getTableProps, // table props from react-table
      getTableBodyProps, // table body props from react-table
      headerGroups, // headerGroups, if your table has groupings
      rows, // rows for the table based on the data passed
      prepareRow // Prepare the row (this function needs to be called for each row before getting the row props)
    } = useTable({
      columns,
      data,
      disableSortBy: disableSort,
      disableSortRemove: true,  
    },
    useSortBy);    
  
    return (
      <>    
        {(data.length > 0)
        ? <CustomTable 
              getTableProps={getTableProps} 
              getTableBodyProps={getTableBodyProps} 
              headerGroups={headerGroups} 
              rows={rows} 
              prepareRow={prepareRow} 
              rowClick={rowClick}
          />  
        : <>No results found</>
        }    
      </>
    )
  }

  const CustomTable = ({getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, rowClick}) => { 
  
    return <Table  responsive {...getTableProps()} className={(rowClick !== undefined) ? 'hover': ''}>
      <thead>
        {headerGroups.map((headerGroup, s) => (
          <tr key={s} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, i) => (            
              <th key={i} 
                  {...column.getHeaderProps((column.ignoreSort) ? {} : column.getSortByToggleProps({ title: undefined }))} 
                  className={'desktop' +
                        ((column.isSorted)
                          ? column.isSortedDesc
                            ? " sort-desc"
                            : " sort-asc"
                          : '') +
                        ((column.headerClass) 
                        ? " " + column.headerClass
                        : '')
              }>{column.render("Header")}
                  {(column.ignoreSort) 
                  ? <></>
                  : <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <BsFillCaretDownSquareFill />
                          : <BsFillCaretUpSquareFill />
                        : column.isSorted}
                    </span>
                  }
              </th>
            ))}
        </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr key={i} {...row.getRowProps()} onClick={rowClick !== undefined ? () => {rowClick(row)} : undefined }>
              {row.cells.map((cell, i) => {
                return <td key={i} className={"mobile-flex showcursor" + ((cell.column.className !== undefined) ? ' ' + cell.column.className : '')} data-header={cell.column.Header} {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  }

  const DeleteCell = ({action, id, title}) => {
    return <Button 
                className="table-btn delete-btn" 
                onClick={() => action(id)}
                title={(title !== undefined) ? title : null}
            >
                  <MdDeleteForever />
            </Button>
  }

  const ButtonCell = ({label, action, id, title, classes}) => {
    return <Button 
                className={"table-btn" + ((classes !== undefined) ? ' ' + classes : '')} 
                onClick={() => action(id)}
                title={(title !== undefined) ? title : null}
            >
              {label}
            </Button>
  }
  
  export {SimpleTable, ButtonCell, DeleteCell};