import React, { useState, useEffect } from 'react';

export const funderslistnoncommercial = <>
    <option key='blankChoice' hidden value> --Select funder to add to the list-- </option>
    <optgroup label="UK">
        <option key="47" value="British Heart Foundation">British Heart Foundation</option>
        <option key="48" value="British Society for Rheumatology">British Society for Rheumatology</option>
        <option key="49" value="Chief Scientist Office, The Scottish Government">Chief Scientist Office, The Scottish Government</option>
        <option key="50" value="Medical Research Council (MRC)">Medical Research Council (MRC)</option>
        <option key="51" value="National Institute for Health Research (NIHR)">National Institute for Health Research (NIHR)</option>
        <option key="52" value="Wellcome Trust">Wellcome Trust</option>
    </optgroup>
    <optgroup label="Africa">
        <option key="1" value="South African Medical Research Council (SAMRC)">South African Medical Research Council (SAMRC)</option>
    </optgroup>
    <optgroup label="Asia">
        <option key="2" value="Indian Council of Medical Research">Indian Council of Medical Research</option>
        <option key="3" value="Japan Science and Technology Agency (JST)">Japan Science and Technology Agency (JST)</option>
        <option key="4" value="Korea National Institute of Health (KNIH)">Korea National Institute of Health (KNIH)</option>
        <option key="5" value="National Research Foundation of Korea">National Research Foundation of Korea</option>
        <option key="6" value="Pakistan Institute of Living and Learning">Pakistan Institute of Living and Learning</option>
        <option key="7" value="Singapore National Medical Research Council (NMRC)">Singapore National Medical Research Council (NMRC)</option>
    </optgroup>
    <optgroup label="Australasia">
        <option key="8" value="Australian Government Research Training Program Scholarship">Australian Government Research Training Program Scholarship</option>
        <option key="9" value="Health Research Council of New Zealand">Health Research Council of New Zealand</option>
        <option key="10" value="Medical Research Future Fund">Medical Research Future Fund</option>
        <option key="11" value="National Health and Medical Research Council (NHMRC)">National Health and Medical Research Council (NHMRC)</option>
        <option key="12" value="State level (eg Victoria has funded many synthesis efforts)">State level (eg Victoria has funded many synthesis efforts)</option>
    </optgroup>
    <optgroup label="Canada">
        <option key="13" value="Canada&quot;s Drug and Health Technology Agency (CADTH)">Canada&quot;s Drug and Health Technology Agency (CADTH)</option>
        <option key="14" value="Canadian Institutes of Health Research (CIHR)">Canadian Institutes of Health Research (CIHR)</option>
        <option key="15" value="Health Canada">Health Canada</option>
        <option key="16" value="Ontario Ministry of Finance">Ontario Ministry of Finance</option>
        <option key="17" value="Public Health Agency of Canada">Public Health Agency of Canada</option>
        <option key="18" value="Social Sciences and Humanities Research Council">Social Sciences and Humanities Research Council</option>
    </optgroup>
    <optgroup label="China and Taiwan">
        <option key="19" value="China Scholarship Council">China Scholarship Council</option>
        <option key="20" value="Fundamental Research Funds for the Central Universities in China">Fundamental Research Funds for the Central Universities in China</option>
        <option key="21" value="Ministry of Health of China">Ministry of Health of China</option>
        <option key="22" value="Ministry of Science and Technology, China">Ministry of Science and Technology, China</option>
        <option key="23" value="Ministry of Science and Technology, Taiwan">Ministry of Science and Technology, Taiwan</option>
        <option key="24" value="National Natural Science Foundation of China">National Natural Science Foundation of China</option>
        <option key="25" value="Natural Science Foundation of China">Natural Science Foundation of China</option>
    </optgroup>
    <optgroup label="Europe">
        <option key="26" value="Deutsche Forschungsgemeinschaft">Deutsche Forschungsgemeinschaft</option>
        <option key="27" value="European Academy of Allergy and Clinical Immunology (EAACI)">European Academy of Allergy and Clinical Immunology (EAACI)</option>
        <option key="28" value="European Commission">European Commission</option>
        <option key="29" value="European Science Research Council (ESRC)">European Science Research Council (ESRC)</option>
        <option key="30" value="Forskingsrådet / Research Council of Norway">Forskingsrådet / Research Council of Norway</option>
        <option key="31" value="Fund for Scientific Research - Flanders (FWO)">Fund for Scientific Research - Flanders (FWO)</option>
        <option key="32" value="Health Research Board, Ireland">Health Research Board, Ireland</option>
        <option key="33" value="Institut national de la santé et de la recherche médicale (Inserm)">Institut national de la santé et de la recherche médicale (Inserm)</option>
        <option key="34" value="Italian Ministry of Health">Italian Ministry of Health</option>
        <option key="35" value="Nederlandse Organisatie voor Wetenschappelijk Onderzoek (NWO)">Nederlandse Organisatie voor Wetenschappelijk Onderzoek (NWO)</option>
        <option key="36" value="Swiss National Science Foundation (SNSF)">Swiss National Science Foundation (SNSF)</option>
        <option key="37" value="The Netherlands Organisation for Health Research and Development (ZonMW)">The Netherlands Organisation for Health Research and Development (ZonMW)</option>
        <option key="38" value="Vetenskapsrådet-Medicine / Swedish Research Council">Vetenskapsrådet-Medicine / Swedish Research Council</option>
        <option key="39" value="ZonMw / Netherlands Organisation for Health Research and Development">ZonMw / Netherlands Organisation for Health Research and Development</option>
    </optgroup>
    <optgroup label="Multinational">
        <option key="40" value="Bill and Melinda Gates Foundation">Bill and Melinda Gates Foundation</option>
        <option key="41" value="UNICEF Innocenti, SDG synthesis">UNICEF Innocenti, SDG synthesis</option>
        <option key="42" value="World Health Organization (WHO)">World Health Organization (WHO)</option>
    </optgroup>
    <optgroup label="South America">
        <option key="43" value="Coordenação de Aperfeiçoamento de Pessoal de Nível Superior, Brazil">Coordenação de Aperfeiçoamento de Pessoal de Nível Superior, Brazil</option>
        <option key="44" value="Coordination for the Improvement of Higher Education Personnel (CAPES), Brazil">Coordination for the Improvement of Higher Education Personnel (CAPES), Brazil</option>
        <option key="45" value="Oswaldo Cruz Foundation (Fiocruz)">Oswaldo Cruz Foundation (Fiocruz)</option>
        <option key="46" value="Sao Paulo Research Foundation (FAPESP)">Sao Paulo Research Foundation (FAPESP)</option>
    </optgroup>
    <optgroup label="USA">
        <option key="53" value="Agency for Healthcare Research and Quality">Agency for Healthcare Research and Quality</option>
        <option key="54" value="Centers for Disease Control and Prevention">Centers for Disease Control and Prevention</option>
        <option key="55" value="National Institutes of Health (NIH)">National Institutes of Health (NIH)</option>
        <option key="56" value="Patient-Centered Outcomes Research Institute (PCORI)">Patient-Centered Outcomes Research Institute (PCORI)</option>
        <option key="57" value="United States Department of Defense">United States Department of Defense</option>
        <option key="58" value="US Department of Veteran Affairs">US Department of Veteran Affairs</option>
    </optgroup>
</>




export const funderslistcommercial = <>
    <option key='blankChoice' hidden value> --Select funder to add to the list-- </option>
    <optgroup label="UK">
        <option key="" value="AstraZeneca plc">AstraZeneca plc</option>
        <option key="" value="GlaxoSmithKline plc">GlaxoSmithKline plc</option>
    </optgroup>
    <optgroup label="USA">
        <option key="AbbVie Inc" value="AbbVie Inc">AbbVie Inc</option>
        <option key="Amgen Inc" value="Amgen Inc">Amgen Inc</option>
        <option key="Bristol-Myers Squibb" value="Bristol-Myers Squibb">Bristol-Myers Squibb</option>
        <option key="Eli Lilly" value="Eli Lilly">Eli Lilly</option>
        <option key="Gilead Sciences" value="Gilead Sciences">Gilead Sciences</option>
        <option key="Johnson &amp; Johnson" value="Johnson &amp; Johnson">Johnson &amp; Johnson</option>
        <option key="Merck &amp; Co., Inc" value="Merck &amp; Co., Inc"></option>
    </optgroup>
    <optgroup label="Europe">
        <option key="Hoffmann-La Roche AG" value="Hoffmann-La Roche AG">Hoffmann-La Roche AG</option>
        <option key="Novartis International AG" value="Novartis International AG">Novartis International AG</option>
        <option key="Novo Nordisk" value="Novo Nordisk"></option>
        <option key="Sanofi S.A." value="Sanofi S.A."></option>
    </optgroup>

    <optgroup label="Asia">
        <option key="Takeda" value="Takeda">Takeda</option>

    </optgroup>
</>