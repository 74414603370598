import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../TemplateUtils/Loading';
import { Form, Button } from 'react-bootstrap';
import { UseAppContext } from '../../AppContextProvider';
import RecordNavControls from '../TemplateUtils/RecordNavControls';
import RecordIdentifier from '../TemplateUtils/RecordIdentifier';
import RecordCaption from '../TemplateUtils/RecordCaption';
import RecordTimebomb from '../TemplateUtils/RecordTimebomb';
import MEditor from '../TemplateUtils/MEditor';
import { getPreview } from '../TemplateUtils/utils.js';
import { useNavigate } from 'react-router-dom';
import { MdBreakfastDining, MdOpenInFull } from 'react-icons/md';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';
import { renderToStaticMarkup, renderToString } from 'react-dom/server'
import { convert, compile } from 'html-to-text';

const fname = "TemplateLive";
const TemplateLive = props => {

    // ========================================================================
    // state variables
    // ========================================================================
    const [current, setCurrent] = useState();
    const [recordtemplate, setRecordTemplate] = useState([]);
    const [error, setError] = useState(null);                   // true if there is an error
    const [loading, setLoading] = useState(true);               // true if loding data
    const [diffs, setDiffs] = useState(false);
    const nav = useNavigate();


    // ========================================================================
    // the context data store
    // ========================================================================
    const {
        recordData: {
            record
        }
    } = UseAppContext();


    const getLivePreview = async () => {
        //        const data = await getPreview(record.recordversion);
        //        setRecordTemplate(props.preview);
    }

    // ========================================================================
    // Initialsise on first call
    // ========================================================================
    useEffect(() => {
        //        getLivePreview();
        setRecordTemplate(props.preview);
        console.log(props.windowparams);
        setVersion(props.windowparams, props.preview);
    }, [props.preview]);



    // ========================================================================
    // Handle going back without saving changes
    // =======================================================================
    const back = () => {
        props.back("list");
        setTimeout(() => {
            window.history.back();
        }, 100)
    }

    const populatePreview = () => {
        if (recordtemplate === null || recordtemplate.length === 0) {
            return null;
        }
        let ret = recordtemplate.map((item, i) => {
            {
                return (renderToString(<ListItems source={item.blockcontent} params={props.windowparams} />) === "" ? null :
                    <div key={"div-" + i} className={i === 0 ? "boxme" : "section"}>
                        {item.TemplateBlockCaption !== "" && <h1 key={"divh-" + i}>{item.TemplateBlockCaption}</h1>}
                        <ListItems source={item.blockcontent} params={props.windowparams} />
                    </div>
                )

            }

        });
        return ret;
    }


    const setSection = (section) => {
        nav("/register/" + section);
    }

    const setVersion = (props, source) => {
        if (props === undefined || props.page === undefined || source == undefined || source.length == 0) {
            return null;
        }

        let major = null;
        let minor = null;
        if (props.major != undefined) {
            major = parseInt(props.major);
        }
        if (props.minor != undefined) {
            minor = parseInt(props.minor);
        }

        // define which version we are looking at
        let current2 = { major: major, minor: minor, index: 0 };

        var complist = source;

        const comp = complist[0].blockcontent[0];
        if (major !== null) {
            for (let k = 0; k < comp.PublishedHTML.length; k++) {
                if (comp.PublishedHTML[k].VersionID === major) {
                    if (comp.PublishedHTML[k].MinorVersionID === minor) {
                        current2.major = comp.PublishedHTML[k].VersionID;
                        current2.minor = comp.PublishedHTML[k].MinorVersionID;
                        current2.index = k;
                        break;
                    }
                }
            }
        }
        setCurrent({ ...current2 });
    }

    // ========================================================================
    // Generate the list of sections
    // ========================================================================
    const ListItems = (props, params) => {
        let ret = [];

        if (props === undefined || props.source === undefined || props.params === undefined || current == undefined || props.source.length == 0 | current === undefined) {
            return null;
        }

        // if we did not pass in m and m then find the latest version
        var comps = props.source;

        for (let i = 0; i < comps.length; i++) {
            const comp = comps[i];
            const compname = comp.TemplateSection;
            const caption = comp.caption;

            // not sure we need this in Live
            if (comp.PublishedHTML === "") {
                continue;
            }

            let totalchangecount = 0;
            let recentchange = null;
            let values = [];

            let currentvalue = null;
            let currentindex = null;
            for (let i = 0; i < comp.PublishedHTML.length; i++) {
                let item = comp.PublishedHTML[i];
                if (current.major === item.VersionID && current.minor === item.MinorVersionID) {
                    currentvalue = item.PublishedHTML;
                    currentindex = i;
                    if (currentvalue !== null) {
                        values.push(item.PublishedHTML);
                    }
                }
                if(i === currentindex + 1) {
                    values.push(item.PublishedHTML);
                }
                
            }

            let uniq = [...new Set(values)];
            totalchangecount = uniq.length;

            var now = comp.FieldStatus;
            var errors = comp.ErrorCount;
            // we need to be able to override the current version for some fields
            let index = current.index;
            if (compname === 'TemplateVersions') {
                index = 0;
            }
            if (comp.Mandatory === 1) {
                ret.push(<div key={"div-" + i}><h2 className={"preview-nav"} key={"li_" + i} onClick={() => setSection(compname)} >{caption}
                    {totalchangecount > 1 && caption !== '' &&
                        <span className="changes"> {totalchangecount - 1} change{totalchangecount - 1 === 1 ? "" : "s"}
                        </span>
                    }

                </h2>
                    {1 === 2 && recentchange && caption !== '' && <p className="changed">This field has changed since previous version</p>}
                    <div key={"lix_" + i} dangerouslySetInnerHTML={{ __html: comp.PublishedHTML[index].PublishedHTML }}></div>
                </div>)
            }
            else {
                if (comp.PublishedHTML[current.index].PublishedHTML !== "") {
                    ret.push(<div key={"div-" + i}><h2 key={"li_" + i} >{caption}
                        {totalchangecount > 1 && caption !== '' &&
                            <span className="changes"> {totalchangecount - 1} change{totalchangecount - 1 === 1 ? "" : "s"}
                            </span>
                        }

                    </h2>
                        {1 === 2 && recentchange && caption !== '' && <p className="changed">This field has changed since previous version</p>}
                        <div key={"divzz-" + i} dangerouslySetInnerHTML={{ __html: comp.PublishedHTML[index].PublishedHTML }}></div>
                    </div>)
                }

            }

            // and add previous versions
            if(compname === 'TemplateVersions') {
                console.log(uniq);
            }
            if (diffs && caption !== "" && compname != 'TemplateVersions') {
                for (let j = 0; j < uniq.length - 1; j++) {
                    const text2 = convert(uniq[j], {}).split(/[\r\n]/).join(" ");
                    const text1 = convert(uniq[j + 1], {}).split(/[\r\n]/).join(" ");
                    ret.push(<ReactDiffViewer oldValue={text1} showDiffOnly={true} newValue={text2} splitView={true} compareMethod={DiffMethod.SENTENCES} hideLineNumbers={true} />);
                }
            }

        }

        return ret.length > 0 ? ret : null;
    }

    const publish = () => {

    }

    const showDiffs = () => {
        setDiffs(!diffs);

    }

    // ========================================================================
    // return the content
    // ========================================================================
    return (<>
        {props.publish !== undefined &&
            <Button className="mb-3y mt-3 no-print" variant="primary" onClick={() => publish()}>Publish this version now</Button>
        }
        <Button className="mb-3y mt-3 no-print" variant="primary" onClick={() => window.print()}>Print</Button>
        <Button className="mb-3y mt-3 no-print" variant="primary" onClick={() => showDiffs()}>{diffs ? "Hide" : "Show"} differences with previous version </Button>
        {props.purpose !== "live" && <Button className="mb-3y mt-3 no-print" variant="primary" onClick={() => back()}>Close preview</Button>}
        <div className="page-inner-restrict publicdocument">
            {//previewdata !== null && <div dangerouslySetInnerHTML={{ __html: previewdata }}></div>
            }
            {props.purpose !== "live" && <div id="watermark">PREVIEW</div>}
            {props.purpose === "live" &&
                <>
                    <div className="header">
                        <div className="div-double">
                            <img src="/images/nihr-master-logo-325px.png" alt="NIHR image" />
                        </div>
                        <div className="div-single">
                            <div className="div-single-right-large">PROSPERO</div>
                            <div className="div-single-right-small">International prospective register of systematic reviews</div>
                        </div>
                    </div>

                </>
            }
            {props.purpose === "live" && current !== undefined && props.latest !== undefined && props.latest === 'Not latest' && <div className="notlatest">This is version {current.major + "." + current.minor} which is not the latest version of this record. See the citation for the latest version.</div>}




            {populatePreview()}
        </div>
        {props.purpose !== "live" && <div><p><br /></p>
            <Button className="no-print" variant="primary" onClick={() => back()}>Close preview</Button>
            <p><br /></p>
        </div>
        }

    </>
    )

}


export default TemplateLive;