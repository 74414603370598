import React, { useEffect, useState } from 'react';
import { Form, Row, Container, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { UseAppContext } from '../AppContextProvider';

import Header from '../header/header';
import HomeBanner from '../home/homebanner';
import RecordTriage from './recordtriage';
import { useParams } from "react-router-dom"
import { getKey } from '../Template//TemplateUtils/utils.js';
import RecordHelp from '../Template/TemplateUtils/RecordHelp';


const fname = "ReviewCoverSheet";
const ReviewCoverSheet = () => {
    const [agreetoterms, setAgreeTerms] = useState(false);

    const navigate = useNavigate();

    // ========================================================================
    // the context data store
    // ========================================================================
    const {
        recordData: {
            record
        },

        userData: {
            user
        }
    } = UseAppContext();

    if (user === undefined || user === null || user.useruuid === undefined || user.useruuid === null) {
        navigate("/login");
        return <>Not logged in</>;
    }

    const changePage = () => {
        sessionStorage.setItem("currentMode", "recordTriage");
        navigate("../choosereviewtype");
    };


    return <Container className="page-outer recordfields mb-5">

        <Row>
            <HomeBanner img="4k-gf58fb1b71_1920.jpg" tag="Register a review" />
        </Row>
        <>
            <h1>Registering your review</h1>
            <h2 className="mt-3">Terms of use for registering reviews</h2>
            <ol>
                <li>Registration in PROSPERO is governed by the Terms and Conditions of Use and the Privacy policy agreed when creating your PROSPERO account.</li>
                <li>All data entered and information provided must be accurate and truthful.</li>
                <li>Reviews must be in English and in scope. more info.</li>
                <li>Reviews must be registered before data extraction has started, or before IPD have been received.</li>
                <li>Reviews building on previous work should state this and should not plagiarise published work by reproducing text.</li>
                <li>PROSPERO accepts information in good faith and relies on the integrity of those registering their reviews, who are entirely responsible for content.</li>
                <li>Action will be taken if inaccuracies in data are identified at any time including information supplied about the stage of review or anticipated completion time. Deliberate falsification of data including review team membership will be regarded as research misconduct.</li>
            </ol>

            <RecordHelp className="smalltext" fname={fname} helpsection="help1" label="More information..." />


            <div className={"radiobox selected pt-3 pb-3"}>

                <Form.Check type={"checkbox"}  >
                    <Form.Check.Input type={"checkbox"} name="terms"
                        value={agreetoterms}
                        checked={agreetoterms}
                        onChange={(e) => setAgreeTerms(e.target.checked)}
                    />
                    <Form.Check.Label>I have read and understand the terms above and agree to abide by them</Form.Check.Label>
                </Form.Check>
            </div>
            <center><Button className="mt-5" variant="primary" size="lg"
                onClick={changePage}
                disabled={!(agreetoterms)}
            >Continue</Button>
            </center>

        </>
    </Container>
}

export default ReviewCoverSheet;