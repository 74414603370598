import React, { useEffect } from 'react';
import { Row, Container, Tabs, Tab } from 'react-bootstrap';
import SearchSimple from './SimpleSearch';
import SearchHistory from './SearchHistory';
import SavedSearch from './SavedSearch';
import HomeBanner from '../home/homebanner';
import { UseAppContext } from '../AppContextProvider';
import './search.css';

const Search = () => {
        
    const {
        userSearchData: {
            userSearch, setUserSearch
        }
    } = UseAppContext();
  
    useEffect(()=>{        
        // localStorage.removeItem("searchHistory");
        if(userSearch.history === undefined) {
            // look for local storage
            const history = localStorage.getItem("PROSPERO_searchHistory");
            if(history !== undefined && history !== null) { // update user search with local storage history
                setUserSearch({...userSearch, "history": JSON.parse(history)});
            }
        }
        
        
    },[]);

    const updateSelectedTab = (key) => {
        setUserSearch({...userSearch, "tab": key});

    }    
    
    return <Container className="page-outer"> 
            <Row>
                <HomeBanner img="surface-gdba4802a4_1920.jpg" tag="Search PROSPERO"/>
            </Row>
            <Row id="searchTabs" >
                <div className="texterror">The new search is not enabled for this test version please do not use.</div>
                <Tabs activeKey={userSearch.tab} onSelect={(key) => updateSelectedTab(key)}>
                    <Tab eventKey="simple" title="Search">
                        <SearchSimple />
                    </Tab>
                    <Tab eventKey="mesh" title="MeSH search">
                        <h2>MeSH search</h2>
                    </Tab>
                    <Tab eventKey="history" title="Search history">
                        <SearchHistory />
                    </Tab>
                    <Tab eventKey="saved" title="Saved searches">
                        <SavedSearch />
                    </Tab>
                  
                </Tabs>
            </Row>
        </Container>
}

export default Search;