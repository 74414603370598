import React, { useEffect, useState, useRef } from 'react';
import { BsTypeBold, BsTypeItalic } from 'react-icons/bs';
import { AiOutlineUnorderedList, AiOutlineOrderedList } from 'react-icons/ai';
import { AsyncTypeahead, Token } from "react-bootstrap-typeahead";
import { Form, ListGroup, InputGroup } from 'react-bootstrap';
import { BsTrash } from 'react-icons/bs';
import { fetchoptions } from './utils.js';




import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import { checkPropTypes } from 'prop-types';

const TypeAhead = props => {
  const [isLoading, setIsLoading] = useState(true);
  const [selected, setSelected] = useState(props.selectdata);
  const [options, setOptions] = useState([]);
  const [isMultiple, setIsMultiple] = useState((props.ismultiple === undefined ? true : props.ismultiple));
  const [baseURL, setBaseURL] = useState(props.baseurl);


  useEffect(() => {
    //      window.addEventListener('paste', handlePaste);
    notifycallback();
    setIsLoading(false);

    //    return () => {
    //    window.removeEventListener('paste', handlePaste);
    //};
  }, [selected]);



  const notifycallback = () => {
    if (selected != null && !isLoading) {
      props.notify(selected, props.listname === undefined ? "list1" : props.listname );
      setOptions([]);
    }
  }



  const fudge = (opts) => {
    setOptions(opts);
  }

  let keyCount = 0;
  const getKey = (root) => {
    return root + "_" + keyCount++;
  }

  const _removeItem = (i) => {
    var copyListItems = [...selected];
    copyListItems.splice(i, 1)
    setSelected(copyListItems);
  }

  const SelectedItems = (props) => {
    const [selecteditems, setSelectedItems] = useState(props.items);
    if (selecteditems == null)
      return null;
    var classname = "hidden";
    const Results = (props) => {
      var ret = selecteditems.map((item, i) => {
        var key = i;
        classname = "visible";
        return <ListGroup.Item key={key} draggable
          as="li"
          className="d-flex justify-content-between align-items-start notranslate"

          onDragStart={(e) => dragStart(e, { key })}
          onDragEnter={(e) => dragEnter(e, { key })}
          onDragOver={(e) => dragOver(e, { key })}
          onDragEnd={drop}
        >
          <div className="ms-2 me-auto">
            {
              (item.customOption == true ?
                <span className="color-red ">***NEW KEYWORD***  <b>{item.label} </b></span> :
                <span>{item.label} {item.count != undefined ? "(" + item.count + ")" : null}</span>
              )
            }
          </div>
          <BsTrash className="bg-icon" onClick={() => _removeItem(i)} />
        </ListGroup.Item>
      })

      if (ret.length > 0)
        return <div className={"typeaheadlist "}>
          <ListGroup as="ol" numbered>
            {ret}</ListGroup>
        </div>
      else
        return ret;
    }
    return <Results items={selecteditems} />
  }

  const dragItem = useRef();
  const dragOverItem = useRef();

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const dragOver = (e, position) => {
    e.preventDefault()
  };


  const drop = (e) => {
    // get the existing list
    var copyListItems = [...selected];
    // copy the dragged item
    const dragItemContent = copyListItems[dragItem.current.key];
    // remove the dragged item from the list (so the list will be 1 smaller)
    copyListItems.splice(dragItem.current.key, 1);
    // insert the item to the list
    copyListItems.splice(dragOverItem.current.key, 0, dragItemContent);
    // null everything
    dragItem.current = null;
    dragOverItem.current = null;
    // save the new selected list
    setSelected(copyListItems);
  };


  const handleSearch = (query, fudge) => {
    setIsLoading(true);

    fetch(baseURL + query, fetchoptions(props.clean !== undefined ? props.clean : false ))
      .then((resp) => {
        return resp.json()
      })
      .then((items) => {
        var opts = items.results.map((item, i) => {
          var ret = {};
          ret.id = item["@id"];
          ret.label = item.label;
          ret.display = item.label + (item["count"] !== undefined ? " (" + item["count"] + ")" : "");
          ret.type = item["@type"];
          if (item["count"] !== undefined) {
            ret.count = item["count"];
          }
          return ret;
        });
        setIsLoading(false);
        fudge(opts);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });

  };

  const filterBy = () => true;


  const onKeyUpc = (event) => {
    if (event.key === "Enter") {
      console.log(selected);
    }
  }

  const typeaheadRef = useRef(null);



  const handlePaste = (e) => {
    console.log(JSON.stringify(e.clipboardData));
    console.log(e.clipboardData.getData('Text'));
  }


  const testme = (objs) => {
    // iterate the array and fix up any customOpetion: true items by adding type: "PROSPEROKEYWORD" and label = display
    let objsnew = JSON.parse(JSON.stringify(objs));
    let obsnew = [];
    for (let i = 0; i < objsnew.length; i++) {
      if (objsnew[i].customOption !== undefined) {
        objsnew[i].type = "PROSPEROKEYWORD";
        objsnew[i].label = objsnew[i].display;
      }
    }

    // remove du0plicates
    for (let i = objsnew.length; i > 0; i--) {
      for (let j = 0; j < i - 1; j++) {
        if (objsnew[j].label.toUpperCase() === objsnew[i-1].label.toUpperCase()) {
          objsnew.splice(i-1, 1);
          break;
        }
      }

    }
    setSelected(objsnew);
  }

  return (
    <><Form.Group>
      <InputGroup>
        <AsyncTypeahead className={'!m-0  notranslate'}
          placeholder="Begin typing to see options. Minimum 3 characters."
          ref={typeaheadRef}
          clearButton
          multiple={isMultiple}
          filterBy={filterBy}
          selected={selected}
          allowNew={props.allownew !== undefined && props.allownew ? true : false}
          newSelectionPrefix="Add a new item: "
          useCache={false}
          isLoading={isLoading}
          onSearch={(term) => handleSearch(term, fudge)}
          options={options}
          //          defaultSelected={options.slice(0, 1)}
          id="selections-example"
          labelKey="display"
          //labelKey={(option) => `${option.label + 'x'} [${option.count}]`}
          minLength={3}
          onInputChange={(text, e) => {
            console.log(text, e);
          }}
          onChange={testme}
        />

      </InputGroup>
      <SelectedItems items={selected} />
    </Form.Group>
    <p>The order of the terms above is the order they will appear in your record - drag the rows to change the order</p>
    </>
  );
};



export default TypeAhead;