import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';


const ConfirmModal = ({title, message, handleConfirm, handleCancel, showModal}) => {
    return <Modal show={(showModal !== undefined) ? showModal : true} onHide={() => {handleCancel(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{message}</Modal.Body>
                <Modal.Footer>                
                    <Button variant="primary" onClick={() => {handleConfirm()}}>Yes</Button>
                    <Button variant="secondary" onClick={() => {handleCancel(false)}}>No</Button>                    
                </Modal.Footer>
            </Modal>
}

const ConfirmModalCancel = ({title, message, handleConfirm, handleNo, handleCancel, showModal}) => {
    return <Modal show={(showModal !== undefined) ? showModal : true} onHide={() => {handleCancel(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{message}</Modal.Body>
                <Modal.Footer>                
                    <Button variant="primary" onClick={() => {handleConfirm()}}>Yes</Button>
                    <Button variant="secondary" onClick={() => {handleNo()}}>No</Button>
                    <Button variant="secondary" onClick={() => {handleCancel(false)}}>Cancel</Button>
                </Modal.Footer>
            </Modal>
}

const MessageModal = ({title, message, handleConfirm, showModal}) => {
    return <Modal show={(showModal !== undefined) ? showModal : true} onHide={() => {handleConfirm(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{message}</Modal.Body>
                <Modal.Footer>                                    
                    <Button variant="primary" onClick={() => {handleConfirm(false)}}>OK</Button>
                </Modal.Footer>
            </Modal>
}

const EmptyModal = ({title, label, children, handleConfirm, modalClass, showModal}) => {    
    return <Modal show={(showModal !== undefined) ? showModal : true} onHide={() => {handleConfirm(false)}} className={(modalClass !== undefined) ? modalClass : ""}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{children}</Modal.Body>
                <Modal.Footer>                                    
                    <Button variant="primary" onClick={() => {handleConfirm(false)}}>{label}</Button>
                </Modal.Footer>
            </Modal>
}

ConfirmModal.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,    
    handleConfirm: PropTypes.func,
    handleCancel: PropTypes.func
}

ConfirmModalCancel.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,    
    handleConfirm: PropTypes.func,
    handleNo: PropTypes.func,
    handleCancel: PropTypes.func
}

MessageModal.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,    
    handleConfirm: PropTypes.func
}

EmptyModal.propTypes = {
    title: PropTypes.string,
    label: PropTypes.string,
    children: PropTypes.any,
    handleConfirm: PropTypes.func, 
    modalClass: PropTypes.string
}

export {ConfirmModal, MessageModal, EmptyModal, ConfirmModalCancel}
