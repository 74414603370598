
import {prosperoClient} from './axios';

// make the basic login authentication call
export const postLoginAuthentication = (login) => {        
    return prosperoClient.post('authenticate/basic', login).then(res => res.data);
}

// make the user reset password call
export const postPasswordReset = (obj) => {        
    return prosperoClient.post('authenticate/basic/password/reset', obj).then(res => res.data);
}

// check the reset password token is valid
export const getValidCode = (obj) => {        
    return prosperoClient.put('authenticate/basic/password/code', obj).then(res => res.data);
}

// update the users password 
export const postUpdatePassword = (obj) => {        
    return prosperoClient.post('authenticate/basic/password/update', obj).then(res => res.data);
}

// check the session authentication is valid for this user
export const getAuthenticationDetails = () => {       
    return  prosperoClient.get('authenticate/user').then(res => res.data);    
}
