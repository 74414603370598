import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../TemplateUtils/Loading';
import RecordSectionRequired from '../TemplateUtils/RecordSectionRequired';
import { Form, Button } from 'react-bootstrap';
import { UseAppContext } from '../../AppContextProvider';
import RecordNavControls from '../TemplateUtils/RecordNavControls';
import RecordIdentifier from '../TemplateUtils/RecordIdentifier';
import RecordCaption2 from '../TemplateUtils/RecordCaption2';
import RecordError from '../TemplateUtils/RecordError';
import RecordOldversion from '../TemplateUtils/RecordOldversion';
import ModalBox from '../TemplateUtils/Modal';
import { postfile, gotoPDF, clearData, getKey, Log, getOldVersion, RecordSectionPurposeEdit, getRecordSection, scrollIntoView, showError, cleanHTML, getEditorChecks, checkTempateItem, buildpreview } from '../TemplateUtils/utils.js';

const fname = "TemplatePublishedSearch";
const TemplatePublishedSearch = props => {
    // ========================================================================
    // the context data store
    // ========================================================================
    const {
        recordData: {
            record
        }
    } = UseAppContext();


    // ========================================================================
    // state variables
    // ========================================================================
    const [data, setData] = useState();                         // the actual section data
    const [showpreview, setShowPreview] = useState(false);      // toggle show/hide the preview
    const [dirty, setDirty] = useState(false);                  // true if anything has changed
    const [error, setError] = useState(null);                   // true if there is an error
    const [checked, setCheck] = useState({});                   // result of checking the content locally (overridden on the server)
    const [showerrors, setShowErrors] = useState(false);        // toggle showing any errors reported by check()
    const [loading, setLoading] = useState(true);               // true if loding data
    const [rerender, setRerender] = useState(0);                // helper to force a rerender
    const [oldversion, setOldversion] = useState(null);         // placeholder for any version that has not been saved 
    const [remove, setRemove] = useState(false);                // flag for removing a PDF
    const [file, setFile] = useState(null);                     // file to deal woith
    const [showModal, setShowModal] = useState(false);                       // doi to deal woith
    const [modalHeading, setModalHeading] = useState(false);                       // doi to deal woith
    const [modalBody, setModalBody] = useState(false);                       // doi to deal woith

    // ========================================================================
    // Help links and text. set to NULL if not required for this component
    // ========================================================================
    const pdfhelp = "https://www.crd.york.ac.uk/prospero/documents/Guide%20to%20searching%20v1.pdf";
    const videohelp = "https://www.youtube.com/watch?v=MltKLEGad9s";
    const shorthelp = <p>A search strategy may be deposited with CRD in PDF format and will be made available as part of your record.</p>;
    const longhelp = <><p>A search strategy may be deposited with CRD in PDF format and will be made available as part of your record.</p>
    </>;


    // ========================================================================
    // Helper function to get the data for this section from the server
    // ========================================================================
    const getData = async (recordversion, fname) => {
        await getRecordSection(recordversion, fname, RecordSectionPurposeEdit)
            .then((resp) => {
                Log(resp);
                if (resp.status === "error") {
                    setError(resp);
                    setLoading(false);
                }
                else {
                    if(resp.FieldStatus < 100) {
                        buildpreview(resp);
                    }
                    setData(resp);
                    setCheck(checkTempateItem(resp));
                    setLoading(false);
                }
            });
    }


    // ========================================================================
    // Initialsise on first call
    // ========================================================================
    useEffect(() => {
        getOldVersion(record.recordversion, fname);
        getData(record.recordversion, fname);
    }, []);


    // ========================================================================
    // Handle the browser back button
    // ========================================================================
    useEffect(() => {
        const beforeUnLoad = (e) => {
            if (dirty) {
                e.preventDefault();
                e.stopPropagation();
                e.returnValue = "Are you sure you want to leave? Changes will not be saved";
                return "Are you sure you want to leave? Changes will not be saved";
            }
        }


        window.addEventListener('beforeunload', beforeUnLoad);
        return () => {
            window.removeEventListener("beforeunload", beforeUnLoad);
        }
    }, [dirty]);

    // ========================================================================
    // helper function to load or create the data, and perform initial check
    // ========================================================================
    useEffect(() => {
        if (!loading) {
            setDirty(true);
        }
        else {
            setTimeout(() => {
                var topOfElement = 0;
                let elem = document.getElementById('scrollto_' + fname);
                if (elem !== null) {
                    topOfElement = elem.offsetTop;
                    window.scroll({ top: topOfElement, behavior: "smooth" });
                }
                //scrollIntoView("scrollto_" + fname);
            }, 100);
        }
        setCheck(checkTempateItem(data));
    }, [rerender]);


    // ========================================================================
    // Handle going back without saving changes
    // =======================================================================
    const back = () => {
        setDirty(false);
        props.back("list");
        setTimeout(() => {
            window.history.back();
        }, 100)
    }


    // ========================================================================
    // callback from the editor onblur
    // ========================================================================
    const notifytext = (editorname, editor) => {
        var state = data;
        let content = state.content;
        let c0 = content[0];

        const clean = cleanHTML(editor.datahtml);
        if (c0.editors[editorname].datahtml === clean) {
            return;
        }
        state.preview = clean;
        editor.datahtml = clean;
        c0.editors[editorname] = editor;
        content[0] = c0;
        state.content = content;

        setDirty(true);
        buildpreview(state);
        setData(state);
        setRerender(rerender + 1);
    }


    // ========================================================================
    // get the editor data for the editor
    // ========================================================================
    const getEditorData = (editorname) => {
        if (data != undefined && data !== null) {
            return data.content[0].editors[editorname];
        }
        return null;
    }

    // ========================================================================
    // handle already editing data
    // ========================================================================
    const useoldversion = (option) => {
        if (option) {
            let tempdata = JSON.parse(oldversion);
            tempdata.EditingToken = data.EditingToken;
            setOldversion(null);
            setData(tempdata);
        }
        else {
            setOldversion(null);
        }
    }

    // ========================================================================
    // get the value of an object element
    // ========================================================================
    const getTagValue = (tag) => {
        let tempdata = data;
        let tags = tempdata.content[0].tags;
        return tags[tag];
    }


    // ========================================================================
    // set the value of an object element
    // ========================================================================
    const setTagValue = (e) => {
        var tempdata = data;
        let name = e.currentTarget.name;
        let value = e.currentTarget.value;

        // ========================================================================
        // if we are changing a top-level selection, cleaqr all data
        // ========================================================================
        if (name === "protocolwritten") {
            tempdata = clearData(tempdata);
        }


        // ========================================================================
        // grab the elements we are interested in
        // ========================================================================
        var mytags = tempdata.content[0].tags;



        mytags[name] = value;
        let content = tempdata.content;
        content[0].tags = mytags;
        tempdata.content = content;
        buildpreview(tempdata);
        setData(tempdata);
        setRerender(rerender + 1);
    }


    // ========================================================================
    // Check if an object element has a particular value
    // ========================================================================
    const isSelected = (key, item) => {
        let tempdata = data;
        let tags = tempdata.content[0].tags;
        if (tags[key] === item) {
            return "checked";
        }
        return null;
    }


    // ========================================================================
    // Remove reference to a PDF
    // ========================================================================
    const removePDF = () => {
        let tempdata = data;
        let content = tempdata.content;
        let mytags = content[0].tags;
        mytags.pdf = "";
        mytags.protocolpermission = "";
        content[0].tags = mytags;
        tempdata.content = content;
        buildpreview(tempdata);
        setRemove(false);
        setData(tempdata);
        setRerender(rerender + 1);
    }


    // ========================================================================
    // show the PDF
    // NOTE: this really needs to be a call to the server with the record id
    // ========================================================================
    const showPDF = () => {
        let tempdata = data;
        let tags = tempdata.content[0].tags;
        gotoPDF(tags.pdf);
    }



    // ========================================================================
    // get the file to be uploaded
    // ========================================================================
    const handleFileChange = (e) => {
        if (e.target.files) {
            // basic check here
            if (e.target.files[0].type !== "application/pdf" || e.target.files[0].size > 2 * 1024 * 1024) {
                if (e.target.files[0].type !== "application/pdf") {
                    setModalHeading("Cannot upload file")
                    setModalBody("The file you are trying to upload is not a PDF file. This section only accepts POD files")
                }
                else if (e.target.files[0].size > 2000000) {
                    setModalHeading("Cannot upload file")
                    setModalBody("The file you are trying to upload is too large. This section only accepts files up to 2MB")
                }
                setShowModal(true);
                const buttons = window.document.getElementsByClassName("fileuploadbutton ");
                if (buttons !== null && buttons !== undefined && buttons.length > 0) {
                    buttons[0].disabled = "disabled";
                }
                else {
                    buttons[0].disabled = "false";
                }
                return false;
            }
            else {
                setFile(e.target.files[0]);
            }

        }
    };


    // ========================================================================
    // Handle the file upload
    // NOTE: back end to be written
    // ========================================================================
    const handleUploadClick = async () => {
        var tempdata = data;
        var mytags = tempdata.content[0].tags;
        await postfile()
            .then((resp) => {
                if (resp.status === "ok") {
                    let e = { currentTarget: { name: "pdf", value: resp.filename } };
                    setTagValue(e);
                    let value = resp.filename ;
                    mytags.pdf = value;
                }
                else {
                    let e = { currentTarget: { name: "pdf", value: "" } };
                    setTagValue(e);
                    setError(resp.errormessage);
                }
            }
            );

        buildpreview(tempdata);
        setData(tempdata);
        setRerender(rerender + 1);
    };



    // ========================================================================
    // return the content
    // ========================================================================
    return (<>
        {loading && <LoadingSpinner />}
        {error !== null && <RecordError errormessage={error} />}
        {!showpreview && !loading && (<div id={"scrollto_" + fname} className="page-inner-restrict pb-3 recordfields">
            {error === null && oldversion === null && (
                <><RecordIdentifier variant={data.RecordTemplateVariant} />
                    <RecordCaption2
                        caption={data.caption}
                        variant={data.RecordTemplateVariant}
                        fname={fname}
                    />
                    <div className={"radiobox"}>
                        <h2>Please select from the options below <RecordSectionRequired /></h2>
                        <Form.Group>
                            <Form.Check type="radio" id={getKey()} >
                                <Form.Check.Input type="radio" name="searchstrategyavailable" checked={isSelected("searchstrategyavailable", "nosearchstrategyavailable") == null ? false : true} defaultValue="nosearchstrategyavailable" onChange={(e) => setTagValue(e)} />
                                <Form.Check.Label className={isSelected("searchstrategyavailable", "nosearchstrategyavailable") == null ? "labelnotselected" : "labelselected"}
                                >A search strategy is not available</Form.Check.Label>
                            </Form.Check>

                            <Form.Check type="radio" id={getKey()} >
                                <Form.Check.Input type="radio" name="searchstrategyavailable" checked={isSelected("searchstrategyavailable", "searchstrategyavailableupload") == null ? false : true} defaultValue="searchstrategyavailableupload" onChange={(e) => setTagValue(e)} />
                                <Form.Check.Label className={isSelected("searchstrategyavailable", "searchstrategyavailableupload") == null ? "labelnotselected" : "labelselected"}
                                >Upload your search strategy to PROSPERO in PDF format</Form.Check.Label>
                            </Form.Check>
                            <Form.Check type="radio" id={getKey()} >
                                <Form.Check.Input type="radio" name="searchstrategyavailable" checked={isSelected("searchstrategyavailable", "searchstrategyavailable") == null ? false : true} defaultValue="searchstrategyavailable" onChange={(e) => setTagValue(e)} />
                                <Form.Check.Label className={isSelected("searchstrategyavailable", "searchstrategyavailable") == null ? "labelnotselected" : "labelselected"}
                                >A full search strategy is available in the full protocol as described in the <i>Availability of full protocol</i> section</Form.Check.Label>
                            </Form.Check>

                        </Form.Group>

                    </div>
                    {isSelected("searchstrategyavailable", "searchstrategyavailableupload") === "checked" &&
                        <div className={"radiobox"}>
                        <h2>Search strategy PDF <RecordSectionRequired /></h2>
                            {getTagValue("pdf") === "" && <>
                                <p className="helptextinline">You can upload a PDF of your search strategy and we will store it along with your PROSPERO record. A link will appear in your published PROSPERO record so people can download your PDF.</p>
                                <Form.Control
                                    className="fileupload mt-3"
                                    type="file"
                                    name="fileupload"
                                    id="fileupload"
                                    onChange={handleFileChange}
                                />
                                <Button disabled={(file === null ? true : false)} className="fileuploadbutton" variant="primary" onClick={() => handleUploadClick()}>Upload file</Button>
                                {showError(fname + "_pdf", checked, showerrors) && <div className="formerror">You must upload a PDF for this option</div>}
                            </>
                            }
                            {getTagValue("pdf") !== "" && <>
                                <p className="helptextinline">A PDF of your search strategy is stored along with your PROSPERO record. A link will appear in your published PROSPERO record so people can download your PDF.</p>

                                {showError(fname + "_pdf", checked, showerrors) && <div className="formerror">You must upload a PDF.</div>}
                                {!remove &&
                                    <>
                                        <Button className="mt-3" variant="primary" onClick={() => setRemove(true)}>Remove PDF...</Button>
                                        <Button className="mt-3" variant="outline-primary" onClick={() => showPDF()}>Preview PDF</Button>
                                    </>
                                }
                                {remove &&
                                    <><div className="helptextinline color-red ">
                                        <p>Are you sure you want to remove the PDF from this record? Removing the PDF here will NOT remove the link from the currently published record, it will only affect the new published version of the PROSPERO record. The original PDF will remain attached to previous versions of the record.</p>
                                        <Button className="mt-3" variant="warning" onClick={() => setRemove(false)}>Cancel remove</Button>
                                        <Button className="mt-3" variant="primary" onClick={() => removePDF()}>Confirm remove PDF</Button>
                                    </div>
                                    </>
                                }

                            </>
                            }

                        </div>
                    }
                </>)}
            {oldversion !== null && <RecordOldversion oldversion={oldversion} newversion={data} useoldversion={useoldversion} />}
            <RecordNavControls data={data} back={back} preview={setShowPreview} dirty={dirty} cancomplete={checked} setshowerrors={setShowErrors} showerrors={showerrors} error={error === null && oldversion === null ? null : "error"} />
        </div>)
        }
        {showpreview && (<div className="page-inner-restrict publicdocument">
            <h1>{data.caption}</h1>
            <div dangerouslySetInnerHTML={{ __html: data.preview }} />
            <div><p><br /></p>
                <p><br /></p>
            </div>
            <Button variant="primary" onClick={() => setShowPreview(false)}>Close preview</Button>
        </div>)}
        {showModal && <ModalBox show={showModal} heading={modalHeading} body={modalBody} setclose={setShowModal} />}


    </>
    )

}


export default TemplatePublishedSearch;