import axios from 'axios';
import { scryRenderedComponentsWithType } from 'react-dom/test-utils';
import {defs}  from './siteDefinitions';

// create the axios service with the base URL
const prosperoClient = axios.create({
    baseURL: defs.prosperoURL    
}); 

// get the session token
const getToken = () => {    
    return sessionStorage.getItem('token');
}

// set default headers to be used on all calls
axios.defaults.headers = {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0'
}; 

// add additional headers to the prospero calls
prosperoClient.interceptors.request.use(function (config) {
    // access token used for authentication
    config.headers['prospero-access-token'] = getToken();

    // GD security header
    let now = new Date().getTime();
    config.headers['prospero-auth-token'] = btoa(now.toString());    
    return config;
});

// check for authentication error
prosperoClient.interceptors.response.use(
    (res) => {                              
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
       
      // TODO this will eventually deal with faile authentication
      if (originalConfig != undefined && originalConfig.url !== "user/authenticate/basic" && err.response) {
        // Access Token was expired
       /* if (err.response.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;

            // log user out of app          
            sessionStorage.removeItem('token');              
            window.location = "/"; // redirect to refresh page

          return Promise.reject(err);
  
        //   try {
        //     const rs = await httpClient.post("/authenticate/refresh", {
        //       refreshToken: TokenService.getLocalRefreshToken(),
        //     });
  
        //     const { accessToken } = rs.data;
        //     TokenService.updateLocalAccessToken(accessToken);
  
        //     return httpClient(originalConfig);
        //   } catch (_error) {
        //     return Promise.reject(_error);
        //   }
        }*/
      }
      
      if(err.url === undefined)
        err.url = "unknown";
        if(err.data === undefined)
        err.data = null;
        if(err.response === undefined)
        err.response = {};
        if(err.response.data === undefined)
        err.response.data = [];
     
        return Promise.reject(err);
    }
  );


export {prosperoClient};