import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ModalBox(props) {
  const [show, setShow] = useState(props.show);

  const handleClose = () => {
    props.ok !== undefined ? props.ok() : null;
    props.setclose(false);

  }
  
  const handleShow = () => setShow(true);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.body}</Modal.Body>
        <Modal.Footer> {props.yes !== undefined &&
          <Button variant="secondary" onClick={props.yes}>
            Yes
          </Button>
        }
          {props.no !== undefined &&
            <Button variant="secondary" onClick={props.no}>
              No
            </Button>
          }
          {props.ok !== undefined &&
          <Button variant="secondary" onClick={handleClose}>
            OK
          </Button>
}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalBox;