import React, { useEffect, useState } from 'react';
import { prosperoClient } from '../api/axios';
import { axiosoptions, getRecordApproval, formatDate, submitVersion } from '../Template/TemplateUtils/utils.js';
import { Table, ProgressBar, Button } from 'react-bootstrap';

import { Navigate, useNavigate } from 'react-router-dom';
import { UseAppContext } from '../AppContextProvider';
import { Accordion, Row, ListGroup } from 'react-bootstrap';
import TemplatePreview from '../Template/TemplateItem/TemplatePreview';
import TemplateTitle from '../Template/TemplateItem/TemplateTitle';
import TemplateReviewQuestion from '../Template/TemplateItem/TemplateReviewQuestion';
import TemplateCondition from '../Template/TemplateItem/TemplateCondition';
import TemplateIntervention from '../Template/TemplateItem/TemplateIntervention';
import TemplateComparator from '../Template/TemplateItem/TemplateComparator';
import TemplateKeywords from '../Template/TemplateItem/TemplateKeywords';
import TemplateCheckSimilar from '../Template/TemplateItem/TemplateCheckSimilar';
import TemplateStudyDesign from '../Template/TemplateItem/TemplateStudyDesign';
import TemplateStudyDateRestrictions from '../Template/TemplateItem/TemplateStudyDateRestrictions';
import TemplateReviewTimeline from '../Template/TemplateItem/TemplateReviewTimeline';
import TemplatePublishedProtocol from '../Template/TemplateItem/TemplatePublishedProtocol';
import TemplateUnpublishedStudies from '../Template/TemplateItem/TemplateUnpublishedStudies';
import TemplateSearchDatabases from '../Template/TemplateItem/TemplateSearchDatabases';
import TemplateOtherStudyIDMethods from '../Template/TemplateItem/TemplateOtherStudyIDMethods';
import TemplateSearchLanguage from '../Template/TemplateItem/TemplateSearchLanguage';
import TemplatePublishedSearch from '../Template/TemplateItem/TemplatePublishedSearch';
import TemplateScreening from '../Template/TemplateItem/TemplateScreening';
import TemplateScreeningAdditional from '../Template/TemplateItem/TemplateScreeningAdditional';
import TemplateReviewTeam from '../Template/TemplateItem/TemplateReviewTeam';
import TemplateDataExtraction from '../Template/TemplateItem/TemplateDataExtraction';
import TemplateRiskOfBias from '../Template/TemplateItem/TemplateRiskOfBias';
import TemplateOrgAffil from '../Template/TemplateItem/TemplateOrgAffil';
import TemplateFunding from '../Template/TemplateItem/TemplateFunding';
import TemplatePeerReview from '../Template/TemplateItem/TemplatePeerReview';
import TemplateAdditionalInfo from '../Template/TemplateItem/TemplateAdditionalInfo';
import TemplateDataSynthesis from '../Template/TemplateItem/TemplateDataSynthesis';
import TemplatePopulation from '../Template/TemplateItem/TemplatePopulation';
import TemplateOutcomesMain from '../Template/TemplateItem/TemplateOutcomesMain';
import TemplateOutcomesAdditional from '../Template/TemplateItem/TemplateOutcomesAdditional';
import TemplateReviewStatusNew from '../Template/TemplateItem/TemplateReviewStatusNew'; // note only used in new registrations
import TemplateReviewStatus from '../Template/TemplateItem/TemplateReviewStatus';
import TemplateReviewPublication from '../Template/TemplateItem/TemplateReviewPublication';
import TemplateReviewPublicationNew from '../Template/TemplateItem/TemplateReviewPublicationNew';
import TemplateContext from '../Template/TemplateItem/TemplateContext';
import TemplateRevisionNote from '../Template/TemplateItem/TemplateRevisionNote';
import TemplateReportingBias from '../Template/TemplateItem/TemplateReportingBias';
import TemplateCertainty from '../Template/TemplateItem/TemplateCertainty';
import TemplateReviewStage from '../Template/TemplateItem/TemplateReviewStage';
import TemplateReviewStageNew from '../Template/TemplateItem/TemplateReviewStageNew';
import TemplateRationale from '../Template/TemplateItem/TemplateRationale';
import TemplateConflict from '../Template/TemplateItem/TemplateConflict';
import TemplateMeSH from '../Template/TemplateItem/TemplateMeSH';
import TemplateVersions from '../Template/TemplateItem/TemplateVersions';
import TemplateProsperoCitation from '../Template/TemplateItem/TemplateProsperoCitation';
import TemplateProsperoFullCitation from '../Template/TemplateItem/TemplateProsperoFullCitation';
import TemplateOriginalTitle from '../Template/TemplateItem/TemplateOriginalTitle';
import TemplateSearches from '../Template/TemplateItem/TemplateSearches';
import TemplateDataExtractionLegacy from '../Template/TemplateItem/TemplateDataExtractionLegacy';
import TemplateRiskOfBiasLegacy from '../Template/TemplateItem/TemplateRiskOfBiasLegacy';
import TemplateDataSynthesisLegacy from '../Template/TemplateItem/TemplateDataSynthesisLegacy';
import TemplateSubgroups from '../Template/TemplateItem/TemplateSubgroups';
import TemplateNamedContact from '../Template/TemplateItem/TemplateNamedContact';
import TemplateCountry from '../Template/TemplateItem/TemplateCountry';
import TemplateRecordStatusLabel from '../Template/TemplateItem/TemplateRecordStatusLabel';
import TemplateOtherRegDetails from '../Template/TemplateItem/TemplateOtherRegDetails';
import TemplateSameAuthor from '../Template/TemplateItem/TemplateSameAuthor';
import TemplateFirstSubmitted from '../Template/TemplateItem/TemplateFirstSubmitted';
import TemplateFirstPublished from '../Template/TemplateItem/TemplateFirstPublished';




import RecordHelp from '../Template/TemplateUtils/RecordHelp';
import RecordError from '../Template/TemplateUtils/RecordError';

import { RiCheckLine } from 'react-icons/ri';


const fname = "recordtriage";
const RecordTriage = (props) => {
  let control = localStorage.getItem("PROSPEROCONTROL") ? JSON.parse(localStorage.getItem("PROSPEROCONTROL")) : {};
  let xlastitem = control.lastitem ? control.lastitem : "";
  let opentabs = control.opentabs ? control.opentabs : 0;

  // set the initial state  
  const [tabs, setTabs] = useState(opentabs);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [purpose, setPurpose] = useState("useredit");
  const [listing, setListing] = useState("list");
  const [lastitem, setLastItem] = useState(xlastitem);
  const [increment, setIncrement] = useState(1);

  const [recordtemplate, setRecordTemplate] = useState([]);
  const [recordtemplatename, setRecordTemplateName] = useState({});
  const [approval, setApproval] = useState(null);

  const [submiterror, setSubmitError] = useState();

  const nav = useNavigate();

  // ========================================================================
  // helper function to generate object keys
  // ========================================================================
  let keyCount = 0;
  const getKey = (root) => {
    return root + "_" + keyCount++;
  }


  // ========================================================================
  // the context data store
  // ========================================================================
  const {
    recordData: {
      record, recordfields, recordtitle, recordsave, setRecordTitle, recordaccordionkeys, setRecordAccordionKeys
    },
    userData: {
      user
    }
  } = UseAppContext();

  // ========================================================================
  // helper function to load or create the data
  // ========================================================================
  if (user === undefined || user === null || user.useruuid === undefined || user.useruuid === null) {
    setTimeout(() => {
      nav("/login")
    }, 100)

    return <>Not logged in</>;
  }

  useEffect(() => {

    if (loading) {
      getRecordTemplate();


      //        const searchParams = new URLSearchParams(document.location.search);
      //      console.log(searchParams);

      if (props.params.page === undefined) {
        setListing("list");
      }
      else {
        control.lastitem = props.params.page;
        localStorage.setItem("PROSPEROCONTROL", JSON.stringify(control));
        setListing(props.params.page);
      }
    }
  }, []);


  const getRecordTitle = (tempdata) => {
    for (let i = 0; i < tempdata.length; i++) {
      let item = tempdata[i];
      let blockcontent = item.blockcontent;
      if (blockcontent !== undefined) {
        for (let j = 0; j < blockcontent.length; j++) {
          let content = blockcontent[j];
          if (content.TemplateSection === "TemplateTitle") {
            setRecordTitle(content.PreviewHTML);
            return;
          }
        }
      }
    }
  }

  const getRecordTemplate = async () => {
    try {
      const params = {
        purpose: "3048712C-3056-4DA3-A463-24D87E9E509D", // NOT USED
        recordversion: record.recordversion
      };

      const ret = await prosperoClient.get('record/template', { params }, axiosoptions)
        .then(res => {
          setLoading(false);
          setRecordTemplate(res.data.json);
          setRecordTemplateName(res.data.record);
          if (res.data.record.EditingStatus === "Awaiting approval") {
            getRecordApprovalTable();
          }
          record.recordtitle = getRecordTitle(res.data.json);
          
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
          setError(error);
        });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  }

  const getRecordApprovalTable = async () => {
    await getRecordApproval(record.recordversion)
      .then((ret) => {
        console.log(ret);
        setApproval(ret);
      })
  }



  if (props.params.page === undefined && listing !== "list") {
    setListing("list");
  }
  else if (props.params.page !== undefined && props.params.page !== listing) {
    control.lastitem = props.params.page;
    localStorage.setItem("PROSPEROCONTROL", JSON.stringify(control));
    setListing(props.params.page);
  }


  useEffect(() => {
    if (!loading) {
      //      localStorage.setItem("PROSPERO_" + record.recordid, JSON.stringify(recordfields));
    }
    setLoading(false);
  }, [recordsave]);



  const triage = {
    TemplatePreview: TemplatePreview,
    TemplateTitle: TemplateTitle,
    TemplateReviewQuestion: TemplateReviewQuestion,
    TemplateCondition: TemplateCondition,
    TemplateIntervention: TemplateIntervention,
    TemplateComparator: TemplateComparator,
    TemplateKeywords: TemplateKeywords,
    TemplateCheckSimilar: TemplateCheckSimilar,
    TemplateStudyDesign: TemplateStudyDesign,
    TemplateStudyDateRestrictions: TemplateStudyDateRestrictions,
    TemplateReviewTimeline: TemplateReviewTimeline,
    TemplatePublishedProtocol: TemplatePublishedProtocol,
    TemplateUnpublishedStudies: TemplateUnpublishedStudies,
    TemplateSearchDatabases: TemplateSearchDatabases,
    TemplateOtherStudyIDMethods: TemplateOtherStudyIDMethods,
    TemplateSearchLanguage: TemplateSearchLanguage,
    TemplatePublishedSearch: TemplatePublishedSearch,
    TemplateScreening: TemplateScreening,
    TemplateScreeningAdditional: TemplateScreeningAdditional,
    TemplateReviewTeam: TemplateReviewTeam,
    TemplateDataExtraction: TemplateDataExtraction,
    TemplateRiskOfBias: TemplateRiskOfBias,
    TemplateOrgAffil: TemplateOrgAffil,
    TemplateFunding: TemplateFunding,
    TemplatePeerReview: TemplatePeerReview,
    TemplateAdditionalInfo: TemplateAdditionalInfo,
    TemplateDataSynthesis: TemplateDataSynthesis,
    TemplatePopulation: TemplatePopulation,
    TemplateOutcomesMain: TemplateOutcomesMain,
    TemplateOutcomesAdditional: TemplateOutcomesAdditional,
    TemplateReviewStatus: TemplateReviewStatus,
    TemplateReviewStatusNew: TemplateReviewStatusNew,
    TemplateReviewPublication: TemplateReviewPublication,
    TemplateReviewPublicationNew: TemplateReviewPublicationNew,
    TemplateContext: TemplateContext,
    TemplateReportingBias: TemplateReportingBias,
    TemplateCertainty: TemplateCertainty,
    TemplateReviewStage: TemplateReviewStage,
    TemplateReviewStageNew: TemplateReviewStageNew,
    TemplateRationale: TemplateRationale,
    TemplateConflict: TemplateConflict,
    TemplateMeSH: TemplateMeSH,
    TemplateVersions: TemplateVersions,
    TemplateProsperoCitation: TemplateProsperoCitation,
    TemplateProsperoFullCitation: TemplateProsperoFullCitation,
    TemplateRevisionNote: TemplateRevisionNote,
    TemplateOriginalTitle: TemplateOriginalTitle,
    TemplateSearches: TemplateSearches,
    TemplateDataExtractionLegacy:TemplateDataExtractionLegacy, //y
    TemplateRiskOfBiasLegacy:TemplateRiskOfBiasLegacy, //yy
    TemplateDataSynthesisLegacy:TemplateDataSynthesisLegacy, //y
    TemplateSubgroups:TemplateSubgroups,
    TemplateNamedContact: TemplateNamedContact,
    TemplateCountry: TemplateCountry,
    TemplateRecordStatusLabel: TemplateRecordStatusLabel,
    TemplateOtherRegDetails: TemplateOtherRegDetails,
    TemplateSameAuthor: TemplateSameAuthor,
    TemplateFirstSubmitted: TemplateFirstSubmitted,
    TemplateFirstPublished: TemplateFirstPublished
  };

  // ========================================================================
  // flag the section we have clicked and open it. Set to "list" to show the list instead
  // ========================================================================
  const setSection = (section) => {
    // clear the scroll parameter
    localStorage.removeItem("top")
    if (section !== "list" && triage[section] != undefined) {
      setLastItem(section);
      setIncrement(increment + 1);
      //      nav("/register/" + section + "/preview");
      nav("/register/" + section);
      return;
    }
    else {
      getRecordTemplate()
        .then((ret) => {
          setIncrement(increment + 1);
          setListing(section);
        })
    }

  };

  // ========================================================================
  // Generate the list of sections
  // ========================================================================
  const ListItems = (props) => {
    if (loading)
      return null;

    let ret = [];

    var comps = props.source;
    if (comps !== undefined) {
      for (var i = 0; i < comps.length; i++) {
        const comp = comps[i];
        const compname = comp.TemplateSection;
        const caption = comp.caption;

        var now = comp.FieldStatus;
        var errors = comp.ErrorCount;
        if (comp.Mandatory > 0) {
          ret.push(<ListGroup.Item
            key={getKey("li_")}
            active={lastitem == compname ? true : false}
            action
            onClick={(e) => setSection(compname)}
            as="li"
            className="d-flex justify-content-between align-items-start">
            <div className="ms-2 me-auto">
              <div className="fw-bold cursorpointer">{caption}</div>
            </div>
            {now === 100 ? <RiCheckLine size="2rem" color="green" /> : now === 0 ? <span>Not started</span> : <span className="color-orange">In progress</span>}
          </ListGroup.Item>

          )
        }
      }
    }

    return ret;
  }


  const accordionSelect = (e) => {
    console.log(e);
    control.opentabs = e;
    localStorage.setItem("PROSPEROCONTROL", JSON.stringify(control));
  }

  const getAccordionProgress = (section, colour) => {
    var keys = Object.keys(section);
    var comps = section;

    const total = keys.length;
    let countdone = 0;
    let countinprogress = 0;

    for (var i = 0; i < keys.length; i++) {
      const comp = comps[keys[i]];

      var now = 0;
      if (comp.FieldStatus != undefined && comp.FieldStatus > 0) {
        now = comp.FieldStatus;
      }
      if (now === 100) {
        countdone++;
      }
      else if (now !== 0) {
        countinprogress++;
      }
    }

    if(colour !== undefined && colour === true) {
      return total !== countdone ? "white" : null;
    }

    return <div className={"accordion-progress"}>
      <ProgressBar >
        <ProgressBar variant="success" min={0} max={total} now={countdone} key={1} />
        <ProgressBar variant="warning" min={0} max={total} now={countinprogress} key={2} />
        <ProgressBar striped variant="warning" min={0} max={total} now={total - countinprogress - countdone} key={3} />
      </ProgressBar>
    </div>
  }

  const piedata = {
    labels: [],
    datasets: [
      {
        data: [25, 25, 50],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)'
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)'
        ],
        borderWidth: 1,
      },
    ],
  };


  const submitThisVersion = async () => {
    const ret = await submitVersion(record.recordversion);
    if (ret.status === "error") {
      setSubmitError(ret);
    }
    else {
      nav("/myprospero");
    }
  }


  const showDashboard = () => {
    nav("/recorddashboard");
  }



  /*
    Convert the returned template into an accordion
  */

  const checkBlockContent = (blockcontent) => {
    let count = 0;
    if (blockcontent !== undefined) {
      for (let i = 0; i < blockcontent.length; i++) { 
        count = count + blockcontent[i].Mandatory;
      }
    }
    return count;
  }

  const populateAccordion = () => {
    if (recordtemplate === undefined || recordtemplate.length === 0) {
      return null;
    }
    let ret = recordtemplate.flatMap((item, i) => {
      return item.Display === 0 || checkBlockContent(item.blockcontent) === 0 ? [] : <Accordion.Item key={i} eventKey={i}>
        <Accordion.Header className={getAccordionProgress(item.blockcontent, true)}>{item.TemplateBlockCaption} {getAccordionProgress(item.blockcontent)}</Accordion.Header>
        <Accordion.Body className="radiobox selected pt-3 pb-3">
          <div dangerouslySetInnerHTML={{ __html: item.TemplateBlockHTML }} />
          <ListGroup as="ol" numbered>
            <ListItems source={item.blockcontent} />
          </ListGroup>
        </Accordion.Body>
      </Accordion.Item>
    });
    return ret;
  }

  if (listing === "list") {
    return <div className="page-inner-restrict pb-3">
      <Row className="plaintext">
        <div>
          <center>
            <Button variant="primary" onClick={() => setSection("TemplatePreview")}>Preview this PROSPERO record</Button>
            <Button variant="primary" onClick={() => showDashboard()}>Go to record dashboard</Button>
          </center>
        </div>
      </Row>
      {submiterror && <RecordError errormessage={submiterror} />}

      <Row className="plaintext">
        <h1 dangerouslySetInnerHTML={{ __html: recordtitle }} />
      </Row>

      <>
        {approval === null && <p className="helptextinline">Complete all sections below. You will not be able to submit your record until all sections are complete </p>}
        {approval !== null && <p className="helptextinline">The only field you can edit while the record is in the Approval state is.</p>}

        <Accordion
          defaultActiveKey={opentabs}
          onSelect={accordionSelect}

        >
          {populateAccordion()}
        </Accordion>
      </>
      <Row className="plaintext">
        <div>
          <center>
            <Button variant="primary" onClick={() => setSection("TemplatePreview")}>Preview this PROSPERO record</Button>
            <Button variant="primary" onClick={() => showDashboard()}>Go to record dashboard</Button>
          </center>
        </div>
      </Row>

    </div>
  }
  else {
    var comps = triage;
    const SpecificSection = comps[listing];
    return <SpecificSection key={getKey("specific-" + increment)} recordmetadata={recordtemplatename} back={setSection} purpose={purpose} preview={listing === 'TemplatePreview' ? recordtemplate : null} />;
  }

}

export default RecordTriage;