import React, { useEffect, useState } from 'react';
import { Row, Container, Button } from 'react-bootstrap';
import { UseAppContext } from '../AppContextProvider';
import { Navigate, useNavigate } from 'react-router-dom';
import { Log, getDashboardCounts } from '../Template/TemplateUtils/utils.js';
import HomeBanner from './homebanner';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import { RiIndeterminateCircleFill } from 'react-icons/ri';

// Register the items we need for the graphics
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


const Home = () => {
    const [total, setTotal] = useState(null);
    const [results, setResults] = useState(null);

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const video1 = "./videos/PROSPERO VIDEO 1.mp4?autoplay=0";
    const navigate = useNavigate();


    const {
        userData: {
            user
        },
        setLogout
    } = UseAppContext();

    // ========================================================================
    // helper function to load or create the data
    // for any rerender we set the dirty flag to indicate we need to save
    // ========================================================================
    useEffect(() => {
        if (total === null) {
            getcounts();
        }

    }, []);

    const getcounts = async () => {
        await getDashboardCounts()
            .then((resp) => {
                if (resp.errormessage !== undefined) {
                    Log(resp.errormessage);
                    setResults(null);
                    setTotal(220000);
                }
                else if (resp.status !== 204) {
                    setResults(resp.results.reverse());
                    setTotal(resp.total);
                }
            })
            .catch((e) => {
                Log(e);
                setResults(null);
                setTotal(220000);
            })
            ;
    }



    sessionStorage.removeItem("currentMode");

    const changePage = () => {
        sessionStorage.setItem("currentMode", "recordTriage");
        navigate("../reviewcoversheet");
        //        navigate("../choosereviewtype");
        //        navigate("../register");
    };
    const changePageLogin = () => {
        navigate("../login");
        //        navigate("../choosereviewtype");
        //        navigate("../register");
    };


    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Recent PROSPERO registrations',
            },
        },
    };

    const getMyData = (period) => {
        if (results === null) {
            return [];
        }

        const start = period * 12;
        const end = start + 12;
        const rdata = results.flatMap((item, index) => {
            if (index < start || index >= end) {
                return [];
            }
            else {
                return item.itemcount;
            }
        });
        return rdata;
    }

    const getLabels = () => {
        if (results === null) {
            return [];
        }

        const rlabels = results.flatMap((item, index) => {
            if (index >= 12) {
                return [];
            }
            else {
                return item.Month;
            }
        });
        return rlabels;

    }

    const getYearCaption = (level) => {
        if (results === null) {
            return "";
        }

        let yr1 = results[level * 12].Year;
        let yr2 = null;
        if (results[level * 12].Month !== "January") {
            yr2 = yr1 + 1;
        }
        return "" + yr1 + (yr2 === null ? "" : "-" + yr2);
    }

    const mydata = {
        labels: getLabels(),
        datasets: [
            {
                label: getYearCaption(0),
                data: getMyData(0),
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: getYearCaption(1),
                data: getMyData(1),
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };





    if (loading)
        return <div>LOADING!!</div>
    else if (error)
        return <div>ERROR</div>
    else
        return <Container className="page-outer">

            <Row>
                <HomeBanner img="PROSPERO_banner3.jpg" tag="PROSPERO home page" />
            </Row>
            <Row>
                <div className="page-full">
                    <div className="page-23left">
                        <div className="plaintext">
                            <h1>What is PROSPERO?</h1>
                            <p>PROSPERO is an international systematic review registry that aims to promote transparency and open science, reduce reporting bias and help prevent unintended duplication and research waste.</p>
                            <p>The PROSPERO database currently includes records of over {total === undefined ? 220000 : <b>{Math.round(total / 1000) * 1000}</b>} prospectively registered systematic reviews with health related outcomes, providing easy access to key information about planned, in-progress and completed reviews. </p>
                            <p>PROSPERO is produced by the Centre for Reviews and Dissemination (CRD) and funded by the National Institute for Health and Care Research (NIHR)</p>
                        </div>
                        {results !== null && // results.errormessage != undefined &&
                            <Line options={options} data={mydata} height="120px" />
                        }
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                        <div className="ratio ratio-16x9">
                            <video className="video-fluid z-depth-1" loop controls >
                                <source src={video1} type="video/mp4" />
                            </video>
                        </div>
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>


                    </div>
                    <div className="page-13right">
                        <div className="call-to-action-right">Registering a review</div>
                        <div className="clear"></div>
                        <div className="plaintext" ><p><em>Registering a review is quick and easy and free of charge. You must be logged in to register a review.</em></p></div>
                        <div><center>
                            {user  && <Button variant="outline-primary" size="lg" onClick={changePage}>Register your review now</Button>}
                            {!user && <Button variant="outline-primary" size="lg" onClick={changePageLogin}>Log in to register your review</Button>}
                        </center></div>
                        <div className="plaintext" > </div>
                        <div className="hidden"><center><a href="HowToRegister" target="_blank">How to register</a></center></div>


                        <div className="clear">&nbsp;</div>

                        <div className="call-to-action-right">News</div>
                        <div className="clear"></div>
                        <div className="plaintext" >
                            <p><em>We are pleased to launch the new PROSPERO system!</em></p>
                            <p><em>New functionality includes automated system checks for similar already registered systematic reviews, guided completion of fields including using pick lists and type ahead functions and faster searching.</em></p>
                            <p><em>We hope you will enjoy our updated look and find the new system easy to use. We welcome your <a href="feedback">feedback</a>.</em></p>
                        </div>
                        <div><center><a href="/newsarchive" target="_blank">See previous news items in the archive...</a></center></div>

                    </div>
                </div>
            </Row>
            <Row>
            </Row>
        </Container>
}

export default Home;