import React, { useEffect, useState, useRef } from 'react';
import { Form, Button } from 'react-bootstrap';
import { gotoDOI, parseDOI, checkDOI, showError } from '../TemplateUtils/utils.js';
import RecordHelp from './RecordHelp';
import Citation from './Citation';


const fname = "DOI";
const DOI = props => {
    const [loading, setLoading] = useState(true);
    const [doilink, setDoiLink] = useState(null);                       // doi to deal woith
    const [doichecked, setDoiChecked] = useState(false);                       // doi to deal woith
    const [citation, setCitation] = useState({});                       // doi to deal woith
    const [errormessage, setErrorMessage] = useState("");

    // ========================================================================
    // helper function to load or create the data
    // for any rerender we set the dirty flag to indicate we need to save
    // ========================================================================
    useEffect(() => {
        setDoiLink(props.doi.trim())
        setCitation(null);
        setCitation(props.citation);
        setDoiChecked(props.doichecked);
        setLoading(false);
    }, []);


    useEffect(() => {
        if (!loading) {
            if (props.postcitation !== undefined) {
                props.postcitation(citation);
            }
            if (props.postdoi !== undefined) {
                props.postdoi(doilink);
            }
        }
    }, [doilink, citation]);


    const pd = async (doi) => {
        if (doi === null) {
            return null;
        }
        let ret = await parseDOI(doi)
            .then((resp) => {
                return resp;
            }
            )
            .then((resp) => {
                if(resp.errormessage !== undefined) {
                    setErrorMessage(resp.errormessage);
                    setCitation({});
                    if (props.postcitation !== undefined) {
                        props.postcitation({});
                    }
                }
                else {
                    setErrorMessage("");
                    setCitation(resp.results);
                    if (props.postcitation !== undefined) {
                        props.postcitation(resp.results);
                    }
                }
                if (props.postdoi === undefined) {
                    props.postdoi("");
                }
                else {
                    props.postdoi(doi);
                }
            }
            )
        return ret;
    }

    // ========================================================================
    // show the DOI
    // ========================================================================
    const showDOI = () => {
        if (doilink !== "") {
            pd(doilink)
        }
        gotoDOI(doilink);
    }


    return <>
        <div >
            <h2 className="">{props.caption}<RecordHelp className="smalltext" fname={"DOI"} helpsection="help1" label=" (What is a DOI?)" /></h2>
            <p className="helptextinline">The DOI will appear in your published PROSPERO record as a clickable link, so please ensure it is correct by clicking the "Check DOI" button.
            </p>
            <Form.Control
                type="text"
                name="doi"
                id={"inputid-1"}
                aria-describedby={"inputid_help"}
                onChange={(e) => { setCitation({}); setDoiLink(e.currentTarget.value.trim()) }}
                value={doilink === null ? "" : doilink}
                placeholder="e.g. 10.1093/ajae/aaq063"
                className="doiwidth mt-3"
            />
            <Button variant="primary" onClick={() => showDOI()} disabled={doilink === null || doilink === "" ? true : false}>Check DOI</Button>
            <RecordHelp className="smalltext" fname={fname} helpsection="help2" label="What if my DOI does not resolve?" />
            {citation !== undefined && citation !== {} && <Citation format={'citation_display'} data={citation} />}
            {errormessage != "" && <div className="formerror">{errormessage}</div>}
        </div>
    </>

}

export default DOI;

