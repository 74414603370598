import React, { useEffect, useState, useRef } from 'react';
import { stripHTML, formatDate, toWords, baseURL, feURL } from './utils.js';
import Citation from './Citation';
import { renderToStaticMarkup, renderToString } from 'react-dom/server'
import { RiCheckLine } from "react-icons/ri";

// ============================================================
// Class to build the preview, one clause per section
// ============================================================
class Preview {

    // ============================================================
    // TemplateTitle
    // ============================================================
    TemplateTitle(data) {
        let preview = "";

        // get the editor text
        // NOTE: title editor text is plain text so we need to add P tags
        let freetext = data.content[0].editors.editor1.datahtml;

        if (freetext != undefined && freetext != "" && freetext !== "<p></p>") {
            preview = preview + "<p>" + freetext + "</p>";
        }
        else {
            preview = preview + "<p>No title available.</p>";
        }
        data.preview = preview;
        data.plaintext = data.content[0].editors.editor1.datatext;
        return true;

    }

    // ============================================================
    // TemplateTitle
    // ============================================================
    TemplateOriginalTitle(data) {
        let preview = "";

        // get the editor text
        // NOTE: title editor text is plain text so we need to add P tags
        let freetext = data.content[0].editors.editor1.datahtml;

        if (freetext != undefined && freetext != "" && freetext !== "<p></p>") {
            preview = preview + "<p>" + freetext + "</p>";
        }
        else {
            preview = preview + "";
        }
        data.preview = preview;
        data.plaintext = data.content[0].editors.editor1.datatext;
        return true;

    }

    // ============================================================
    // TemplateReportingBias
    // ============================================================
    TemplateReportingBias(data) {
        const content = data.content[0];
        const tags = content.tags;
        let freetext = content.editors.editor1.datahtml;
        let preview = "";

        if (tags.biastype === "") {
            preview = preview + "<p>No decision made yet</p>"
        }
        else if (tags.biastype === "nobiastype") {
            preview = preview + "<p>Risk of bias due to missing results will not be assessed</p>"
        }
        else {
            if (freetext != undefined && freetext != "" && freetext !== "<p></p>") {
                preview = preview + freetext;
            }
            else {
                preview = preview + "<p>Risk of bias due to missing results will be assessed</p>"
            }
        }

        data.preview = preview;
        return true;
    }


    // ============================================================
    // TemplateRevisionNote
    // ============================================================
    TemplateRevisionNote(data) {
        let preview = "";
        let freetext = data.content[0].editors.editor1.datahtml;

        if (freetext != undefined && freetext != "" && freetext !== "<p></p>") {
            preview = preview + freetext;
        }
        else {
            preview = preview + "<p>No revision note entered yet.</p>";
        }

        data.preview = preview;
        return true;
    }
    // ============================================================
    // TemplateContext
    // ============================================================
    TemplateContext(data) {
        let preview = "";
        let freetext = data.content[0].editors.editor1.datahtml;

        if (freetext != undefined && freetext != "" && freetext !== "<p></p>") {
            preview = preview + freetext;
        }
        else {
            preview = preview + "<p>No context available.</p>";
        }

        data.preview = preview;
        return true;
    }

    // ============================================================
    // TemplateContext
    // ============================================================
    TemplateNamedContact(data) {
        let preview = "";
        const content = data.content[0];
        const tags = content.tags;

        preview = preview
            + (tags.title === "" ? "" : tags.title)
            + (tags.firstname === "" ? "" : " " + tags.firstname)
            + (tags.lastname === "" ? "" : " " + tags.lastname)
            + (tags.email === "" ? "" : " (" + tags.email + ")")
            + (tags.orcid === "" ? "" : "<br />ORCID: " + tags.orcid + "")
            + (tags.organisation === "" ? "" : "<br />" + tags.organisation + "")
            + (tags.country === "" ? "" : " (" + tags.country + ")")

        if (preview === "") {
            preview = preview + "<p>No named contact available</p>";
        }
        else {
            preview = "<p>" + preview.trim() + "</p>";
        }

        data.preview = preview;
        return true;
    }

    // ============================================================
    // TemplateSubgroups
    // TemplateCTemplateSubgroupsontext
    TemplateSubgroups(data) {
        let preview = "";
        let freetext = data.content[0].editors.editor1.datahtml;

        if (freetext != undefined && freetext != "" && freetext !== "<p></p>") {
            preview = preview + freetext;
        }
        else {
            preview = preview + "";
        }

        data.preview = preview;
        return true;
    }

    // ============================================================
    // TemplateDataSynthesisLegacy
    TemplateDataSynthesisLegacy(data) {
        let preview = "";
        let freetext = data.content[0].editors.editor1.datahtml;

        if (freetext != undefined && freetext != "" && freetext !== "<p></p>") {
            preview = preview + freetext;
        }
        else {
            preview = preview + "No data synthesis yet";
        }

        data.preview = preview;
        return true;
    }

    // ============================================================
    // TemplateDataSynthesisLegacy
    // ============================================================
    TemplateRiskOfBiasLegacy(data) {
        let preview = "";
        let freetext = data.content[0].editors.editor1.datahtml;

        if (freetext != undefined && freetext != "" && freetext !== "<p></p>") {
            preview = preview + freetext;
        }
        else {
            preview = preview + "No risk of bias yet";
        }

        data.preview = preview;
        return true;
    }


    // ============================================================
    // TemplateDataExtractionLegacy
    // ============================================================
    TemplateDataExtractionLegacy(data) {
        let preview = "";
        let freetext = data.content[0].editors.editor1.datahtml;

        if (freetext != undefined && freetext != "" && freetext !== "<p></p>") {
            preview = preview + freetext;
        }
        else {
            preview = preview + "No data extraciton yet";
        }

        data.preview = preview;
        return true;
    }






    // ============================================================
    // TemplateSearch
    // ============================================================
    TemplateSearches(data) {
        let preview = "";
        let freetext = data.content[0].editors.editor1.datahtml;

        if (freetext != undefined && freetext != "" && freetext !== "<p></p>") {
            preview = preview + freetext;
        }
        else {
            preview = preview + "<p>No search entered.</p>";
        }

        data.preview = preview;
        return true;
    }

    // ============================================================
    // TemplateRationale
    // Checked 2024-02-21
    // ============================================================
    TemplateRationale(data) {
        let preview = "";
        let freetext = data.content[0].editors.editor1.datahtml;

        if (freetext != undefined && freetext != "" && freetext != "<p></p>") {
            preview = preview + freetext;
        }
        else {
            preview = preview + "<p>No rationale available.</p>";
        }

        data.preview = preview;
        return true;
    }

    // ============================================================
    // TemplateCertainty
    // ============================================================
    TemplateCertainty(data) {
        const content = data.content[0];
        const tags = content.tags;
        let freetext = content.editors.editor1.datahtml;
        let preview = "";

        if (tags.certaintytype === "") {
            preview = preview + "<p>No decision made yet</p>"
        }
        else if (tags.certaintytype === "nocertaintytype") {
            preview = preview + "<p>Certainty of findings will not be assessed</p>"
        }
        else {

            if (freetext != undefined && freetext != "" && freetext !== "<p></p>") {
                preview = preview + freetext;
            }
            else {
                preview = preview + "<p>Certainty of findings will be assessed</p>"
            }
        }

        data.preview = preview;
        return true;
    }


    // ============================================================
    // TemplateReviewQuestion
    // ============================================================
    TemplateReviewQuestion(data) {
        let preview = "";
        let freetext = data.content[0].editors.editor1.datahtml;

        if (freetext != undefined && freetext != "" && freetext !== "<p></p>") {
            preview = preview + freetext;
        }
        else {
            preview = preview + "<p>No review question available.</p>";
        }

        data.preview = preview;
        return true;
    }

        // ============================================================
    // TemplateSameAuthor
    // ============================================================
    TemplateSameAuthor(data) {
        let preview = "";
        let freetext = data.content[0].editors.editor1.datahtml;

        if (freetext != undefined && freetext != "" && freetext !== "<p></p>") {
            preview = preview + freetext;
        }

        data.preview = preview;
        return true;
    }

    // ============================================================
    // TemplateOtherRegDetails
    // ============================================================
    TemplateOtherRegDetails(data) {
        let preview = "";
        let freetext = data.content[0].editors.editor1.datahtml;

        if (freetext != undefined && freetext != "" && freetext !== "<p></p>") {
            preview = preview + freetext;
        }
        else {
            preview = preview + "";
        }

        data.preview = preview;
        return true;
    }

    // ============================================================
    // TemplateOutcomesMain
    // ============================================================
    TemplateOutcomesMain(data) {
        let preview = "";
        let freetext = data.content[0].editors.editor1.datahtml;

        if (freetext != undefined && freetext != "" && freetext !== "<p></p>") {
            preview = preview + freetext;
        }
        else {
            preview = preview + "<p>No outcomes specified.</p>";
        }

        data.preview = preview;
        return true;
    }

    // ============================================================
    // TemplateOutcomesAdditional
    // ============================================================
    TemplateOutcomesAdditional(data) {
        let preview = "";
        let freetext = data.content[0].editors.editor1.datahtml;

        if (freetext != undefined && freetext != "" && freetext !== "<p></p>") {
            preview = preview + freetext;
        }
        else {
            preview = preview + "<p>There are no additional outcomes.</p>";
        }

        data.preview = preview;
        return true;
    }

    // ============================================================
    // TemplateKeywords
    // ============================================================
    TemplateKeywords(data) {
        let preview = "";

        const content = data.content[0];
        const list = content.lists.list1;

        for (let i = 0; i < list.length; i++) {
            if (preview != "") {
                if (i < list.length - 1) {
                    preview = preview + "; ";
                }
                else {
                    preview = preview + "; ";
                }
            }
            preview = preview + "<span>" + list[i].label + "</span>"
        }

        data.preview = "<p>" + preview + "</p>";
        return true;
    }



    // ============================================================
    // TemplateCountry
    // ============================================================
    TemplateCountry(data) {
        let preview = "";

        const content = data.content[0];
        const list = content.lists.list1;

        for (let i = 0; i < list.length; i++) {
            if (preview != "") {
                if (i < list.length - 1) {
                    preview = preview + "; ";
                }
                else {
                    preview = preview + "; ";
                }
            }
            preview = preview + "<span>" + list[i].label + "</span>"
        }

        data.preview = "<p>" + preview + "</p>";
        return true;
    }


    // ============================================================
    // TemplateKeywords
    // ============================================================
    TemplateMeSH(data) {
        let preview = "";

        const content = data.content[0];
        const list = content.lists.list1;

        for (let i = 0; i < list.length; i++) {
            if (preview != "") {
                if (i < list.length - 1) {
                    preview = preview + "; ";
                }
                else {
                    preview = preview + "; ";
                }
            }
            preview = preview + "<span>" + list[i].label + "</span>"
        }

        data.preview = "<p>" + preview + "</p>";
        return true;
    }





    // ============================================================
    // TemplateFirstPublished
    // ============================================================
    TemplateFirstPublished(data) {
        let preview = "";

        const content = data.content[0];
        const list = content.lists.list1;

        if (list.length === 0) {
            preview = preview + "<p>This PROSPERO record has not been published.</p>";
        }
        else {
            for (let i = 0; i < list.length; i++) {
                if (preview != "") {
                    if (i < list.length - 1) {
                        preview = preview + "; ";
                    }
                    else {
                        preview = preview + "; ";
                    }
                }
                preview = preview + "<span>" + list[i] + "</span>"
            }
        }
        data.preview = preview;
        return true;
    }


    // ============================================================
    // TemplatePopulation
    // ============================================================
    TemplatePopulation(data) {
        let preview = "";

        const content = data.content[0];

        let freetext = content.editors.editor1.datahtml;

        preview = preview + "<h3>Included</h3>";
        if (freetext != undefined && freetext != "" && freetext !== "<p></p>") {
            preview = preview + freetext;
        }
        else {
            preview = preview + "<p>No inclusion criteria defined yet</p>";
        }

        freetext = content.editors.editor2.datahtml;
        if (freetext != undefined && freetext != "" && freetext != "<p></p>") {
            preview = preview + "<h3>Excluded</h3>";
            preview = preview + freetext;
        }

        data.preview = preview;
        return true;
    }


    // ============================================================
    // TemplateCondition
    // ============================================================
    TemplateCondition(data) {
        let preview = "";

        const content = data.content[0];

        const list1 = content.lists.list1;

        let tagspreview = list1.map(function (item) {
            return item.label;
        }).join("; ");

        // get the editor text
        let freetext = content.editors.editor1.datahtml;
        if (freetext === "<p></p>")
            freetext = "";

        if (tagspreview != "") {
            preview = preview + "<p><i>" + tagspreview + "</i></p>";
        }

        if (freetext != undefined && freetext != "" && freetext !== "<p></p>") {
            preview = preview + freetext;
        }
        data.preview = preview;
        return true;
    }



    // ============================================================
    // TemplateIntervention
    // ============================================================
    TemplateIntervention(data) {
        let preview = "";

        const content = data.content[0];

        preview = preview + "<h3>Included</h3>";
        const list1 = content.lists.list1;

        let tagspreview = list1.map(function (item) {
            return item.label;
        }).join("; ");

        // get the editor text
        let freetext = content.editors.editor1.datahtml;
        if (freetext === "<p></p>")
            freetext = "";

        if (tagspreview != "") {
            preview = preview + "<p><i>" + tagspreview + "</i></p>";
        }
        if (freetext != undefined && freetext != "" && freetext !== "<p></p>") {
            preview = preview + freetext;
        }


        // get the editor text
        freetext = content.editors.editor2.datahtml;
        if (freetext === "<p></p>")
            freetext = "";

        if ((freetext !== undefined && freetext !== "")) {
            preview = preview + "<h3>Excluded</h3>";
            if (freetext != undefined && freetext != "" && freetext !== "<p></p>") {
                preview = preview + freetext;
            }
        }

        data.preview = preview;
        return true;
    }



    // ============================================================
    // TemplateComparator
    // ============================================================
    TemplateComparator(data) {
        let preview = "";

        const content = data.content[0];
        const tags = content.tags;

        if (tags.comparatoryn === "") {
            preview = preview + "<p>No comparator decision made yet</p>"
        }
        else if (tags.comparatoryn === "comparatorn") {
            preview = preview + "<p>This review does not have any comparators</p>"
        }
        else {
            preview = preview + "<h3>Included</h3>";
            const list1 = content.lists.list1;

            let tagspreview = list1.map(function (item) {
                return item.label;
            }).join("; ");

            // get the editor text
            let freetext = content.editors.editor1.datahtml;
            if (freetext === "<p></p>")
                freetext = "";

            if (tagspreview != "") {
                preview = preview + "<p><i><span class='picoselected'>PICO tags selected: </span>" + tagspreview + "</i></p>";
            }

            if (freetext != undefined && freetext != "" && freetext !== "<p></p>") {
                preview = preview + freetext;
            }

            // get the editor text
            freetext = content.editors.editor2.datahtml;
            if (freetext === "<p></p>")
                freetext = "";

            if ((freetext !== undefined && freetext !== "")) {
                preview = preview + "<h3>Excluded</h3>";
                if (freetext != undefined && freetext != "" && freetext !== "<p></p>") {
                    preview = preview + freetext;
                }
            }
        }
        data.preview = preview;
        return true;
    }



    // ============================================================
    // TemplateStudyDesign
    // ============================================================
    TemplateStudyDesign(data) {
        let preview = "";
        const content = data.content[0];
        let tags = data.content[0].tags;

        preview = preview + "<h3>Included</h3>";


        if (tags.randomized === "" && tags.nonrandomized === "") {
            preview = preview + "<p>No data types selected.</p>";
        }
        else if (tags.randomized !== "" && tags.nonrandomized === "") {
            preview = preview + "<p>Only randomized study types will be included.</p>";
        }
        else if (tags.randomized === "" && tags.nonrandomized !== "") {
            preview = preview + "<p>Only nonrandomized study types will be included.</p>";
        }
        else {
            preview = preview + "<p>Both randomized and nonrandomized study types will be included.</p>";
        }

        let freetext = content.editors.editor1.datahtml;
        let text = content.editors.editor1.datatext;

        if (freetext != undefined && freetext != "" && text.trim() != "") {
            preview = preview + freetext;
        }

        freetext = content.editors.editor2.datahtml;
        text = content.editors.editor2.datatext;

        if (freetext != undefined && freetext != "" && text.trim() != "") {
            preview = preview + "<h3>Excluded</h3>";
            preview = preview + freetext;
        }

        data.preview = preview;
        return true;
    }


    // ============================================================
    // TemplateSearchDatabases
    // ============================================================
    TemplateSearchDatabases(data) {
        let preview = "";

        const content = data.content[0];

        var list = content.lists.list1;


        if (list.length === 0) {
            preview = preview + "<p>No databases selected yet.</p>";
        }
        else if (list.length === 1 && list[0] === "Other") {
            preview = preview + "<p>None of the major databases will be searched but other important databases will be, as described below.</p>";
        }
        else if (list.length === 1 && list[0] !== "Other") {
            preview = preview + "<p>The main database to be searched is <i>" + list[0] + "</i>.</p>";
        }
        else if (list.length === 2 && list[0] === "Other") {
            preview = preview + "<p>The main database to be searched is <i>" + list[1] + "</i>.</p>";
        }
        else if (list.length === 2 && list[1] === "Other") {
            preview = preview + "<p>The main database to be searched is <i>" + list[0] + "</i>.</p>";
        }
        else {
            preview = preview + "<p>The main databases to be searched are ";

            let temp = "";
            for (let i = 0; i < list.length; i++) {
                if (list[i] === "Other") {
                    continue;
                }

                if (temp !== "" && i === list.length - 1) {
                    temp = temp + " and ";
                }
                else if (temp !== "") {
                    temp = temp + ", ";
                }
                temp = temp + "<i>" + list[i] + "</i>";
            }
            preview = preview + temp + ".</p>";
        }

        if (content.editors.editor1.datahtml != undefined) {
            let freetext = content.editors.editor1.datahtml;
            if (freetext !== "") {
                preview = preview + "<h3>Other important or specialist databases that will be searched</h3>";
                preview = preview + freetext;
            }
        }

        data.preview = preview;
        return true;

    }





    // ============================================================
    // TemplateAdditionalInfo
    // ============================================================
    TemplateAdditionalInfo(data) {
        let preview = "";
        let freetext = data.content[0].editors.editor1.datahtml;

        if (freetext != undefined && freetext != "" && freetext !== "<p></p>") {
            preview = preview + freetext;
        }
        else {
            preview = preview + "";
        }

        data.preview = preview;
        return true;

    }

    // ============================================================
    // TemplatePeerReview
    // ============================================================
    TemplatePeerReview(data) {
        let preview = "";

        const content = data.content[0];
        const tags = content.tags;

        const type = tags.peerreviewed;
        if (type === "notpeerreviewed") {
            preview = preview + "<p>There has been no peer review of this planned review.</p>"
        }
        else {
            let freetext = content.editors.editor1.datahtml;

            if (freetext != undefined && freetext != "" && freetext != "<p></p>") {
                preview = preview + freetext;
            }
        }

        data.preview = preview;
        return true;
    }



    // ============================================================
    // TemplateConflict
    // ============================================================
    TemplateConflict(data) {
        let preview = "<p>Declared individual interests are recorded under team member details.";

        // GD 2024-02-23 added read-only elements to content as a second array
        // e.g. data.content[1][0] etc

        // ============================================================
        // Commercial funders
        // ============================================================
        let funding = {};
        if (data.content.length > 1) {
            const subs = data.content[1];
            for (let i = 0; i < subs.length; i++) {
                let sub = subs[i];
                if (sub.section !== undefined && sub.section.TemplateSection !== undefined && sub.section.TemplateSection === "TemplateFunding") {
                    funding = sub.section;
                    break;
                }
            }

        }

        if ((funding.lists !== undefined && funding.lists.list2 !== undefined && funding.lists.list2.length > 0) || 
            (funding.editors.editor2.datahtml !== undefined && funding.editors.editor2.datahtml != "" )) {
            preview = preview + " This review is funded by a commercial organisation.";
        }

        // ============================================================
        // Personal cofe
        // ============================================================
        let teamcount = 0;
        if (data.content.length > 1) {
            const subs = data.content[1];
            for (let i = 0; i < subs.length; i++) {
                let sub = subs[i];
                if (sub.section !== undefined && sub.section.TemplateSection !== undefined && sub.section.TemplateSection === "TemplateReviewTeam") {
                    let team = sub.section.lists.list1;
                    for (let j = 0; j < team.length; j++) {
                        let member = team[j];
                        if (member.role === 'team' && member.conflictyn === "cofi") {
                            teamcount++;
                        }
                    }
                    break;
                }
            }

        }

        if (teamcount > 0) {
            preview = preview + " " + toWords(teamcount)[0].toUpperCase() + toWords(teamcount).substring(1) + " review team member" + (teamcount !== 1 ? 's have declared a ' : ' has declared a ') + "potential conflict of interest";
        }

        const content = data.content[0];
        const tags = content.tags;
        preview = preview + ".";

        const type = tags.conflict;
        if (type === "conflictn") {
            preview = preview + " No additional interests are recorded for this review.</p>"
        }
        else if (type === "conflicty") {
            preview = preview + "This review also notes the following interests:</p>"

            let freetext = content.editors.editor1.datahtml;

            if (freetext != undefined && freetext != "" && freetext != "<p></p>") {
                preview = preview + freetext;
            }
        }
        else {
            preview = preview + "</p>";
        }

        data.preview = preview;
        return true;
    }


    // ============================================================
    // TemplateConfTemplateReviewPublicationlict
    // ============================================================
    TemplateReviewPublication(data) {
        let preview = "";

        // GD 2024-02-23 added read-only elements to content as a second array
        // e.g. data.content[1][0] etc


        const content = data.content[0];
        const tags = content.tags;
        const list = content.lists.list1;

        if (tags.willpublish === "") {
            preview = preview + "<p>Decision whether to publish has not been made yet.</p>"
        }
        else if (tags.willpublish === "willpublishn") {
            preview = preview + "<p>Results of the review will not be published.</p>"

            let freetext = content.editors.editor1.datahtml;

            if (freetext != undefined && freetext != "" && freetext !== "<p></p>") {
                preview = preview + freetext;
            }
        }
        else {
            // languages

            if (list.length === 0) {
                preview = preview + "<p>Results of the review will be published.</p>";
            }
            else {
                preview = preview + "<p>Results of the review will be published in ";
                for (let i = 0; i < list.length; i++) {
                    if (i !== 0) {
                        if (i < list.length - 1) {
                            preview = preview + ", ";
                        }
                        else {
                            preview = preview + " and ";
                        }
                    }
                    preview = preview + "<span>" + list[i].label + "</span>"
                }
                preview = preview + ".</p>";
            }


            if (tags.preprintdoi !== "") {
                preview = preview + "<h3>Preprint publication</h3>"
                if (Object.keys(tags.preprintdoicitation).length > 0) {
                    preview = preview + renderToStaticMarkup(<Citation linktodoi={"link"} data={tags.preprintdoicitation} />);
                }
                else {
                    preview = preview + tags.preprintdoi;
                }
            }

            preview = preview + "<h3>Journal publication</h3>"
            if (tags.published === "") {
                preview = preview + "<p>Journal publication decision not made yet.</p>"
            }
            else if (tags.published === "publishedn") {
                preview = preview + "<p>Not yet published in a journal but will be in future.</p>"
            }
            else {
                if (Object.keys(tags.publisheddoicitation).length > 0) {
                    preview = preview + renderToStaticMarkup(<Citation linktodoi={"link"} data={tags.publisheddoicitation} />);
                }
                else {
                    preview = preview + tags.publisheddoi;
                }
            }

        }

        // handle legacy records
        
        let freetext = content.editors.editor2.datahtml;

        if (freetext != undefined && freetext != "" && freetext !== "<p></p>") {
            preview = preview + "<h3>Publication details</h3>"
            preview = preview + freetext;
        }

        data.preview = preview;
        return true;
    }

    // ============================================================
    // TemplateFunding
    // ============================================================
    TemplateFunding(data) {
        let preview = "";

        const content = data.content[0];
        const tags = content.tags;

        // take both com and noncom lists
        const list = [...content.lists.list1, ...content.lists.list2];

        const type = tags.fundingtype;
        if (type === "fundingtypenoncom") {
            for (let i = 0; i < list.length; i++) {

                if (i > 0 && i == list.length - 1) {
                    preview = preview + " and ";
                }
                else if (i > 0) {
                    preview = preview + ", ";
                }
                preview = preview + "<i>" + list[i] + "</i>";
            }
            preview = preview + ".</p>"

            if (tags.grantyn === "grant") {
                preview = preview + "<h3>Grant number</h3>";
                preview = preview + tags.grantnumber;
            }
        }
        else if (type === "fundingtypesup") {
            preview = preview + "<p>Review has no specific/external funding but is supported by guarantor/review team (non-commercial) institutions.</p>";

        }
        else if (type === "fundingtypeno") {
            preview = preview + "<p>Review has no funding and no agreed support from an academic institution and is done in authors’ own time.</p>";
        }
        else {
            preview = preview + "<p>No funding option chosen yet</p>"
        }


        // get the editor text
        let freetext1 = content.editors.editor1.datahtml;
        let freetext2 = content.editors.editor2.datahtml;
        let freetext3 = content.editors.editor3.datahtml;

        if (freetext1 != undefined && freetext1 != "" && freetext1 !== "<p></p>") {
            preview = preview + "<h3>Additional non-commercial funding information</h3>";
            preview = preview + freetext1;
        }
        if (freetext1 != undefined && freetext1 != "" && freetext1 !== "<p></p>") {
            preview = preview + "<h3>Additional commercial funding information</h3>";
            preview = preview + freetext2;
        }
        if (freetext1 != undefined && freetext1 != "" && freetext1 !== "<p></p>") {
            preview = preview + "<h3>Additional information about funding</h3>";
            preview = preview + freetext3;
        }

        data.preview = preview;
        return true;
    }

    TemplateCheckSimilar(data) {
        let preview = "";
        const content = data.content[0];
        const tag = content.tags;


        if (tag.checksimilar === "") {
            preview = preview + "<p>No checking decision made yet</p>"
        }
        else if (tag.checksimilar === "notsimilar") {
            if (content.editor.datahtml == undefined) {
                preview = preview + "<p>No records were checked. The authors have not given a reason yet</p>"
            }
            else {
                preview = preview + "<p>When this review was registered the review team chose not to examine already registered reviews that PROSPERO identified as similar to this review. The team gave the following reason(s) for not checking</p>"
                let freetext = content.editor.datahtml;
                preview = preview + freetext;
            }
        }
        else {

            preview = preview + "<p><i>PROSPERO identified a number of existing PROSPERO records that were similar to this one (last check made on " + formatDate(new Date()) + "). These are shown below along with the reasons given by that the review team for the reviews being different and/or proceeding.</i></p>";

            // convert tags to a string
            let tags = data.content[0].lists.list1;
            let tagspreview = tags.map(function (item, index) {
                if (index < content.mintocheck || item.select1 === "similar" || item.select1 === "bogus") {
                    let ret = "<li>";
                    ret = ret + "<span class='previewchecktitle'>";
                    ret = ret + stripHTML(item.title.trim()) + " [published " + formatDate(item.datefirstpublished) + "]";
                    ret = ret + "</span>";
                    ret = ret + "<span class='previewcheckaccno'>";
                    ret = ret + " [" + item.accessionnumber + "]. ";
                    ret = ret + "</span>";

                    // author response
                    if (item.select1 === "similar") {
                        ret = ret + "<span class='previewcheckreason'>";
                        if (item.select2 === "exit") {
                            ret = ret + "The review was acknoweldged as <b>similar</b> to the current review and the authors chose to abandon it.";
                        }
                        else {
                            ret = ret + "The review was acknoweldged as <b>similar</b> but the authors opted to continue because ";
                            ret = ret + item.select3.map(function (reason) {
                                if (reason === "continue-uptodate") {
                                    return "<i>the review will be more up to date</i>"
                                }
                                else if (reason === "continue-outcomes") {
                                    return "<i>the review looks at additional or different outcomes</i>"
                                }
                                else if (reason === "continue-methods") {
                                    return "<i>the review uses improved methods</i>"
                                }
                                else if (reason === "continue-conflict") {
                                    return "<i>there is some concern about potential conflicts of interest</i>"
                                }
                                else if (reason === "continue-population") {
                                    return "<i>there are differences in population</i>"
                                }
                                else if (reason === "continue-pico") {
                                    return "<i>there are differences in intervention or comparator</i>"
                                }

                            }).join(", ");

                            if (item.select3other != "") {
                                ret = ret + ". The authors also noted: <i>\"" + item.select3other + "</i>\"";
                            }
                            ret = ret + "</span>";
                        }
                    }
                    else if (item.select1 === "bogus") {
                        ret = ret + "<span class='previewcheckreason'>";
                        ret = ret + "The review was judged <b>not to be similar</b>";
                        ret = ret + "</span>";
                    }
                    else {
                        ret = ret + "<span class='previewcheckreason'>";
                        ret = ret + "<b>The authors did not check this review</b>";
                        ret = ret + "</span>";
                    }

                    ret = ret + "</li>";
                    return ret;
                }
            }).join(" ");

            if (tagspreview != "") {
                preview = preview + "<ul>" + tagspreview + "</ul>";
            }
        }
        data.preview = preview;
        return true;
    }



    // ============================================================
    // TemplateRiskOfBias
    // ============================================================
    TemplateRiskOfBias(data) {
        let preview = "";

        const content = data.content[0];
        const tags = content.tags;
        const list1 = content.lists.list1;
        const list2 = content.lists.list2;
        const editor1 = content.editors.editor1;
        const editor2 = content.editors.editor2;

        if (tags.robtype === "") {
            preview = preview + "<p>No option selected yet.</p>";
        }
        else if (tags.robtype === "norobtype") {
            preview = preview + "<p>Risk of bias/study quality will not be assessed.</p>";
        }
        else {
            // randomised rob tools
            if (tags.robrandomised !== undefined && tags.robrandomised === "robrandomised") {
                if (list1.length == 0) {
                    preview = preview + "<p>No risk of bias/study quality tools have been selected yet.</p>";
                }
                else {
                    let temp = "";
                    let doother = false;

                    for (let i = 0; i < list1.length; i++) {
                        if (list1[i] !== "Other") {
                            if (temp !== "") {
                                if (i == list1.length - 1) {
                                    temp = temp + " and ";
                                }
                                else {
                                    temp = temp + ", ";
                                }
                            }
                            temp = temp + "<i>" + list1[i].trim() + "</i>";
                        }
                        else {
                            doother = true;
                        }
                    }
                    preview = preview + "<p>Risk of bias will be assessed using: ";
                    preview = preview + temp;
                    preview = preview + "</p>";

                    if (doother) {
                        if (editor1.datahtml !== undefined && editor1.datahtml !== null) {
                            preview = preview + editor1.datahtml;
                        }
                    }
                }
            }
            // nonrandomised rob tools
            if (tags.robnonrandomised !== undefined && tags.robnonrandomised === "robnonrandomised") {
                if (list2.length == 0) {
                    preview = preview + "<p>No risk of bias/study quality tools for Nonrandomised studies have been selected yet.</p>";
                }
                else {
                    let temp = "";
                    let doother = false;

                    for (let i = 0; i < list2.length; i++) {
                        if (list2[i] !== "OtherNonrandomised") {
                            if (temp !== "") {
                                if (i == list2.length - 1) {
                                    temp = temp + " and ";
                                }
                                else {
                                    temp = temp + ", ";
                                }
                            }
                            temp = temp + "<i>" + list2[i].trim() + "</i>";
                        }
                        else {
                            doother = true;
                        }
                    }
                    preview = preview + "<p>Risk of bias of nonrandomised studies will be assessed using: ";
                    preview = preview + temp;
                    preview = preview + ".</p>";


                    if (doother) {
                        if (editor2.datahtml !== undefined && editor2.datahtml !== null) {
                            preview = preview + editor2.datahtml;
                        }
                    }
                }
            }



            // method
            if (tags.robmethod === "") {
                preview = preview + "<p>No RoB method selected yet.</p>";
            }
            else if (tags.robmethod === "robmethod2") {
                preview = preview + "<p>Data will be assessed independently by at least two people (or person/machine combination) with a process to resolve differences.</p>";
            }
            else if (tags.robmethod === "robmethod1m") {
                preview = preview + "<p>Data will be assessed by one person (or a machine) and checked by at least one other person (or machine).</p>";
            }
            else if (tags.robmethod === "robmethod1") {
                preview = preview + "<p>Data will be assessed by one person (or machine) only.</p>";
            }

            // additional
            preview = preview + "";

            if (tags.robadditional === "") {
                preview = preview + "<p>Information not available on how to handle missing information.</p>";
            }
            else if (tags.robadditional === "robadditional") {
                preview = preview + "<p>Additional information will be sought from study investigators if required information is unclear or unavailable in the study publications/reports.</p>";
            }
            else if (tags.robadditional === "norobadditional") {
                preview = preview + "<p>Additional information will <b>not</b> be sought from study investigators if required information is unclear or unavailable in the study publications/reports.</p>";
            }

        }

        data.preview = preview;
        return true;
    }
    // ============================================================
    // TemplateDataExtraction
    // ============================================================
    TemplateDataExtraction(data) {
        let preview = "";

        const content = data.content[0];
        const tags = content.tags;

        if (tags.extractionmethod === "") {
            preview = preview + "<p>No option selected yet.</p>";
        }
        else {
            if (tags.extractionmethod === "extractionmethodno") {
                preview = preview + "Data will not be extracted from publications or reports";
            }
            else if (tags.extractionmethod === "extractionmethod2") {
                preview = preview + "<p>Data will be extracted independently by at least two people (or person/machine combination) with a process to resolve differences.</p>";
            }
            else if (tags.extractionmethod === "extractionmethod1m") {
                preview = preview + "<p>Data will be extracted by one person (or a machine) and checked by at least one other person (or machine).</p>";
            }
            else if (tags.extractionmethod === "extractionmethod1") {
                preview = preview + "<p>Data will be extracted by one person (or machine) only.</p>";
            }
            else if (tags.extractionmethod === "extractionmethodother") {
                if (content.editors.editor1.datahtml !== undefined && content.editors.editor1.datahtml !== null) {
                    preview = preview + content.editors.editor1.datahtml;
                }
                else {
                    preview = preview + "<p>Information not available.</p>";
                }
            }
            else {
                preview = preview + "<p>Information not available.</p>";
            }

            //            preview = preview + "<p>&nbsp;</p>";

            if (tags.extractionmethod !== "extractionmethodno") {
                if (tags.extractionadditional === "") {
                    preview = preview + "<p>Information not available on how to handle missing data.</p>";
                }
                else if (tags.extractionadditional === "extractionadditionalno") {
                    preview = preview + "<p>Authors will not be contacted for further information.</p>";
                }
                else if (tags.extractionadditional === "extractionadditional") {
                    preview = preview + "<p>Authors will be asked to provide any required data not available in published reports.</p>";
                }

            }
        }


        // deal with the additional
        if (tags.extractiontype === "extractionipd") {
            preview = preview + "<p>Study datasets/IPD will be obtained from study investigators or via a data repository</p>";
            let freetext = data.content[0].editors.editor2.datahtml;

            if (freetext != undefined && freetext != "" && freetext !== "<p></p>") {
                preview = preview + freetext;
            }
        }
        data.preview = preview;
        return true;
    }


    TemplateProsperoCitation(data) {
        if (data === undefined) {
            return;
        }

        let title = "No title defined";
        let authors = [];
        if (data.content.length > 1) {
            const subs = data.content[1];
            for (let i = 0; i < subs.length; i++) {
                let sub = subs[i];
                if (sub.section !== undefined && sub.section.TemplateSection !== undefined && sub.section.TemplateSection === "TemplateTitle") {
                    title = sub.section.editors.editor1.datahtml;
                }
                else if (sub.section !== undefined && sub.section.TemplateSection !== undefined && sub.section.TemplateSection === "TemplateReviewTeam") {
                    let people = [];
                    for (let i = 0; i < sub.section.lists.list1.length; i++) {
                        let member = sub.section.lists.list1[i];
                        if (member.role === 'team') {
                            people.push(member);
                        }
                    }
                    authors = people.map((person, index) => {
                        return <>
                            {index > 0 ? <span>, </span> : null}
                            <span key={"author_" + index} className="prosperocitation-author">{person.firstname} {person.lastname}</span>
                        </>
                    })
                }
            }
        }

        let preview = renderToStaticMarkup(
            <>
                <div className="prosperocitation-title">{title}</div>
                <br />
                <div className="prosperocitation-authors">
                    {authors}
                </div>
            </>);

        data.preview = preview;
        return true;

    }

    TemplateProsperoFullCitation(data) {
        if (data === undefined) {
            return;
        }

        let title = "No title defined";
        let authors = [];
        if (data.content.length > 1) {
            const subs = data.content[1];
            for (let i = 0; i < subs.length; i++) {
                let sub = subs[i];
                if (sub.section !== undefined && sub.section.TemplateSection !== undefined && sub.section.TemplateSection === "TemplateTitle") {
                    title = sub.section.editors.editor1.datahtml;
                }
                else if (sub.section !== undefined && sub.section.TemplateSection !== undefined && sub.section.TemplateSection === "TemplateReviewTeam") {
                    let people = [];
                    for (let i = 0; i < sub.section.lists.list1.length; i++) {
                        let member = sub.section.lists.list1[i];
                        if (member.role === 'team') {
                            people.push(member);
                        }
                    }

                    authors = people.map((person, index) => {
                        return <>
                            {index > 0 ? <span>, </span> : null}
                            <span key={"author_" + index} className="prosperocitation-author">{person.firstname} {person.lastname}</span>
                        </>
                    })
                }
            }
        }

        let preview = renderToStaticMarkup(
            <p id="fullcitation">{authors}. {title}. PROSPERO 2024 [CRD number available on publication]. Available from <a href="https://www.crd.york.ac.uk/prospero/display_record.php?RecordID=3802">https://www.crd.york.ac.uk/prospero/display_record.php?RecordID=3802</a></p>
        );

        data.preview = preview;
        return true;

    }

    // ============================================================
    // TemplateReviewTeam
    // ============================================================
    TemplateReviewTeam(data) {
        let preview = "";

        const content = data.content[0];
        let people = [];
        for (let i = 0; i < content.lists.list1.length; i++) {
            let member = content.lists.list1[i];
            if (member.role === 'team') {
                people.push(member);
            }
        }
        const tags = content.tags;

        // check all the people are correct
        for (let i = 0; i < people.length; i++) {
            let person = people[i];
            preview = preview + "<p>";
            preview = preview + "<span class='person-name'>" + (person.title === "" ? "" : person.title + " ");
            preview = preview + (person.firstname === "" || person.lastname === "" ? "No name given." : person.firstname + " " + person.lastname) + "</span>";
            if (person.email === tags.guarantor && person.email === tags.contact) {
                preview = preview + " (review guarantor and contact)";
            }
            else {
                preview = preview + (person.email === tags.guarantor ? " (review guarantor)" : "");
                preview = preview + (person.email === tags.contact ? " (review contact)" : "") + ". ";
            }
            preview = preview + (person.orcid === undefined || person.orcid === "" ? "" : " ORCID: " + person.orcid + ".");
            preview = preview + "<span>" + (person.organisation === "" ? "" : " " + person.organisation + ".</span>");
            preview = preview + "<span>" + (person.country === "" ? "" : " " + person.country + ".</span>");

            preview = preview + "</p>";

            if (person.conflictyn === "") {
                preview = preview + " <p><span>No conflict of interest decision selected yet.</span></p>";
            }
            else if (person.conflictyn === "cofi") {
                preview = preview + "<i>Conflict of interest</i>";
                if (person.editors.editor1.datahtml !== undefined) {
                    preview = preview + person.editors.editor1.datahtml;
                }
                else {
                    preview = preview + "<p>Conflict of interest indicated but not yet given.</p>"
                }
            }
            else {
                preview = preview + " <p>No conflict of interest declared.</p>";
            }
        }

        // now add the global CofI statement
        /*        preview = preview + "<h3>Other potential review conflicts of interest</h3>";
                if (content.conflictyn === "") {
                    preview = preview + "No decision selected yet.";
                }
                else if (content.conflictyn === "NoConflict") {
                    preview = preview + "<p>No other potential conflicts of interest to declare for this review</p>";
                }
                else if (content.editor.datahtml === undefined || content.editor.datahtml === null) {
                    preview = preview + "<p>Conflict declared but no explanation given yet</p>";
                }
                else if (content.editor.datahtml !== undefined && content.editor.datahtml !== null) {
                    preview = preview + content.editor.datahtml;
                }
        
        */
        data.preview = preview;
        return true;
    }




    // ============================================================
    // TemplateScreeningAdditional
    // ============================================================
    TemplateScreeningAdditional(data) {
        let preview = "";
        let freetext = data.content[0].editor.datahtml;

        if (freetext != undefined && freetext != "" && freetext !== "<p></p>") {
            preview = preview + freetext;
        }
        else {
            preview = preview + "";
        }

        data.preview = preview;
        return true;
    }


    // ============================================================
    // TemplateOrgAffil
    // ============================================================
    TemplateOrgAffil(data) {
        let preview = "";
        let freetext = data.content[0].editors.editor1.datahtml;

        if (freetext != undefined && freetext != "" && freetext !== "<p></p>") {
            preview = preview + freetext;
        }
        else {
            preview = preview + "";
        }

        data.preview = preview;
        return true;
    }






    // ============================================================
    // TemplateScreening
    // ============================================================
    TemplateScreening(data) {
        let preview = "";

        // 
        let tags = data.content[0].tags;

        if (tags.screeningmethod === "screeningmethod2") {
            preview = preview + "<p>Studies will be screened independently by at least two people (or person/machine combination) with a process to resolve differences.</p>";
        }
        else if (tags.screeningmethod === "screeningmethod1m") {
            preview = preview + "<p>Studies will be screened by one person (or a machine) and checked by at least one other person (or machine).</p>";
        }
        else if (tags.screeningmethod === "screeningmethod1") {
            preview = preview + "<p>Studies will be screened by one person (or machine) only.</p>";
        }
        else {
            let freetext = data.content[0].editor.datahtml;
            if (freetext === undefined) {
                preview = preview + "<p>No details supplied.</p>";
            }
            else if (freetext !== "") {
                preview = preview + freetext;
            }

        }

        data.preview = preview;
        return true;
    }



    // ============================================================
    // TemplatePublishedSearch
    // ============================================================
    TemplatePublishedSearch(data) {
        let preview = "";

        // 
        let tags = data.content[0].tags;

        if (tags.searchstrategyavailable === "nosearchstrategyavailable") {
            preview = preview + "<p>A full search strategy is not available.</p>";
        }
        else if (tags.searchstrategyavailable === "searchstrategyavailableupload") {
            preview = preview + "<p>";
            preview = preview + "A full search strategy has been uploaded to PROSPERO. ";
            preview = preview + 'The PDF may be accessed through this link <a href="' + feURL + "temppdf/" + tags.pdf + '" target="_blank">' + feURL + "temppdf/" + tags.pdf + '</a>.';
            preview = preview + "</p>";
        }
        else {
            preview = preview + "<p>";
            preview = preview + "A full search strategy is available in the full protocol as described in the <i>Availability of full protocol</i> section";
            preview = preview + "</p>"
        }

        data.preview = preview;
        return true;
    }



    // ============================================================
    // TemplateSearchLanguage
    // ============================================================
    TemplateSearchLanguage(data) {
        let preview = "";

        const content = data.content[0];
        const tags = content.tags;
        if (tags.searchlanguagerestriction === "") {
            preview = preview + "<p>No selection made.</p>";
        }
        else if (tags.searchlanguagerestriction === "nosearchlanguagerestriction") {
            preview = preview + "<p>There are no language restrictions.</p>";
        }
        else {
            preview = preview + "<p>The review will only include studies published in ";

            // check the list
            const list = content.lists.list1;

            let temp = "";
            for (var i = 0; i < list.length; i++) {

                if (temp !== "" && i === list.length - 1) {
                    temp = temp + " and ";
                }
                else if (temp !== "") {
                    temp = temp + ", ";
                }
                temp = temp + list[i].label; // do not use .display here as it may contain frequency counts
            }
            preview = preview + temp + ".</p>";
        }

        data.preview = preview;
        return true;

    }

    // ============================================================
    // TemplateOtherStudyIDMethods
    // ============================================================
    TemplateOtherStudyIDMethods(data) {
        let preview = "";

        const content = data.content[0];

        var list = content.lists.list1;


        if (list.length === 0) {
            preview = preview + "No other methods will be used.";
        }
        else {
            preview = preview + "<p>Other studies will be identified by: ";

            let temp = "";
            for (var i = 0; i < list.length; i++) {

                if (temp !== "" && i === list.length - 1) {
                    temp = temp + " and ";
                }
                else if (temp !== "") {
                    temp = temp + ", ";
                }
                temp = temp + "<i>" + list[i] + "</i>"
            }
            preview = preview + temp + ".</p>";
        }

        if (content.editors.editor1.datahtml != undefined) {
            let freetext = content.editors.editor1.datahtml;
            if (freetext !== "" && freetext != "<p></p>") {
                preview = preview + "<h3>Addiitonal information about identifying studies</h3>";
                preview = preview + freetext;
            }
        }
        data.preview = preview;
        return true;

    }

    // ============================================================
    // TemplateUnpublishedStudies
    // ============================================================
    TemplateUnpublishedStudies(data) {
        let preview = "";

        const content = data.content[0];

        // first check the tags
        const tags = content.tags;
        if (tags.unpublishedstudies === "unpublishedstudies" && tags.publishedstudies === "publishedstudies") {
            preview = "<p>Both published and unpublished studies will be sought.</p>"
        }
        else if (tags.publishedstudies === "publishedstudies") {
            preview = "<p>Only published studies will be sought.</p>"
        }
        else if (tags.unpublishedstudies === "unpublishedstudies") {
            preview = "<p>Only unpublished studies will be sought.</p>"
        }
        else {
            preview = "<p>No selection made.</p>"
        }


        data.preview = preview;
        return true;

    }


    // ============================================================
    // TemplateStudyDateRestrictions
    // ============================================================
    TemplateStudyDateRestrictions(data) {
        let preview = "";
        let tags = data.content[0].tags;

        if (tags.daterestrictions === "nodaterestrictions") {
            preview = preview + "<p>There are no search date restrictions.</p>";
        }
        else if (tags.startdaterestrictions === "nostartdaterestrictions" && tags.enddaterestrictions === "noenddaterestrictions") {
            preview = preview + "<p>No search date restrictions specified yet.</p>";
        }
        else {
            preview = preview + "<p>";
            if (tags.startdaterestrictions === "startdaterestrictions" && tags.enddaterestrictions === "noenddaterestrictions") {
                preview = preview + "Databases will be searched for articles published by " + formatDate(tags.startdate) + ", there are no search end date restrictions.";
            }
            else if (tags.startdaterestrictions === "startdaterestrictions" && tags.enddaterestrictions === "enddaterestrictions") {
                preview = preview + "Databases will be searched for articles published by " + formatDate(tags.startdate) + " and before by " + formatDate(tags.enddate) + ".";
            }
            else if (tags.startdaterestrictions === "nostartdaterestrictions" && tags.enddaterestrictions === "enddaterestrictions") {
                preview = preview + "Databases will be searched for articles published before " + formatDate(tags.enddate) + ", there are no restrictions on search start date.";
            }

            preview = preview + "</p>";
        }

        data.preview = preview;
        return true;
    }

    // ============================================================
    // TemplatePublishedProtocol
    // ============================================================
    TemplateReviewTimeline(data) {
        let preview = "";
        let tags = data.content[0].tags;

        preview = preview + "<p>";
        if (tags.startdate !== "") {
            preview = preview + "Start date: " + formatDate(tags.startdate) + ". ";
        }
        else {
            preview = preview + "No start date given. ";
        }
        preview = preview + "<br />";

        if (tags.enddate !== "") {
            preview = preview + "End date: " + formatDate(tags.enddate) + ". ";
        }
        else {
            preview = preview + "No end date given. ";
        }

        preview = preview + "</p>";

        data.preview = preview;
        return true;
    }



    // ============================================================
    // TemplatePublishedProtocol
    // ============================================================
    TemplatePublishedProtocol(data) {
        let preview = "";

        // 
        let tags = data.content[0].tags;

        if (tags.protocolwritten === "noprotocolwritten") {
            preview = preview + "<p>A full protocol has not been written.</p>";
        }
        else if (tags.protocolwritten === "protocolpublished") {
            preview = preview + "<p>";
            preview = preview + "A full protocol has been written and published. ";
            if (tags.doi !== "") {
                //                preview = preview + 'The protocol may be accessed through this link <a href="https://dx.doi.org/' + tags.doi + '" target="_blank">https://dx.doi.org/' + tags.doi + '</a>.';
                preview = preview + 'The protocol may be accessed here:';
                if (Object.keys(tags.doicitation).length > 0) {
                    preview = preview + renderToStaticMarkup(<Citation linktodoi={"link"} data={tags.doicitation} />);
                }

            }
            else {
                preview = preview + 'No link has been provided yet.'
            }
            preview = preview + "</p>";
        }
        else if (tags.protocolwritten === "protocolupload") {
            preview = preview + "<p>";
            preview = preview + "A full protocol has been written and uploaded to PROSPERO. ";
            if (tags.protocolpermission === "protocolpermission") {
                preview = preview + 'The protocol may be accessed through this link <a href="' + feURL + "temppdf/" + tags.pdf + '" target="_blank">' + feURL + "temppdf/" + tags.pdf + '</a>.';
            }
            else {
                preview = preview + 'The protocol will be made available after the review is completed.'
            }
            preview = preview + "</p>";
        }
        else {
            preview = preview + "<p>";
            preview = preview + "A full protocol has been written but is not available because:";
            preview = preview + "</p><i>";
            let editor = data.content[0].editors.editor1;
            preview = preview + editor.datahtml;
            preview = preview + "</i>";
        }

        data.preview = preview;
        return true;
    }


    // ============================================================
    // TemplateReviewStage
    // ============================================================
    TemplateReviewStage(data) {
        let preview = "";
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // check the tags
        const tags = content.tags;

        preview = preview + "<div style='padding-top: 2rem; overflow-x:auto;''>";
        preview = preview + "<table class='preview-table'>";
        preview = preview + "<thead>";
        preview = preview + "<tr>";

        preview = preview + "<th>Review stage</th>";
        preview = preview + "<th class='preview-center'>Started</th>";
        preview = preview + "<th class='preview-center'>Completed</th>";
        preview = preview + "</tr>";
        preview = preview + "</thead>";

        preview = preview + "<tbody>";

        preview = preview + "<tr class='preview-row'>";
        preview = preview + "<td class='preview-caption'>Pilot work</td>";
        preview = preview + "<td class='preview-border preview-" + (tags.pilot === 'Started' || tags.pilot === 'Completed' ? "green" : "blank") + "'>" + (tags.pilot === 'Started' || tags.pilot === 'Completed' ? "✓" : "") + "&nbsp;</td>";
        preview = preview + "<td class='preview-" + (tags.pilot === 'Completed' ? "green" : "blank") + "'>" + (tags.pilot === 'Completed' ? "✓" : "") + "</td>";
        preview = preview + "</tr>";

        preview = preview + "<tr class='preview-spacer'><td/><td/><td/></tr>";

        preview = preview + "<tr class='preview-row'>";
        preview = preview + "<td class='preview-caption'>Formal searching/study identification</td>";

        preview = preview + "<td class='preview-border preview-" + (tags.searching === 'Started' || tags.searching === 'Completed' ? "green" : "blank") + "'>" + (tags.searching === 'Started' || tags.searching === 'Completed' ? "✓" : "") + "&nbsp;</td>";
        preview = preview + "<td class='preview-" + (tags.searching === 'Completed' ? "green" : "blank") + "'>" + (tags.searching === 'Completed' ? "✓" : "") + "</td>";
        preview = preview + "</tr>";

        preview = preview + "<tr class='preview-spacer'><td/><td/><td/></tr>";

        preview = preview + "<tr class='preview-row'>";
        preview = preview + "<td class='preview-caption'>Screening search results against inclusion criteria</td>";
        preview = preview + "<td class='preview-border preview-" + (tags.screening === 'Started' || tags.screening === 'Completed' ? "green" : "blank") + "'>" + (tags.screening === 'Started' || tags.screening === 'Completed' ? "✓" : "") + "&nbsp;</td>";
        preview = preview + "<td class='preview-" + (tags.screening === 'Completed' ? "green" : "blank") + "'>" + (tags.screening === 'Completed' ? "✓" : "") + "</td>";
        preview = preview + "</tr>";

        preview = preview + "<tr class='preview-spacer'><td/><td/><td/></tr>";

        preview = preview + "<tr class='preview-row'>";
        preview = preview + "<td class='preview-caption'>Data extraction or receipt of IPD</td>";
        preview = preview + "<td class='preview-border preview-" + (tags.extraction === 'Started' || tags.extraction === 'Completed' ? "green" : "blank") + "'>" + (tags.extraction === 'Started' || tags.extraction === 'Completed' ? "✓" : "") + "&nbsp;</td>";
        preview = preview + "<td class='preview-" + (tags.extraction === 'Completed' ? "green" : "blank") + "'>" + (tags.extraction === 'Completed' ? "✓" : "") + "</td>";
        preview = preview + "</tr>";

        preview = preview + "<tr class='preview-spacer'><td/><td/><td/></tr>";

        preview = preview + "<tr class='preview-row'>";
        preview = preview + "<td class='preview-caption'>Risk of bias/quality assessment</td>";
        preview = preview + "<td class='preview-border preview-" + (tags.rob === 'Started' || tags.rob === 'Completed' ? "green" : "blank") + "'>" + (tags.rob === 'Started' || tags.rob === 'Completed' ? "✓" : "") + "&nbsp;</td>";
        preview = preview + "<td class='preview-" + (tags.rob === 'Completed' ? "green" : "blank") + "'>" + (tags.rob === 'Completed' ? "✓" : "") + "</td>";
        preview = preview + "</tr>";

        preview = preview + "<tr class='preview-spacer'><td/><td/><td/></tr>";

        preview = preview + "<tr class='preview-row'>";
        preview = preview + "<td class='preview-caption'>Data synthesis</td>";
        preview = preview + "<td class='preview-border preview-" + (tags.synthesis === 'Started' || tags.synthesis === 'Completed' ? "green" : "blank") + "'>" + (tags.synthesis === 'Started' || tags.synthesis === 'Completed' ? "✓" : "") + "&nbsp;</td>";
        preview = preview + "<td class='preview-" + (tags.synthesis === 'Completed' ? "green" : "blank") + "'>" + (tags.synthesis === 'Completed' ? "✓" : "") + "</td>"; preview = preview + "</tr>";

        preview = preview + "</tbody>";
        preview = preview + "</table>";

        if (tags.other !== undefined) {
            preview = preview + "<p><br />";
            preview = preview + tags.other;
            preview = preview + "</p>";
        }

        preview = preview + "</div>";


        data.preview = preview;
        return true;
    }


    // ============================================================
    // TemplateReviewStageNew
    // ============================================================
    TemplateReviewStageNew(data) {
        let preview = "";
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // check the tags
        const tags = content.tags;

        preview = preview + "<div style='padding-top: 2rem; overflow-x:auto;''>";
        preview = preview + "<table class='preview-table'>";
        preview = preview + "<thead>";
        preview = preview + "<th>Review stage</th>";
        preview = preview + "<th class='preview-center'>Started</th>";
        preview = preview + "<th class='preview-center'>Completed</th>";
        preview = preview + "</thead>";

        preview = preview + "<tbody>";

        preview = preview + "<tr class='preview-row'>";
        preview = preview + "<td class='preview-caption'>Pilot work</td>";
        preview = preview + "<td class='preview-border preview-" + (tags.pilot === 'Started' || tags.pilot === 'Completed' ? "green" : "blank") + "'>" + (tags.pilot === 'Started' || tags.pilot === 'Completed' ? "✓" : "") + "&nbsp;</td>";
        preview = preview + "<td class='preview-" + (tags.pilot === 'Completed' ? "green" : "blank") + "'>" + (tags.pilot === 'Completed' ? "✓" : "") + "</td>";
        preview = preview + "</tr>";

        preview = preview + "<tr class='preview-spacer'><td/><td/><td/></tr>";

        preview = preview + "<tr class='preview-row'>";
        preview = preview + "<td class='preview-caption'>Formal searching/study identification</td>";

        preview = preview + "<td class='preview-border preview-" + (tags.searching === 'Started' || tags.searching === 'Completed' ? "green" : "blank") + "'>" + (tags.searching === 'Started' || tags.searching === 'Completed' ? "✓" : "") + "&nbsp;</td>";
        preview = preview + "<td class='preview-" + (tags.searching === 'Completed' ? "green" : "blank") + "'>" + (tags.searching === 'Completed' ? "✓" : "") + "</td>";
        preview = preview + "</tr>";

        preview = preview + "<tr class='preview-spacer'><td/><td/><td/></tr>";

        preview = preview + "<tr class='preview-row'>";
        preview = preview + "<td class='preview-caption'>Screening search results against inclusion criteria</td>";
        preview = preview + "<td class='preview-border preview-" + (tags.screening === 'Started' || tags.screening === 'Completed' ? "green" : "blank") + "'>" + (tags.screening === 'Started' || tags.screening === 'Completed' ? "✓" : "") + "&nbsp;</td>";
        preview = preview + "<td class='preview-" + (tags.screening === 'Completed' ? "green" : "blank") + "'>" + (tags.screening === 'Completed' ? "✓" : "") + "</td>";
        preview = preview + "</tr>";

        preview = preview + "<tr class='preview-spacer'><td/><td/><td/></tr>";

        preview = preview + "<tr class='preview-row'>";
        preview = preview + "<td class='preview-caption'>Data extraction or receipt of IPD</td>";
        preview = preview + "<td class='preview-border preview-" + (tags.extraction === 'Started' || tags.extraction === 'Completed' ? "green" : "blank") + "'>" + (tags.extraction === 'Started' || tags.extraction === 'Completed' ? "✓" : "") + "&nbsp;</td>";
        preview = preview + "<td class='preview-" + (tags.extraction === 'Completed' ? "green" : "blank") + "'>" + (tags.extraction === 'Completed' ? "✓" : "") + "</td>";
        preview = preview + "</tr>";

        preview = preview + "<tr class='preview-spacer'><td/><td/><td/></tr>";

        preview = preview + "<tr class='preview-row'>";
        preview = preview + "<td class='preview-caption'>Risk of bias/quality assessment</td>";
        preview = preview + "<td class='preview-border preview-" + (tags.rob === 'Started' || tags.rob === 'Completed' ? "green" : "blank") + "'>" + (tags.rob === 'Started' || tags.rob === 'Completed' ? "✓" : "") + "&nbsp;</td>";
        preview = preview + "<td class='preview-" + (tags.rob === 'Completed' ? "green" : "blank") + "'>" + (tags.rob === 'Completed' ? "✓" : "") + "</td>";
        preview = preview + "</tr>";

        preview = preview + "<tr class='preview-spacer'><td/><td/><td/></tr>";

        preview = preview + "<tr class='preview-row'>";
        preview = preview + "<td class='preview-caption'>Data synthesis</td>";
        preview = preview + "<td class='preview-border preview-" + (tags.synthesis === 'Started' || tags.synthesis === 'Completed' ? "green" : "blank") + "'>" + (tags.synthesis === 'Started' || tags.synthesis === 'Completed' ? "✓" : "") + "&nbsp;</td>";
        preview = preview + "<td class='preview-" + (tags.synthesis === 'Completed' ? "green" : "blank") + "'>" + (tags.synthesis === 'Completed' ? "✓" : "") + "</td>"; preview = preview + "</tr>";

        preview = preview + "</tbody>";
        preview = preview + "</div>";


        data.preview = preview;
        return true;
    }



    // ============================================================
    // TemplatePublishedProtocol
    // ============================================================
    TemplateReviewStatus(data) {
        let preview = "";
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // check the tags
        const tags = content.tags;
        const editor1 = content.editors.editor1;
        let text = "";

        if (tags.currentstatus === undefined || tags.currentstatus === "") {
            preview = preview + "<p>No status givent</p>";
        }
        else if (tags.currentstatus === "ongoing") { // if we are here
            preview = preview + "<p>The review is currently planned or ongoing.</p>";
        }
        else if (tags.currentstatus === "completed") { // if we are here
            preview = preview + "<p>The review is completed.</p>";
        }
        else if (tags.currentstatus === "discontinued") { // if we are here
            // ===================================================================================
            // ===================================================================================
            // ===================================================================================
            preview = preview + "<p>The review has been discontinued.</p>";
            preview = preview + "<h3>Reasons for discontinuing the review</h3>";
            text = editor1.datahtml;
            if (text == undefined || text === "" || text === "<p></p>") {
                preview = preview + "<p>No content available yet</p>";
            }
            else {
                preview = preview + text;
            }


        }

        data.preview = preview;
        return true;

    }

    // ============================================================
    // TemplateReviewStatusNew
    // ============================================================
    TemplateReviewStatusNew(data) {
        let preview = "";
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // check the tags
        const tags = content.tags;
        const editor1 = content.editors.editor1;
        let text = "";

        if (tags.currentstatus === undefined || tags.currentstatus === "") {
            preview = preview + "<p>No status givent</p>";
        }
        else if (tags.currentstatus === "ongoing") { // if we are here
            preview = preview + "<p>The review is currently <i>planned or ongoing</i>.</p>";
        }

        data.preview = preview;
        return true;

    }

    // ============================================================
    // TemplateDataSynthesis
    // ============================================================
    TemplateDataSynthesis(data) {
        let preview = "";
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // check the tags
        const tags = content.tags;
        const editor1 = content.editors.editor1;
        let text = "";

        if (tags.synthesisyn === undefined || tags.synthesisyn === "") {
            preview = preview + "<p>No decision about data synthesis made yet</p>";
        }
        else if (tags.synthesisyn === "synthesisn") { // if we are here
            preview = preview + "<p>No formal data synthesis is planned - data will be described but not combined.</p>";
        }
        else if (tags.synthesisyn === "synthesisy") { // if we are here
            // ===================================================================================
            // ===================================================================================
            // ===================================================================================
            text = editor1.datahtml;
            if (text == undefined || text === "" || text === "<p></p>") {
                preview = preview + "<p>No content available yet</p>";
            }
            else {
                preview = preview + text;
            }
        }

        data.preview = preview;
        return true;

    }

    // ============================================================
    // TemplatePublishedProtocol
    // ============================================================
    TemplateDataSynthesisQuantitative(data) {
        let preview = "";
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // check the tags
        const tags = content.tags;
        const editor1 = content.editors.editor1;
        const editor2 = content.editors.editor2;
        const editor3 = content.editors.editor3;
        const editor4 = content.editors.editor4;
        const editor5 = content.editors.editor5;
        const editor6 = content.editors.editor6;
        let text = "";

        if (tags.synthesisyn === undefined || tags.synthesisyn === "") {
            preview = preview + "<p>No selection made yet</p>";
        }
        else if (tags.synthesisyn === "synthesisn") { // if we are here
            preview = preview + "<p>No formal quantitative data synthesis is planned - data will be described but not combined.</p>";
        }
        else if (tags.synthesisyn === "synthesisy") { // if we are here
            // ===================================================================================
            // ===================================================================================
            // ===================================================================================
            preview = preview + "<h3>Requirements to be met before data will be synthesised</h3>";
            text = editor1.datahtml;
            if (text == undefined || text === "" || text === "<p></p>") {
                preview = preview + "<p>No content available yet</p>";
            }
            else {
                preview = preview + text;
            }

            // ===================================================================================
            // ===================================================================================
            // ===================================================================================
            preview = preview + "<h3>Method of combining study data</h3>";
            text = editor2.datahtml;
            if (text == undefined || text === "" || text === "<p></p>") {
                preview = preview + "<p>No content available yet</p>";
            }
            else {
                preview = preview + text;
            }
            // ===================================================================================
            // ===================================================================================
            // ===================================================================================

            preview = preview + "<h3>Missing data</h3>";
            text = editor3.datahtml;
            if (text == undefined || text === "" || text === "<p></p>") {
                preview = preview + "<p>No content available yet</p>";
            }
            else {
                preview = preview + text;
            }

            // ===================================================================================
            // ===================================================================================
            // ===================================================================================
            preview = preview + "<h3>Covariates</h3>";
            if (tags.covariatesyn === undefined || tags.covariatesyn === "") {
                preview = preview + "<p>No selection made yet</p>";
            }
            else if (tags.covariatesyn === "covariatesn") { // if we are here
                preview = preview + "<p>No examination of differential effectiveness/treatment covariate interactions is planned.</p>";
            }
            else {
                text = editor4.datahtml;
                if (text == undefined || text === "" || text === "<p></p>") {
                    preview = preview + "<p>No content available yet</p>";
                }
                else {
                    preview = preview + text;
                }
            }

            // ===================================================================================
            // ===================================================================================
            // ===================================================================================
            preview = preview + "<h3>Heterogeneity</h3>";
            if (tags.heterogeneityyn === undefined || tags.heterogeneityyn === "") {
                preview = preview + "<p>No selection made yet</p>";
            }
            else if (tags.heterogeneityyn === "heterogeneityn") { // if we are here
                preview = preview + "<p>Heterogeneity will not be explored.</p>";
            }
            else {
                text = editor5.datahtml;
                if (text == undefined || text === "" || text === "<p></p>") {
                    preview = preview + "<p>No content available yet</p>";
                }
                else {
                    preview = preview + text;
                }
            }


            // ===================================================================================
            // ===================================================================================
            // ===================================================================================
            preview = preview + "<h3>Planned sensitivity analyses</h3>";
            if (tags.sensitivityyn === undefined || tags.sensitivityyn === "") {
                preview = preview + "<p>No selection made yet</p>";
            }
            else if (tags.sensitivityyn === "sensitivityn") { // if we are here
                preview = preview + "<p>No sensitivity analyses are planned.</p>";
            }
            else {
                text = editor6.datahtml;
                if (text == undefined || text === "" || text === "<p></p>") {
                    preview = preview + "<p>No content available yet</p>";
                }
                else {
                    preview = preview + text;
                }
            }
        }

        data.preview = preview;
        return true;

    }

    // ============================================================
    // TemplateDataSynthesisQualitative
    // ============================================================
    TemplateDataSynthesisQualitative(data) {
        let preview = "";
        let templatesection = data.section;
        let ret = { ok: true, errors: [] };

        const content = data.content[0];

        // check the tags
        const tags = content.tags;
        const editor1 = content.editors.editor1;
        const editor2 = content.editors.editor2;
        const editor3 = content.editors.editor3;
        const editor4 = content.editors.editor4;
        let text = "";

        if (tags.synthesisyn === undefined || tags.synthesisyn === "") {
            preview = preview + "<p>No selection made yet</p>";
        }
        else if (tags.synthesisyn === "synthesisn") { // if we are here
            preview = preview + "<p>No formal quanlitative data synthesis is planned - data will be described but not combined.</p>";
        }
        else if (tags.synthesisyn === "synthesisy") { // if we are here
            // ===================================================================================
            // ===================================================================================
            // ===================================================================================
            preview = preview + "<h3>Requirements to be met before data will be synthesised</h3>";
            text = editor1.datahtml;
            if (text == undefined || text === "" || text === "<p></p>") {
                preview = preview + "<p>No content available yet</p>";
            }
            else {
                preview = preview + text;
            }

            // ===================================================================================
            // ===================================================================================
            // ===================================================================================
            preview = preview + "<h3>Method of combining study data</h3>";
            text = editor2.datahtml;
            if (text == undefined || text === "" || text === "<p></p>") {
                preview = preview + "<p>No content available yet</p>";
            }
            else {
                preview = preview + text;
            }

            // ===================================================================================
            // ===================================================================================
            // ===================================================================================

            preview = preview + "<h3>How will data be coded and compared between studies?</h3>";
            text = editor3.datahtml;
            if (text == undefined || text === "" || text === "<p></p>") {
                preview = preview + "<p>No content available yet</p>";
            }
            else {
                preview = preview + text;
            }

            // ===================================================================================
            // ===================================================================================
            // ===================================================================================

            preview = preview + "<h3>Methods for expressing the synthesis</h3>";
            text = editor4.datahtml;
            if (text == undefined || text === "" || text === "<p></p>") {
                preview = preview + "<p>No content available yet</p>";
            }
            else {
                preview = preview + text;
            }

        }

        data.preview = preview;
        return true;

    }

}



export default Preview;