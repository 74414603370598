import React, { useState } from 'react';
import { UseAppContext } from '../../AppContextProvider';



const RecordIdentifier = props => {            
        const [helpitem, setHelpItem] = useState(null);

    
    // ========================================================================
    // the context data store
    // ========================================================================
    const {
        recordData:{
                 recordtitle, recordvariant
        }
    } = UseAppContext();

    let title = recordtitle;
    let variant = props.variant;
        return  ( <div className="recordidentifier">
                <div dangerouslySetInnerHTML={{ __html: (title.replace("</p>", " <span>[" + variant + " review]</span></p>") )}} />

        </div>
        )

}

export default RecordIdentifier;