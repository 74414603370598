import React from 'react';

export const helptext = {
    ReviewCoverSheet: {
        help1: {
            helphtml: <>
                <h2>Do not register reviews that are out of scope</h2>
                <p>PROSPERO only accepts registrations of systematic reviews examining outcomes that are of direct relevance to human health and wellbeing</p>

                <h2>PROSPERO does not register</h2>
                <ul>
                    <li>Systematic reviews assessing sports performance </li>
                    <li>Systematic reviews of in-vitro studies only</li>
                    <li>Literature reviews that use only a systematic search and no other systematic review methods</li>
                    <li>Scoping reviews (these may be incorporated in future)</li>
                </ul>
                <p>If your review is out of scope there are other places where you can register or lodge your full protocol. This includes the Open Science Framework where registration is free of charge <a href="https://osf.io/registries" rel="noreferrer" target="_blank">https://osf.io/registries</a>.</p>
                <h2>When to register your review</h2>
                <p>Do not register too early. Your systematic review protocol should be complete before you submit your registration request</p>
                <p>Registering reviews that are never done is unhelpful to the research community and may discredit the research team. You should therefore have the necessary resource in place to complete the review before you register your protocol (notification of award of research funding or firm commitment that author time is available for unfunded projects).</p>

                <h2>Do not register too early</h2>
                <p>Your systematic review protocol should be finalised (or very nearly finalised) before you submit your PROSPERO registration record. </p>
                <p>Any edits made after registration will create new versions of your registration record, which will need to be approved by all team members, and all versions will be available publicly. If the record includes many versions this can be confusing for PROSPERO users. If the record includes poorly developed versions this can discredit the review team. </p>
                <p>You can create and save your record and come back to edit it as many times as you need to before you submit. Only submitted versions become part of the public record</p>

                <h2>Do not register speculatively</h2>
                <p>Registering reviews that are never done is unhelpful to the research community and may discredit the research team. </p>
                <p>You should therefore have the necessary resource in place to complete the review before you register your protocol (notification of award of research funding or firm commitment that author time is available for unfunded projects).</p>
                <h2>Submissions must be in English</h2>
                <p>All information must be entered in English. However any uploaded search strategies or full protocols can be in any language.</p>
                <h2>Enter the required information in all fields</h2>
                <p>PROSPERO records need to be fully searchable therefore all fields need to be completed even if a full protocol is uploaded. Submissions that refer readers to a protocol without providing the required information in the fields will not be accepted.</p>
            </>
        },
        help2: {
            helphtml: <>
                <p>PROSPERO does not register reviews/publish registration records that are created as training exercises or mini reviews done as part of a student course or module.</p>
                <p>This is because there are likely to be multiple versions created by different students, the review may not be completed and/or is unlikely to be published. Therefore inclusion could make it more difficult for research teams and decision makers to locate the (non traing project) reviews that they seek. </p>
                <p>PROSPERO does not have resource to handle these sorts of student registrations.</p>
                <p>However students can use PROSPERO to create a registration record and to share this with supervisors, teachers and colleagues. The only difference is that the record will not be published on the PROSPERO site and the record owner will not be required to update the record.</p>

            </>
        }
    },

    recordtriage: {
        help1: {
            helphtml: <>
                <h1>What do the PROSPERO registration stages mean?</h1>
                <p>As your record progresses through the PROSPERO registration process its state changes to indicate what action is needed. The possible state are:</p>
                <h3>Stable</h3>
                <p>The registration record is published and is not being edited or amended. No action is necessary until you need to amend or update the record.</p>
                <h3>In process</h3>
                <p>The registration record is being edited. No changes will be published until the registration or updating process is completed. If the record is already published and you are making an amendment or an update then the published version will not change until the registration process is complete.</p>
                <h3>Awaiting approval</h3>
                <p>Editing of the the PROSPERO registration record is complete and the record is waiting for all authors to approve its publication. No changes will be published until all authors have approved. The only actions possible in this stage are to abort the registration process or to amend the review team details.</p>
                <h3>Pending publication</h3>
                <p>The PROSPERO record has been approved by the whole review team and is pending publication. No changes to the current version of the record are possible. If you need to make changes then start an amendment or an update.</p>
            </>
        },
        help2: {
            helphtml: <>
                <h1>What do the numbers in brackets mean?</h1>
                <p>The numbers in brackets (parentheses) at the end of the PICO labels are a count of how many times that label has been used in the Cochrane ontology. In general it is better to use a keyword that has a larger count where appropriate.</p>
            </>
        },
        help3: {
            helphtml: <>
                <h1>Things that we do not classify as clear and direct</h1>
                <p>A systematic review of bullying investigating impact on mental health would be eligible whereas one assessing the impact of bullying on educational attainment would not be</p>
            </>
        },
        help4: {
            helphtml: <><p>PROSPERO does not register reviews/publish registration records that are created as training exercises or mini reviews done as part of a student course or module.</p>
                <p>This is because there are likely to be multiple versions created by different students, the review may not be completed and/or is unlikely to be published. Therefore inclusion could make it difficult for research teams and decision makers to locate the (non student project) reviews that they seek. PROSPERO does not have resource to handle these sorts of student registrations.</p>
                <p>However students can use PROSPERO to create a registration record and to share this with supervisors, teachers and colleagues. The only difference is that the record will not be published on the PROSPERO site and the record contact will not be required to update the record.</p>
            </>
        }
    },
    TemplateReviewQuestion: {
        mainhelp: {
            default: <><p>Provide an explicit statement of the objective(s) or question(s) to be addressed. This should be stated clearly and precisely. Review questions may be specific or broad. Consider breaking very broad questions down into a series of more specific questions.</p>
                <p className="example"><b>Example: </b><span className="notranslate">How does pre-operative chemotherapy impact on survival of early stage non-small cell lung cancer compared to surgery alone?</span></p>
            </>
        }
    },
    TemplateContext: {
        mainhelp: {
            default: <>
                <p>Give summary details of the setting and other relevant characteristics which help define the inclusion or exclusion criteria.</p>
                <p>Include relevant details if these form part of the review’s eligibility criteria but are not reported elsewhere in the PROSPERO record.</p>
                <p className="example"><b>Example: </b><span className="notranslate">Studies in hospital accident and emergency departments. Research in low- and middle-income countries only will be included.</span></p>
            </>
        }
    },

    TemplateCountry: {
        mainhelp: {
            default: <>
                <p>Select the country in which the review is being carried out from the drop down list. For multi-national collaborations select all the countries involved.</p>
            </>
        }
    },

    TemplateSameAuthor: {
        mainhelp: {
            default: <>
                <p>If you are registering an update of an existing review give details of the earlier versions and include a full bibliographic reference, if available.</p>
            </>
        }
    },

    TemplateOtherRegDetails: {
        mainhelp: {
            default: <>
                <p>Name any other organisation where the systematic review title or protocol is registered (e.g. Campbell, or The Joanna Briggs Institute) together with any unique identification number assigned by them.</p>
                <p>If extracted data will be stored and made available through a repository such as the Systematic Review Data Repository (SRDR), details and a link should be included here. If none, leave blank.</p>
            </>
        }
    },

    TemplateNamedContact: {
        mainhelp: {
            default: <>
                <p><b>PLEASE NOTE this information will be published in the PROSPERO record so please do not enter private information, i.e. personal home address</b></p>
            </>
        }
    },

    
    TemplateSubgroups: {
        mainhelp: {
            default: <>
                <p>State any planned investigation of ‘subgroups’. Be clear and specific about which type of study or participant will be included in each group or covariate investigated. State the planned analytic approach.</p>
            </>
        }
    },
    TemplateDataSynthesisLegacy: {
        mainhelp: {
            default: <>
                <p>Describe the methods you plan to use to synthesise data. This must not be generic text but should be specific to your review and describe how the proposed approach will be applied to your data.</p>
                <p>If meta-analysis is planned, describe the models to be used, methods to explore statistical heterogeneity, and software package to be used.</p>
            </>
        }
    },
    TemplateRiskOfBiasLegacy: {
        mainhelp: {
            default: <>
                <p>State which characteristics of the studies will be assessed and/or any formal risk of bias/quality assessment tools that will be used.</p>
            </>
        }
    },

    
    
    TemplateSearches: {
        mainhelp: {
            default: <>
                <p>State the sources that will be searched (e.g. MEDLINE). Give the search dates, and any restrictions (e.g. language or publication date). Do NOT enter the full search strategy (it may be provided as a link or attachment in the Link to search strategy section.)</p>
            </>
        },
        help1: {
            helphtml: <>
                <p>State the sources that will be searched (e.g. MEDLINE). Give the search dates, and any restrictions (e.g. language or publication date). Do NOT enter the full search strategy (it may be provided as a link or attachment in the Link to search strategy section.)</p>
                <p>The search strategy reported in systematic review protocols should:</p>
                <ul>
                    <li>Name all sources that will be used to identify studies for the systematic review.</li>
                </ul>
                <p>Sources include (but are not limited to) bibliographic databases, reference lists of eligible studies and review articles, key journals, conference proceedings, trials registers, Internet resources and contact with study investigators, experts and manufacturers.</p>
                <p>Systematic reviews typically use more than one database. Examples of electronic bibliographic databases include MEDLINE, EMBASE, PsycINFO. Other database sources include The Cochrane Library, Health Technology Assessment Database, and Web of Science.</p>
                <ul>
                    <li>Search dates (from and to)</li>
                    <li>Restrictions on the search including language and publication period</li>
                    <li>Whether searches will be re-run prior to the final analysis</li>
                </ul>
                <p>It is considered good practice for searches to be re-run just before the final analyses and any further studies identified, retrieved for inclusion. </p>
                <ul>
                    <li>Whether unpublished studies will be sought</li>
                </ul>
            </>
        }
    },
    TemplateReviewStatusNew: {
        mainhelp: {
            default: <><p className="helptextinline">To be eligible for registration in PROSPERO the initial status of the review must be <i>Planned or ongoing</i>. You will be prompted to change this status as the review progresses.</p>

            </>
        }
    },
    TemplateReviewStatus: {
        mainhelp: {
            default: <><p className="helptextinline">You will be prompted to change this status as the review progresses.</p>

            </>
        }
    },
    TemplateReportingBias: {
        mainhelp: {
            default: <><p>If the study results that are available and can be included in analyses differ systematically from those that are not available/are missing this can introduce bias.</p>
            </>
        },
        help1: {
            helphtml: <>
                <p>The validity of a synthesis may be threatened when the <b>available results differ systematically from the missing results</b>. This is known as “bias due to missing results” and arises from “reporting biases” such as selective non-publication and selective non-reporting of results.</p>
                <p>Direct methods for assessing the risk of bias due to missing results include comparing outcomes and analyses pre-specified in study registers, protocols, and statistical analysis plans with results that were available in study reports. </p>
                <p>Statistical and graphical methods exist to assess whether the observed data suggest potential for missing results (such as contour enhanced funnel plots, Egger’s test) and how robust the synthesis is to different assumptions about the nature of potentially missing results (such as selection models). <a href="https://www.bmj.com/content/372/bmj.n160" rel="noreferrer" target="_blank">PRISMA 2020 explanation and elaboration.</a></p>

            </>
        }
    },
    TemplateCertainty: {
        mainhelp: {
            default: <><p>Good quality systematic reviews often use some criteria to decide how certain (or confident) they are in the body of evidence for important review outcomes.</p>
            </>
        },
        help1: {
            helphtml: <>
                <p>Systematic reviews often use some criteria to decide how certain (or confident) they are in the body of evidence for each important outcome. Common factors considered include precision of the effect estimate (or sample size), consistency of findings across studies, study design limitations and missing results (risk of bias), and how directly the studies address the question. Tools and frameworks can be used to provide a systematic, explicit approach to assessing these factors and provide a common approach and terminology for communicating certainty. For example, using the GRADE approach, the research team will first apply criteria to assess each GRADE domain (imprecision, inconsistency, risk of bias, and so forth) and then make an overall judgment of whether the evidence supporting a result is of high, moderate, low, or very low certainty. <a href="https://www.bmj.com/content/372/bmj.n160" rel="noreferrer" target="_blank">PRISMA 2020 explanation and elaboration.</a></p>
            </>
        }





    },
    TemplateOriginalTitle: {
        mainhelp: {
            default: <><p>For reviews in languages other than English, this field should be used to enter the title in the language of the review. This will be displayed together with the English language title. </p>
                <p>Acronyms may be included in titles, but should also be given in full unless they are regarded as more usual than the expansion (e.g. HIV).</p>
                <p>PLEASE NOTE THIS FIELD WILL NOT BE AVAILABLE FOR NEW RECORDS.</p>
                <p className="example"><b>Example: </b><span className="notranslate">Revisión sistemática y meta-análisis de la recurrencia y la supervivencia tras la fase de radiación en comparación con post-operatorio en el sarcoma localizados resecables de tejido blando.</span></p>
            </>
        }
    },
    TemplateTitle: {
        mainhelp: {
            default: <><p>Give the working title of the review, for example the one used for obtaining funding. </p>
                <p>Acronyms may be included in titles, but should also be given in full unless they are regarded as more usual than the expansion (e.g. HIV).</p>
                <p>The title must be in English. If the original title is in a different language the English version must be entered here. The non-English version can be given in the field labelled “Other information”.</p>
                <p>If the final title of the review differs, this can be displayed in the Publication of Final Report Field.</p>
                <p className="example"><b>Example: </b><span className="notranslate">Systematic review and meta-analysis of recurrence and survival following pre- versus post-operative radiation in localized, resectable soft-tissue sarcoma.</span></p>
            </>
        }
    },
    TemplateKeywords: {
        mainhelp: {
            default: <><p>Give words or phrases that best describe the review. Be as specific and precise as possible. Avoid acronyms and abbreviations unless these are in wide use. MeSH terms will be applied in a later section and do not need to be entered here.
            </p>
                <p>MeSH terms will be applied in a separate section.</p>
                <p>PICO terms do not need to be re-entered here.</p>
                <p className="example"><b>Example: </b>psoriasis; psoriatic arthritis; interleukin 17 inhibitors; secukinumab; ixekizumab; brodalumab; bimekizumab;</p>
            </>
        }
    },
    TemplateOutcomesMain: {
        mainhelp: {
            default: <><p>List the main outcomes for which data will be sought here.</p>
                <p>This should include, where relevant, the outcome definition, acceptable measurement instruments, the time point(s) at which measured. The effect measure to be used in synthesis should also be listed here.
                </p>
            </>

        },
        help1: {
            helphtml: <>
                <h1>Ouctomes</h1>
                <p>Elements to be included in outcome description where relevant include:
                    <ul>
                        <li>Outcome (e.g. quality of life)</li>
                        <li>Details/definition (e.g. change in quality of life score from baseline)</li>
                        <li>Timing(s) of measurement(s) (e.g. at 6 months from baseline and end of study)</li>
                        <li>Measurement (e.g. any validated HRQoL instrument)</li>
                        <li>Effect measure to be used in synthesis (e.g. standardised mean difference)</li>
                    </ul>
                </p>
                <p><i>This example could be written as:</i></p>
                <p>Quality of life: change in quality of life score from baseline measured by any validated HRQoL instrument at 6-months after study entry and at end of study. Data for this outcome will be synthesised using standardised mean difference.</p>
            </>
        }
    },
    TemplateOutcomesAdditional: {
        mainhelp: {
            default: <><p>List the addiitonal outcomes for which data will be sought here.</p>
                <p>This should include, where relevant, the outcome definition, acceptable measurement instruments, the time point(s) at which measured. The effect measure to be used in synthesis should also be listed here.
                </p>
            </>

        },
        help1: {
            helphtml: <>
                <h1>Ouctomes</h1>
                <p>Elements to be included in outcome description where relevant include:
                    <ul>
                        <li>Outcome (e.g. quality of life)</li>
                        <li>Details/definition (e.g. change in quality of life score from baseline)</li>
                        <li>Timing(s) of measurement(s) (e.g. at 6 months from baseline and end of study)</li>
                        <li>Measurement (e.g. any validated HRQoL instrument)</li>
                        <li>Effect measure to be used in synthesis (e.g. standardised mean difference)</li>
                    </ul>
                </p>
                <p><i>This example could be written as:</i></p>
                <p>Quality of life: change in quality of life score from baseline measured by any validated HRQoL instrument at 6-months after study entry and at end of study. Data for this outcome will be synthesised using standardised mean difference.</p>
            </>
        }
    },

    TemplateCondition: {
        mainhelp: {
            default: <><p>State the disease, condition or healthcare domain being studied.</p>
                <p>Note that background/descriptive information about the condition may be provided in the <b>Rationale</b> section</p>
                <p className="example"><b>Example: </b>Diabetes Mellitus. Physical Activity Level.</p>
            </>

        },
        help1: {
            helphtml: <>
                <h1>What are PICO labels?</h1>
                <p>Adding labels to your record enhances discoverability and aids data sharing. PROSPERO also uses labels to help identify similar reviews.</p>
            </>
        },
        help2: {
            helphtml: <>
                <h1>What do the numbers in brackets mean?</h1>
                <p>The numbers in brackets (parentheses) at the end of the PICO labels are a count of how many times that label has been used in the Cochrane ontology. In general it is better to use a keyword that has a larger count where appropriate.</p>
            </>
        }
    },
    TemplatePopulation: {
        mainhelp: {
            default: <><p>Specify the participants or populations being studied in the review using inclusion and exclusion criteria as appropriate.</p>
                <p className="example"><b>For example:</b><br />Inclusion: Adults with schizophrenia (as diagnosed using any recognised diagnostic criteria).<br />Exclusion: Adolescents (under 18 years of age) and elderly people (over 70).</p>
            </>
        },
        help1: {
            helphtml: <>
                <p>Using both inclusion and exclusions criteria is recommended where it provides clarification.</p>
                <p>For example</p>
                <p>Inclusion: people with heart failure</p>
                <p>Exclusion:people who also have other life limiting conditions </p>
                <p>Inclusion: </p>
                <p>People over 60 years of age</p>
                <p>Exclusion: Studies that include participants of all ages where data from participants over 60 years are not available separately.</p>
                <p>There is no need to mirror inclusion/exclusion criteria where the  exclusion is obvious For example, where</p>
                <p>Inclusion: children ≤ 16 years, </p>
                <p>it is not necessary to state that people &gt; 16 years will be excluded</p>
            </>
        }
    },
    TemplateIntervention: {
        mainhelp: {
            default: <><p>Describe the interventions or exposures to be reviewed. The preferred format includes details of both inclusion and exclusion criteria (if appropriate).</p>
            </>
        },
        help1: {
            helphtml: <>
                <h1>What are PICO labels?</h1>
                <p>Adding labels to your record enhances discoverability and aids data sharing. PROSPERO also uses labels to help identify similar reviews.</p>
            </>
        },
        help2: {
            helphtml: <>
                <h1>What do the numbers in brackets mean?</h1>
                <p>The numbers in brackets (parentheses) at the end of the PICO labels are a count of how many times that label has been used in the Cochrane ontology. In general it is better to use a keyword that has a larger count where appropriate.</p>
            </>
        }
        ,
        help3: {
            helphtml: <>
                <p>Give full and clear descriptions or definitions of the nature of the interventions or the exposures to be reviewed. This is particularly important for reviews of complex interventions (interventions involving the interaction of several elements). If appropriate, an operational definition describing the content and delivery of the intervention should be given.</p>
                <p>Ideally, an intervention should be reported in enough detail that others could reproduce it or assess its applicability to their own setting. The preferred format includes details of both inclusion and exclusion criteria.</p>
                <p>For reviews of qualitative studies give details of the focus of the review.</p>
                <p>Examples include tobacco crop substitution or diversification, removing subsidies on tobacco production, restricting trade in tobacco products, measures to prevent smuggling, measures to reduce illicit cross-border shopping, restricting advertising of tobacco products, restrictions on selling tobacco products to minors, mandatory health warning labels on tobacco products, increasing the price of tobacco products, restricting access to cigarette vending machines, restricting smoking in the workplace, and restricting smoking in public places. Such approaches could also form part of wider, multifaceted interventions in schools, workplaces or communities.</p>
            </>
        }
    }
    ,
    TemplateComparator: {
        mainhelp: {
            default: <><p>Where relevant, give details of the alternatives against which the main intervention will be compared (e.g. another intervention, a placebo or usual care).</p>
            </>
        },
        help1: {
            helphtml: <>
                <h1>What are PICO labels?</h1>
                <p>Adding labels to your record enhances discoverability and aids data sharing. PROSPERO also uses labels to help identify similar reviews.</p>
            </>
        },
        help2: {
            helphtml: <>
                <h1>What do the numbers in brackets mean?</h1>
                <p>The numbers in brackets (parentheses) at the end of the PICO labels are a count of how many times that label has been used in the Cochrane ontology. In general it is better to use a keyword that has a larger count where appropriate.</p>
            </>
        }
    },

    TemplateOtherStudyIDMethods: {
        mainhelp: {
            default: <><p>Please indicate the main sources other than bibliographic databases that will be searched.</p>
            </>
        }
    },
    TemplateStudyDateRestrictions: {
        mainhelp: {
            default: <><p>Give details of any date restrictions on searches.</p>
            </>
        }
    },
    TemplateSearchLanguage: {
        mainhelp: {
            default: <><p>Please indicate whether you will restrict the language of publication of the studies you search for.</p>
            </>
        }
    },
    TemplatePublishedSearch: {
        mainhelp: {
            default: <><p>A search strategy may be deposited with CRD in PDF format and will be made available as part of your record.</p>
            </>
        }
    },
    TemplateAdditionalInfo: {
        mainhelp: {
            default: <>
                <p>Add any important additional information that will help readers understand the context of your review or provide.</p>
            </>
        },
        help1: {
            helphtml: <>
                <p>Add important additional information.</p>
                <p>For example, if this review builds on a previous review by this or another team give details, or if the review has been commissioned to inform specific guidelines or policies, or there are vital details that will help to clarify methods or distinguish your review from other reviews.</p>
                <p>You may also name additional review team members (not already listed as part of the research team) and/or partner organisations or contributors here.</p>
            </>
        }
    },
    TemplateOrgAffil: {
        mainhelp: {
            default: <><p>State the main organisational affiliation or sponsor for this review.</p>
                <p>This can be an organisation (e.g. Campbell) or a host institution (e.g. University of York)</p>
            </>
        }
    },

    TemplateStudyDesign: {
        mainhelp: {
            default: <><p>Give details of the types of study (study designs) that will be included in the review. If there are no restrictions on the types of study, or certain study types will be  excluded, this should be stated. </p>
                <p>If different study designs will be included in different parts of the review, this should be made clear.</p>
                <p className="example"><b>Example: </b>The review of clinical effectiveness will include only RCTs. The review of patient experience will include focus group and interview studies.</p>
            </>
        }
    },
    TemplateReviewTimeline: {
        mainhelp: {
            default: <><p>These fields may be edited at any time. All edits to published records will appear in the record audit trail.</p>
            </>
        },
        help1: {
            helphtml: <>

                <p>Dates can be changed if the project schedule changes. Reason for changes will be collected and shown as part of the registration record.</p>
                <h2>Start date</h2>
                <p>The start date is any point after the review protocol is completed but before data extraction begins.</p>
                <h2>End date</h2>
                <p>A protocol can be considered complete when it is approved by a funder or the person commissioning the review; when peer review is complete; when the protocol is published or when the authors decide that it is complete and they do not anticipate any major revisions to the design of the systematic review.</p>
                <p>Systematic reviews usually take several months to complete. If the entered end date is less than 28 days after the start date this will be flagged.</p>
                <p>When the end date is reached the PROSPERO record owner will receive an email asking them to update the registration record.</p>
            </>
        }
    },

    TemplatePublishedProtocol: {
        mainhelp: {
            default: <>
                <p>Give the DOI if the protocol is published. Alternatively, an unpublished protocol can be uploaded to PROSPERO in PDF format. The protocol will be made available as part of your record.</p>
            </>
        },
        help1: {
            helphtml: <>
                <h1>What is a DOI?</h1>
                <p>A digital object identifier (DOI) should be provided by the publisher and is used to uniquely identify and link to your published protocol.</p>
                <p>For more information see <a href="https://en.wikipedia.org/wiki/Digital_object_identifier" rel="noreferrer" target="_blank">https://en.wikipedia.org/wiki/Digital_object_identifier</a></p>
            </>
        },
        help2: {
            helphtml: <>
                <h1>What if my DOI does not resolve?</h1>
                <p>If your DOI does not resolve to the correct document you should check the following:
                    <ol>
                        <li>Check that the DOI you entered is in the correct format. DOIs start with <b>10.</b></li>
                        <li>Check that the DOI is typed exactly as you see it on screen</li>
                    </ol>
                </p>
                <p>You can check your DOI manually by typing it into the DOI box at <a href="https://dx.doi.org/" rel="noreferrer" target="_blank">https://dx.doi.org/</a></p>
            </>
        }
    },

    DOI: {
        help1: {
            helphtml: <>
                <h1>What is a DOI?</h1>
                <p>A digital object identifier (DOI) should be provided by the publisher and is used to uniquely identify and link to your published protocol.</p>
                <p>For more information see <a href="https://en.wikipedia.org/wiki/Digital_object_identifier" rel="noreferrer" target="_blank">https://en.wikipedia.org/wiki/Digital_object_identifier</a></p>
            </>
        },
        help2: {
            helphtml: <>
                <h1>What if my DOI does not resolve?</h1>
                <p>If your DOI does not resolve to the correct document you should check the following:
                    <ol>
                        <li>Check that the DOI you entered is in the correct format. DOIs start with <b>10.</b></li>
                        <li>Check that the DOI is typed exactly as you see it on screen</li>
                    </ol>
                </p>
                <p>You can check your DOI manually by typing it into the DOI box at <a href="https://dx.doi.org/" rel="noreferrer" target="_blank">https://dx.doi.org/</a></p>
            </>
        }
    },

    TemplateSearchDatabases: {
        mainhelp: {
            default: <>
                <p>This is intended to cover only the main bibliographic databases and does not need to be an exhaustive list. A full list can be given in your uploaded search strategy or protocol.</p>
            </>
        }
    },


    TemplatePeerReview: {
        mainhelp: {
            default: <>
                <p>Has your full review protocol and/or funding application been peer reviewed?</p>
            </>
        },
        help1: {
            helphtml: <>
                <p>Peer review is a process where one persons scholarly work, research or ideas is scrutinised by others who are experts in the same field.</p>
                <p>Peer review is intended to improve the quality of research outputs and is used by many journals and research funders.</p>
                <p>E.g. If your systematic review is funded by award of a research grant, you will likely have received peer review comment as part of that process. </p>
                <p>E.g. If your review is being done within a formal organisational structure e.g. Cochrane or Campbell, peer review is part of the review production process.</p>
            </>
        }

    },

    TemplateRationale: {
        mainhelp: {
            default: <>
                <p>Briefly, describe the rationale for the review explaining why it is being done and what it might add to existing knowledge.</p>
            </>
        },
        help1: {
            htmlhelp: <>
                <p>Briefly, describe the rationale for the review explaining why it is being done and what it might add to existing knowledge.</p>
                <p>This may include information about e.g. the health condition being studied, burden of disease and potential impact of an intervention.</p>

                <p>If other reviews have addressed the same or a very similar research question explain why this review is needed. </p>
                <p>For example, if previous reviews are out of date, methodologically flawed or better methods are now available; if multiple existing reviews have discordant results; or the current review was commissioned to inform a guideline or policy for a particular organisation.</p>
                <p>Running the PROSPERO checking function to identify similar reviews registered in PROSPERO can help identify similar reviews but the rationale should not be restricted to reviews found in PROSPERO. </p>
                <p>If this review is an update or replication of an existing review, state this and cite the previous review.</p>
                <p>If the review builds on a previous review e.g. if it adopts the same methods to carry out a review in a different populations, state this and cite the previous review.</p>
            </>
        }
    },

    TemplateConflict: {
        mainhelp: {
            default: <>
                <p>Any potential conflict of interest declared for individual team members will be noted under this heading in the public registration record. Funding by a commercial organisation will also be noted in the same section.</p>
            </>
        }
    },

    TemplateMeSH: {
        mainhelp: {
            default: <>
                <p>Medical Subject Headings (MeSH) are standardised index terms applied to citations to aid discovery of articles on a particular subject regardless of the terminology used by the author.</p>
            </>
        }
    },
    TemplateFirstPublished: {
        mainhelp: {
            default: <>
                <p>This section contains the history of publications of this PROSPERO record.</p>
            </>
        }
    },
    TemplateCheckSimilar: {
        mainhelp: {
            default: <>
                <p>PROSPERO requests that to avoid unintended duplication you check reviews identified as being similar to your own before you can proceed with registration.</p>
                <p>Your review title and review question together with condition, intervention and comparator tags and keywords are used to identify similar systematic reviews already registered in PROSPERO (please note, this does not include not yet registered records).</p>
                <p>Until registration is complete, each time this record is edited checks will be repeated (to identify any similar reviews registered in PROSPERO since your last session).</p>
                <p><b>PROSPERO does not stop you from registering a similar review</b>  but does collect and display your reasons for proceeding.</p>

            </>
        },
        help1: {
            helphtml: <>
                <p>You only need to look at the first three potential matches. The responses you make to those matches will appear in your PROSPERO record. Sometimes there are more than three matches that might be of interest, you can choose to view those if you wish. If you choose make a decision about those matches then that decision will also appear in your PROSPERO record.</p>
            </>
        }

    },





    TemplateReviewStageNew: {
        mainhelp: {
            default: <>
                <p>This information must be updated/confirmed as accurate each time you submit an amendment or update to this record until you mark it as completed.</p>
            </>
        }
    },

    TemplateReviewStage: {
        mainhelp: {
            default: <>
                <p>This information must be updated/confirmed as accurate each time you submit an amendment or update to this record until you mark it as completed.</p>
            </>
        }
    },

    TemplateScreening: {
        help1: {
            helphtml: <>
                <h1>Why is this a problem?</h1>
                <p>Give some more guided help here.</p>
                <p>For more information see <a href="https://en.wikipedia.org/wiki/Digital_object_identifier" rel="noreferrer" target="_blank">https://en.wikipedia.org/wiki/Digital_object_identifier</a></p>
            </>
        }
    }
    , TemplateDataExtraction: {
        help1: {
            helphtml: <>
                <p>Individual Participant Data (IPD) reviews are a specific type of systematic review that involve the collection, checking and re-analysis of the original data for each participant in each study. </p>
                <p>IPD relates to the raw information recorded for each participant in a research study, and can be represented by a dataset containing a separate row per participant and columns containing values for relevant participant-level variables, such as baseline characteristics, treatments received, and follow-up details including any outcome events. In contrast, aggregate data relates to information averaged or estimated across all participants in a study, and can be represented by a dataset containing a single row per study and columns containing aggregated study information such as the intervention effect, the total participants, the mean age, and the proportion male in each treatment group. </p>
                <p>IPD reviews usually rely on establishing partnerships with study investigators who, in addition to providing IPD, may play an active role throughout the process. Clinical study data repositories and sharing platforms offering another source of obtaining IPD.</p>
            </>
        }
    }
    , TemplateDataSynthesis: {
        mainhelp: {
            default: <>
                <p>Information on how to report methods of synthesis can be found in the PRISMA statement and relevant extensions with more detail provided in the relevant explanation and elaboration documents <a href="http://www.prisma-statement.org/" rel="noreferrer" target="_blank">http://www.prisma-statement.org/</a></p>
            </>
        },
        help1: {
            helphtml: <>
                <p>Description should include but not be limited to:</p>
                <ul>
                    <li>Methods to be used to combine data including meta-analysis model (e.g. fixed-effect or random-effects) and methods (e.g. Mantel-Haenszel, inverse variance, DerSimonian-Laird) </li>
                    <li>How missing data will be handled</li>
                    <li>Methods to be used to identify or quantify statistical heterogeneity (e.g. visual inspection of results, formal statistical test for heterogeneity, heterogeneity variance (τ<sup>2</sup>), inconsistency (such as I<sup>2</sup>), prediction intervals)</li>
                    <li>Any planned investigation of different subgroups of studies (where each study is included in one subgroup only) or participants (where data on subsets of participants within a study are available, allowing the study to be included in more than one subgroup) with the rationale for these investigations.
                        Give details of which factors are to be explored and levels of those factors (e.g. studies at high risk of bias versus studies at low risk of bias) (e.g. people with stage II, stage III, stage IV cancer) and methods to be used (e.g. subgroup meta-analysis, meta-regression)</li>
                    <li>Any planned sensitivity analyses to assess robustness of the synthesized results</li>
                </ul>
                <p>Further information on methods of synthesis can be found in the Cochrane handbook Chapter 12 <a href="https://training.cochrane.org/handbook/current/chapter-12" rel="noreferrer" target="_blank">https://training.cochrane.org/handbook/current/chapter-12</a></p>
            </>
        }
    }

    , TemplateRiskOfBias: {
        help1: {
            helphtml: <>
                <h2>Scoping reviews</h2>

                <p>Systematic reviews usually aim to provide answers to well defined research questions using data from relatively quality assessed studies. Scoping reviews generally address broader pathfinding questions and do not assess the quality of included studies.</p>

                <p>A scoping review is a type of evidence synthesis that aims to systematically identify and map the breadth of evidence available on a particular topic, field, concept, or issue, often irrespective of source (i.e., primary research, reviews, non-empirical evidence) within or across particular contexts. <a href="https://journals.lww.com/jbisrir/fulltext/2022/04000/what_are_scoping_reviews__providing_a_formal.2.aspx" rel="noreferrer" target="_blank">Munn et al 2022.</a></p>

                <p>The JBI Scoping Reviews Methodology Group have grouped the purpose of scoping reviews into six broad indications:
                    <ul>
                        <li>Identify the types of available evidence in a given field</li>
                        <li>Clarify key concepts/definitions in the literature</li>
                        <li>Examine how research is conducted on a certain topic or field</li>
                        <li>Identify key characteristics or factors related to a concept</li>
                        <li>Act as a precursor to a systematic review</li>
                        <li>Identify and analyse knowledge gaps</li>
                    </ul>
                </p>
                <p>Authors deciding between the systematic review or scoping review approach should determine exactly what question they are asking and the purpose of their review. They should consider whether or not they wish to use the results of their review to answer a clinically meaningful question or provide evidence to inform practice. If their question addresses the feasibility, appropriateness, meaningfulness or effectiveness of a certain treatment or practice, then a systematic review is likely the most valid approach. However, if they are interested in the identification of certain characteristics/concepts in papers or studies, and in the mapping, reporting or discussion of these characteristics/concepts, a scoping review is the better choice.
                    <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6245623/" rel="noreferrer" target="_blank">Munn et al 2018.</a></p>

            </>
        },

        help4: {
            helphtml: <>
                <p>The <a href="https://www.latitudes-network.org/" rel="noreferrer" target="_blank">LATITUDES</a> website is an excellent source of information on validity assessment tools that can be used to assess risk of bias of studies included in evidence syntheses. It provides information on the study design targeted by each tool, links to the websites and papers associated with individual tools and to training materials on study design and on validity assessment.</p>
                <p>The PROSPERO picklist includes the tools that LATITUDES, lists as being key tools plus a small number of others that are in widespread use. The list will be kept under review with new tools added as appropriate. </p>

            </>
        },
        help3: {
            helphtml: <>
                <h1>Risk of Bias toools</h1>
                <h2>AMSTAR-2</h2><p className="robnotes">Used in overview/review of reviews/rapid review that includes existing reviews</p>
                <h2>CAMARADES quality checklist</h2>
                <h2>Cochrane RoB-1</h2><p className="robnotes">Used in systematic reviews of interventions that include RCTs, overviews if they look at primary studies (RCTs) as well as existing systematic reviews, rapid reviews that look at primary studies</p>
                <h2>Cochrane RoB-2</h2><p className="robnotes">Used in systematic reviews of interventions that include RCTs, overviews if they look at primary studies (RCTs) as well as existing systematic reviews, rapid reviews that look at primary studies</p>
                <h2>Downs and Black</h2><p className="robnotes">Used in systematic reviews of observational studies</p>
                <h2>Newcastle-Ottowa</h2><p className="robnotes">Used in systematic reviews of case control studies/cohort studies, overviews and rapid reviews that include primary case control studies/cohort studies</p>
                <h2>PROBAST</h2><p className="robnotes">Used in systematic reviews of prediction models</p>
                <h2>QUADAS</h2><p className="robnotes">Used in systematic review of diagnostic test accuracy studies, overviews (if they look at primary DTA studies as well as existing systematic reviews), rapid reviews that consider primary DTA studies</p>
                <h2>QUADAS-2</h2><p className="robnotes">Used in systematic review of diagnostic test accuracy studies</p>
                <h2>QUADAS-C</h2><p className="robnotes">Used in systematic review of comparative diagnostic test accuracy studies, overviews (if they look at primary comparative DTA studies as well as existing systematic reviews), rapid reviews that consider primary comparative DTA studies</p>
                <h2>QUADPAS</h2><p className="robnotes">Used in systematic reviews of prognostic accuracy studies, overviews (if they look at primary prognostic accuracy studies as well as existing systematic reviews), rapid reviews that consider primary prognostic studies</p>
                <h2>ROBINS-E</h2><p className="robnotes">Used in systematic reviews of observational epidemiological studies (a potential effect of an exposure on an outcome)</p>
                <h2>ROBINS-I</h2><p className="robnotes">Used in systematic reviews of cohort studies of interventions</p>
                <h2>ROBIS</h2><p className="robnotes">Used in overview/review of reviews/rapid review that includes existing reviews</p>
                <h2>SYRCLE</h2>
            </>
        },
        intervention: {
            helphtml: <>
                <h2>Risk of bia assessment for INTERVENTION reviews</h2>
                <p>You are working on an intervention review.</p>
                <p>Describe the method of assessing risk of bias or quality assessment. State which characteristics of the studies will be assessed and any formal risk of bias tools that will be used.</p>
                <p>Methods for assessing risk of bias reported in systematic review protocols should include:
                    <ul>
                        <li>Which characteristics will be assessed (e.g. methods of randomisation, treatment allocation, blinding).</li>
                        <li>Whether assessment will be done at study or outcome level</li>
                        <li>The criteria used to assess internal validity, if formal a risk of bias assessment is planned (e.g. the Cochrane risk of bias tool, ROBINS, QUADAS). </li>
                        <li>How the results of the assessment will inform data synthesis (where applicable).</li>
                        <li>The number of reviewers that will be involved in the quality assessment</li>
                        <li>How disagreements between reviewers judgements will be resolved</li>
                    </ul>
                </p>
            </>
        },
        dta: {
            helphtml: <>
                <h2>Risk of bia assessment for DTA reviews</h2>
                <p>You are working on a diagnostic test accuracy review.</p>
                <p>Describe the method of assessing risk of bias or quality assessment. State which characteristics of the studies will be assessed and any formal risk of bias tools that will be used.</p>
                <p>Methods for assessing risk of bias reported in systematic review protocols should include:
                    <ul>
                        <li>Which characteristics will be assessed (e.g. methods of randomisation, treatment allocation, blinding).</li>
                        <li>Whether assessment will be done at study or outcome level</li>
                        <li>The criteria used to assess internal validity, if formal a risk of bias assessment is planned (e.g. the Cochrane risk of bias tool, ROBINS, QUADAS). </li>
                        <li>How the results of the assessment will inform data synthesis (where applicable).</li>
                        <li>The number of reviewers that will be involved in the quality assessment</li>
                        <li>How disagreements between reviewers judgements will be resolved</li>
                    </ul>
                </p>

                <p>One type of bias specific to systematic review of diagnostic test accuracy, is verification bias. This occurs when not all of the study group receive confirmation of the diagnosis by the same reference standard. If the results of the index test influence the decision on whether to perform the reference standard or which reference standard is used, estimated diagnostic accuracy may be biased.</p>

                <p>A protocol should include:
                    <ul>
                        <li>Consideration of all relevant sources of bias, including those specific to systematic reviews of DTA</li>
                        <li>How these biases will be assessed including any tool(s) that will be used</li>
                    </ul>
                </p>
            </>
        }
    }
    , TemplateReviewTeam: {
        mainhelp: {
            default: <><p>List the review team members. All reviews must have at least two team members. </p>
                <p><b>All named team members will be required to confirm the record content before publication of the PROSPERO record.</b></p>
            </>
        },
        help1: {
            helphtml: <>
                <h1>Who should be in the review team?</h1>
                <p>List the main members of the review team. This must include a senior member of the team who will serve as guarantor for the submission. Usually a small number of additional people will also be included and each will appear alongside the review title as PROSPERO record ‘authors’. These will usually be the people who have been actively involved in preparing the review protocol and registration record.</p>
                <p>Team members will receive an email from the PROSPERO system requiring them to approve their participation and review content.</p>
                <p>The record owner is responsible for ensuring that all team members complete this task (this is managed from the owner's user account).</p>
                <p>If contact details or information about potential conflicts of interest is incorrect for any team member (or if they would like any other amendments to the record) they should ask the record owner to do this on their behalf. <b>Only the record owner is able to edit the registration record.</b></p>
                <p>Each team member is responsible for the accuracy of their own personal information and potential conflicts or perceived conflicts of interest related to this review.</p>
                <p>Additional members and collaborators may be listed under additional information at the end of this record.</p>
            </>
        },
        help2: {
            helphtml: <>
                <h1>What does it mean to be a team member?</h1>
                <p>Team members will receive an email from the PROSPERO system requiring them to approve their participation and review content.</p>
                <p>The review contact is responsible for ensuring that all team members complete this task (this is manged from the contact user account).</p>
                <p>If contact details or information about potential conflicts of interest is incorrect for any team member (or if they would like any other amendments to the record) they should ask the review contact to do this on their behalf. <b>Only the review contact is able to edit the registration record.</b></p>
                <p>Each team member is responsible for the accuracy of their own personal information and potential conflicts or perceived conflicts of interest related to this review.</p>
                <p>Additional members and collaborators may be listed under additional information at the end of this record.</p>
            </>
        },
        help3: {
            helphtml: <>
                <h1>What does a guarantor do?</h1>
                <p>Dunno.</p>
            </>
        },
        help4: {
            helphtml: <>
                <h1>What does a contact do?</h1>
                <p>Dunno.</p>
            </>
        }
    }
    , Contact: {
        help1: {
            helphtml: <>
                <h1>What does a review guarantor do?</h1>
                <p>Dunno.</p>
            </>
        },
        help2: {
            helphtml: <>
                <h1>What does a review contact do?</h1>
                <p>Dunno.</p>
            </>
        }
    },
    SimpleSearch: {
        help1: {
            helphtml: <>
                <h1>How do I run a search in PROSPERO</h1>
                <p>Dunno.</p>
            </>
        },
        help2: {
            helphtml: <>
                <h1>What next?</h1>
                <p>Dunno.</p>
            </>
        }
    },
    SearchHistory: {
        help1: {
            helphtml: <>
                <h1>How do I run a list of searches in PROSPERO</h1>
                <p>Dunno.</p>
            </>
        },
        help2: {
            helphtml: <>
                <h1>What next?</h1>
                <p>Dunno.</p>
            </>
        }
    }
    , Triage: {
        help1: {
            helphtml: <>
                <h1>What is an intervention review?</h1>
                <p>Intervention reviews seek to answer questions about the effectiveness of healthcare interventions (medicines, treatments or policies) on the people who receive them.</p>
            </>
        }
        , help2: {
            helphtml: <>
                <h1>What is a diagnostic test accuracy review?</h1>
                <p>Diagnostic test accuracy refers to the ability of a test to distinguish between patients with disease or a specified target condition, and those without (Leeflang et al. 2008). Systematic reviews of diagnostic accuracy studies synthesise the data from primary diagnostic studies to provide insight into the ability of medical tests to detect a specified target condition. They may do this by synthesising estimates of test performance, exploring sources of variability in test accuracy and /or comparing the accuracy of different tests (McInnes et al. 2008).</p>
            </>
        }
    }
}

