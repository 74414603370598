import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, InputGroup, FormControl } from "react-bootstrap";
import { UseAppContext } from '../AppContextProvider';
import { postSearch } from './SearchUtils';
import RecordHelp from '../Template/TemplateUtils/RecordHelp';

const SearchBar =  ({setSearch}) => {       
    const [term, setTerm] = useState('');
    const [field, setField] = useState('title');
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const {
      userSearchData: {
          userSearch, setUserSearch
      }
    } = UseAppContext();

    const setRun = async (e) => {
        e.preventDefault();        
        if(term === '') // return as no search term          
          return;        
          
        setLoading(true);
        // get the search
        let search = {};
        search.term = term;

        // get the fields
        search.field = [field];

        search.label = term + ':' + field;
        
        // now we want to run the search and update the history
        try {                        
          const resp = await postSearch(search);
          if(resp.data !== undefined && resp.data.results !== undefined && resp.data.results.hits !== undefined && resp.data.results.hits.total != undefined)
          {
              let hitCount = resp.data.results.hits.total.value;                
          
              // get the history from the search data
              let history = (userSearch.history !== undefined) ? [...userSearch.history] : [];

              // add the new search results
              const row = {"hits": (hitCount !== undefined) ? hitCount : 0, "term": search.term, "label": search.label, "line": (history.length + 1), "field": search.field};
              history.push(row);
              // save in the local storage
              localStorage.setItem("PROSPERO_searchHistory", JSON.stringify(history));

              // update the search              
              setSearch(search, history, resp.data);
              setTerm(''); // reset the term
              setLoading(false);
          } else { // error as hits total not returned
              console.error("ERROR", resp.data);
              setError(true);
              setLoading(false);
          }
        } catch (err) {
            setError(err);
            setLoading(false);
            console.error("ERROR 2", err);
            // show the error message
            return false;
        }
    }

    return (    
          <Row>            
            <Col>
              <Form className="d-flex" onSubmit={(e) => {setRun(e)}}>
                <InputGroup>
                    <FormControl 
                      type="search" 
                      className="me-2" 
                      placeholder="Search" 
                      onChange={e => setTerm(e.target.value)} 
                      value={term} />
                    <Form.Select className="me-2 form-control" id="searchFieldSelect">
                        {/* <option value="">All fields</option> */}
                        <option value="title">Title</option>
                        <option value="author">Author</option>
                        <option value="year">Year</option>
                    </Form.Select>
                    <Button id="searchBtn" variant="primary" type="submit" size="sm">Search</Button>
                </InputGroup>
              </Form>
              <div className="float-end me-2"><RecordHelp className="smalltext" fname={"SimpleSearch"} helpsection="help1" label="Display search help guide" /></div>
            </Col>
          </Row>        
      );       
}

export default SearchBar;