import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';


const RecordSectionOptional = props => {

    const rendertooltip = props => (<Tooltip {...props}>This field is optional.</Tooltip>);

    return (<OverlayTrigger placement="bottom" overlay={rendertooltip}><span className="optional"> (optional)</span>
    </OverlayTrigger>
    )
}

export default RecordSectionOptional;