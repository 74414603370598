export const definedwordcounts = {
    TemplateTitle: {
        editor1: {
            minwords: 5, maxwords: 30,
            invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A"
            ]
        }
    },

    TemplateOriginalTitle: {
        editor1: {
            minwords: 0, maxwords: 30,
            invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A"
            ]
        }
    },


    TemplateReviewQuestion: {
        editor1: {
            minwords: 10, maxwords: 50,
            invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A"
            ]
        }
    },

    
    TemplateSameAuthor: {
        editor1: {
            minwords: 0, maxwords: 200,
            invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A"
            ]
        }
    },

    
    TemplateRationale: {
        editor1: {
            minwords: 5, maxwords: 250,
            invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A"
            ]
        }
    },
    TemplateRevisionNote: {
        editor1: {
            minwords: 10, maxwords: 250,
            invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A"
            ]
        }
    },
    TemplateContext: {
        editor1: {
            minwords: 5, maxwords: 250,
            invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A"
            ]
        }
    },
    TemplateOtherRegDetails: {
        editor1: {
            minwords: 0, maxwords: 50,
            invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A"
            ]
        }
    },
    
    TemplateCountry: {
        editor1: {
            minwords: 5, maxwords: 250,
            invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A"
            ]
        }
    },

    TemplateSubgroups: {
        editor1: {
            minwords: 0, maxwords: 250,
            invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A"
            ]
        }
    },

    TemplateNamedContact: {
        editor1: {
            minwords: 0, maxwords: 100,
            invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A"
            ]
        }
    },
    TemplateDataSynthesisLegacy: {
        editor1: {
            minwords: 5, maxwords: 400,
            invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A"
            ]
        }
    },
    TemplateDataExtractionLegacy: {
        editor1: {
            minwords: 5, maxwords: 400,
            invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A"
            ]
        }
    },
    TemplateRiskOfBiasLegacy
        : {
        editor1: {
            minwords: 5, maxwords: 200,
            invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A"
            ]
        }
    },

    TemplateSearches: {
        editor1: {
            minwords: 5, maxwords: 300,
            invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A"
            ]
        }
    },
    TemplateReviewPublication: {
        editor1: {
            minwords: 5, maxwords: 50,
            invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A"
            ]
        },
        editor2: {
            minwords: 0, maxwords: 50,
            invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A"
            ]
        }
    },
    TemplateReportingBias: {
        editor1: {
            minwords: 0, maxwords: 50,
            invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A"
            ]
        }
    },
    TemplateCertainty: {
        editor1: {
            minwords: 5, maxwords: 250,
            invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A"
            ]
        }
    },
    TemplateOutcomesAdditional: {
        editor1: {
            minwords: 0, maxwords: 300,
            invalidtext: [
            ]
        }
    },
    TemplateReviewStatus: {
        editor1: {
            minwords: 10, maxwords: 100,
            invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A"
            ]
        }
    },
    TemplateOutcomesMain: {
        editor1: {
            minwords: 5, maxwords: 250,
            invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A"
            ]
        }
    },
    TemplatePopulation: {
        editor1: {
            minwords: 5, maxwords: 200,
            invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A"
            ]
        },
        editor2: {
            minwords: 0, maxwords: 200,
            invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A"
            ]
        },
    },
    TemplateCondition: {
        editor1: {
            minwords: 0, maxwords: 200, altmin: 1,
            invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A"
            ]
        }
    },
    TemplateIntervention: {
        editor1: {
            minwords: 0, maxwords: 200, altmin: 1,
            invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A"
            ]
        },
        editor2: {
            minwords: 0, maxwords: 200,
            invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A"
            ]
        }
    },
    TemplateComparator: {
        editor1: {
            minwords: 0, maxwords: 200, altmin: 1,
            invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A"
            ]
        },
        editor2: {
            minwords: 0, maxwords: 200,
            invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A"
            ]
        }
    },
    TemplateStudyDesign: {
        editor1: {
            minwords: 0, maxwords: 150,
            invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A"
            ]
        },
        editor2: {
            minwords: 0, maxwords: 150,
            invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A"
            ]
        }
    },

    TemplateSearchDatabases: {
        editor1: {
            minwords: 1, maxwords: 20,
            allowallcaps: true,
            invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A"
            ]
        }
    },


    TemplateRiskOfBias: {
        editor1: { minwords: 1, maxwords: 50 },
        editor2: { minwords: 1, maxwords: 50 },
    },
    TemplateDataSynthesis: {
        editor1: { minwords: 30, maxwords: 500 },
        editor2: { minwords: 30, maxwords: 300 }
    },
    TemplateDataSynthesisQualitative: {
        editor1: { minwords: 1, maxwords: 50 },
        editor2: { minwords: 20, maxwords: 250 },
        editor3: { minwords: 1, maxwords: 250 },
        editor4: { minwords: 5, maxwords: 250 }
    },
    TemplateDataSynthesisQuantitative: {
        editor1: { minwords: 5, maxwords: 100 },
        editor2: { minwords: 5, maxwords: 100 },
        editor3: { minwords: 5, maxwords: 100 },
        editor4: { minwords: 20, maxwords: 250 },
        editor5: { minwords: 20, maxwords: 250 },
        editor6: { minwords: 20, maxwords: 250 },
    },
    TemplateDataExtraction: {
        editor1: {
            minwords: 5, maxwords: 50,
            invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A",
                "No conflict"
            ]
        },
        editor2: {
            minwords: 0, maxwords: 50,
            invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A",
                "No conflict"
            ]
        }
    },
    TemplateReviewTeam: {
        editor1: {
            minwords: 5, maxwords: 100,
            invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A",
                "No conflict"
            ]
        }
    },
    Contact: {
        editor1: {
            minwords: 5, maxwords: 100,
            invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A",
                "No conflict"
            ]
        }
    },
    TemplateScreeningAdditional: {
        editor1: {
            minwords: 0, maxwords: 250,
            invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A"
            ]
        }
    },
    TemplateScreening: {
        editor1: {
            minwords: 1, maxwords: 50,
            invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A"
            ]
        }
    },
    TemplateOtherStudyIDMethods: {
        editor1: {
            minwords: 0, maxwords: 50,
            invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A"
            ]
        }
    },
    TemplatePublishedProtocol: {
        editor1: { minwords: 5, maxwords: 50 }
    },
    TemplateOrgAffil: {
        editor1: { minwords: 1, maxwords: 20 }
    },
    TemplateFunding: {
        editor1: {
            minwords: 0, maxwords: 50, altmin: 1, invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A"
            ]
        },
        editor2: {
            minwords: 0, maxwords: 50, altmin: 1, invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A"
            ]
        },
        editor3: {
            minwords: 0, maxwords: 50, invalidtext: [
                "Not applicable",
                "None",
                "NA",
                "N/A"
            ]
        }
    },
    TemplateConflict: {
        editor1: { minwords: 10, maxwords: 200, invalidtext: [
            "Not applicable",
            "None",
            "NA",
            "N/A"
        ] },
    },
    TemplatePeerReview: {
        editor1: { minwords: 10, maxwords: 200,invalidtext: [
            "Not applicable",
            "None",
            "NA",
            "N/A"
        ] },
    },
    TemplateAdditionalInfo: {
        editor1: { minwords: 0, maxwords: 250,invalidtext: [
            "Not applicable",
            "None",
            "NA",
            "N/A"
        ] },
    },
    TemplateCheckSimilar: {
        editor1: { minwords: 10, maxwords: 200 },
    }
};
