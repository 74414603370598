import React from 'react';


const RecordNotEnglish = props => {            

    // ========================================================================
    // if the suggested text != the existing text, dis[play]
    // ========================================================================    

    return  ( <div>
        {props.percent > props.threshold && (<div className="texterror">Please check that this text is in English. Entering non-English text in this field may result in your submission being rejected.</div>)}
        </div>
    )

}

export default RecordNotEnglish;