import React, { useEffect, useState } from 'react';
import { Row, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import HomeBanner from '../home/homebanner';
import { useParams } from "react-router-dom"
import RecordError from '../Template/TemplateUtils/RecordError';
import {defs}  from '../api/siteDefinitions';

const Help = () => {   
    const [data, setData] = useState({});
    const [testData, setTestData] = useState({});

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [helppage, setHelpPage] = useState(null);
    const [helppagename, setHelpPageName] = useState();
    
    const navigate = useNavigate(); 
    let params = useParams()


    // ========================================================================
    // helper function to load the actual page
    // ========================================================================
    useEffect(() => {
        try {
            setError(false);
            setHelpPage("");
            getPage(params.page);
        }
        catch(e) {
            setError(true);
        }
    }, [helppagename]);

    const randomcode = () => {
        let now = new Date().getTime();
    
        return "?" + now.toString();
      }
    

    const getPage= (page) => {
        fetch(defs.publicHelp + page + ".html" + randomcode()) 
        .then((response) => response.text())
        .then((html) => { 
            if(html.indexOf("npm start") > -1) {
                setError(true);
            }
            else {
                setHelpPage( <div dangerouslySetInnerHTML={{ __html: html }} />); 
                setHelpPageName(page);
            }
            
        })
        .catch((error) => { 
            setError(true);
        });
    
    }



    if (params.page === undefined ) {
        setHelpPageName("whyregister");
      }
      else if (params.page !== undefined && params.page !== helppagename) {
        setHelpPageName(params.page);
      }
    
    
  
    if (loading) 
        return <div>LOADING!!</div>    
    else    
        return <Container className="page-outer"> 
            <Row>
                <HomeBanner img="summer-background-sea-water.jpg" tag="About PROSPERO"/>
            </Row>

            {error && <RecordError errormessage="ERROR loading help page. Please contact CRD for help" />}
            { helppage != null && helppage }
        </Container>
}

export default Help;