import React, { useState, useEffect } from 'react';
import { Row, Container, Col, Button, Form, FloatingLabel, Spinner } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import { getUserDetails, Log } from '../Template/TemplateUtils/utils.js';
import MyDetails from '../Template/TemplateUtils/mydetails';
import HomeBanner from '../home/homebanner';

import Header from '../header/header';
import ResetPassword from './resetPassword';

import { postLoginAuthentication } from '../api/login.api';
import { UseAppContext } from '../AppContextProvider';

import './login.css';

const UserDetails = () => {
    const [data, setData] = useState();                         // the actual section data
    const [dirty, setDirty] = useState(false);                  // true if anything has changed
    const [error, setError] = useState(null);                   // true if there is an error
    const [checked, setCheck] = useState({});                   // result of checking the content locally (overridden on the server)
    const [loading, setLoading] = useState(true);               // true if loding data
    const [rerender, setRerender] = useState(0);                // helper to force a rerender


    // ========================================================================
    // Helper function to get the data for this section from the server
    // ========================================================================
    const getData = async (recordversion, fname) => {
        await getUserDetails()
            .then((resp) => {
                Log(resp);
                if (resp.status === "error") {
                    setError(resp);
                    setLoading(false);
                }
                else {
                    if (resp.FieldStatus < 100) {
                    }
                    setData(resp);
                    setLoading(false);
                }
            })
            .catch((e) => {
                console.log(e);
                return {};
            })
            ;
    }


    // ========================================================================
    // Initialsise on first call
    // ========================================================================
    useEffect(() => {
        getData();
    }, []);


    const isEmailOK = (email) => {
        return true;
    }
    const savecontact = (contact) => {
        if (contact === null) {
            return;
        }
        else {
            setData(contact);
            setRerender(rerender + 1);
        }
    }

    return <Container className="page-outer">
        <Row>
            <HomeBanner img="splashing-275950_1280.jpg" tag="PROSPERO user" />
        </Row>
        <Row className="pt-3" >
            {data !== undefined &&
                <MyDetails data={data} save={savecontact} itemid={0} display={"card"} isemailok={isEmailOK} password={"yes"} hideconflict={"yes"} />
            }
        </Row>
    </Container>
}



export default UserDetails