import React from 'react';
import { toSentenceCase2 } from './utils.js';


const RecordAllCaps = props => {            
    var tempstring= "";
    // ========================================================================
    // if the suggested text != the existing text, dis[play]
    // ========================================================================    

    const wordCount = (raw) => {

        var count = 0;
        if(raw.blocks != undefined) {
            for(var i = 0; i < raw.blocks.length; i++) {
                var block = raw.blocks[i];
                count = count + block.text.split(' ').filter(function(str){return str!="";}).length;
                tempstring = tempstring + block.text;
            }
        }
        else {
            count = raw.split(' ').filter(function(str){return str!="";}).length;
            tempstring = raw;
        }
        
        return count;
    }

    const wordcount = wordCount(props.raw);
    return  ( <div>
        {!props.skipme && wordcount > 1 && tempstring.replace(/[1-9]/g,"x") == tempstring.toUpperCase().replace(/[1-9]/g,"X") && (<div className="texterror">The text you entered is all capital letters. Text fields should be in sentence case. Click <span className="clickehere" onClick={() => props.fixme(toSentenceCase2(props.raw))}>here</span> to convert.</div>)}
        </div>
    )

}

export default RecordAllCaps;