import React, { useEffect, useState } from 'react';
import { Row, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import HomeBanner from './homebanner';
import Header from '../header/header';

const HowToRegister = () => {
    const [data, setData] = useState({});
    const [testData, setTestData] = useState({});

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    if (loading)
        return <div>LOADING!!</div>
    else if (error)
        return <div>ERROR</div>
    else
        return <Container className="page-outer">

            <Row>
                <HomeBanner img="PROSPERO_banner3.jpg" tag="How To Register " />
            </Row>
            <Row>
                <h3> </h3>

                <div className="ratio ratio-16x9">
                    <iframe src="/videos/PROSPERO VIDEO 1.mp4?autoplay=0" title="How to register video" allowfullscreen></iframe>
                </div>

            </Row>
        </Container>
}

export default HowToRegister;