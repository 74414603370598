import React, { useEffect, useState } from 'react';
import { Form, Row, Container, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Header from '../header/header';
import HomeBanner from '../home/homebanner';
import { UseAppContext } from '../AppContextProvider';
import RecordTriage from './recordtriage';
import RecordError from '../Template/TemplateUtils/RecordError';
import { useParams } from "react-router-dom"
import { getKey, createRecord } from '../Template//TemplateUtils/utils.js';
import RecordHelp from '../Template/TemplateUtils/RecordHelp';

const ChooseReviewType = () => {
    const [data, setData] = useState({});
    const [type, setType] = useState(null);

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();



    // ========================================================================
    // the context data store
    // ========================================================================
    const {
        recordData: {
            record, setRecord
        },
        userData: {
            user
        }
    } = UseAppContext();


    if (user === undefined || user === null || user.useruuid === undefined || user.useruuid === null) {
        navigate("/login");
        return <>Not logged in</>;
    }

    const allowCreaterecord = async () => {
        await createRecord(data.target, data.variant)
            .then((resp) => {
                if (resp.status !== "success") {
                    setError(resp.errormessage);
                }
                else {
                    let ret = {};
                    ret.recordversion = resp.recordversion;
                    setError(null);
                    setRecord(ret);
                    localStorage.setItem("currentrecordversion", ret)
                    changePage();
                }
            });
    }



    let params = useParams()

    const list = {
        target: [
            { value: "humans", label: "A systematic review of studies with people/humans as participants" }
            , { value: "animals", label: "A systematic review of studies with animals as participants that is directly relevant to human health" }
        ]
        , purpose: [
            { value: "live", label: "This record is NOT being created as part of a student project or training course", explain: <><p className="helptextinline">All of the registration process will be available to you and your record will be published on the public PROSPERO site</p></> }
            ,{ value: "training", label: "This PROSPERO record is being created as part of a training course", explain: <p className="helptextinline">All of the registration process will be available to you but your record will not be published on the public PROSPERO site. You will be able to give access to your record to your supervisor.</p> }
        ]
        , variant: [
            { value: "Intervention", label: "Intervention review", explain: <p className="helptextinline mb-5">Assessing the benefits, harms or experience of a health intervention (medicine, treatment, vaccine, device, preventative measure, procedure or policy).</p> }
            , { value: "dta", label: "Diagnostic test accuracy", explain: <p className="helptextinline mb-5">Assessing the accuracy of a diagnostic test in detecting or excluding a particular disease or condition.</p> }
            , { value: "prognostic", label: "Prognostic review", explain: <p className="helptextinline mb-5">Assessing the probable course or future outcome of people with a particular health problem.</p> }
            , { value: "epidemiological", label: "Review of incidence or prevalence", explain: <p className="helptextinline mb-5">Assessing the prevalence or incidence of a disease or condition.</p> }
            , { value: "methodological", label: "Methodology", explain: <p className="helptextinline">Examining the evidence on methodological aspects of systematic reviews, trials and other evaluations of health and social care.</p> }
        ]
    }



    const setTargetValue = (e) => {
        let value = e.currentTarget.value;
        let name = e.currentTarget.name;
        let tempdata = { ...data };
        tempdata[name] = value;
        setData(tempdata);

    }

    const createRadioList = (caption, text, listtype, decide) => {
        return <div className={"radiobox"}>
            {caption !== null && <h2 >{caption}</h2>}
            {decide !== undefined && decide !== null && <RecordHelp className="smalltext" fname={"recordtriage"} helpsection={decide} label="(Help me decide)" />}

            {text !== null && text}
            <Form.Group>
                {list[listtype].map((item, index) => {
                    return <><Form.Check type="radio" key={getKey()} id={getKey()} >
                        <Form.Check.Input type="radio" name={listtype} checked={data[listtype] === item.value ? true : false} defaultValue={item.value} onChange={(e) => setTargetValue(e)} />
                        <Form.Check.Label className={data[listtype] === item.value ? "labelselected" : "labelnotselected"}
                        >{item.label}</Form.Check.Label>
                    </Form.Check>
                        {item.explain !== undefined && item.explain}
                    </>
                })

                }
            </Form.Group>
        </div>
    }

    const changePage = () => {
        sessionStorage.setItem("currentMode", "recordTriage");
        navigate("../register");
    };



    if (loading)
        return <div>LOADING!!</div>
    else if (error)
        return <RecordError errormessage={error} />
    else
        return <Container className="page-outer recordfields mb-5">

            <Row>
                <HomeBanner img="4k-gf58fb1b71_1920.jpg" tag="Register a review" />
            </Row>
            <>

                <div>
                    <h1>What are you registering?</h1>
                    <p className="helptextinline">PROSPERO uses different templates for different types of review.</p>
                    <p className="helptextinline">Use this page to  select the correct template. This page will determine the information you need to provide in your registration record. Answer correctly here to avoid having to restart the process later.</p>
                </div>
                {createRadioList("Review of human or animal studies?", <><p className="helptextinline">PROSPERO comprises two complementary registries - one of reviews of human health and wellbeing and one of animal pre-clinical studies that have a clear and direct link to human health and wellbeing </p><h3>I am registering</h3></>, "target")}

                {data.target !== undefined && data.target !== null && <div>
                    {createRadioList("Is this registration record being created by a student as part of a course or module?", null, "purpose", "help4")}
                </div>}

                {data.purpose !== undefined && data.purpose !== null && <div>
                    {createRadioList("What type of review are you planning?", null, "variant")}
                </div>
                }
                {data.variant !== undefined && data.variant !== null &&
                    <div><center><Button className="mt-5" variant="primary" size="lg"
                        onClick={allowCreaterecord}
                    >Register your review now</Button></center></div>

                }

            </>

        </Container>
}

export default ChooseReviewType;