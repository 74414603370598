import React, { useEffect, useState, useMemo } from 'react';
import { Button, Col, Container, Form, Row, InputGroup, FormControl, Accordion } from "react-bootstrap";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { UseAppContext } from '../AppContextProvider';


const SearchFilters = ({results}) => {       
    const [hidePanel, setHidePanel] = useState(false);
    // const [selected, setSelected] = useState({});

    const funders = ["British Heart Foundation",
    "British Society for Rheumatology",
    "Chief Scientist Office, The Scottish Government",
    "Medical Research Council (MRC)",
    "National Institute for Health Research (NIHR)",
    "Wellcome Trust",
    "South African Medical Research Council (SAMRC)",
    "Pakistan Institute of Living and Learning",
    "Singapore National Medical Research Council (NMRC)",
    "National Research Foundation of Korea",
    "Japan Science and Technology Agency (JST)",
    "Korea National Institute of Health (KNIH)",
    "Indian Council of Medical Research",
    "Medical Research Future Fund",
    "Health Research Council of New Zealand",
    "National Health and Medical Research Council (NHMRC)",
    "Australian Government Research Training Program Scholarship",
    "State level (eg Victoria has funded many synthesis efforts)",
    "Social Sciences and Humanities Research Council",
    "Public Health Agency of Canada",
    "Ontario Ministry of Finance",
    "Canada's Drug and Health Technology Agency (CADTH)",
    "Canadian Institutes of Health Research (CIHR)",
    "Health Canada",
    "Ministry of Health of China",
    "Ministry of Science and Technology, China",
    "Ministry of Science and Technology, Taiwan",
    "Natural Science Foundation of China",
    "China Scholarship Council",
    "Fundamental Research Funds for the Central Universities in China",
    "National Natural Science Foundation of China",
    "Vetenskapsrådet-Medicine / Swedish Research Council",
    "Swiss National Science Foundation (SNSF)",
    "The Netherlands Organisation for Health Research and Development (ZonMW)",
    "ZonMw / Netherlands Organisation for Health Research and Development",
    "Deutsche Forschungsgemeinschaft",
    "European Academy of Allergy and Clinical Immunology (EAACI)",
    "European Commission",
    "European Science Research Council (ESRC)",
    "Forskingsrådet / Research Council of Norway",
    "Fund for Scientific Research - Flanders (FWO)",
    "Nederlandse Organisatie voor Wetenschappelijk Onderzoek (NWO)",
    "Health Research Board, Ireland",
    "Institut national de la santé et de la recherche médicale (Inserm)",
    "Italian Ministry of Health",
    "Bill and Melinda Gates Foundation",
    "UNICEF Innocenti, SDG synthesis",
    "World Health Organization (WHO)",
    "Oswaldo Cruz Foundation (Fiocruz)",
    "Sao Paulo Research Foundation (FAPESP)",
    "Coordenação de Aperfeiçoamento de Pessoal de Nível Superior, Brazil",
    "Coordination for the Improvement of Higher Education Personnel (CAPES), Brazil",
    "Centers for Disease Control and Prevention",
    "Agency for Healthcare Research and Quality",
    "National Institutes of Health (NIH)",
    "Patient-Centered Outcomes Research Institute (PCORI)",
    "United States Department of Defense",
    "US Department of Veteran Affairs"]

    const regions = ["UK",
    "Africa",
    "Asia",
    "Australasia",
    "Canada",
    "China and Taiwan",
    "Europe",
    "Multinational",
    "South America",
    "USA"];
    
    const {
        userSearchData: {
            userSearch, setUserSearch
        }
    } = UseAppContext();



    const [data, setData] = useState({});
    const [testData, setTestData] = useState({});
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    

    // ========================================================================
    // helper function to load or create the data
    // ========================================================================
    


    // ========================================================================
    // helper function to generate object keys
    // ========================================================================
    let keyCount = 0;
    const getKey = (root) => {
        return root + "_" + keyCount++;
    }

    // check if the filter item has been selected
    const isChecked = (field, value) => {
        try {
            if(userSearch.filter !== undefined && userSearch.filter.selected !== undefined) { // check field exists
                const selected = {...userSearch.filter.selected};
                if(selected[field] !== undefined) {        
                    const filter = selected[field];
                    if(filter !== undefined) {
                        const index = filter.indexOf(value);
                        if (index > -1) {
                            return true;
                        }
                    }
                }
            }
        } catch(err) {
            console.error("Is checked error", err);
        }

        return false;
    }


    const Body = ({name, fields, handleClick}) => {        

        let ret = [];
        if(fields !== undefined && fields.length > 0) {
            fields.map((field, i) => {
                if(results !== null && results.aggs !== undefined && results.aggs !== null && results.aggs[field] !== undefined && results.aggs[field].length !== 0) {            
                    const agg = results.aggs[field];
                    for(var j = 0; j < agg.length; j++) {                
                        let key = agg[j].key;
                        let temp = <Form.Check className="searchfilters-level2" type={"checkbox"}  key={key+"1"}  >
                            <Form.Check.Input key={key+"2"} type={"checkbox"} onChange={() => null} checked={isChecked(name, key)} name={name} onClick={(e) => handleClick(e, name, key, fields)} value={key}/>
                            <Form.Check.Label >{key} ({agg[j].doc_count})</Form.Check.Label>
                        </Form.Check>
        
                        ret.push(temp);
                   }
                }
            });


        }
        
       return ret;
    }

    // categories are AND'd, values in categories are OR'd
    const handleClick = (event, name, value, fields) => {
        // handle the selected boxes
        try {
            let sel = [];
            let selected = {};
            if(userSearch.filter !== null && userSearch.filter !== undefined && userSearch.filter.selected !== undefined) {
                selected = {...userSearch.filter.selected};

                if(userSearch.filter.selected[name] !== undefined) // check field exists
                    sel = [...userSearch.filter.selected[name]];                
            }
            
            let obj = value;
            if(event.currentTarget.checked) {
                sel.push(obj);
            }
            else {
                const index = sel.indexOf(obj);
                if (index > -1) { // only splice array when item is found
                    sel.splice(index, 1); // 2nd parameter means remove one item only
                }
            }     
            
            selected[name] = sel;

            // update the filter            
            let currentfilter = {bool : {
                must : [],
                must_not : [],
                should : [],
                }
            };
    
            let temp2 = [];
            let ret = buildNodes("recordtypecheck", fields);
            if(ret != null)
                temp2.push(ret);
            
            ret = buildNodes("publishedcountscheck", fields);
            if(ret != null) 
                temp2.push(ret);
            
            ret = buildNodes("reviewstatuscheck", fields);
            if(ret != null)
                temp2.push(ret);
            
            ret = buildNodes("yearfirstpublishedheadercheck", fields);
            if(ret != null)
                temp2.push(ret);
            
            ret = buildNodes("funderscheck", fields);
            if(ret != null)
                temp2.push(ret);
            
            ret = buildNodes("reviewercountcheck", fields);
            if(ret != null)
                temp2.push(ret);
            
            ret = buildNodes("reviewercountpotscheck", fields);
            if(ret != null)
                temp2.push(ret);                
            
            if(temp2 != [])
                currentfilter.bool.must = temp2;

            // set the selected filter items in the usersearch object   
            
            const filter = {...userSearch.filter, "current": currentfilter, "selected": selected};            
            setUserSearch({...userSearch, "filter": filter});
        } catch(err) {
            console.error("SELECT FILTER ERROR", err);
        }
    }

    const buildNodes = (parent, fields) => {
        let nodes = document.getElementsByName(parent);
        let ret = {bool : {
                should : []
            }
        };  
        let temp = [];
        for(var i=0; i<nodes.length; i++){
            var node = nodes[i];
            var obj;
            if(node.checked ) {

                if(parent == "publishedcountscheck") {
                    obj = {
                        range: {
                            datefirstpublished: {
                                gte: 0,
                                lte: 0
                            }
                        }
                    
                    };
                    if(node.value == "One day") {
                        obj.range.datefirstpublished.gte = "now-1d/d";
                        obj.range.datefirstpublished.lte = "now";
                    }
                    else if(node.value == "Seven days") {
                        obj.range.datefirstpublished.gte = "now-7d/d";
                        obj.range.datefirstpublished.lte =  "now";
                    }
                    else if(node.value == "This month") {
                        obj.range.datefirstpublished.gte = "now-1M/M";
                        obj.range.datefirstpublished.lte = "now";
                    }
                    else if(node.value == "This year") {
                        obj.range.datefirstpublished.gte = "now-1y/y";
                        obj.range.datefirstpublished.lte =  "now";
                    }
                } else if(parent == "reviewercountpotscheck") {
                    obj = {
                        range: {
                            reviewercount: {
                                gte: 0,
                                lte: 0
                            }
                        }
                    
                    };
                    if(node.value == "Only one") {
                        obj.range.reviewercount.gte = 0
                        obj.range.reviewercount.lte = 1
                    }
                    else if(node.value == "Only Two") {
                        obj.range.reviewercount.gte = 2
                        obj.range.reviewercount.lte = 2
                    }
                    else if(node.value == "Three to nine") {
                        obj.range.reviewercount.gte = 3
                        obj.range.reviewercount.lte = 9
                    }
                    else if(node.value == "Ten to twenty") {
                        obj.range.reviewercount.gte = 10
                        obj.range.reviewercount.lte = 20
                    }
                    else if(node.value == "More than twenty") {
                        obj.range.reviewercount.gte = 21
                        obj.range.reviewercount.lte = 100000
                    }
                } else {
                    obj = {
                        query_string: {
                            query: "\"" + node.value + "\"",
                            fields: fields
                        }
                    };        
                }
                temp.push(obj);
            }
        }
        ret.bool.should = temp;
        if(temp === null || temp.length === 0)
            return null;
        return ret;
    };

    


    const toggleVisible = (event) => {
return;
        let toggle = event.target.nextElementSibling;
        if (toggle.classList.contains("hidden")) {
            toggle.classList.remove("hidden");
          } else {
            toggle.classList.add("hidden");
          }
        
    }

    return (<div className={"searchfilters" + ((hidePanel) ? ' hide-panel' : '')}>
        <div id="recordfields">
            
            {(hidePanel) 
            ?   <div className="filtercaption"><Button id="filterSlideButton" onClick={() => {setHidePanel(!hidePanel)}}><FaAngleRight /></Button></div>
            :   <>
                    <div className="filtercaption">Filter options<Button id="filterSlideButton" onClick={() => {setHidePanel(!hidePanel)}}><FaAngleLeft /></Button></div>
                    <Accordion defaultActiveKey="0"
                    alwaysOpen={true}
                    
                    flush>
                    <Accordion.Item  eventKey="0">
                    <Accordion.Header>Record type</Accordion.Header>
                        <Accordion.Body>
                    <Form.Group className="mb-3 ms-3">
                            <Body fields={["recordtype"]} handleClick={handleClick} name="recordtypecheck" />
                        </Form.Group>
                    </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item  eventKey="10">
                    <Accordion.Header>Date in PROSPERO</Accordion.Header>
                        <Accordion.Body>
                        <Form.Group className="mb-3 ms-3">
                            <Body fields={["publishedcounts"]} handleClick={handleClick} name="publishedcountscheck" />
                        </Form.Group>
                        </Accordion.Body>
                    </Accordion.Item>


                    <Accordion.Item  eventKey="20">
                    <Accordion.Header>Review status</Accordion.Header>
                        <Accordion.Body>
                        <Form.Group className="mb-3 ms-3">
                            <Body  fields={["reviewstatus"]} handleClick={handleClick} name="reviewstatuscheck" />
                        </Form.Group>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="30">
                    <Accordion.Header>Publication year</Accordion.Header>
                        <Accordion.Body>
                        <Form.Group className="mb-3 ms-3">
                            <Body fields={["yearfirstpublished"]} handleClick={handleClick} name="yearfirstpublishedheadercheck" />
                        </Form.Group>
                    </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item  eventKey="40">
                    <Accordion.Header>Funders</Accordion.Header>
                        <Accordion.Body>
                        <Form.Group className="mb-3 ms-3">
                            {funders.map((funder, i) => {
                                return <Form.Check key={i} className="searchfilters-level2" type={"checkbox"}>
                                            <Form.Check.Input type={"checkbox"} onChange={() => null} checked={isChecked('funder', 0)} name={'funder'} onClick={(e) => handleClick(e, 'funder', 0)} value={funder}/>
                                            <Form.Check.Label >{funder}</Form.Check.Label>
                                        </Form.Check>                            
                            })}
                            {/* <Body fields={["funders"]} handleClick={handleClick} name="funderscheck" /> */}
                        </Form.Group>

                    </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item  eventKey="50">
                    <Accordion.Header>Region</Accordion.Header>
                        <Accordion.Body>
                        <Form.Group className="mb-3 ms-3">
                            {regions.map((region, i) => {
                                return <Form.Check key={i} className="searchfilters-level2" type={"checkbox"}>
                                            <Form.Check.Input type={"checkbox"} onChange={() => null} checked={isChecked('region', 0)} name={'region'} onClick={(e) => handleClick(e, 'region', 0)} value={region}/>
                                            <Form.Check.Label >{region}</Form.Check.Label>
                                        </Form.Check>                            
                            })}                            
                            {/* <Body aggs={props.aggs != undefined && props.aggs != null ? props.aggs.reviewercount : null} fields={["reviewercount"]} handleclick={handleClick} filter={props.currentfilter} name="reviewercountcheck" selected={props.selected}/> */}
                        </Form.Group>

                    </Accordion.Body>
                    </Accordion.Item>                    
                </Accordion>  
                </>              
            }
        </div>
    </div>
    );
             
}

export default SearchFilters;