import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { prosperoClient } from '../../api/axios';
import sanitizeHtml from 'sanitize-html';
import { definedwordcounts } from "../TemplateUtils/Wordcounts.js"


import Preview from '../TemplateUtils/BuildPreview';
import Check from '../TemplateUtils/Check';
import {
    EditorState,
    convertFromRaw,
} from 'draft-js';
import { redirect } from 'react-router-dom';
import { defs } from '../../api/siteDefinitions';
import { convertFromHTML } from 'draft-convert';

const Logging = true;

const baseURL = defs.prosperoURL;
const feURL = defs.feURL;
//"http://localhost:8000/";

const RecordSectionPurposeEdit = "6d05037c-58a5-4be1-bf6a-44c790bae137";

const unknownError = {
    status: "error",
    errormessage: {
        errorcode: 0,
        errormessage: "An unknown error has occurred. Please refresh the page and try again"
    }
}

const makeURL = (part) => {
    return feURL + part;
}

const fetchoptions = (clean = false) => {
    if(clean) {
        return {}
    }
    let now = new Date().getTime();
    let h = btoa(now.toString());
    let ret = { headers: { "prospero-auth-token": h } };
    return ret;
}

// ========================================================================
// Help links and text. set to NULL if not required for this component
// ========================================================================
const getOldVersion = (recordversion, fname) => {
    let oldVersion = localStorage.getItem(recordversion + "_" + fname);
    if (oldVersion !== undefined && oldVersion !== null) {
        return oldVersion;
    }
    else {
        return null;
    }
}


// generic error handler for fetch calls
// the node server always returns status 200 but the payload will contain status: xxx if an error was thrown
// and hte errormessage element will exist and be populated
const handleErrors = (response) => {
    try {
        // if the server reported an uncaught error, throw it
        if (response.status === 204) { // deliberate no content
            throw Error("No content");
        }
        else if (!response.ok) { // deliberate no content
            throw Error(response.statusText);
        }
    }
    catch (e) {
        // any failure, throw generic error
        throw Error("Unknown error");
    }
    return response;
}



const getSectionTagValue = (data, section, tag) => {
    const sectiondata = data[section];
    if (sectiondata !== undefined && sectiondata !== null) {
        if (sectiondata.content[0].tags[tag] !== undefined) {
            return sectiondata.content[0].tags[tag];
        }
    }
    return null
}


// ========================================================================
// helper function to show or hide error divs
// ========================================================================
const showError = (id, checked, show) => {
    if (show === false) {
        return false;
    }
    if (checked === undefined) {
        return false;
    }
    let errs = checked.errors;
    if (errs === undefined) {
        return false;
    }
    for (var i = 0; i < errs.length; i++) {
        if (errs[i] === id)
            return true;
    }

    return false;
}


// ========================================================================
// helper function to generate object keys
// ========================================================================
const getKey = () => {
    return "key_" + Math.random() * 10000000;
}


// ========================================================================
// helper function to scroll into view
// ========================================================================
const scrollIntoView = (elementid, behaviour = 'instant') => {
    const element = document.getElementById(elementid);
    if (element) {
        //element.scrollIntoView({ behavior: behaviour });
    }
}




// ============================================================
// This just passes the cehck on to the appropirate subcheck
// ============================================================
const checkTempateSubItem = (section, data) => {
    // data must be of the correct format
    if (data === undefined
        || data === null
    ) {
        return false;
    }

    let c = new Check(definedwordcounts);
    let ret;
    try {
        ret = c[section](data);
    }
    catch (e) {
        ret = false;
    }
    return ret;
}

// ============================================================
// This just passes the cehck on to the appropirate subcheck
// ============================================================
const checkTempateItem = (data) => {
    // data must be of the correct format
    if (data === undefined
        || data === null
        || data.content === undefined
        || data.content === null
        || data.content.length === 0
        || data.section === undefined
        || data.section === null

    ) {
        return false;
    }

    let c = new Check(definedwordcounts);
    let ret;
    try {
        ret = c[data.section](data);
    }
    catch (e) {
        ret = false;
    }
    return ret;
}



// ============================================================
// This just passes the cehck on to the appropirate subcheck
// ============================================================
const buildpreview = (data) => {
    // data must be of the correct format
    if (data === undefined
        || data === null
        || data.content === undefined
        || data.content === null
        || data.content.length === 0
        || data.section === undefined
        || data.section === null

    ) {
        return false;
    }

    let c = new Preview();
    let ret;
    try {
        ret = c[data.section](data);
    }
    catch (e) {
        ret = false;
    }
    return ret;
}



// empty all data from the data block
const clearData = (data) => {
    // clear all editors
    const editors = data.content[0].editors;
    if (editors !== undefined) {
        let keys = Object.keys(editors);
        let total = keys.length;

        for (let i = 0; i < keys.length; i++) {
            editors[keys[i]] = {};
        }
    }
    // clear all lists
    const lists = data.content[0].lists;
    if (lists !== undefined) {
        let keys = Object.keys(lists);
        let total = keys.length;

        for (let i = 0; i < keys.length; i++) {
            lists[keys[i]] = [];
        }
    }

    // clear all tags
    const tags = data.content[0].tags;
    if (tags !== undefined) {
        let keys = Object.keys(tags);
        let total = keys.length;

        for (let i = 0; i < keys.length; i++) {
            tags[keys[i]] = "";
        }
    }
    return data;
}


const gotoPDF = (pdf) => {
    let URL = feURL + "temppdf/" + pdf;
    return window.open(URL, "PROSPERO PDF check", "_blank");
}

const gotoDOI = (doi) => {
    let URL = "https://dx.doi.org/" + doi;
    return window.open(URL, "PROSPERO DOI check", "_blank");
}

const checkDOI = (doi) => {
    // strip protocol if it is there
    let ret = doi.toLowerCase().trim();
    ret = ret.replace("https://dx.doi.org/", "").replace("http://dx.doi.org/", "");
    return ret;
}

const getEditorChecks = (section, index, data) => {
    // bespoke for this item
    let min = null;
    if (section === 'TemplateCondition' && data !== undefined && data.content !== undefined && data.content[0] !== undefined && data.content[0].lists !== undefined && data.content[0].lists.list1 !== undefined) {
        if (index === 'editor1') {
            const list = data.content[0].lists.list1;
            if (list.length === 0) {
                min = 1;
            }
        }
    }

    const alllimits = definedwordcounts[section];
    const thislimit = JSON.parse(JSON.stringify(alllimits[index]));

    // fixup  min count if neecssary
    thislimit.minwords = min === null ? thislimit.minwords : min;
    return thislimit;
}


// ========================================================================
// Format a date for display
// ========================================================================    
const formatDate = (isodate) => {
    const locale = 'en-GB';
    const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
    }
    let datestring = "";
    try {
        datestring = new Date(isodate).toLocaleString(locale, options)
        if (datestring === "Invalid Date")
            datestring = "";
    }
    catch (e) {

    }
    return datestring;
}

// ========================================================================
// count the number of words in the editor
// ========================================================================    
const wordCount = (raw) => {
    // iterate all blocks
    if (raw === undefined || raw.blocks === undefined)
        return 0;
    var count = 0;
    for (var i = 0; i < raw.blocks.length; i++) {
        var block = raw.blocks[i];
        count = count + block.text.split(' ').filter(function (str) { return str != ""; }).length;
    }
    return count;
}



const axiosoptions = {
    timeout: 10000,
}

const stripHTML = (dirty) => {
    let clean = sanitizeHtml(dirty, {
        allowedTags: [],
        allowedAttributes: {}
    });

    // and fix up smart quotes
    clean = clean.replace('“', '"');
    clean = clean.replace('”', '"');
    clean = clean.replace('<p></p>', '');

    return clean;
}

const cleanHTML = (dirty) => {
    let clean = sanitizeHtml(dirty, {
        allowedTags: ['b', 'i', 'strong', 'em', 'ul', 'ol', 'li', 'p', 'span'],
        parser: {
            lowerCaseTags: true
        },
        allowedAttributes: {
            "span": ["class"]
        }
    });
    clean = clean.replace('“', '"');
    clean = clean.replace('”', '"');
    clean = clean.replace('<p></p>', '');
    return clean;
}


const ConfirmModal = ({ title, message, handleConfirm, handleCancel }) => {
    return <Modal show={true} onHide={() => { handleCancel(false) }}>
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={() => { handleConfirm() }}>Yes</Button>
            <Button variant="secondary" onClick={() => { handleCancel(false) }}>No</Button>
        </Modal.Footer>
    </Modal>
}

const MessageModal = ({ title, message, handleConfirm }) => {
    return <Modal show={true} onHide={() => { handleConfirm(false) }}>
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={() => { handleConfirm(false) }}>OK</Button>
        </Modal.Footer>
    </Modal>
}

const EmptyModal = ({ title, label, child, handleConfirm }) => {
    return <Modal show={true} onHide={() => { handleConfirm(false) }}>
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{child}</Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={() => { handleConfirm(false) }}>{label}</Button>
        </Modal.Footer>
    </Modal>
}


// ==================================================================
// GD 2023-04-20
// Return text as HTML
// ==================================================================
export function createMarkup(input) {
    return { __html: input };
}


// ==================================================================
// GD 2023-02-20
// very rough check that the language is English - checked more thoroughly on submit
// ==================================================================
export function isEnglish(str) {
    var mapObj = {
        "≤": "",
        "≥": "",
        "Α": "",
        "α": "",
        "•": ""


    };

    var re = new RegExp(Object.keys(mapObj).join("|"), "gi");
    str = str.replace(re, function (matched) {
        return mapObj[matched];
    });

    const array = str.split('');
    let count = 0;
    let len = str.length;
    for (var i = 0; i < len; i++) {
        const charval = array[i].charCodeAt(0);
        if (charval > 127) {
            count++;
        }
    }

    const check = (count / len) * 100.0;
    return check;
}

export function toSentenceCase2(raw) {
    for (var i = 0; i < raw.blocks.length; i++) {
        var block = raw.blocks[i];
        block.text = toSentenceCase(block.text);
    }
    return raw;
}



// ==================================================================
// GD 2023-02-20
// converts string to sentence case
// ==================================================================
export function toSentenceCase(str) {
    // ==================================================================
    // sanity
    // ==================================================================
    if (str === null || str.trim() === '') {
        return str;
    }

    // ==================================================================
    // If this string is all caps  we will convert it all
    // ==================================================================

    if (str === str.toUpperCase()) {
        str = str.toLowerCase();
    }
    else {
        // ==================================================================
        // If this string has more then 2 words in caps we will lower case it
        // ==================================================================
        const array = str.split('');
        let nwords = array.length;

        var uppercasewords = 0;
        str.split(' ')
            .map((i, index) => {
                if (i === i.toUpperCase()) {
                    uppercasewords++;
                }
            });

        if (uppercasewords >= 2) {
            str = str.toLowerCase();
        }
    }
    var prev = ".";
    const ret = str.split(' ')
        .map((i, index) => {
            if (i == '' || i == ' ')
                return i

            // if the whole word is upper case then just return it
            if (i === i.toUpperCase() && i.length > 1) {
                prev = i.split('').reverse().join('')[0];
                return i;
            }

            // if the word is the start of a sentence OR the letter "i" uppercase the first letter
            if (prev.match(/[.?!]/) || i === "i") {
                prev = i.split('').reverse().join('')[0];
                return i[0].toUpperCase() + i.substring(1)
            }

            // grab the last character of the word
            prev = i.split('').reverse().join('')[0];

            // lowercase the first letter of the word
            return i[0].toLowerCase() + i.substring(1);
        })
        .join(' ')
    return ret;
}

const getKeyPeople = (contacts) => {
    let ret = { guarantor: "", contact: "" }
    for (var i = 0; i < contacts.length; i++) {
        let item = contacts[i];
        if (item.guarantor !== "") {
            ret.guarantor = item.fullname;
        }
        if (item.contact !== "") {
            ret.contact = item.contact;
        }
    }
    return ret;
}

const parseDOI = async (doi) => {
    let citation = await fetch(baseURL + "record/parsedoi/?doi=" + doi, fetchoptions())
        .then((resp) => {
            return resp.json()
        }
        )
        .then((cit) => {
            if (cit.errormessage !== undefined) {
                return cit.errormessage;
            }
            else {
                return cit;
            }
        }
        )
        .catch((e) => {
            console.log(e);
        })
        ;

    return citation;

}

const mod = async (target, type) => {
    const params = {
        target: target,
        variant: type
    };

    // make the call
    const mod = await prosperoClient.post(baseURL + "record/mod", params, {
        timeout: 180000,
    })
        .then((resp) => {
            return resp.data
        }
        )
        .catch((e) => {
            console.log(e);
        })
        ;

    return mod;

}

const getROBTools = async (recordversion) => {
    var opts = await fetch(baseURL + "record/robtools/?recordversion=" + recordversion, fetchoptions())
        .then((resp) => {
            return resp.json()
        }
        )
        .then((items) => {
            if (items.errormessage !== undefined) {
                return items.errormessage;
            }
            else {
                var opts = items.results.map((item, i) => {
                    return item;
                });
                return opts;
            }
        }
        )
        .catch((e) => {
            console.log(e);
        })
        ;

    return opts;
};


const getPreview = async (recordversion) => {
    var opts = await fetch(baseURL + "record/preview/?recordversion=" + recordversion, fetchoptions())
        .then((resp) => {
            return resp.json()
        }
        )
        .then((items) => {
            if (items.errormessage !== undefined) {
                return items.errormessage;
            }
            else {
                return items.html;
            }
        }
        )
        .catch((e) => {
            console.log(e);
        })
        ;

    return opts;
};


const createRecord = async (target, variant) => {
    const params = {
        target: target,
        variant: variant
    };

    // make the call
    const ret = await prosperoClient.post(baseURL + "record/createrecord", params, axiosoptions)
        .then((resp) => {
            return resp.data;
        }
        )
        .then((data) => {
            if (data.status !== "success") {
                return data;
            }
            else {
                //let ret = {};
                //ret.recordversion = data.recordversion;
                //return ret;
                return data;
            }
        }
        )
        .catch((e) => {
            console.log(e);
        })
        ;

    return ret;
};


const createversion = async (recordversion) => {
    const params = {
        recordversion: recordversion
    };

    // make the call
    const ret = await prosperoClient.post(baseURL + "record/createversion", params, axiosoptions)
        .then((resp) => {
            return resp.data;
        }
        )
        .then((data) => {
            if (data.status !== "success") {
                return data.errormessage;
            }
            else {
                let ret = {};
                ret.recordversion = data.recordversion;
                return ret;
            }
        }
        )
        .catch((e) => {
            console.log(e);
        })
        ;

    return ret;
};


const createminorversion = async (recordversion) => {
    const params = {
        recordversion: recordversion
    };

    // make the call
    const ret = await prosperoClient.post(baseURL + "record/createminorversion", params, axiosoptions)
        .then((resp) => {
            return resp.data;
        }
        )
        .then((data) => {
            if (data.status !== "success") {
                return data.errormessage;
            }
            else {
                let ret = {};
                ret.recordversion = data.recordversion;
                return ret;
            }
        }
        )
        .catch((e) => {
            console.log(e);
        })
        ;

    return ret;
};


const createmajorversion = async (recordversion) => {
    const params = {
        recordversion: recordversion
    };

    // make the call
    const ret = await prosperoClient.post(baseURL + "record/createmajorversion", params, axiosoptions)
        .then((resp) => {
            return resp.data;
        }
        )
        .then((data) => {
            if (data.status !== "success") {
                return data.errormessage;
            }
            else {
                let ret = {};
                ret.recordversion = data.recordversion;
                return ret;
            }
        }
        )
        .catch((e) => {
            console.log(e);
        })
        ;

    return ret;
};


const getSearchDBs = async (recordversion) => {
    var opts = await fetch(baseURL + "record/searchdbs/?recordversion=" + recordversion, fetchoptions())
        .then((resp) => {
            return resp.json()
        }
        )
        .then((items) => {
            var opts = items.results.map((item, i) => {
                return item;
            });
            return opts;
        }
        )
        .catch((e) => {
            console.log(e);
        })
        ;

    return opts;
};


const formatName = (title, firstname, lastname) => {
    let ret = "";

    if (title !== undefined && title !== null && title.trim() !== "") {
        ret = ret + title + " ";
    }

    if (firstname !== undefined && firstname !== null && firstname.trim() !== "") {
        ret = ret + firstname + " ";
    }

    if (lastname !== undefined && lastname !== null && lastname.trim() !== "") {
        ret = ret + lastname;
    }
    return ret;
}

const getRecordApproval = async (recordversion) => {
    var opts = await fetch(baseURL + "record/approval/?recordversion=" + recordversion, fetchoptions())
        .then((resp) => {
            return resp.json()
        })
        .then((resp) => {
            return resp;
        }
        )
        .catch((e) => {
            console.log(e);
        })
        ;
    return opts;
};

const getAuthorApproval = async (code) => {
    var opts = await fetch(baseURL + "record/authorapproval/?code=" + code, fetchoptions())
        .then((resp) => {
            return resp.json()
        })
        .then((items) => {
            return items;
        }
        )
        .catch((e) => {
            console.log(e);
            return unknownError;
        })
        ;
    return opts;
};


const publishversion = async (recordversion, content) => {
    const params = {
        recordversionid: recordversion,
        content: content
    };

    const opts = await prosperoClient.post('record/publishversion', { params }, axiosoptions)
        .then((resp) => {
            console.log(resp);
            return resp.json()
        })
        .then((items) => {
            console.log(items);
            return items;
        }
        )
        .catch((e) => {
            console.log(e);
        })
        ;
    console.log(opts);
    return opts;
}

const confirmuser = async (code) => {
    const params = {
        code: code
    };


    const opts = await prosperoClient.post('user/confirm', params, axiosoptions)
        .then((resp) => {
            console.log(resp);
            return resp.data;
        })
        .catch((e) => {
            console.log(e);
        })
        ;
    return opts;
}

const getRecordAuthorView = async (code) => {
    var opts = await fetch(baseURL + "record/view/?code=" + code, fetchoptions())
        .then((resp) => {
            console.log(resp);
            return resp.json()
        })
        .then((items) => {
            console.log(items);
            return items;
        }
        )
        .catch((e) => {
            console.log(e);
        })
        ;
    console.log(opts);
    return opts;
}


const getPublicView = async (params) => {
    let page = "";
    let major = 0;
    let minor = 0;
    if (params.page != undefined) {
        page = params.page;
    }
    if (params.major != undefined) {
        major = params.major;
    }
    if (params.minor != undefined) {
        minor = params.minor;
    }
    var opts = await fetch(baseURL + "view/" + page + "/" + major + "/" + minor, fetchoptions())
        .then((resp) => {
            console.log(resp);
            return resp.json()
        })
        .then((items) => {
            console.log(items);
            return items;
        }
        )
        .catch((e) => {
            console.log(e);
        })
        ;
    console.log(opts);
    return opts;
}



const approvePublication = async (code) => {
    const params = {
        code: code
    };

    // make the call
    var opts = await prosperoClient.post(baseURL + "record/approvepublication", params, axiosoptions)
        .then((resp) => {
            return resp.data;
        })
        .catch((e) => {
            console.log(e);
        })
        ;
    console.log(opts);
    return opts;
};

const rejectPublication = async (code) => {
    const params = {
        code: code
    };

    // make the call
    var opts = await prosperoClient.post(baseURL + "record/rejectpublication", params, axiosoptions)
        .then((resp) => {
            return resp.data;
        })
        .catch((e) => {
            console.log(e);
        })
        ;
    console.log(opts);
    return opts;
};

const getRecordSection = async (recordversion, section, purpose) => {
    try {
        return await fetch(baseURL + "record/section?recordversion=" + recordversion + "&section=" + section + "&purpose=" + purpose, fetchoptions())
            .then((resp) => {
                if (resp.status !== 204 && resp.ok) {
                    return resp.json(); // this is returned as json from handleErrors
                }
            }
            )
            .catch((e) => {
                console.log(e);
                return "";
            })
            ;
    }
    catch (error) {
        return "";
    }
};


const getUserDetails = async () => {
    try {
        return await fetch(baseURL + "record/userdetails", fetchoptions())
            .then((resp) => {
                if (resp.status !== 204 && resp.ok) {
                    return resp.json(); // this is returned as json from handleErrors
                }
            }
            )
            .catch((e) => {
                console.log(e);
                return "";
            })
            ;
    }
    catch (error) {
        return "";
    }
};


const putResendEmail = async (recordversion, uniqueid) => {
    let ret = null;
    const params = {
        recordversion: recordversion,
        uniqueid: uniqueid
    };

    // make the call
    await prosperoClient.post(baseURL + "record/resendemail", params, axiosoptions)
        .then(resp => {
            // if we don't return a business layer error then return the data packet
            let ret = resp.data;
            if (resp.data.status === "ok") {
                ret = resp.data;
            }
            else {
                //throw new ErrorPROSPEROError("System error", "Could not save data for this field", 10005, null)
            }
        })
        .catch(error => {
            // we have already specified the error so just rethrow it
            if (error.name === "PROSPEROError")
                throw error;
            else {
                let e = new Error("Could not save data");
                e.code = 10005;
                e.name = "PROSPEROError";
                e.data = "There was a serious system failure and your section was not saved. Please copy any work and paste it into a text editor for later then close this section by clicking the \"Go back\" button above";
                throw e;
            }
        });
    return ret;
};



const putRecordSection = async (recordversion, data, force) => {
    let ret = null;
    const params = {
        recordversion: recordversion,
        data: data,
        force
    };

    // make the call
    await prosperoClient.post(baseURL + "record/section", params, axiosoptions)
        .then(resp => {
            // if we don't return a business layer error then return the data packet
            let ret = resp.data;
            if (resp.data.status === "success") {
                ret = resp.data;
            }
            else {
                let e = new Error(resp.data.errormessage);
                e.name = "PROSPEROError";
                e.message = resp.data.errormessage.message
                e.code = resp.data.errormessage.errorcode
                e.data = resp.data.errormessage.data;
                throw e;
                //throw new ErrorPROSPEROError("System error", "Could not save data for this field", 10005, null)
            }
        })
        .catch(error => {
            // we have already specified the error so just rethrow it
            if (error.name === "PROSPEROError")
                throw error;
            else {
                let e = new Error("Could not save data");
                e.code = 10005;
                e.name = "PROSPEROError";
                e.data = "There was a serious system failure and your section was not saved. Please copy any work and paste it into a text editor for later then close this section by clicking the \"Go back\" button above";
                throw e;
            }
        });
    return ret;
};


const joinup = async (data) => {
    let ret = null;
    const params = {
        data: data
    };

    // make the call
    ret = await prosperoClient.post(baseURL + "user/join", params, axiosoptions)
        .then(resp => {
            // if we don't return a business layer error then return the data packet
            return resp.data
        })
        .catch(error => {
            // we have already specified the error so just rethrow it
            if (error.name === "PROSPEROError")
                throw error;
            else {
                let e = new Error("Could not save data");
                e.code = 10005;
                e.name = "PROSPEROError";
                e.data = "There was a serious system failure and your account has not been created";
                throw e;
            }
        });
    return ret;
};

const postfile = async () => {
    let formData = new FormData();
    let file = window.document.getElementById("fileupload").files[0];
    formData.append("uploaded_file", file);
    // make the call
    let ret = await prosperoClient.post(baseURL + "record/upload", formData, {
        headers: {
            "enctype": "multipart/form-data"
        }
    })
        .then(resp => {
            // if we don't return a business layer error then return the data packet
            return resp.data
        })
        .catch(error => {
            // we have already specified the error so just rethrow it
            if (error.name === "PROSPEROError")
                throw error;
            else {
                let e = new Error("Could not save data");
                e.code = 10005;
                e.name = "PROSPEROError";
                e.data = "There was a serious system failure and your account has not been created";
                throw e;
            }
        });
    return ret;
};

const getDashboardCounts = async (lastmessage) => {
    const defaultmessage = { total: 220000, counts: [] };
    try {
        return await fetch(baseURL + "record/dashboardcounts?id=" + (lastmessage === undefined || lastmessage === null ? 0 : lastmessage), fetchoptions())
            .then((resp) => {
                if (resp.status !== 204 && resp.ok) {
                    return resp.json(); // this is returned as json from handleErrors
                }
            }
            )
            .catch((e) => {
                console.log(e);
                return defaultmessage;
            })
            ;
    }
    catch (error) {
        return defaultmessage;
    }
};

const getMessages = async (lastmessage) => {
    const defaultmessage = { messages: [] };
    try {
        return await fetch(baseURL + "record/dashboardmessages?id=" + (lastmessage === undefined || lastmessage === null ? 0 : lastmessage), fetchoptions())
            .then((resp) => {
                if (resp.status !== 204 && resp.ok) {
                    return resp.json(); // this is returned as json from handleErrors
                }
            }
            )
            .catch((e) => {
                console.log(e);
                return defaultmessage;
            })
            ;
    }
    catch (error) {
        return defaultmessage;
    }
};

const setShowHelpItem = () => {
    alert("Not defined yet");
}

const checkEditorContent = (editor, editorname, templatesection, usealtmin = false) => {
    const alllimits = definedwordcounts[templatesection];
    const limits = alllimits[editorname];

    if (editor === undefined || editor === null || editor.datahtml === undefined || editor.datahtml === null || editor.datahtml === "") {
        if ((usealtmin === false ? limits.minwords : limits.altmin) > 0) {
            return false;
        }
    }
    else if (editor.datahtml !== undefined && editor.datahtml !== null) {
        const text = stripHTML(editor.datahtml);
        let wordcount = text.split(' ').filter(function(str){return str!="";}).length;

        if ((wordcount < (usealtmin === false ? limits.minwords : limits.altmin) || wordcount > limits.maxwords)) {
            return false
        }
    }

    return true;
}

const checkEditorContentRaw = (editor, editorname, templatesection, usealtmin = false) => {
    const alllimits = definedwordcounts[templatesection];
    const limits = alllimits[editorname];

    if (editor === undefined || editor === null || editor.dataraw === undefined || editor.dataraw === null || editor.dataraw === "") {
        if ((usealtmin === false ? limits.minwords : limits.altmin) > 0) {
            return false;
        }
    }
    else if (editor.dataraw !== undefined && editor.dataraw !== null) {
        const estate = convertFromRaw(editor.dataraw);
        const ed = EditorState.createWithContent(estate);

        /* 2023-11-06 GD removed this check as it returns incorrect retults for an empty string
        const currentcontent = ed.getCurrentContent().getPlainText().trim();
        if (currentcontent === "") {
            return false;
        }
        */

        const wordcount = wordCount(editor.dataraw)
        if ((wordcount < (usealtmin === false ? limits.minwords : limits.altmin) || wordcount > limits.maxwords)) {
            return false
        }
    }

    return true;
}


const Log = (message) => {
    Logging && console.log(message);
}


const forceedit = () => {
    alert("helloe");
}

class PROSPEROError extends Error {
    constructor(originalmessage, message, code, more) {
        super(originalmessage, { message: message, code: code, more: more });
        this.name = "PROSPEROError";
    }
}


const copyToClipboard = (id) => {
    let element = document.getElementById(id);
    if (element !== null) {
        var textField = document.createElement('textarea');
        textField.innerText = element.innerText;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
    }
}


const toWords = (n) => {
    if (n < 0)
        return "Error!!!!";

    const single_digit = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine']
    const double_digit = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen']
    const below_hundred = ['twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety']

    if (n === 0)
        return 'zero';

    let word = ""
    const translate = (n) => {
        if (n < 10) {
            word = single_digit[n] + ' '
        }
        else if (n < 20) {
            word = double_digit[n - 10] + ' '
        }
        else if (n < 100) {
            let rem = translate(n % 10)
            word = below_hundred[(n - n % 10) / 10 - 2] + ' ' + rem
        }
        else if (n < 1000) {
            word = single_digit[Math.trunc(n / 100)] + ' Hundred ' + translate(n % 100)
        }
        else if (n < 1000000) {
            word = translate(parseInt(n / 1000)).trim() + ' Thousand ' + translate(n % 1000)
        }
        else if (n < 1000000000) {
            word = translate(parseInt(n / 1000000)).trim() + ' Million ' + translate(n % 1000000)
        }
        else {
            word = translate(parseInt(n / 1000000000)).trim() + ' Billion ' + translate(n % 1000000000)
        }
        return word;
    }

    let result = translate(n);
    return result.trim();

}

const submitVersion = async (recordversion) => {
    const params = {
        recordversion: recordversion
    };
    const ret = await prosperoClient.post(baseURL + "record/submitversion", params, axiosoptions)
        .then((resp) => {
            return resp.data;
        }
        )
        .catch((e) => {
            console.log(e);
        })
        ;

    return ret;
}

const cancelVersion = async (recordversion) => {
    const params = {
        recordversion: recordversion
    };
    const ret = await prosperoClient.post(baseURL + "record/cancelversion", params, axiosoptions)
        .then((resp) => {
            return resp.data;
        }
        )
        .catch((e) => {
            console.log(e);
        })
        ;

    return ret;
}


const deleteVersion = async (recordversion) => {
    const params = {
        recordversion: recordversion
    };
    const ret = await prosperoClient.post(baseURL + "record/deleteversion", params, axiosoptions)
        .then((resp) => {
            return resp.data
        }
        )
        .then((data) => {
            return data;
        }
        )
        .catch((e) => {
            console.log(e);

        })
        ;

    return ret;
}

const getPreviewPlainText = (content, target) => {
    // return "" if not found
    let ret = "";
    if (content === undefined || content === null || target === undefined || target === null || content[1] === undefined) {
        return ret;
    }


    const array = content[1];

    for (let i = 0; i < array.length; i++) {
        const item = array[i];
        if (item.section.TemplateSection !== undefined && item.section.TemplateSection === target) {
            if (item.section.editors.editor1.datahtml !== undefined) {
                ret = stripHTML(item.section.editors.editor1.datahtml);
                break;
            }
        }
    }

    return ret;

}
// ==================================================================
// ==================================================================
// ==================================================================


export {
    PROSPEROError, RecordSectionPurposeEdit, Log, getROBTools, baseURL, getKey, scrollIntoView, showError, ConfirmModal, MessageModal, EmptyModal, axiosoptions,
    stripHTML, cleanHTML, wordCount, formatDate, getEditorChecks, checkDOI, gotoDOI, gotoPDF, buildpreview, checkTempateItem,
    checkTempateSubItem, getKeyPeople, getSectionTagValue, checkEditorContent, getDashboardCounts, getMessages,
    getRecordSection, putRecordSection, getOldVersion, setShowHelpItem, clearData, getSearchDBs, createRecord, getPreview, getRecordApproval, parseDOI,
    toWords, copyToClipboard, submitVersion, deleteVersion, getAuthorApproval, formatName, getRecordAuthorView, approvePublication, rejectPublication, getPreviewPlainText
    , createversion, cancelVersion, mod, getUserDetails, createminorversion, createmajorversion, getPublicView, joinup, postfile, feURL, confirmuser, fetchoptions
    , putResendEmail,makeURL
}
