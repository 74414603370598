import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import LoadingSpinner from '../TemplateUtils/Loading';
import RecordSectionRequired from '../TemplateUtils/RecordSectionRequired';
import { Form, Button } from 'react-bootstrap';
import { UseAppContext } from '../../AppContextProvider';
import RecordNavControls from '../TemplateUtils/RecordNavControls';
import RecordIdentifier from '../TemplateUtils/RecordIdentifier';
import RecordCaption2 from '../TemplateUtils/RecordCaption2';
import RecordError from '../TemplateUtils/RecordError';
import RecordOldversion from '../TemplateUtils/RecordOldversion';
import { getKey, Log, getOldVersion, RecordSectionPurposeEdit, getRecordSection, scrollIntoView, showError, checkTempateItem, buildpreview } from '../TemplateUtils/utils.js';


const fname = "TemplateStudyDateRestrictions";
const TemplateStudyDateRestrictions = props => {
    // ========================================================================
    // the context data store
    // ========================================================================
    const {
        recordData: {
            record
        }
    } = UseAppContext();


    // ========================================================================
    // state variables
    // ========================================================================
    const [data, setData] = useState();                         // the actual section data
    const [showpreview, setShowPreview] = useState(false);      // toggle show/hide the preview
    const [dirty, setDirty] = useState(false);                  // true if anything has changed
    const [error, setError] = useState(null);                   // true if there is an error
    const [checked, setCheck] = useState({});                   // result of checking the content locally (overridden on the server)
    const [showerrors, setShowErrors] = useState(false);        // toggle showing any errors reported by check()
    const [loading, setLoading] = useState(true);               // true if loding data
    const [rerender, setRerender] = useState(0);                // helper to force a rerender
    const [oldversion, setOldversion] = useState(null);         // placeholder for any version that has not been saved 
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());


    // ========================================================================
    // Helper function to get the data for this section from the server
    // ========================================================================
    const getData = async (recordversion, fname) => {
        await getRecordSection(recordversion, fname, RecordSectionPurposeEdit)
            .then((resp) => {
                Log(resp);
                if (resp.status === "error") {
                    setError(resp);
                    setLoading(false);
                }
                else {
                    if(resp.FieldStatus < 100) {
                        buildpreview(resp);
                    }
                    setData(resp);
                    if (resp.content[0].tags.startdate !== null && resp.content[0].tags.startdate != "") {
                        let storedstartdate = new Date(resp.content[0].tags.startdate);
                        setStartDate(storedstartdate);
                    }
                    if (resp.content[0].tags.enddate !== null && resp.content[0].tags.enddate != "") {
                        let storedenddate = new Date(resp.content[0].tags.enddate);
                        setEndDate(storedenddate);
                    }

                    setCheck(checkTempateItem(resp));
                    setLoading(false);
                }
            });
    }


    // ========================================================================
    // Initialsise on first call
    // ========================================================================
    useEffect(() => {
        getOldVersion(record.recordversion, fname);
        getData(record.recordversion, fname);
    }, []);


    // ========================================================================
    // Handle the browser back button
    // ========================================================================
    useEffect(() => {
        const beforeUnLoad = (e) => {
            if (dirty) {
                e.preventDefault();
                e.stopPropagation();
                e.returnValue = "Are you sure you want to leave? Changes will not be saved";
                return "Are you sure you want to leave? Changes will not be saved";
            }
        }


        window.addEventListener('beforeunload', beforeUnLoad);
        return () => {
            window.removeEventListener("beforeunload", beforeUnLoad);
        }
    }, [dirty]);

    // ========================================================================
    // helper function to load or create the data, and perform initial check
    // ========================================================================
    useEffect(() => {
        if (!loading) {
            setDirty(true);
        }
        setCheck(checkTempateItem(data));
    }, [rerender]);


    // ========================================================================
    // Handle going back without saving changes
    // =======================================================================
    const back = () => {
        setDirty(false);
        props.back("list");
        setTimeout(() => {
            window.history.back();
        }, 100)
    }


    // ========================================================================
    // Check if an object element has a particular value
    // ========================================================================
    const isSelected = (key, item) => {
        let tempdata = data;
        let tags = tempdata.content[0].tags;
        if (tags[key] === item) {
            return "checked";
        }
        return null;
    }


    // ========================================================================
    // get the value of an object element
    // ========================================================================
    const getTagValue = (tag) => {
        let tempdata = data;
        let tags = tempdata.content[0].tags;
        return tags[tag];
    }


    // ========================================================================
    // Handle the browser back button
    // ========================================================================
    const setDates = (name, value) => {
        let mytags = data.content[0].tags;

        mytags[name] = value;

        if (name === 'startdate') {
            setStartDate(value);
        }
        else if (name === 'enddate') {
            setEndDate(value);
        }
        buildpreview(data);
        setRerender(rerender + 1);
    }

    // ========================================================================
    // set the value of an object element
    // ========================================================================
    const setTagValue = (e) => {
        var tempdata = data;
        var mytags = tempdata.content[0].tags;
        let name = e.currentTarget.name;
        let value = e.currentTarget.value;

        // ========================================================================
        // element-sepcific transformations
        // ========================================================================
        if (value === "nodaterestrictions") {
            mytags.startdaterestrictions = "nostartdaterestrictions";
            mytags.enddaterestrictions = "noenddaterestrictions";
            mytags.startdate = "";
            mytags.enddate = "";
            setStartDate(null);
            setEndDate(null);
        }
        else if (value === "nostartdaterestrictions") {
            mytags.startdate = "";
            setStartDate(null);
        }
        else if (value === "noenddaterestrictions") {
            mytags.enddate = "";
            setEndDate(null);
        }

        mytags[name] = value;
        let content = tempdata.content;
        content[0].tags = mytags;
        tempdata.content = content;
        buildpreview(tempdata);
        setData(tempdata);
        setRerender(rerender + 1);
    }

    // ========================================================================
    // handle already editing data
    // ========================================================================
    const useoldversion = (option) => {
        if (option) {
            let tempdata = JSON.parse(oldversion);
            tempdata.EditingToken = data.EditingToken;
            setOldversion(null);
            setData(tempdata);
        }
        else {
            setOldversion(null);
        }
    }


    // ========================================================================
    // return the content
    // ========================================================================
    return (<>
        {loading && <LoadingSpinner />}
        {error !== null && <RecordError errormessage={error} />}
        {!showpreview && !loading && (<div id={"scrollto_" + fname} className="page-inner-restrict pb-3 recordfields">
            {error === null && oldversion === null && (
                <><RecordIdentifier variant={data.RecordTemplateVariant} />
                    <RecordCaption2
                        caption={data.caption}
                        variant={data.RecordTemplateVariant}
                        fname={fname}
                    />

                    <Form.Group className={"mb-3"}>
                        <div className={isSelected("daterestrictions", "nodaterestrictions") === "checked" ? "radiobox selected" : "radiobox"}>
                            <h2>Are there any search date restrictions? <RecordSectionRequired /></h2>
                            <Form.Check type={"radio"} id={getKey()} >
                                <Form.Check.Input type={"radio"} name="daterestrictions" checked={isSelected("daterestrictions", "nodaterestrictions") == null ? false : true} defaultValue="nodaterestrictions" onChange={(e) => setTagValue(e)} />
                                <Form.Check.Label className={isSelected("daterestrictions", "nodaterestrictions") == null ? "labelnotselected" : "labelselected"}
                                >There are no search date restrictions</Form.Check.Label>
                            </Form.Check>

                            <Form.Check type={"radio"} id={getKey()} >
                                <Form.Check.Input type={"radio"} name="daterestrictions" checked={isSelected("daterestrictions", "daterestrictions") == null ? false : true} defaultValue="daterestrictions" onChange={(e) => setTagValue(e)} />
                                <Form.Check.Label className={isSelected("daterestrictions", "daterestrictions") == null ? "labelnotselected" : "labelselected"}
                                >There are search date restrictions</Form.Check.Label>
                            </Form.Check>
                        </div>
                    </Form.Group>
                    {isSelected("daterestrictions", "daterestrictions") === "checked" &&
                        <>
                            <div className={isSelected("daterestrictions", "nodaterestrictions") === "checked" ? "radiobox selected" : "radiobox"}>
                                <h2>Search start date restrictions</h2>
                                <Form.Group >
                                    <Form.Check type={"radio"} id={getKey()} >
                                        <Form.Check.Input type={"radio"} name="startdaterestrictions" checked={isSelected("startdaterestrictions", "nostartdaterestrictions") == null ? false : true} defaultValue="nostartdaterestrictions" onChange={(e) => setTagValue(e)} />
                                        <Form.Check.Label>There are no restrictions on the search start date (databases will be searched from inception)</Form.Check.Label>
                                    </Form.Check>
                                    <Form.Check type={"radio"} id={getKey()} >
                                        <Form.Check.Input type={"radio"} name="startdaterestrictions" checked={isSelected("startdaterestrictions", "startdaterestrictions") == null ? false : true} defaultValue="startdaterestrictions" onChange={(e) => setTagValue(e)} />
                                        <Form.Check.Label>Databases will be searched for articles published from</Form.Check.Label>
                                    </Form.Check>

                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => setDates('startdate', date)}
                                        selectsStart
                                        disabled={isSelected("startdaterestrictions", "startdaterestrictions") == null ? true : false}
                                        startDate={startDate}
                                        endDate={endDate}
                                        dateFormat="dd MMMM yyyy"
                                        className={"mt-3"}
                                    />
                                    {showError(fname + "_startdate", checked, showerrors) && <div id={fname + "_startdate"} className="formerror">You must provide a date here</div>}
                                </Form.Group>
                            </div>

                            <div className={isSelected("daterestrictions", "nodaterestrictions") === "checked" ? "radiobox selected" : "radiobox"}>
                                <h2>Search end date restrictions</h2>
                                <Form.Group >
                                    <Form.Check type={"radio"} id={getKey()} >
                                        <Form.Check.Input type={"radio"} name="enddaterestrictions" checked={isSelected("enddaterestrictions", "noenddaterestrictions") == null ? false : true} defaultValue="noenddaterestrictions" onChange={(e) => setTagValue(e)} />
                                        <Form.Check.Label>There are no restrictions on search end dates (searches will be up to the date of searching)</Form.Check.Label>
                                    </Form.Check>
                                    <Form.Check type={"radio"} id={getKey()} >
                                        <Form.Check.Input type={"radio"} name="enddaterestrictions" checked={isSelected("enddaterestrictions", "enddaterestrictions") == null ? false : true} defaultValue="enddaterestrictions" onChange={(e) => setTagValue(e)} />
                                        <Form.Check.Label>Databases will be searched for articles published up to </Form.Check.Label>
                                    </Form.Check>
                                    <DatePicker 
                                        selected={endDate}
                                        onChange={(date) => setDates('enddate', date)}
                                        selectsEnd
                                        disabled={isSelected("enddaterestrictions", "enddaterestrictions") == null ? true : false}
                                        startDate={startDate}
                                        dateFormat="dd MMMM yyyy"
                                        endDate={endDate}
                                        minDate={startDate}
                                        className={"mt-3"}
                                    />
                                    {showError(fname + "_enddate", checked, showerrors) && <div id={fname + "_enddate"} className="formerror">You must provide a date here</div>}
                                </Form.Group>
                            </div>
                            {showError(fname + "_incorrectdates", checked, showerrors) && <div id={fname + "_incorrectdates"} className="formerror">The end date must be after the start date</div>}
                            {showError(fname + "_bothselected", checked, showerrors) && <div id={fname + "_bothselected"} className="formerror">You have indicated there are date restrictions but not specidfied any.</div>}
                        </>
                    }


                </>)}
            {oldversion !== null && <RecordOldversion oldversion={oldversion} newversion={data} useoldversion={useoldversion} />}
            <RecordNavControls data={data} back={back} preview={setShowPreview} dirty={dirty} cancomplete={checked} setshowerrors={setShowErrors} showerrors={showerrors} error={error === null && oldversion === null ? null : "error"} />
        </div>)
        }
        {
            showpreview && (<div className="page-inner-restrict pb-3 publicdocument">
                <h1>{data.caption}</h1>
                <div dangerouslySetInnerHTML={{ __html: data.preview }} />
                <div><p><br /></p>
                    <p><br /></p>
                </div>
                <Button variant="primary" onClick={() => setShowPreview(false)}>Close preview</Button>
            </div>)
        }


    </>
    )

}

export default TemplateStudyDateRestrictions;