import React, { useEffect, useState } from 'react';

import { confirmuser, approvePublication, rejectPublication, getAuthorApproval, formatName, getRecordAuthorView } from '../Template/TemplateUtils/utils.js';
import { UseAppContext } from '../AppContextProvider';
import ModalBox from '../Template/TemplateUtils/Modal';
import { prosperoClient } from '../api/axios';
import { Button, Table, Row, Container, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Header from '../header/header';
import HomeBanner from '../home/homebanner';
import RecordTriage from './recordtriage';
import { useParams } from "react-router-dom"
import RecordError from '../Template/TemplateUtils/RecordError';
import TemplatePreview from '../Template/TemplateItem/TemplatePreview';


const ConfirmUser = () => {
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [approval, setApproval] = useState();
    const [preview, setPreview] = useState();
    const [recordtemplate, setRecordTemplate] = useState([]);
    const [approved, setApproved] = useState();

    // ========================================================================
    // the context data store
    // ========================================================================
    const navigate = useNavigate();


    let windowparams = useParams()

    const confirmUser = async () => {
        await confirmuser(windowparams.code)
            .then((resp) => {

                if (resp.status === 'error') {
                    setError(resp.errormessage);
                }
                else if (resp.status === 'ok') {
                    setApproved(true);
                }
            });

    }


    // ========================================================================
    // helper function to load or create the data
    // ========================================================================
    useEffect(() => {
    }, []);

    return <Container className="page-outer recorddashboard">

        <Row>
            <HomeBanner img="4k-gf58fb1b71_1920.jpg" tag="User account activation" />
        </Row>

        <div className="approval-page">
            <h2 className="approval-name">Activate account</h2>
            <p><span className="approval-text">Please click the button below to activate this account</span></p>
        </div>

        <div className="approval-buttons mt-3">

            {!error && !approved && <>

                <Button variant="primary" onClick={() => confirmUser()}>Activate now</Button>
            </>
            }
        </div>
        {approved &&
            <ModalBox show={approved} heading={"User account activated"} body={"The account has been activated. Please log in now."} ok={() => navigate('/login')} setclose={setApproved} />
        }
        {error !== false &&
            <ModalBox show={error} heading={"User account not activated"} body={error.errormessage} ok={() => navigate('/join')} setclose={setError} />
        }
    </Container>
}

export default ConfirmUser;