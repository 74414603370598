import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, InputGroup } from 'react-bootstrap';
import LoadingSpinner from './Loading';
import { cleanHTML, getKey, showError, stripHTML, getEditorChecks, checkTempateSubItem } from './utils.js';
import { confirm } from 'react-bootstrap-confirmation';
import MEditor from '../TemplateUtils/MEditor';
import { RiMailSendLine } from 'react-icons/ri';
import { RiCheckLine } from 'react-icons/ri';
import { RiEqualLine } from 'react-icons/ri';
import { RiCloseLine } from 'react-icons/ri';
import Country from '../TemplateUtils/Country';
import RecordHelp from '../TemplateUtils/RecordHelp';
import RecordSectionRequired from '../TemplateUtils/RecordSectionRequired';
import RecordSectionOptional from '../TemplateUtils/RecordSectionOptional';
import { BsNutFill } from 'react-icons/bs';



// ========================================================================
// set this to true to clear exising data of this field for testing
// ========================================================================
const test = false;

// ========================================================================
// Module defs
// ========================================================================
const fname = "Contact";

const Contact = props => {
    const [itemid, setItemId] = useState(props.itemid);
    const [data, setData] = useState();
    const [validation, setValidation] = useState({});
    const [loading, setLoading] = useState(true);
    const [rerender, setRerender] = useState(0);
    const [checked, setCheck] = useState(0);
    const [checkedsave, setCheckedSave] = useState(0);
    const [goBack, setGoBack] = useState(false);
    const [dirty, setDirty] = useState(false);
    const [deleteperson, setDeletePerson] = useState(false);
    const [showerrors, setShowErrors] = useState(true);
    const [notok, setNotOk] = useState(false);

    // ========================================================================
    // generate local keys (repeatable across renders)
    // ========================================================================
    let keyval = 1;
    const getLocalKey = () => {
        return fname + "_" + props.itemid + "_" + keyval++;
    }

    // ========================================================================
    // helper function to load or create the data
    // ========================================================================
    useEffect(() => {
        const input = JSON.stringify(props.data);
        let rawdata = JSON.parse(input);
        setData(rawdata);
    }, []);

    useEffect(() => {
        if (data != null) {
            setLoading(false);
        }
    }, [data]);


    // ========================================================================
    // helper function to set the dirty flag
    // ========================================================================
    const onChange = () => {
        setCheckedSave(0);
        setDirty(true);
    }


    // ========================================================================
    // helper function to set the dirty flag
    // ========================================================================
    const onChangeEmail = (e) => {
        onChange();
        if (!props.isemailok(e.currentTarget.value)) {
            setNotOk(true);
        }
        else {
            setNotOk(false);
        }
    }

    // ========================================================================
    // Helper to handle the form data
    // ========================================================================  
    const handleSubmit1 = async () => {
        // grab the values
        let email = stripHTML(document.getElementById(itemid + "validationCustomUsername").value);
        let orcid = stripHTML(document.getElementById(itemid + "validationCustomOrcid").value);
        let title = stripHTML(document.getElementById(itemid + "validationCustomTitle").value);
        let firstname = stripHTML(document.getElementById(itemid + "validationCustomFirstname").value);
        let lastname = stripHTML(document.getElementById(itemid + "validationCustomLastname").value);
        let org = stripHTML(document.getElementById(itemid + "validationCustom03").value);
        let country = stripHTML(document.getElementById(itemid + "validationCustom04").value);


        // grab the data
        let state = data;

        // save the data
        state = { ...state, email: email, orcid: orcid, firstname: firstname, lastname: lastname, organisation: org, country: country, title: title };

        // grab the email check result
        let emailok = data.emailok;

        // if the email is different from the save email re-check it
        setLoading(true);
        if (email !== data.email || emailok === false) {
            emailok = await checkTempateSubItem("Email", email)
                .then((resp) => {
                    return resp;
                })
                .catch((response) => {
                    emailok = false;
                });
            // save the data and add a date the email was checked
            let date = new Date();
            state = { ...state, emailchecked: date, emailok: emailok };
        }
        setLoading(false);

        // check the content of this form locally for the UI
        let ret = [];
        ret = checkTempateSubItem(fname, state);


        if (emailok !== undefined && !emailok) {
            ret.ok = false
            ret.formerrors.email = true;
        }
        if (ret.ok === false) {
            state.status = "error";
        }
        else {
            state.status = "ok";
        }
        setCheck(ret);

        // add an error to the returned errors if the email was flagged incorrect above
        let ferrs = ret.formerrors;
        if (emailok === false) {
            ferrs = { ...ferrs, email: !emailok };
        }

        // save everything
        setValidation(ferrs);

        if (Object.keys(ferrs).length > 0 && checkedsave === 0) {
            setData(state);
            setCheckedSave(1);
            setDirty(true);
        }
        else {
            // NOTE we can save back to the parent page here because that page
            // works on a copy of the original data
            setData(state);
            props.save(state);

            return;
        }

        // handle the case where the checks failed but the user still wants to save
        if (checkedsave === 1000) {
            state = { ...state, status: "error" };
            setData(state);
            props.save(state);

        }
    };


    // ========================================================================
    // Check if an object element has a particular value
    // ========================================================================
    const isSelected = (key, item) => {
        if (data[key] === item) {
            return "checked";
        }
        return null;
    }


    // ========================================================================
    // set the value of an object element
    // ========================================================================
    const setTagValue = (e, tag) => {
        let value = e.currentTarget.value;
        data[tag] = value;
        onChange();
        setRerender(rerender + 1);
    }

    // ========================================================================
    // ========================================================================
    // callback from the editor onblur
    // ========================================================================
    const notifytext = (editorname, editor) => {
        let c0 = data;

        const clean = cleanHTML(editor.datahtml);
        editor.datahtml = clean;
        c0.editors[editorname] = editor;
    }

    // ========================================================================
    // get the editor data for the editor
    // ========================================================================
    const getEditorData = (editor) => {
        if (data != undefined && data !== null) {
            return data.editors[editor];
        }
        return null;
    }

    if (data === null || data === undefined) {
        return null;
    }


    if (props.display === "inline") {
        return <>
            <div className="contact_inline">
                {data.organisation === undefined ? "" : <span className="contact_org">{data.organisation}</span>}
                {data.country === undefined || data.country === "" ? "" : <span className="contact_country"> ({data.country})</span>}

            </div>
        </>
    }

    // ========================================================================
    // Render
    // ========================================================================
    return <>
        {loading && <LoadingSpinner text="Checking email..." />}
        {!loading && <>
            <h1 id={"scrollto_" + fname}>{props.hideconflict === "yes" ? "User details" : "Review team member"}</h1>

            <div className={""}>
                {props.hideconflict !== "yes" ? (<h2>Contact details for {(data.title !== "" ? data.title + " " : "")
                    + (data.firstname !== "" ? data.firstname + " " : "")
                    + (data.lastname !== "" ? data.lastname + " " : "")}</h2>)
                    : ""
                }
                <Form>
                    <Row >
                        <Form.Group key={getLocalKey()} as={Col} md="3" controlId={itemid + "validationCustomTitle"}>
                            <Form.Label className="pt-3">Title <RecordSectionRequired /></Form.Label>
                            <Form.Control
                                key={getLocalKey()}
                                as="select"
                                type="select"
                                name="title"
                                isInvalid={validation.title === undefined ? false : validation.title}
                                defaultValue={data.title === undefined ? "" : data.title}
                                onChange={onChange}
                            >
                                <option value="">None</option>
                                <option value="Mr">Mr</option>
                                <option value="Mrs">Mrs</option>
                                <option value="Miss">Miss</option>
                                <option value="Ms">Ms</option>
                                <option value="Dr">Dr</option>
                                <option value="Professor">Professor</option>
                                <option value="Assistant Professor">Assistant Professor</option>
                                <option value="Associate Professor">Associate Professor</option>
                                <option value="Mx">Mx</option>
                                <option value="Misc">Misc</option>
                                <option value="Ind">Ind</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">Please enter a title</Form.Control.Feedback>
                        </Form.Group>


                        <Form.Group key={getLocalKey()} as={Col} md="3" controlId={itemid + "validationCustomFirstname"}>
                            <Form.Label className="pt-3">First name <RecordSectionRequired /></Form.Label>
                            <Form.Control
                                key={getLocalKey()}
                                type="text"
                                placeholder="First name"
                                isInvalid={validation.firstname === undefined ? false : validation.firstname}
                                defaultValue={data.firstname === undefined ? "" : data.firstname}
                                onChange={onChange}

                            />
                            <Form.Control.Feedback type="invalid">Please enter a first name</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group key={getLocalKey()} as={Col} md="3" controlId={itemid + "validationCustomLastname"}>
                            <Form.Label className="pt-3">Last name <RecordSectionRequired /></Form.Label>
                            <Form.Control
                                key={getLocalKey()}
                                type="text"
                                placeholder="Last name"
                                isInvalid={validation.lastname === undefined ? false : validation.lastname}
                                defaultValue={data.lastname === undefined ? "" : data.lastname}
                                onChange={onChange}

                            />
                            <Form.Control.Feedback type="invalid">Please enter a lsat name</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group key={getLocalKey()} as={Col} md="5" controlId={itemid + "validationCustomUsername"}>
                            <Form.Label className="pt-3">Email (use institutional email if possible) <RecordSectionRequired /></Form.Label>
                            {notok && <div className="texterror">This email address is already in use</div>}
                            <InputGroup hasValidation>
                                <Form.Control
                                    key={getLocalKey()}
                                    type="email"
                                    autoComplete="new-password"
                                    autofill="nope"
                                    placeholder="email address"
                                    isInvalid={validation.email === undefined ? false : validation.email}
                                    defaultValue={data.email === undefined ? "" : data.email}
                                    onChange={onChangeEmail}


                                />
                                <Form.Control.Feedback type="invalid">
                                    Unable to verify this email address. Please check before saving.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>

                        <Form.Group key={getLocalKey()} as={Col} md="3" controlId={itemid + "validationCustomOrcid"}>
                            <Form.Label className="pt-3">ORCID <RecordSectionOptional /></Form.Label>
                            <Form.Control
                                key={getLocalKey()}
                                type="text"
                                placeholder="ORCID"
                                isInvalid={validation.orcid === undefined ? false : validation.orcid}
                                defaultValue={data.orcid === undefined ? "" : data.orcid}
                                onChange={onChange}

                            />
                            <Form.Control.Feedback type="invalid">That does not look like an ORCID. Please check.</Form.Control.Feedback>
                        </Form.Group>



                        <Form.Group key={getLocalKey()} as={Col} md="6" controlId={itemid + "validationCustom03"}>
                            <Form.Label className="pt-3">Institution/Organisation <RecordSectionRequired /></Form.Label>
                            <Form.Control
                                key={getLocalKey()}
                                type="text"
                                placeholder="Institution/Organisation"
                                isInvalid={validation.organisation === undefined ? false : validation.organisation}
                                defaultValue={data.organisation === undefined ? "" : data.organisation}
                                onChange={onChange}


                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide an Institution or Organisation.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group key={getLocalKey()} as={Col} md="3" controlId={itemid + "validationCustom04"}>
                            <div className="pt-3">
                                <Country country={data.country} validation={validation} onChange={onchange} />
                            </div>
                        </Form.Group>
                    </Row>
                </Form>
            </div>

            <div className={"radiobox hidden"}>
                <h2>Guarantor <RecordSectionRequired /></h2>
                <p className="helptextinline">Make this person the guarantor for the re.</p>


                <Form.Group className={"mb-3"}>
                    <Form.Check type={"radio"} id={getKey()} >
                        <Form.Check.Input type={"radio"} name="role" checked={isSelected("role", "team") == null ? false : true} defaultValue="team" onChange={(e) => setTagValue(e, 'role')} />
                        <Form.Check.Label className={isSelected("role", "team") == null ? "labelnotselected" : "labelselected"}
                        >The person is part of the core team and should appear in the team list and citation </Form.Check.Label>
                    </Form.Check>
                    <Form.Check type={"radio"} id={getKey()} >
                        <Form.Check.Input type={"radio"} name="role" checked={isSelected("role", "admin") == null ? false : true} defaultValue="admin" onChange={(e) => setTagValue(e, 'role')} />
                        <Form.Check.Label className={isSelected("role", "cofi") == null ? "labelnotselected" : "labelselected"}
                        >The person is not part of the core team and should not appear in the team list or citation </Form.Check.Label>
                    </Form.Check>
                    {showError(fname + "_mustchoose3", checked, showerrors) && <div className="formerror">Please select an option.</div>}
                </Form.Group>
            </div>


            <div className={"radiobox"}>
                <h2>Conflict of interest</h2>
                <Form.Group className={"mb-3"}>
                    <Form.Check type={"radio"} id={getKey()} >
                        <Form.Check.Input type={"radio"} name="conflictyn" checked={isSelected("conflictyn", "nocofi") == null ? false : true} defaultValue="nocofi" onChange={(e) => setTagValue(e,'conflictyn')} />
                        <Form.Check.Label className={isSelected("conflictyn", "nocofi") == null ? "labelnotselected" : "labelselected"}
                        >No potential conflicts of interest to declare for this review </Form.Check.Label>
                    </Form.Check>
                    <Form.Check type={"radio"} id={getKey()} >
                        <Form.Check.Input type={"radio"} name="conflictyn" checked={isSelected("conflictyn", "cofi") == null ? false : true} defaultValue="cofi" onChange={(e) => setTagValue(e, 'conflictyn')} />
                        <Form.Check.Label className={isSelected("conflictyn", "cofi") == null ? "labelnotselected" : "labelselected"}
                        >Potential conflicts of interest for this review </Form.Check.Label>
                    </Form.Check>
                    {isSelected("conflictyn", "cofi") === "checked" &&
                        <>
                            <p key={getLocalKey()} className={"helptextinline"}>List any potential conflicts or perceived conflicts of interest relating to this review. Conflict of interest information will be shown on the public registration.</p>
                            <MEditor editorname="editor1" key={`editor1`} className={"visible"} notify={notifytext} editordata={getEditorData("editor1")} editorchecks={() => getEditorChecks("Contact", "editor1")} />
                            {showError(data.section + "_musthavecontent", checked, showerrors) && <div id={data.section + "_musthavecontent"} className="formerror">You must honour the word count</div>}
                            {validation.editor && <div id={fname + "_musthavecontent"} className="formerror">You must honour the word count</div>}
                        </>
                    }
                    {showError(fname + "_mustchoose2", checked, showerrors) && <div className="formerror">Please select an option.</div>}
                </Form.Group>
            </div>
            <div className="recordnav">
                {notok === false && <>
                    {checkedsave === 0 && <Button key={getLocalKey()} type="button" variant="secondary" onClick={handleSubmit1}>Check and close</Button>}
                    {checkedsave === 1 && <Button key={getLocalKey()} type="button" variant="secondary" onClick={handleSubmit1}>Errors - close anyway?</Button>}
                    {checkedsave === 2 && <Button key={getLocalKey()} type="button" variant="secondary" onClick={handleSubmit1}>Save</Button>}
                </>}
                &nbsp;
                {!goBack && <Button variant="warning" onClick={() => props.save(null)} >Cancel changes</Button>}

            </div>
        </>}


    </>;

}


export default Contact;