import React from 'react';

const RecordInvalidText = props => {            

    // ========================================================================
    // if the suggested text != the existing text, dis[play]
    // ========================================================================    


    const isInvalid = (raw, list) => {
        if(raw == undefined || raw.blocks === undefined || raw.blocks[0] == undefined|| list == undefined) {
            return false;
        }
        const text = raw.blocks[0].text;
        if(list != undefined && list != null) {
            for(var i = 0; i < list.length; i++) {
                if(text.toLowerCase() === list[i].toLowerCase()) {
                    return true;
                }
                else if(text.toLowerCase() === "-") {
                    return true;
                }
            }
        }
    }

    return  ( <div>
        {isInvalid(props.text, props.invalidtext) && (<div className="texterror mt-3">The text you have entered is not valid for this section and may result in your record being rejected.</div>)}
        </div>
    )

}

export default RecordInvalidText;