import React, { useEffect, useState } from 'react';

import { baseURL, putResendEmail, axiosoptions, getRecordApproval, formatDate, submitVersion, deleteVersion, cancelVersion, createversion, createminorversion, createmajorversion } from '../Template/TemplateUtils/utils.js';
import { UseAppContext } from '../AppContextProvider';
import { confirmAlert } from 'react';


import { prosperoClient } from '../api/axios';
import { Form, Button, Table, Row, Container, Card } from 'react-bootstrap';
import LoadingSpinner from '../Template/TemplateUtils/Loading';
import { useNavigate } from 'react-router-dom';
import Header from '../header/header';
import HomeBanner from '../home/homebanner';
import RecordTriage from './recordtriage';
import { useParams } from "react-router-dom"
import RecordError from '../Template/TemplateUtils/RecordError';
import ModalBox from '../Template/TemplateUtils/Modal';


const RecordDashboard = () => {
    const [data, setData] = useState({});
    const [testData, setTestData] = useState({});
    const [results, setResults] = useState(null);
    const [history, setHistory] = useState(null);
    const [accno, setAccNo] = useState();
    const [approval, setApproval] = useState(null);
    const [recordid, setRecordID] = useState();

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showsection, setShowSection] = useState();

    const [deleteerror, setDeleteError] = useState();
    const [deletethisversion, setDeleteThisVersion] = useState(false);
    const [cancelthisversion, setCancelThisVersion] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [amending, setAmending] = useState(false);
    const [allgood, setAllGood] = useState(false);
    const [emailresent, setEmailResent] = useState(false);

    const navigate = useNavigate();
    let windowparams = useParams();

    // ========================================================================
    // the context data store
    // ========================================================================
    const {
        recordData: {
            record, setRecord
        },
        userData: {
            user
        }
    } = UseAppContext();



    // ========================================================================
    // helper function to load or create the data
    // ========================================================================
    useEffect(() => {
        if (user === undefined || user === null || user.useruuid === undefined || user.useruuid === null) {
            navigate("/login");
            return <>Not logged in</>;
        }
        else {
            getRecord();
            getRecordHistory();
        }
    }, []);

    const getRecord = async () => {
        console.log(record);
        try {
            const params = {
                recordversionid: record.recordversion
            };

            const ret = await prosperoClient.get('record/recorddashboard', { params }, axiosoptions)
                .then(res => {
                    setLoading(false);
                    if (res.data.status === "error") {
                        setError(res.data.errormessage);
                    }
                    else {
                        setResults(res.data.results);
                        setAccNo(res.data.results[0].CRDAccessionNumber);
                        setRecordID(res.data.results[0].RecordID);
                        if (res.data.results.length === 0) {
                            setError("There is no information available for this record");
                        }

                        getRecordApprovalTable();
                    }

                })
                .catch(error => {
                    setLoading(false);
                    console.log(error);
                    setError(error);
                });
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setError(err);
        }
    }


    const getRecordHistory = async () => {
        try {
            const params = {
                recordversion: record.recordversion
            };

            const ret = await prosperoClient.get('record/history', { params }, axiosoptions)
                .then(res => {
                    setLoading(false);
                    if (res.data.status === "error") {
                        setError(res.data.errormessage);
                    }
                    else {
                        setHistory(res.data.results);
                        if (res.data.results.length === 0) {
                            setError("There is no information available for this record");
                        }
                        setShowSection("history");
                    }

                })
                .catch(error => {
                    setLoading(false);
                    console.log(error);
                    setError(error);
                });
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setError(err);
        }
    }


    // 2024-04-22 don't think we need this anymore
    const getLiveVersionOLD = () => {
        for (var i = results.length; i > 0; i--) {
            if (results[i - 1].VersionID === results[i - 1].PublishedVersion) {
                setAccNo(results[i - 1].CRDAccessionNumber);
                return "Version " + (results.length - i + 1);
            }
        }
        return "Version 1";
    }

    const getLiveVersion = () => {
        return "Version " + results[0].VersionID + "." + results[0].MinorVersionID;
    }



    const editVersion = () => {
        navigate("/register");
    }


    const viewRecord = () => {
        if(recordid < 1000000) {
            window.open("https://www.crd.york.ac.uk/prospero/display_record.php?ID=" + accno, "_blank");
        }
        window.open("../view/" + accno, "_blank");
    }

    const deleteThisVersionConfirm = async () => {
        setDeleteThisVersion(false);

        const ret = await deleteVersion(record.recordversion);
        if (ret.status === "error") {
            setDeleteError(ret);
        }
        else {
            setRecord({ recordversion: null });
            localStorage.removeItem("currentrecordversion")
            navigate("/myprospero");
        }
    }

    const deleteThisVersionDeny = async () => {
        setDeleteThisVersion(false);
    }

    const cancelThisVersionConfirm = async () => {
        setCancelThisVersion(false);

        const ret = await cancelVersion(record.recordversion);
        if (ret.status === "error") {
            setDeleteError(ret);
        }
        else {
            navigate("/myprospero");
        }
    }

    const cancelThisVersionDeny = async () => {
        setCancelThisVersion(false);
    }

    const createMinorVersionNow = async () => {
        const ret = await createminorversion(record.recordversion);
        if (ret.status === "error") {
            setDeleteError(ret);
        }
        else {
            setRecord({ recordversion: ret.recordversion });
            localStorage.setItem("currentrecordversion", ret.recordversion)
            navigate("../register");
        }
    }

    const createMajorVersionNow = async () => {
        const ret = await createmajorversion(record.recordversion);
        if (ret.status === "error") {
            setDeleteError(ret);
        }
        else {
            setRecord({ recordversion: ret.recordversion });
            localStorage.setItem("currentrecordversion", ret.recordversion)
            navigate("../register");
        }
    }

    const submitThisVersion = async () => {
        const ret = await submitVersion(record.recordversion);
        navigate("/myprospero");
    }

    const amendThisVersion = async () => {
        const ret = await submitVersion(record.recordversion);
        navigate("/myprospero");

    }

    //    const cancelThisVersion = async () => {
    //  }





    const getRecordTemplate = async () => {
        try {
            const params = {

                purpose: "3048712C-3056-4DA3-A463-24D87E9E509D",
                recordversion: record.recordversion
            };

            const ret = await prosperoClient.get('record/template', { params }, axiosoptions)
                .then(res => {
                    setLoading(false);
                    //              setRecordTemplate(res.data.json);
                    //            setRecordTemplateName(res.data.record);
                    if (res.data.record.EditingStatus === "Awaiting approval") {
                        getRecordApprovalTable();
                    }
                    //          record.recordtitle = getRecordTitle(res.data.json);
                    //        console.log(res.data);
                })
                .catch(error => {
                    setLoading(false);
                    console.log(error);
                    setError(error);
                });
            setLoading(false);
        } catch (err) {
            setLoading(false);
            setError(err);
        }
    }

    const getRecordApprovalTable = async () => {
        await getRecordApproval(record.recordversion)
            .then((ret) => {

                setApproval(ret);
            })
    }


    const resendEmail = async (uid) => {
        await putResendEmail(record.recordversion, uid)
            .then((ret) => {
                setEmailResent(true);
                getRecordApprovalTable();
            })
    }

    const testSubmission = (code) => {
        window.open("/authorapproval/" + code, "_blank")
    }

    if (loading || results == null || results == null)
        return <LoadingSpinner />
    else if (error) {
        return <RecordError errormessage={error} />
    }
    else
        return <Container className="page-outer recorddashboard">

            <Row>
                <HomeBanner img="4k-gf58fb1b71_1920.jpg" tag="Record dashboard" />
            </Row>
            <Row>
                <h2 className="centre">{results[0].Title}</h2>
            </Row>

            {submitting &&
                <Container className="page-outer recorddashboard">

                    <h1 className="mt-5 mb-3">You are about to submit this record for publication</h1>
                    <p>Please check that everything in the record is how you want it to appear when published.</p>
                    <h2>What happens next?</h2>
                    {results[0].TemplateID !== 6 ? <p>All members of the review team (except you, if you are a member of the team) will receive an email containing a link for them to approve publication. The record will not be published until all team members have approved it. If any member of the team does not approve publication, the record will move back to the editing stage and you will have to go through the submission process again and all team members will be required to approve the edited version, even if they already approved the original version.</p>
                        : <p>This record will be published automatically once you confirm the statment below.</p>
                    }
                    <div className="radiobox">

                        <Form.Check type="checkbox" id={"confirm1"} >
                            <Form.Check.Input type="checkbox" name="allgood" checked={allgood} onChange={(e) => setAllGood(e.currentTarget.checked)} />
                            <Form.Check.Label className={"labelselected"}>I confirm that the information provided in this PROSPERO record is truthful and accurate and I approve its publication</Form.Check.Label>
                        </Form.Check>
                    </div>

                    {allgood && results[0].TemplateID !== 6 && <p className="mt-3">Once you submit the record it will be published automatically when all authors have approved it.</p>}

                    <div className="centre mt-5">
                        {allgood && <Button variant="success" onClick={() => submitThisVersion(true)}>Submit this version now</Button>}
                        <Button variant="warning" onClick={() => setSubmitting(false)}>Cancel this submission</Button>
                        <Button variant="secondary" onClick={() => navigate("/myprospero")}>My PROSPERO</Button>
                    </div>

                </Container>
            }
            {amending &&
                <Container className="page-outer recorddashboard">

                    <h1 className="mt-5 mb-3">You are about to submit amendments to this published record</h1>
                    <p>Please check that everything in the record is how you want it to appear when published.</p>
                    <h2>What happens next?</h2>
                    <p>Review team will not be required to approve publication because this is a minor amendment. The guarantor and the named contact will receive courtesy notifications when the record is published.</p>
                    <Form.Check type="checkbox" id={"confirm1"} >
                        <Form.Check.Input type="checkbox" name="allgood" checked={allgood} onChange={(e) => setAllGood(e.currentTarget.checked)} />
                        <Form.Check.Label className={"labelselected"}>I confirm that the information provided in this PROSPERO record is truthful and accurate and I approve its publication</Form.Check.Label>
                    </Form.Check>
                    {allgood && <p className="mt-3">Once you submit the record it will be published automatically when system checks have completed. It will appear as a new version. PROSPERO users will be able to see the previously published version.</p>}

                    <div className="centre mt-5">
                        {allgood && <Button variant="success" onClick={() => amendThisVersion(true)}>Submit these amendments now</Button>}
                        <Button variant="warning" onClick={() => setAmending(false)}>Cancel this submission</Button>
                        <Button variant="secondary" onClick={() => navigate("/myprospero")}>My PROSPERO</Button>
                    </div>

                </Container>
            }
            {
                !submitting && !amending && <>
                    <div className="pb-3 pt-3">
                        <div className="card-centred">
                            <Card key="card01" className="card">
                                <Card.Body>
                                    <Card.Title className="cardtitle">Editing status</Card.Title>
                                    <Card.Text className="cardtext">{results[0].EditingStatus === "Being assessed" ? "Publishing" : results[0].EditingStatus}
                                        {(results[0].EditingStatus === "In process" || results[0].EditingStatus === "Amending") && <>
                                            <span className="percentdone"><br />{Math.floor(results[0].progress.ItemCount * 100 / results[0].progress.Total)}% done</span>
                                        </>
                                        }
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <Card key="card02" className="card">
                                <Card.Body>
                                    <Card.Title className="cardtitle">Registration status</Card.Title>
                                    <Card.Text className="cardtext">{results[0].PublicationStatus}</Card.Text>
                                    {results[0].PublicationStatus === "Registered" && <Card.Link className="cardlink" href="#" onClick={() => viewRecord()}>View published record</Card.Link>}
                                </Card.Body>
                            </Card>
                            <Card key="card03" className="card">
                                <Card.Body>
                                    <Card.Title className="cardtitle">This version</Card.Title>
                                    <Card.Text className="cardtext">{getLiveVersion()}<br /><span className="templatename">(Template: {results[0].TemplateName})</span></Card.Text>
                                </Card.Body>
                            </Card>
                            <div className="clear" />
                        </div>
                    </div>


                    {results[0].EditingStatus === "Being assessed" && approval !== null && <div className="pb-5">
                        <h2>PROSPERO registration progress</h2>
                        <p className="helptextinline">This record is awaiting approval by the author team. The apporoval status for each author is listed below. If one or more authors are holding up publication please email directly and ask them to approve. Authors will be sent one reminder automatically, three weeks after the first reminder. If no response is received, no further progress can be made with the registration</p>
                        <p className="helptextinline">If an author declines to approve publication you may remove them from the author list. They will be sent an automatic email to indicate they have been removed.</p>
                        <Table>
                            <thead>
                                <tr>
                                    <th key="r1">Author</th>
                                    <th key="r2">Email</th>
                                    <th key="r3">Date notified</th>
                                    <th key="r4">Approval status</th>
                                    <th key="r5"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    approval.map((item, index) => {
                                        return <tr className={item.ConfirmationStatus === 'APPROVED' ? "color-green" :
                                            item.ConfirmationStatus === 'REJECTED' ? "color-red" : ""} key={index}>
                                            <td key="c0">{item.Title + ' ' + item.FirstName + ' ' + item.LastName}</td>
                                            <td key="c1">{item.Email}</td>
                                            <td key="c3">{formatDate(item.utcDateFirstSent)}</td>
                                            <td key="c2">{item.ConfirmationStatus}</td>
                                            <td key="c5"><Button variant="outline-primary" disabled={item.ConfirmationStatus === "REQUESTED" || item.ConfirmationStatus === "REMINDED" || item.ConfirmationStatus === "REJECTED" ? false : true} onClick={() => resendEmail(item.UniqueID)}>Resend email</Button>

                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </Table>

                        <Button variant="primary" onClick={() => setCancelThisVersion(true)}>Cancel this submission</Button>
                        <Button variant="secondary" onClick={() => navigate("/myprospero")}>My PROSPERO</Button>


                    </div>}


                    {
                        // =================================================================================
                        // Stable and registered
                        // =================================================================================
                    }
                    {results[0].EditingStatus === "Stable" && results[0].PublicationStatus === "Registered" && <>
                        <h1>Minor amendments</h1>
                        {results[0].TemplateID !== 6 && <p className="helptextinline">You can make minor amendments to this record without requiring all authors to approve. The fields you can edit are:</p>}
                        {results[0].TemplateID === 6 && <p className="helptextinline">Make minor amendments. The fields you can edit are:</p>}
                        <div dangerouslySetInnerHTML={{ __html: results[0].AvailableFields }}></div>
                        <Button className="mt-3 mb-5" variant="primary" onClick={() => createMinorVersionNow()}>Make minor amendments</Button>

                        <h1>Review update</h1>
                        {results[0].TemplateID !== 6 && <p className="helptextinline">Changes to key fields in the PROSPERO record will require all authors to approve them before the new version can be published.</p>}
                        {results[0].TemplateID === 6 && <p className="helptextinline">Changes to key fields in the PROSPERO record will be published as a new major version.</p>}
                        <Button className="mt-3 mb-5" variant="primary" onClick={() => createMajorVersionNow()}>Create a new version for major update</Button>
                        <Button className="mt-3 mb-5" variant="secondary" onClick={() => navigate("/myprospero")}>My PROSPERO</Button>
                    </>
                    }

                    {
                        // =================================================================================
                        // In process and any
                        // =================================================================================
                    }

                    {(results[0].EditingStatus === "In process" || results[0].EditingStatus === "Referred") && <>
                        <h1 className="mt-3" >{results[0].EditingStatus} record</h1>
                        {results[0].TemplateID !== 6 && <p className="helptextinline">This record is currently being edited. You can change any field at this point. When all fields are completed you can submit the record for author approval, after which, if all authors approve, it will be published.</p>}
                        {results[0].TemplateID === 6 && <p className="helptextinline">This record is currently being edited. You can change any field at this point. When all fields are completed you can submit the record and it will be published shortly afterwards.</p>}
                        {Math.floor(results[0].progress.ItemCount * 100 / results[0].progress.Total) === 100 &&
                            <p className="helptextinline"><b>You can now submit this record. </b>Please note, once submitted you will not be able to make any changes to until it is published.</p>
                        }


                        <div className="mt-5 mb-5" >
                            <center>

                                <Button variant="primary" onClick={() => editVersion()}>Edit this version</Button>
                                {Math.floor(results[0].progress.ItemCount * 100 / results[0].progress.Total) === 100 && <Button variant="success" onClick={() => setSubmitting(true)}>Submit this version</Button>}
                                <Button variant="warning" onClick={() => setDeleteThisVersion(true)}>Delete this version{results[0].PublicationStatus === "Registered" ? " (does not delete published version)" : ""}</Button>
                                <Button variant="secondary" onClick={() => navigate("/myprospero")}>My PROSPERO</Button>

                            </center>

                        </div>
                    </>
                    }

                    {results[0].EditingStatus === "Amending" && <>
                        <h1 className="mt-3" >Amending a published record</h1>
                        <p className="helptextinline">This record is currently being edited with minor amendments. You can only change a limited number of fields at this point. When all fields are completed you can submit the record for publication and it will be published without needing author approval.</p>
                        <div className="mt-5 mb-5" >
                            <center>
                                <Button variant="primary" onClick={() => editVersion()}>Edit this version</Button>
                                {Math.floor(results[0].progress.ItemCount * 100 / results[0].progress.Total) === 100 && <Button variant="success" onClick={() => setAmending(true)}>Submit these amendments</Button>}


                                <Button variant="warning" onClick={() => setDeleteThisVersion(true)}>Delete this version (does not delete published version)</Button>
                                <Button variant="secondary" onClick={() => navigate("/myprospero")}>My PROSPERO</Button>
                            </center>

                        </div>
                    </>
                    }


                    {deleteerror && <RecordError errormessage={deleteerror} />}

                    {showsection === "approval" && <>
                        <h1>Author approval status</h1>
                        <Table key="3" bordered hover responsive >
                            <thead>
                                <tr>
                                    <th key="x1">Author</th>
                                    <th key="x2">Email</th>
                                    <th key="x3">First email</th>
                                    <th key="x4">Reminder</th>
                                    <th key="x5">Responded</th>
                                    <th key="x6">Response</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    results[0].Approval.results.map((item, index) => {
                                        return <tr
                                            key={index}
                                        >
                                            <td key="v1">{item.Title + " " + item.FirstName + " " + item.LastName}</td>
                                            <td key="v2">{item.Email}</td>
                                            <td key="v3">{formatDate(item.utcDateFirstSent)}</td>
                                            <td key="v4">{formatDate(item.utcDateReminderSent)}</td>
                                            <td key="v5">{formatDate(item.utcDateOfDecision)}</td>
                                            <td key="v6"
                                                className={item.UserConfirmationCode === "CONFIRMED" ? "color-green" :
                                                    item.UserConfirmationCode === "DECLINED" ? "color-red" :
                                                        item.UserConfirmationCode === "REMINDED" ? "color-orange" : null
                                                }>{item.Caption}</td>
                                        </tr>
                                    })

                                }
                            </tbody>
                        </Table>
                    </>}


                    {showsection === "history" && <>
                        <h1>PROSPERO record history</h1>
                        <Table key="4" bordered hover responsive >
                            <thead>
                                <tr>
                                    <th key="b1">VersionID</th>
                                    <th key="b2">Status</th>
                                    <th key="b3">Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    history.map((item, index) => {
                                        return <tr
                                            key={index}
                                            id={index}
                                            className={item.PublicationStatus === "Registered" && item.EditingStatus === "Stable" ? "color-blue bold" :
                                                 null
                                            }>
                                            <td key="q1">{parseInt(item.VersionID) + "." + parseInt(item.MinorVersionID)}</td>
                                            <td key="q2">{item.Caption}</td>
                                            <td key="q3">{item.DisplayDate}</td>
                                        </tr>
                                    })

                                }
                            </tbody>
                        </Table>
                    </>}
                </>
            }
            <div className="pb-5">&nbsp;</div>

            {
                deletethisversion &&
                <ModalBox show={deletethisversion} heading={"Delete this version"} body={<span>Are you sure your want to permanently delete this version? <br /> THIS CANNOT BE UNDONE</span>} yes={deleteThisVersionConfirm} no={deleteThisVersionDeny} setclose={setDeleteThisVersion} />
            }
            {
                cancelthisversion &&
                <ModalBox show={cancelthisversion} heading={"Cancel submission"} body={<span>Are you sure your want to cancel this submission? Any team members that have already approved will need to re-approve any changes you make.<br /> THIS CANNOT BE UNDONE</span>} yes={cancelThisVersionConfirm} no={cancelThisVersionDeny} setclose={setCancelThisVersion} />
            }
            {
                emailresent &&
                <ModalBox show={emailresent} heading={"Email resent"} body={<span>The email has been resent</span>} setclose={setEmailResent} />
            }

        </Container >
}

export default RecordDashboard;