import React, { useState } from 'react';

export default function useToken() {

    const getToken = () => {
        const tokenString = sessionStorage.getItem('token'); 
        // return "3434546";
        return tokenString;
    };
    const [token, setToken] = useState(getToken());


    //set the jwt token
    const saveToken = userToken => {
        sessionStorage.setItem('token', userToken);
        setToken(userToken);
    };

    return {
        setToken: saveToken,
        token
    }
}