import React, { useEffect, useState } from 'react';
import { Table, Tabs, Tab, Button, Col, Container, Form, Row, InputGroup, FormControl } from "react-bootstrap";
import { UseAppContext } from '../AppContextProvider';
import { SimpleTable } from '../table/table';
import { baseURL,  stripHTML} from '../Template/TemplateUtils/utils.js';

const SearchResultsTable = ({userSearch, updateSelectedTab}) => {

    


    

    return <div className="searchresults">
        <Row className="results-row">
            <Tabs activeKey={userSearch.resultsTab} onSelect={(key) => updateSelectedTab(key)}>
                <Tab eventKey="list" title="List view">
                    <SearchList results={userSearch.results} />
                </Tab>
                <Tab eventKey="table" title="Table view">
                    <SearchTable results={userSearch.results} />
                </Tab>                
            </Tabs>
        </Row>        
    </div>

}

const getRecord = (recordid) => {
    const urlBase = "https://www.crd.york.ac.uk/prospero/display_record.php?RecordID=";
    window.open(urlBase + recordid, "PROSPERO_RECORD_" + recordid);
}


const SearchList = ({results}) => {
    

    const getCitation = (hit) => {

        return <>
                    <span>{stripHTML(hit.title)}</span>
                    <span><b>{" " + hit.yearfirstpublished}</b></span>
                    <span>{", " + hit.recordtype + " "}</span>
                     [<span className="link" onClick={() => getRecord(hit.recordid)}>CRD{hit.accessionnumber}</span>]
                </>
    }
        
    // build the table data
    const TableBody = () => {        
        try {
            if(results !== undefined && results.hits !== undefined) {
                const hits = results.hits.hits;            
                return hits.map((row, i) => {
                    const hit = row._source;
                    // now iterate the columns to build the table data                              
                    return (<tr key={"results-tr-" + i} >
                        <td className="table-list-results">{getCitation(hit)} </td>
                    </tr>);                    
                });
            }
        } catch (err) {
            console.error("SEARCH TABLE ERROR", err);
        }   
        return <></>;     
    }

    return <div >
                <Table bordered responsive >                       
                    <tbody>
                        <TableBody />
                        {/* <Body results={props.results != null ? props.results : null} opts={props.opts} /> */}
                    </tbody>
                </Table>
            </div>
}

const SearchTable = ({results}) => {
    const defaultCols = [        
        {              
            Header: "Record ID",
            accessor: "accessionnumber",
            Cell: (props) => (                
                <span                     
                    onClick={() => {getRecordID(props.row.index)}}
                    className="link"
                >CRD{props.value}</span>
            )
        },
        {              
            Header: "Title",
            accessor: "title"
        },
        {              
            Header: "Author",
            accessor: "author"
        },
        {
            Header: "Year",
            accessor: "yearfirstpublished",
            className: "table-sm"          
        },
        {
            Header: "Record type",
            accessor: "recordtype"
        },
        {
            Header: "Review status",
            accessor: "reviewstatus"
        },
        {
            Header: "Funders",
            accessor: "funders"
        },
        {
            Header: "Region",
            accessor: "region"
        }
    ];   

    const getRecordID = (index) => {
        if(results !== undefined && results.hits !== undefined) {
            const hits = results.hits.hits;
            const row = hits[index];
            if(row !== undefined && row._source !== undefined && row._source.recordid !== undefined) {
                getRecord(row._source.recordid);
            }
        }
        
    }


    const getUserColumns = () => {
        return defaultCols;

    }

    // build the table data
    const getTableData = () => {
        let data = [];
        try {
            if(results !== undefined && results.hits !== undefined) {
                const hits = results.hits.hits;            
                hits.map((row, i) => {
                    const source = row._source;
                    // now iterate the columns to build the table data
                    let rowData = {};
                    getUserColumns().map((col, i) => {
                        rowData[col.accessor] = source[col.accessor];
                    });
                    data.push(rowData);
                });
            }
        } catch (err) {
            console.error("SEARCH TABLE ERROR", err);
        }
        return data;
    }


    return <div>
                <Col className="col-centre mb-3" >               
                    <SimpleTable 
                        data={getTableData()} 
                        columns={getUserColumns()}
                        disableSort={false}
                    /> 
                </Col>
            </div>
}

export default SearchResultsTable;