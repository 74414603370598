import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import {RiQuestionLine, RiYoutubeLine} from 'react-icons/ri';
import { BsInfoCircle } from 'react-icons/bs';

import { UseAppContext } from '../../AppContextProvider';
import HelpInline from '../TemplateUtils/HelpInline';
import RecordIsTranslated  from './RecordIsTranslated';


const RecordCaption = props => {            
    const [view, setView] = useState(199);
    const [helpitem, setHelpItem] = useState(null);

    const renderTooltipVideo = props => (<Tooltip {...props}>See a short video on how to complete this field</Tooltip>);
    const renderTooltipPDF = props => (<Tooltip {...props}>Open PDF documentation on how to complete this field</Tooltip>);
    

    return <>
        <h1>{props.caption}
        {/*
        &nbsp;
        <OverlayTrigger placement="bottom" overlay={renderTooltipPDF}><a href={props.pdfhelp} target="_blank" rel="noreferrer"><RiQuestionLine  size={24} color="red"/></a></OverlayTrigger>
        <OverlayTrigger placement="bottom" overlay={renderTooltipVideo}><a href={props.videohelp} target="_blank" rel="noreferrer"><RiYoutubeLine  size={24} color="red"/></a></OverlayTrigger>
*/}
{/*}
        <BsInfoCircle className="bg-icon" onClick={() => props.setHelpItem(true)} />
*/}
        </h1>
        <RecordIsTranslated />
        
        <HelpInline shorthelp={props.shorthelp} longhelp={props.longhelp} view={view === 100 ? "short" : "long"} />
        {/*view === 100 ? <span className="morehelp" onClick={() => setView(0)}>Show more help...</span> : null */}
    </> 




}

export default RecordCaption;