import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { RiQuestionLine, RiYoutubeLine } from 'react-icons/ri';
import { BsInfoCircle } from 'react-icons/bs';
import { helptext } from "./RecordHelpText";

import { UseAppContext } from '../../AppContextProvider';
import HelpInline2 from '../TemplateUtils/HelpInline2';
import RecordIsTranslated from './RecordIsTranslated';
import RecordHelp from './RecordHelp';


const RecordCaption2 = props => {
  const [data, setData] = useState(1);                         // the actual section data

  // ========================================================================
  // the context data store
  // ========================================================================
  const {
    recordData: {
      showinstructions, setShowInstructions
    }
  } = UseAppContext();

  //  const [view, setView] = useState(199);
  //    const [helpitem, setHelpItem] = useState(null);

  const renderTooltipVideo = props => (<Tooltip {...props}>See a short video on how to complete this field</Tooltip>);
  const renderTooltipPDF = props => (<Tooltip {...props}>Open PDF documentation on how to complete this field</Tooltip>);



  return <div id={"starttext"} >
    <h1>{props.caption}
      {/*
        &nbsp;
        <OverlayTrigger placement="bottom" overlay={renderTooltipPDF}><a href={props.pdfhelp} target="_blank" rel="noreferrer"><RiQuestionLine  size={24} color="red"/></a></OverlayTrigger>
        <OverlayTrigger placement="bottom" overlay={renderTooltipVideo}><a href={props.videohelp} target="_blank" rel="noreferrer"><RiYoutubeLine  size={24} color="red"/></a></OverlayTrigger>

      {}
        <BsInfoCircle className="bg-icon" onClick={() => props.setHelpItem(true)} />
*/}
      <div className="showinstructions required" onClick={() => setShowInstructions(!showinstructions)} >{showinstructions ? "Hide instructions" : "Show instructions"}</div>
    </h1>
    <RecordIsTranslated />

    {showinstructions &&
      <>

        {helptext[props.fname] != undefined && helptext[props.fname]["mainhelp"] !== undefined &&
          helptext[props.fname]["mainhelp"][props.variant] != undefined ? <div className={"helptextinline  mainhelptext"}>
          {helptext[props.fname]["mainhelp"][props.variant]}
          {props.helpmedecide !== undefined && <RecordHelp fname={props.fname} helpsection={props.helpmedecide} label={"More information..."} />}
        </div>
          :
          helptext[props.fname] != undefined && helptext[props.fname]["mainhelp"] !== undefined &&
          helptext[props.fname]["mainhelp"]["default"] != undefined && <div className={"helptextinline mainhelptext"}>
            {helptext[props.fname]["mainhelp"]["default"]}
            {props.helpmedecide !== undefined && <RecordHelp fname={props.fname} helpsection={props.helpmedecide} label={"More information..."} />}
          </div>

        }
        

      </>

    }
    {/*view === 100 ? <span className="morehelp" onClick={() => setView(0)}>Show more help...</span> : null */}
  </div>




}

export default RecordCaption2;