import React from 'react';
import { GiTimeBomb } from 'react-icons/gi';


const RecordTimebomb = props => {

    return <div className="timebomb">
        <div className="timebombleft">
            <GiTimeBomb size={120} color="red" />
        </div>
        <div className="timebombright helptextinline">{props.html}</div>

        <div className="clear"></div>
    </div>
}

export default RecordTimebomb;