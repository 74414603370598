import React, { useEffect, useState, useRef } from 'react';
import { Form, ListGroup, InputGroup } from 'react-bootstrap';
import { BsTrash } from 'react-icons/bs';
import{funderslistnoncommercial, funderslistcommercial} from './FundersList';


const fname = "Funder";
const Funder = props => {
    const [funders, setFunders] = useState(props.type === "commercial" ? funderslistcommercial : funderslistnoncommercial );
    const [selected, setSelected] = useState([]);

    // ========================================================================
    // helper function to load or create the data
    // for any rerender we set the dirty flag to indicate we need to save
    // ========================================================================
    useEffect(() => {
        setSelected(props.selectdata);
    }, [props.selectdata]);

    // ========================================================================
    // generate local keys (repeatable across renders)
    // ========================================================================
    let keyval = 1;
    const getLocalKey = () => {
        return fname + "_" + keyval++;
    }
    const _removeItem = (i) => {
        var copyListItems = [...selected];
        copyListItems.splice(i, 1)
        setSelected(copyListItems);
        props.onChange(copyListItems);

    }
    

        // ========================================================================
    // set the value of an object element
    // ========================================================================
    const setCheckValue = (e) => {
        let value = e.currentTarget.options[e.currentTarget.selectedIndex].value;

        for (let i = 0; i < selected.length; i++) {
            if (selected[i] === value) {
                return; // already there
            }
        }
        setSelected([...selected, value]);
        props.onChange([...selected, value]);
        e.currentTarget.selectedIndex = 0;
    }


    // ========================================================================
    // Helper to check email
    // ========================================================================  
/*
    const getAllFunders = async () =>{
        await fetch("http://localhost:8000/record/funders/?count=500&q=%")
            .then((resp) => {
                return resp.json()
            }
            )
            .then((obj) => {
                if (obj.results != undefined) {
                    const list = obj.results.map((i, index) => {
                        return  <option 
                                    key={getLocalKey()} 
             //                       selected={i.label.replace("zOther", "Other") === props.funder ? true : false } 
                                    value={i.label.replace("zOther", "Other")}>
                                {i.label.replace("zOther", "Other")}
                                </option>
                    })
                    setFunders(<> + {list} + </>);
//                            return obj.results
                }
                else {
                    //alert(obj.err);
                    return null;
                }
            })
            .catch((response) => {
                console.log(response);
                return null;
            });
    }

    if(funders === null) {
//        getAllFunders();
    }
*/


const SelectedItems = (props) => {
    const [selecteditems, setSelectedItems] = useState(props.items);
    if (selecteditems == null)
      return null;
    var classname = "hidden";
    const Results = (props) => {
      var ret = selecteditems.map((item, i) => {
        var key = i;
        classname = "visible";
        return <ListGroup.Item key={key} draggable
          as="li"
          className="d-flex justify-content-between align-items-start notranslate"

          onDragStart={(e) => dragStart(e, { key })}
          onDragEnter={(e) => dragEnter(e, { key })}
          onDragOver={(e) => dragOver(e, { key })}
          onDragEnd={drop}
        >
          <div className="ms-2 me-auto">
            {<span>{item}</span>
            }
          </div>
          <BsTrash className="bg-icon" onClick={() => _removeItem(i)} />
        </ListGroup.Item>
      })

      if (ret.length > 0)
        return <div className={"typeaheadlist "}>
          <ListGroup as="ol" numbered>
            {ret}</ListGroup>
        </div>
      else
        return ret;
    }
    return <Results items={selecteditems} />
  }

  const dragItem = useRef();
  const dragOverItem = useRef();

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const dragOver = (e, position) => {
    e.preventDefault()
  };


  const drop = (e) => {
    // get the existing list
    var copyListItems = [...selected];
    // copy the dragged item
    const dragItemContent = copyListItems[dragItem.current.key];
    // remove the dragged item from the list (so the list will be 1 smaller)
    copyListItems.splice(dragItem.current.key, 1);
    // insert the item to the list
    copyListItems.splice(dragOverItem.current.key, 0, dragItemContent);
    // null everything
    dragItem.current = null;
    dragOverItem.current = null;
    // save the new selected list
    setSelected(copyListItems);
    props.onChange(copyListItems);

  };




    return <>
        <Form.Control
            key={getLocalKey()}
            as="select"
            type="select"
            name="funder"
            size="lg"
            onChange={setCheckValue}
            defaultValue={ props.funder}

        >
            {funders}
        </Form.Control>
        <SelectedItems items={selected} />
    </>

}

export default Funder;

