import React, { useState } from 'react';
import { Button, Form , FloatingLabel} from 'react-bootstrap';
import {BsPatchCheck} from 'react-icons/bs';

import {postPasswordReset} from '../api/login.api';

const ResetPassword = ({setResetPassword}) => {
    const [validated, setValidated] = useState(false);
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [reset, setReset] = useState(false);

    // authenticates the user details entered on the server
    const handleSubmit = async e => {
        e.preventDefault();
        
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
        } else {                 
            setLoading(true);
            try {
                //attempt to authenticate on server
                const data = await postPasswordReset({
                    email
                });
                console.log("RESET, data");

                setReset(true);
            } catch(err) {
                setError(true);
            }
            setLoading(false);
        }
        setValidated(true);
    }

    return <>                
                {(reset)
                ?   <>  
                        <h2><BsPatchCheck className="reset-header-icon" />Email sent</h2>                            
                        <p className="mt-3">If your account exists, an email will be sent to you shortly with instructions on how to reset your password. The link will expire in one hour.</p>
                        <div className="mt-4">
                            <Button onClick={() => {setResetPassword(false)}}>Go to login</Button>
                        </div>
                    </>
                :   <>
                        <h2>Reset/change password</h2>
                        <p>Please enter the email address associated with your PROSPERO account.</p>

                        <Form noValidate validated={validated}  onSubmit={handleSubmit}>
                            <Form.Group className="login-form-group">
                                <FloatingLabel
                                    controlId="resetEmail"
                                    label="Email"
                                    className="mb-3"
                                >
                                    <Form.Control type="email" placeholder="name@example.com" className="login-input" onInput={e => setEmail(e.target.value)} required />                        
                                    <Form.Control.Feedback type="invalid">Please provide a valid email.</Form.Control.Feedback>
                                </FloatingLabel>                                
                            </Form.Group>

                            <div className="mt-4">
                                <Button type="submit" disabled={loading}>Send</Button>
                                <Button onClick={() => {setResetPassword(false)}}>Cancel</Button>
                            </div>                            
                        </Form>

                        {(error)
                        ? <div aria-live="assertive" className="login-error" >We were unable to process the reset password request. Please try again.</div>
                        : <></>
                        }

                        
                    </>
                }
            </>

}

export default ResetPassword;