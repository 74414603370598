import React, { useEffect, useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Header from '../header/header';

const Feedback = () => {
    const [comment, setComment] = useState("");

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const sendFeedback = () => {
        console.log(comment);
        alert("Not implemented");
    }


    const back = () => {
        setTimeout(() => {
            window.history.back();
        }, 100)
    }

    if (loading)
        return <div>LOADING!!</div>
    else if (error)
        return <div>ERROR</div>
    else
        return <Container className="page-outer">
            <div>
                <h1>Feedback</h1>
                <p>We welcome feedback on our new PROSPERO interface. Please let us know what you think in the section below.</p>

                <Form.Label>Your feedback</Form.Label>
                <Form.Control as="textarea" rows={10} onChange={(e) => setComment(e.target.value)} />
                <Button variant="primary" onClick={() => sendFeedback()}>Send feedback</Button>
                <Button variant="primary" onClick={() => back()}>Cancel</Button>

            </div>
        </Container>
}

export default Feedback;