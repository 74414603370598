import React, {useState} from 'react';
import {Button, Row, Col, Form } from "react-bootstrap";
import { SimpleTable, DeleteCell } from '../table/table';
import { ConfirmModal, MessageModal } from '../utils/modal';
import { UseAppContext } from '../AppContextProvider';
import { postSearch } from './SearchUtils';

const HistoryResults =  ({setSearch, setSearchResults}) => {    
    const [selected, setSelected] = useState([]);
    const [checkClear, setCheckClear] = useState(false);
    const [searchError, setSearchError] = useState(false);
    const [showSave, setShowSave] = useState(false);
    const columns = [
            {              
                Header: "",
                accessor: "select" ,
                className: "table-xs",
                Cell: (props) => (
                    <Form.Check 
                        type={'checkbox'}
                        checked={selected.includes(props.row.index)}
                        onChange={e => {actionSelectRow(props.row.index)}}
                    />
                )
            },
            {
                Header: "Line #",
                accessor: "line",
                className: "table-line"
            },
            {
                Header: "Search for",
                accessor: "label",
                className: "table-xxl",
                Cell: (props) => (
                    <span title="Show results" className="search-history-term" onClick={() => {actionRunSearchHistory(props)}}>{props.value}</span>
                ),          
            },
            {
                Header: "Hits",
                accessor: "hits",
                className: "table-sm",          
            },
            {
                Header: "Delete",
                accessor: "delete",     
                className: "table-xs",     
                Cell: (props) => (
                  <DeleteCell 
                    action={actionRemoveRow} 
                    id={props.row.index}
                    title={"Delete search"}
                />
                )
            }
        ];   
    
    const {
        userSearchData: {
            userSearch, setUserSearch
        }
    } = UseAppContext();    


    // single row selection in the history table
    const actionSelectRow = (row) => {
        let newSelected = [...selected];
        if(newSelected.includes(row)) {
            const index = newSelected.indexOf(row);
            if (index > -1)
                newSelected.splice(index, 1);
        } else
            newSelected.push(row);

        setSelected(newSelected);
    }

    // remove this row from the history
    const actionRemoveRow = (index) => {
        let history = [...userSearch.history];
        history.splice(index, 1);

        // update the search history rows here

        // update the line numbers of the rows
        history.map((row, i) => {            
            row["line"] = (i+1);
        });

        setUserSearch({...userSearch, "history": history});
        localStorage.setItem("PROSPERO_searchHistory", JSON.stringify(history));
    }

    // select all rows or remove all rows if all are selected
    const actionSelectAllRows = () => {
        const history = userSearch.history;
        if(selected.length === history.length) {
            // all records are selected so clear
            setSelected([]);
        } else if(history.length > 0) {
            // add all records to selected list
            let newSelected = [];
            history.map((row, i) => {
                newSelected.push(i);
            });
            setSelected(newSelected);
        }
    }

    // clear the content of the history
    const actionClearHistory = () => {
        setUserSearch({...userSearch, "history": []});
        setCheckClear(false);
        // clear the content of the search history
        localStorage.removeItem("PROSPERO_searchHistory");
    }

    // run a combined search on all selected rows
    const actionRunCombinedSearch = async (type) => {        
        if(checkRowCount()) {
            const history = userSearch.history;
            let search = '';
            let label = '';
            let fields = [];
            selected.map((row, i) => {                
                const rowContent = history[row];
                search += ((search === '') ? '' : ' ' + type + ' ') + '(' + rowContent.term + ')';
                label += ((label === '') ? '' : ' ' + type + ' ') + '#' + (row+1);                
                try {
                    if(rowContent.field !== undefined && rowContent.field.length > 0) {
                        rowContent.field.map((field, i) => { 
                            // check if the field already exists in the list of fields
                            if(fields.indexOf(field) === -1)
                                fields.push(field);
                        });
                    }
                } catch(err) {
                    console.error("Error obtaining history field", err);
                }                
            });

            let searchObj = {};
            searchObj.term = search;
            searchObj.label = label;
            searchObj.field = fields;

            // run the new search
            try {                        
                const resp = await postSearch(searchObj);
                if(resp.data !== undefined && resp.data.results !== undefined && resp.data.results.hits !== undefined && resp.data.results.hits.total != undefined)
                {
                    console.log("RESULTS", resp.data);
                    let hitCount = resp.data.results.hits.total.value;                
                
                    // get the history from the search data
                    let history = (userSearch.history !== undefined) ? [...userSearch.history] : [];
      
                    // add the new search results
                    const row = {"hits": (hitCount !== undefined) ? hitCount : 0, "term": search, "label": label, "line": (history.length + 1), "field": fields};
                    history.push(row);
                    // save in the local storage
                    localStorage.setItem("PROSPERO_searchHistory", JSON.stringify(history));
      
                    // update the search              
                    setSearch(searchObj, history, resp.data);
                } else { // error as hits total not returned
                    console.error("ERROR", resp.data);
                    setSearchError(true);
                }
            } catch (err) {
                setSearchError(err);
                console.error("ERROR 2", err);
                // show the error message
                return false;
            }                       
        }
    }    

    const actionRunSearchHistory = async (props) => {
        try {
            const original = props.row.original;

            let searchObj = {};
            searchObj.term = original.term;
            searchObj.field = original.field;
            const resp = await postSearch(searchObj);
            if(resp.data !== undefined && resp.data.results !== undefined && resp.data.results.hits !== undefined && resp.data.results.hits.total != undefined)
            {
                // update the search results             
                setSearchResults(searchObj, resp.data);
            }
        } catch (err) {

        }
    }

    const checkRowCount = () => {
        if(selected.length > 1)
            return true;
        else {
            setSearchError(true);
        }
    }

    const ShowSave = () => {
        // if(showSave) {
        //     return <>Show save details</>
        // } else
            return <></>;
    }

    return (
        <> 
            <ConfirmModal 
                title={"Clear search history"}
                message={"Are you sure you want to clear your search history?"} 
                handleConfirm={actionClearHistory}
                handleCancel={setCheckClear}
                showModal={checkClear}
            />    
            <MessageModal 
                title={"Combined line error"}
                message={"This action requires 2 or more rows to be selected."}
                handleConfirm={setSearchError}
                showModal={searchError}
            />
            <Row>                             
                <Col className="mb-3">
                    <div className="d-inline">
                        <Button variant="primary" onClick={() => {actionSelectAllRows()}} size="sm">Select/unselect all</Button>
                        <Button variant="primary" onClick={() => {setCheckClear(true)}} size="sm">Clear history</Button>
                        <Form.Label className="mx-2">Combine checked lines with</Form.Label>
                        <Button variant="primary" onClick={() => {actionRunCombinedSearch('and')}} size="sm">AND</Button>
                        <Button variant="primary" onClick={() => {actionRunCombinedSearch('or')}} size="sm">OR</Button>
                        <Button variant="primary" onClick={() => {actionRunCombinedSearch('not')}} size="sm">NOT</Button>
                    </div>
                    <div className="float-end">
                        <Button variant="primary" onClick={() => {alert("EXPORT HISTORY")}} size="sm">Export</Button>
                        <Button variant="primary" onClick={() => {alert("Save the saved search")}} size="sm">Save</Button>
                    </div>
                </Col>
          </Row>
          <ShowSave />
          <Row> 
                <Col className="mb-3" id="historyTable">               
                    <SimpleTable 
                        data={userSearch?.history} 
                        columns={columns}
                        disableSort={true}
                    /> 
                </Col>
          </Row>
        </>
    );  
}


export default HistoryResults;