import React, { useState, useEffect, useRef } from 'react';
import { Row, Container, Col, Button, Form , FloatingLabel, Spinner} from 'react-bootstrap';
import validator from 'validator'
import { useNavigate } from 'react-router-dom';

import Header from '../header/header';

import {TbFaceIdError} from 'react-icons/tb';
import {BsPatchCheck} from 'react-icons/bs';

import {getValidCode, postUpdatePassword} from '../api/login.api';
import {defs}  from '../api/siteDefinitions';

import './login.css';

const UpdatePassword = () => {
    

    return <Container className="page-outer"> 
                <Row className="mt-3">
                    <Col md="6" sm="12" className="d-flex justify-content-center">
                        <div className="login-outer">
                            <ResetPasswordDetails />
                        </div>
                    </Col>
                    <Col>
                        <img id="loginImg" src={defs.publicURL + "password-reset.jpg"} alt={"Login"} />
                    </Col>                
                </Row>
            </Container>
}

const ResetPasswordDetails = () => {
    const [code, setCode] = useState("");
    const [loading, setLoading] = useState(true);

    const [error, setError] = useState(false);
    const [errorType, setErrorType] = useState("");
    
    const [validated, setValidated] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");    
    const [processing, setProcessing] = useState(false); 
    const [success, setSuccess] = useState(false);    

    const navigate = useNavigate();
    

    useEffect(()=>{
        validateCode();
    },[]);

    // validate that the code provided is still valid
    const validateCode = async () => {
        // get the code value from the URL
        const queryParameters = new URLSearchParams(window.location.search)
        const code = queryParameters.get("key");    

        setLoading(true);
        setError(false);
        
        // check the password code is valid
        if(code === null || code === '') {
            // no code found in url so show error
            setErrorType("NOCODE");
            setError(true);
        } else {
            // check if the code is still valid on the server
            try {                
                const data = await getValidCode({code});                
                
                if(data.valid === 1) {
                    // code is valid so allow password change                
                    setCode(code);
                } else {
                    setErrorType((data.expired === 1) ? "EXPIRED": "ERROR");                                        
                    setError(true);
                }
            } catch(err) {       
                // error returned validating code so show error         
                if(err.response !== undefined && err.response.data !== undefined)
                    setErrorType(err.response.data.error);
                else
                    setErrorType("ERROR");
                setError(true);
            }
        }
        setLoading(false);
    }

    // authenticates the user details entered on the server
    const handleSubmit = async e => {
        e.preventDefault();
                
        // if (form.checkValidity() === false) {
        if (!checkPasswordValid() || !checkConfirmPassword()) {
            e.preventDefault();
            e.stopPropagation();
        } else {               
            setProcessing(true);
            //reset this users password
            try {                
                const data = await postUpdatePassword({"pwrd":password, code});
                console.log("RESET", data);
                setSuccess(true);
            } catch(err) {       
                // error returned validating code so show error         
                if(err.response !== undefined && err.response.data !== undefined)
                    setErrorType(err.response.data.error);
                else
                    setErrorType("ERROR");
                setError(true);
            }
            setProcessing(false);
        }
        setValidated(true);
    }

    // check that the password is valid using the validator
    const checkPasswordValid = () => {               
        if (validator.isStrongPassword(password, {
            minLength: 8, minLowercase: 1,
            minUppercase: 1, minNumbers: 1, minSymbols: 0})) {
            return true;
        } else {
            return false;
        }
    }

    const checkConfirmPassword = () => {
        return password === confirmPassword;
    }

    if(loading)
        return  <></>
    else if(error)
        return  <>
                    <h2>Reset/change password</h2>
                    <div className="text-center">
                        <div className="reset-error"><TbFaceIdError /></div>
                        <Error code={errorType} />                    
                    </div>
                    <Button onClick={() => {navigate("/home")}} className="full-width">Home</Button>
                </>
    else if(success)
        return <>  
            <h2><BsPatchCheck className="reset-header-icon" />Password reset</h2>                            
            <p className="mt-3">Your password has been successfully reset. Please return to the login page and authenticate using your new password.</p>
            <div className="mt-4">
                <Button onClick={() => {navigate("/login")}} className="full-width">Go to login</Button>
            </div>
        </>
    else
        return  <>                 
                    <h2>Reset/change password</h2>
                    <p>Please enter the email address associated with your PROSPERO account.</p>

                    <Form noValidate  onSubmit={handleSubmit}>
                        <Form.Group className="login-form-group">
                            <FloatingLabel controlId="resetPassword" label="Password">
                                <Form.Control isInvalid={validated && !checkPasswordValid()} type="password" placeholder="Password" className="login-input" onInput ={e => setPassword(e.target.value)} required  pattern={"^[a-zA-Z0-9!@£#%&*_=\$\+]{8,16}$"} />
                                <Form.Control.Feedback type="invalid">Passwords must be at least 8 characters long</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">Passwords must contain a mixture of lowercase and uppercase characters</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">Passwords must contain one number or symbol</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>                                                
                        

                        <Form.Group className="login-form-group">
                            <FloatingLabel controlId="confirmPassword" label="Confirm Password">
                                <Form.Control isInvalid={validated && !checkConfirmPassword()} type="password" placeholder="Password" className="login-input" onInput ={e => setConfirmPassword(e.target.value)} required pattern={"^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,16}$"} />
                                <Form.Control.Feedback type="invalid">Passwords do not match</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>

                        <div className="mt-5">
                            <Button type="submit" className="full-width" disabled={processing}>
                                <>Reset</>
                                {(processing) 
                                ?   <Spinner animation="grow" />
                                :   <></>
                                }
                            </Button>

                        </div>
                    </Form>                     
                </>  


}

const Error = ({code}) => {        
        
        switch(code) {
            case "NOCODE": // no code found
                return <p>An invalid request has been made</p>;
            case "EXPIRED": // code has expired
                return <p>This action has expired</p>;
            default:
                return <p>An invalid request has been made</p>;
        } 

    return  <></>
}

export default UpdatePassword;