import React, { useEffect, useState } from 'react';

import { approvePublication, rejectPublication, getAuthorApproval, formatName, getRecordAuthorView } from '../Template/TemplateUtils/utils.js';
import { UseAppContext } from '../AppContextProvider';


import { prosperoClient } from '../api/axios';
import { Button, Table, Row, Container, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Header from '../header/header';
import HomeBanner from '../home/homebanner';
import RecordTriage from './recordtriage';
import { useParams } from "react-router-dom"
import RecordError from '../Template/TemplateUtils/RecordError';
import TemplatePreview from '../Template/TemplateItem/TemplatePreview';


const AuthorApproval = () => {
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [approval, setApproval] = useState();
    const [preview, setPreview] = useState();
    const [recordtemplate, setRecordTemplate] = useState([]);
    const [decided, setDecided] = useState();

    // ========================================================================
    // the context data store
    // ========================================================================
    
    let windowparams = useParams()

    const getAuthorApprovalDetails = async () => {
        await getAuthorApproval(windowparams.code)
            .then((ret) => {
                if (ret.status === 'error') {
                    setError(ret.errormessage);
                }
                else {
                        setApproval(ret);
                }
            })
    }



    const getrt = async () => {
        const rt = await getRecordAuthorView(windowparams.code);
        setRecordTemplate(rt.json);
    }

    const approveThisPublication = async () => {
        const ret = await approvePublication(windowparams.code);
        if (ret.status === 'error') {
            setError(ret);
        }
        else if (ret.status === 'ok') {
            setDecided(true);
        }

    }


    const rejectThisPublication = async () => {
        const ret = await rejectPublication(windowparams.code);
        if (ret.status === 'error') {
            setError(ret);
        }
        else if (ret.status === 'ok') {
            setDecided(true);
        }

    }


    // ========================================================================
    // helper function to load or create the data
    // ========================================================================
    useEffect(() => {
        getAuthorApprovalDetails();
        getrt();
    }, []);

    if (preview === "TemplatePreview") {
        return <Container className="page-outer recorddashboard"><TemplatePreview key={"preview"} back={setPreview} purpose={"purpose"} preview={recordtemplate} /></Container>;

    }
    else
        return <Container className="page-outer recorddashboard">

            <Row>
                <HomeBanner img="4k-gf58fb1b71_1920.jpg" tag="Author approval" />
            </Row>

            {approval !== undefined &&
                <div className="approval-page">
                    <h2 className="approval-name">Approval by {formatName(approval.Title, approval.FirstName, approval.LastName)}</h2>
                    <p><span className="approval-text">You are confirming or declining your inclusion as part of the review team in the PROSPERO record for the review entitled: <b>{approval.ReviewTitle}</b>.</span></p>
                    <p><span className="approval-text">Agreeing to inclusion means that you confirm your participation in the review, agree with the record content and confirm that your personal details are correct.</span></p>
                    <p><span className="approval-text"><b>Your name, institution and email address will be shown in the record available on the public PROSPERO website.</b></span></p>

                    {approval.Guarantor === "guarantor" &&
                        <p>You are also agreeing to be the guarantor for this record. The review guarantor has responsibility for ensuring everything in the PROSPERO record is correct and will receive email notifications (along with the review contact) when substantial changes are made to the PROSPERO record.
                        </p>
                    }
                    {approval.Contact === "contact" &&
                        <p>You are also agreeing to be the named contact for this record. Being the named contact means that PROSPERO users will be advised to send any correspondence about the PROSPERO record or the review directly to you.
                        </p>
                    }


                </div>
            }

            <div className="approval-buttons mt-5">
                {error && <RecordError errormessage={error} />}

                {!error && decided && <>
                    <Button variant="primary" onClick={() => setPreview("TemplatePreview")}>Preview the draft PROSPERO record</Button>
                <p className="mt-3">Thank you for your response. You do not need to do anything else at this time and can close this page.</p>
                </>}

                {!error && !decided && <>
                    <Button variant="primary" onClick={() => setPreview("TemplatePreview")}>Preview the draft PROSPERO record</Button>

                    <Button variant="primary" onClick={() => approveThisPublication()}>I approve publication</Button>
                    <Button variant="warning" onClick={() => rejectThisPublication()}>I do not approve publication</Button>
                </>
                }
            </div>
        </Container>
}

export default AuthorApproval;