import React, { useEffect, useState } from 'react';
import ProsperoNavbar from '../header/prosperonavbar';
import { UseAppContext } from '../AppContextProvider';
import { Log, getMessages } from '../Template/TemplateUtils/utils.js';
import {defs}  from '../api/siteDefinitions';
import { propsWithBsClassName } from 'react-bootstrap-typeahead/types/utils';

const Header = (props) => {   
    const [data, setData] = useState({});
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);


    const {
        messageData: {
            lastmessage, setLastMessage, messageinterval, setMessageInterval
        }
    } = UseAppContext();

    const getmessages = async () => {
        await getMessages(lastmessage)
          .then((resp) => {
            if (resp.errormessage !== undefined) {
              sessionStorage.setItem('messages', JSON.stringify([]));
            }
            else if (resp.status !== 204) {
              sessionStorage.setItem('messages', JSON.stringify(resp));
              setTimeout(() =>{
                setLastMessage(lastmessage+1)
              }, 100)
              
            }
          })
          .catch((e) => {
          })
          ;
      }
    /*
      if (messageinterval === 0) {
        Log("setting messsage timer");
        getmessages();
        const mi = setInterval(() => {
          getmessages();
        }, 30000);
    
        setTimeout(() =>{
            setMessageInterval(mi);
        }, 100)
        
    }
    */
    




    if (loading) 
        return <div>LOADING!!</div>    
    else if(error)
        return <div>ERROR</div>
    else
        return <><div className="header no-print">
            <div className="div-double">
                <img src={defs.publicURL + "nihr-master-logo-325px.png"} alt="NIHR image"/>
            </div>
            <div className="div-single">
                    <div className="div-single-right-large">PROSPERO</div>
                    <div className="div-single-right-small">International prospective register of systematic reviews</div>
            </div>
            <div style={{clear: "both"}}/>
            <div className="testversion">THIS IS A TEST VERSION OF PROSPERO. ALL DATA ARE TEMPORARY AND MAY BE DIFFERENT TO PUBLISHED RECORDS.</div>
            {props.menu === 1 && <ProsperoNavbar />}
        </div> 
        </>
}

export default Header;