import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../TemplateUtils/Loading';
import RecordSectionRequired from '../TemplateUtils/RecordSectionRequired';
import { Button } from 'react-bootstrap';
import { UseAppContext } from '../../AppContextProvider';
import TypeAhead from '../TemplateUtils/TypeAhead';
import RecordNavControls from '../TemplateUtils/RecordNavControls';
import RecordIdentifier from '../TemplateUtils/RecordIdentifier';
import RecordCaption2 from '../TemplateUtils/RecordCaption2';
import RecordError from '../TemplateUtils/RecordError';
import RecordOldversion from '../TemplateUtils/RecordOldversion';
import { stripHTML, Log, getOldVersion, RecordSectionPurposeEdit, getRecordSection, scrollIntoView, showError, checkTempateItem, buildpreview } from '../TemplateUtils/utils.js';
import SearchResultsTable from '../../search/TemplateSearchResults';
import {defs} from '../../api/siteDefinitions';

const fname = "TemplateKeywords";
const TemplateKeywords = props => {
    // ========================================================================
    // the context data store
    // ========================================================================
    const {
        recordData: {
            record
        }
    } = UseAppContext();


    // ========================================================================
    // state variables
    // ========================================================================

    const [data, setData] = useState();                         // the actual section data
    const [showpreview, setShowPreview] = useState(false);      // toggle show/hide the preview
    const [dirty, setDirty] = useState(false);                  // true if anything has changed
    const [error, setError] = useState(null);                   // true if there is an error
    const [checked, setCheck] = useState({});                   // result of checking the content locally (overridden on the server)
    const [showerrors, setShowErrors] = useState(false);        // toggle showing any errors reported by check()
    const [loading, setLoading] = useState(true);               // true if loding data
    const [rerender, setRerender] = useState(0);                // helper to force a rerender
    const [oldversion, setOldversion] = useState(null);         // placeholder for any version that has not been saved 
    const [results, setResults] = useState({});


    // ========================================================================
    // Helper function to get the data for this section from the server
    // ========================================================================
    const getData = async (recordversion, fname) => {
        await getRecordSection(recordversion, fname, RecordSectionPurposeEdit)
            .then((resp) => {
                Log(resp);
                if (resp.status === "error") {
                    setError(resp);
                    setLoading(false);
                }
                else {
                    if (resp.FieldStatus < 100) {
                        buildpreview(resp);
                    }
                    setData(resp);
                    setCheck(checkTempateItem(resp));
                    setLoading(false);
                }
            })
            .catch((e) => {
                console.log(e);
            })
            ;
    }


    // ========================================================================
    // Initialsise on first call
    // ========================================================================
    useEffect(() => {
        getOldVersion(record.recordversion, fname);
        getData(record.recordversion, fname);
    }, []);


    //=========================================================
    // Handle the browser back button
    // ========================================================================

    useEffect(() => {
        const beforeUnLoad = (e) => {
            if (dirty) {
                e.preventDefault();
                e.stopPropagation();
                e.returnValue = "Are you sure you want to leave? Changes will not be saved";
                return "Are you sure you want to leave? Changes will not be saved";
            }
        }


        window.addEventListener('beforeunload', beforeUnLoad);
        return () => {
            window.removeEventListener("beforeunload", beforeUnLoad);
        }
    }, [dirty]);


    // ========================================================================
    // helper function to load or create the data
    // for any rerender we set the dirty flag to indicate we need to save
    // ========================================================================
    useEffect(() => {
        if (!loading) {
            setDirty(true);
        }
        else {
            setTimeout(() => {
                var topOfElement = 0;
                let elem = document.getElementById('scrollto_' + fname);
                if (elem !== null) {
                    topOfElement = elem.offsetTop;
                    window.scroll({ top: topOfElement, behavior: "smooth" });
                }
                //scrollIntoView("scrollto_" + fname);
            }, 100);
        }
        // check the status of everything after wach rerender
        setCheck(checkTempateItem(data));
    }, [rerender]);



    // ========================================================================
    // Handle going back without saving changes
    // =======================================================================
    const back = () => {
        setDirty(false);
        props.back("list");
        setTimeout(() => {
            window.history.back();
        }, 100)
    }



    const notify = (selected) => {
        let content = data.content;
        let c0 = content[0];
        c0.lists.list1 = selected;
        content[0] = c0;
        buildpreview(data);
        setData({ ...data, content: content });
        setRerender(rerender + 1);
    }


    /* leave this here for now - we may want to reactivate it
        const keywordSearch = async () => {
            let tags = data.content[0].tags;
            let array = [];
            array = tags.map(function (item) {
                return item.label;
            });
    
            if (array.length === 0) {
                setResults(null);
                return;
            }
            try {
                const params = {
                    terms: array
                };
    
    
                const ret = await prosperoClient.post('record/keywordsearch', params, axiosoptions)
                    .then(res => {
                        console.log(res.data);
                        setResults(res.data.results);
                    })
                    .catch(error => {
                        console.log(error);
                        setError(error);
                    });
                setLoading(false);
            } catch (err) {
                setLoading(false);
                setError(err);
    
            }
    
        }
    */

    // ========================================================================
    // handle already editing data
    // ========================================================================
    const useoldversion = (option) => {
        if (option) {
            let tempdata = JSON.parse(oldversion);
            tempdata.EditingToken = data.EditingToken;
            setOldversion(null);
            setData(tempdata);
        }
        else {
            setOldversion(null);
        }
    }



    return (<>
        {loading && <LoadingSpinner />}
        {error !== null && <RecordError errormessage={error} />}
        {!showpreview && !loading && (<div id={"scrollto_" + fname} className="page-inner-restrict pb-3 recordfields">
            {error === null && oldversion === null && (<><RecordIdentifier variant={data.RecordTemplateVariant} />
                <RecordCaption2
                    caption={data.caption}
                    variant={data.RecordTemplateVariant}
                    fname={fname}
                />

                <div className="radiobox">
                    <h2>Please enter three or more keywords <RecordSectionRequired /></h2>
                    <p className="helptextinline">The counts at the end of the terms below show how many times that keyword has been used in PROSPERO. It is usually better to use a keyword that has a larger count.</p>
                    <TypeAhead
                        id={"inputid-2"}
                        baseurl={defs.prosperoURL + "record/keywords?count=5001&q="}
                        ismultiple={true}
                        notify={notify}
                        selectdata={data.content[0].lists.list1}
                        inputProps={{ className: "notranslate" }}
                        className={"notranslate"}
                        allownew={true}
                    />
                    {showError(data.section + "_mustchoose", checked, showerrors) && <div id={data.section + "_mustchoose"} className="formerror">You must give at least three keywords.</div>}


                    {/*            <Button variant="primary" onClick={() => keywordSearch()}>Seacch...</Button>  */}
                    <div className="clear">
                        {(results != null && results.hits != null) && <><h3>Top 10 records matching your keyword(s)</h3>
                            <SearchResultsTable opts="keywords" results={results != null ? results.hits : null} /></>}

                    </div>
                </div>
                {data !== undefined && data.content[0].tags !== undefined && data.content[0].tags.legacykeywords !== undefined && data.content[0].tags.legacykeywords !== "" &&
                    <div className="radiobox">
                        <h2 className="color-red ">Legacy keywords</h2>
                        <p className="helptextinline">Your PROSPERO record contains keywords from a previous version of PROSPERO where keywords were free text. The keywords field is now a multi-select field and your keywords have been converted to a list. For maximum discoverability, please check they appear in the list above.</p>
                        <div>{stripHTML(data.content[0].tags.legacykeywords)}</div>
                    </div>

                }

            </>)}

            {oldversion !== null && <RecordOldversion oldversion={oldversion} newversion={data} useoldversion={useoldversion} />}

            <RecordNavControls data={data} back={back} preview={setShowPreview} dirty={dirty} cancomplete={checked} setshowerrors={setShowErrors} showerrors={showerrors} error={error === null && oldversion === null ? null : "error"} />
        </div>)}
        {showpreview && (<div className="page-inner-restrict publicdocument">
            <h1>{data.caption}</h1>
            <div dangerouslySetInnerHTML={{ __html: data.preview }} />
            <div><p><br /></p>
                <p><br /></p>
            </div>
            <Button variant="primary" onClick={() => setShowPreview(false)}>Close preview</Button>
        </div>)}

    </>
    )

}


export default TemplateKeywords;