import React, {useState, useEffect} from 'react';
import { SimpleTable, DeleteCell, ButtonCell } from '../table/table';
import { Row, Spinner } from 'react-bootstrap';
import { UseAppContext } from '../AppContextProvider';
import { FaPlay } from "react-icons/fa";
import { MdFormatListBulletedAdd } from "react-icons/md";

const SavedSearch =  () => {    
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const {
        userData: {
          user
        },
        setLogout
    } = UseAppContext();

    const columns = [
        {              
            Header: "Search name",
            accessor: "name"            
        },
        {
            Header: "First created",
            accessor: "dateCreated"            
        },
        {
            Header: "Last run",
            accessor: "dateRun"          
        },
        {
            Header: "Load",
            accessor: "load",     
            className: "table-xs",
            Cell: (props) => (
                <ButtonCell 
                    label={<MdFormatListBulletedAdd />} 
                    action={actionLoadSearch} 
                    id={props.row.index}
                    title={"Load saved search"}
                />
            )
        },
        {
            Header: "Run",
            accessor: "run",     
            className: "table-xs",
            Cell: (props) => (
                <ButtonCell 
                        classes="table-icon-md" 
                        label={<FaPlay />} 
                        action={actionRunSearch} 
                        id={props.row.index}
                        title={"Run saved search"}
                />
            )
        },
        {
            Header: "Delete",
            accessor: "delete",     
            className: "table-xs",
            Cell: (props) => (
                <DeleteCell 
                    action={actionRemoveSearch} 
                    id={props.row.index}
                    title={"Delete saved searched"}
                />
            )
        }
    ];

    useEffect(() => {        
        // reload the saved searches table
        // console.log("loading saved searches");
        // if(user !== null && user.userID !== undefined) {
            loadSavedSearches();
        // }
    }, [])

    // load the saved search
    const loadSavedSearches = async () => {

        console.log("load saved searches");
        // dummy the data for now
        setData([{"name": "My first saved search", "dateCreated": "2023-04-20",  "dateRun": "2023-11-11"},
                    {"name": "That COVID-19 search", "dateCreated": "2023-05-18",  "dateRun": "2023-11-22"},
                    {"name": "A search for asthma", "dateCreated": "2023-07-01",  "dateRun": "2023-12-01"}])


    }    

    // remove the search from the saved searches
    const actionRemoveSearch = (id) => {
        alert("This will delete search: " + id);
    }

    const actionLoadSearch = (id) => {
        alert("This will add the saved search: " + id + " to the existing search history");
    }

    const actionRunSearch = (id) => {
        alert("This will overwrtie the existing search history with search: " + id);
    }
  
    // check if the user is currently logged in
    // if(user !== null && user.userID === undefined) {
    //     return (<>The user must be logged in to access saved searches</>);
    // } else {
        return (
            <div className="mt-3 px-3 mb-5 pb-5">
                {(loading)
                ?   <Spinner animation="grow" />
                :   <Row className="mt-4">    
                        <SimpleTable 
                            data={data}
                            columns={columns}
                            disableSort={false}
                        />
                    </Row>
                }
            </div>
        );  
    // }
}

export default SavedSearch;