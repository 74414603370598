import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../TemplateUtils/Loading';
import RecordSectionRequired from '../TemplateUtils/RecordSectionRequired';
import { Form, Col, Card, ListGroup, Button } from 'react-bootstrap';
import { confirm } from 'react-bootstrap-confirmation';
import { UseAppContext } from '../../AppContextProvider';
import RecordNavControls from '../TemplateUtils/RecordNavControls';
import RecordIdentifier from '../TemplateUtils/RecordIdentifier';
import RecordCaption2 from '../TemplateUtils/RecordCaption2';
import RecordError from '../TemplateUtils/RecordError';
import RecordOldversion from '../TemplateUtils/RecordOldversion';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { getKey, Log, getOldVersion, RecordSectionPurposeEdit, getRecordSection, scrollIntoView, showError, stripHTML, getEditorChecks, checkTempateItem, buildpreview } from '../TemplateUtils/utils.js';
import RecordHelp from '../TemplateUtils/RecordHelp';
import Contact from '../TemplateUtils/Contact';
import { RiMailLine } from "react-icons/ri";
import { RiThumbUpLine } from "react-icons/ri";
import { RiUserLine } from "react-icons/ri";
import { RiEdit2Line } from "react-icons/ri";
import { RiDeleteBin6Line } from "react-icons/ri";
import { RiUserSettingsLine } from "react-icons/ri";
import Modal from 'react-bootstrap/Modal';

// ========================================================================
// Module defs
// ========================================================================
const fname = "TemplateReviewTeam";
const TemplateReviewTeam = props => {
    // ========================================================================
    // the context data store
    // ========================================================================
    const {
        recordData: {
            record
        }
    } = UseAppContext();


    // ========================================================================
    // state variables
    // ========================================================================
    const [data, setData] = useState();                         // the actual section data
    const [showpreview, setShowPreview] = useState(false);      // toggle show/hide the preview
    const [dirty, setDirty] = useState(false);                  // true if anything has changed
    const [error, setError] = useState(null);                   // true if there is an error
    const [checked, setCheck] = useState({});                   // result of checking the content locally (overridden on the server)
    const [showerrors, setShowErrors] = useState(false);        // toggle showing any errors reported by check()
    const [loading, setLoading] = useState(true);               // true if loding data
    const [rerender, setRerender] = useState(0);                // helper to force a rerender
    const [oldversion, setOldversion] = useState(null);             // placeholder for any version that has not been saved 
    const [opentabs, setOpenTabs] = useState(0);
    const [currentcontact, setCurrentContact] = useState(null);
    const [currentcontactindex, setCurrentContactIndex] = useState(null);
    const [deletecontact, setDeleteContact] = useState(null);
    const [theguarantor, setGuarantor] = useState(false);
    const [thecontact, setContact] = useState(false);
    const [tempguarantor, setTempGuarantor] = useState("");

    // ========================================================================
    // Helper function to get the data for this section from the server
    // ========================================================================
    const getData = async (recordversion, fname) => {
        await getRecordSection(recordversion, fname, RecordSectionPurposeEdit)
            .then((resp) => {
                Log(resp);
                if (resp.status === "error") {
                    setError(resp);
                    setLoading(false);
                }
                else {
                    if (resp.FieldStatus < 100) {
                        buildpreview(resp);
                    }
                    setData(resp);
                    setCheck(checkTempateItem(resp));
                    setLoading(false);
                    if (resp.content[0].tags.guarantor !== "") {
                        setTempGuarantor(resp.content[0].tags.guarantor);
                    }
                    else if (resp.content[0].lists.list1.length > 0) {
                        setTempGuarantor(resp.content[0].lists.list1[0].email);
                    }

                }
            });
    }


    // ========================================================================
    // Initialsise on first call
    // ========================================================================
    useEffect(() => {
        getOldVersion(record.recordversion, fname);
        getData(record.recordversion, fname);
    }, []);


    // ========================================================================
    // Handle the browser back button
    // ========================================================================
    useEffect(() => {
        const beforeUnLoad = (e) => {
            if (dirty) {
                e.preventDefault();
                e.stopPropagation();
                e.returnValue = "Are you sure you want to leave? Changes will not be saved";
                return "Are you sure you want to leave? Changes will not be saved";
            }
        }


        window.addEventListener('beforeunload', beforeUnLoad);
        return () => {
            window.removeEventListener("beforeunload", beforeUnLoad);
        }
    }, [dirty]);

    // ========================================================================
    // helper function to load or create the data, and perform initial check
    // ========================================================================
    useEffect(() => {
        if (!loading) {
            setDirty(true);
        }
        setCheck(checkTempateItem(data));
    }, [rerender]);


    // ========================================================================
    // Handle going back without saving changes
    // =======================================================================
    const back = () => {
        setDirty(false);
        props.back("list");
        setTimeout(() => {
            window.history.back();
        }, 100)
    }

    // ========================================================================
    // handle already editing data
    // ========================================================================
    const useoldversion = (option) => {
        if (option) {
            let tempdata = JSON.parse(oldversion);
            tempdata.EditingToken = data.EditingToken;
            setOldversion(null);
            setData(tempdata);
        }
        else {
            setOldversion(null);
        }
    }


    const accordionSelect = (e) => {
        setOpenTabs(e);
    }



    const deletePerson = () => {
        var tags = data.content[0].lists.list1;
        tags.splice(opentabs, 1);
        setOpenTabs(-1);
        setDeleteContact(null);
        buildpreview(data);
        setRerender(rerender + 1);
    }

    const renderTooltipOK = props => (<Tooltip {...props}>This team member's record is OK</Tooltip>);
    const renderTooltipError = props => (<Tooltip {...props}>This team member's record is not complete</Tooltip>);
    const renderguarantor = props => (<Tooltip {...props}>The guarantor is reponsible for content of the record</Tooltip>);
    const rendercontact = props => (<Tooltip {...props}>The contact is theo only person who can edit the record</Tooltip>);


    const setCurrentContactNow = (index, item) => {
        setCurrentContact(item);
        setCurrentContactIndex(index);
    }

    const populateAccordion = (list) => {


        let ret = list.map((item, i) => {
            return <ListGroup.Item key={i} eventKey={i} className="" onClick={(e) => accordionSelect(i)}>
                <Card.Body>
                    <a onClick={() => (setCurrentContactNow(i, item))} target="_blank" rel="noreferrer">
                        <div className="card-image">
                            <OverlayTrigger delay={{ show: 1000, hide: 200 }} placement="bottom" overlay={item.status === "ok" ? renderTooltipOK : renderTooltipError}>

                                {item.status === "ok" ? <RiUserLine className={"personok"} />
                                    : <RiUserSettingsLine className={"personerror"} />}

                            </OverlayTrigger>
                        </div>
                    </a>


                    <div className="card-content">
                        <Card.Title>
                            <span onClick={() => (setCurrentContactNow(i, item))} className="card-person">

                                {
                                    (item.title !== "" ? item.title + " " : "")
                                    + (item.firstname !== "" ? item.firstname + " " : "")
                                    + (item.lastname !== "" ? item.lastname + " " : "[No name given yet]")
                                }
                            </span> <RiEdit2Line onClick={() => (setCurrentContactNow(i, item))} />

                            {item.email !== data.content[0].tags.guarantor && <RiDeleteBin6Line onClick={() => (setDeleteContact(item))} />}

                        </Card.Title>
                        <Card.Text>
                            {item.email}
                            {item.email === data.content[0].tags.guarantor && <><OverlayTrigger delay={{ show: 1000, hide: 200 }} placement="bottom" overlay={renderguarantor}><span className="contact-guarantor"> <RiThumbUpLine /> Guarantor&nbsp;&nbsp;&nbsp;</span></OverlayTrigger></>}
                            {/*item.email === data.content[0].tags.contact && <><OverlayTrigger delay={{ show: 1000, hide: 200 }} placement="bottom" overlay={rendercontact}><span className="contact-contact"> <RiMailLine /> Contact</span></OverlayTrigger></>*/}

                            {/* GD 2024-03-25 not sure why this is here <Contact data={list[i]} save={savecontact} itemid={i} display={"inline"} /> */}
                        </Card.Text>
                    </div>
                </Card.Body>
            </ListGroup.Item>
        });
        return ret;
    }

    const addTeamMember = () => {
        data.content[0].lists.list1 = [...data.content[0].lists.list1, data.content[0].default];
        setOpenTabs(data.content[0].lists.list1.length - 1);
        buildpreview(data);
        setRerender(rerender + 1);
    }

    const savecontact = (contact) => {
        setCurrentContact(null);
        if (contact === null) {
            return;
        }
        else {
            // deal with guarantor and contact email changes
            if (currentcontact.email === data.content[0].tags.guarantor) {
                data.content[0].tags.guarantor = contact.email;
            }
            if (currentcontact.email === data.content[0].tags.contact) {
                data.content[0].tags.contact = contact.email;
            }
            let v = JSON.stringify(contact);
            data.content[0].lists.list1[opentabs] = JSON.parse(v);
            buildpreview(data);
            setRerender(rerender + 1);
        }
    }


    const getReviewContact = () => {
        if (data == undefined) {
            return null;
        }

        const list1 = data.content[0].lists.list1;
        for (let i = 0; i < list1.length; i++) {
            const list = list1[i];
            if (list.contact !== "") {
                return list;
            }
        }
        return null;
    }

    const getReviewGuarantor = () => {
        if (data == undefined) {
            return null;
        }

        const list1 = data.content[0].lists.list1;
        for (let i = 0; i < list1.length; i++) {
            const list = list1[i];
            if (list.guarantor !== "") {
                return list;
            }
        }
        return null;
    }

    const isEmailOK = (email) => {
        let count = 0;
        const list = data.content[0].lists.list1;
        for (let i = 0; i < list.length; i++) {
            if (i === currentcontactindex) {
                continue;
            }
            if (list[i].email === email) {
                count++;
            }
        }
        if (count > 0) {
            return false;
        }
        else {
            return true;
        }
    }



    if (currentcontact !== undefined && currentcontact !== null) {
        return <Contact data={currentcontact} save={savecontact} itemid={1} display={"card"} reviewguarantor={getReviewGuarantor()} reviewcontact={getReviewContact()} isemailok={isEmailOK} />
    }



    const populatePersonList = (list, target) => {
        let ret = [];

        for (let i = 0; i < list.length; i++) {
            const person = list[i];
            ret.push(<option key={"option-" + i}
                defaultChecked={person.email === person ? true : false}
                value={person.email}>
                {(person.title !== "" ? person.title + " " : "")
                    + (person.firstname !== "" ? person.firstname + " " : "")
                    + (person.lastname !== "" ? person.lastname + " " : "")

                }
            </option>);

        }

        return ret;
    }

    const populateGuarantorContact = (list, tags) => {
        let guarantor = null;
        let ret = [];
        for (let i = 0; i < list.length; i++) {
            const person = list[i];
            if (person.email === tags.guarantor) {
                guarantor = person;
            }
        }

        ret.push(<h3 key="h3-1">Guarantor</h3>);
        if (guarantor === null) {
            ret.push(<p key="p-2">No guarantor specified yet.</p>);
        }
        else {
            ret.push(
                <p key="p-1">{(guarantor.title !== "" ? guarantor.title + " " : "")
                    + (guarantor.firstname !== "" ? guarantor.firstname + " " : "")
                    + (guarantor.lastname !== "" ? guarantor.lastname + " " : "")
                    + " is the guarantor for the review"
                }
                </p>)
        }
        ret.push(<Button className="mt-3" key="p-6" variant="outline-primary" onClick={() => setGuarantor(true)}>Change guarantor</Button>);




        return ret;
    }

    const setGuarantorAndLeave = () => {
        setGuarantor(false);
        data.content[0].tags.guarantor = tempguarantor;
        setDirty(true);
        setRerender(rerender + 1);
    }

    const onChangeGuarantor = (e) => {
        setTempGuarantor(e.currentTarget.value);
        setDirty(true);
        setRerender(rerender + 1);
    }

    const onChangeContact = (e) => {
        data.content[0].tags.contact = e.currentTarget.value;
        setDirty(true);
        setRerender(rerender + 1);
    }

    if (theguarantor) {

        return <>
            <h1>Set review guarantor</h1>
            <p className="helptextinline">Set the review guarantor here. The review guarantor has responsibility for ensuring everything in the PROSPERO record is correct and will receive email notifications (along with the review contact) when substantial changes are made to the PROSPERO record.</p>

            <div className={"radiobox"}>
                <h2>Select the guarantor</h2>

                <Form.Group className="mb-5" as={Col} md="3">
                    <Form.Control
                        key={"sel1"}
                        as="select"
                        type="select"
                        name="guarantorlist"
                        defaultValue={tempguarantor}
                        onChange={onChangeGuarantor}
                    >
                        {populatePersonList(data.content[0].lists.list1, data.content[0].tags.guarantor)}
                    </Form.Control>
                </Form.Group>

                <Button variant="primary" onClick={() => setGuarantorAndLeave()}>Set guarantor</Button>
                <Button variant="warning" onClick={() => setGuarantor(false)}>Cancel</Button>
            </div>
        </>
    }

    else if (thecontact) {
        return <>
            <h1>Set review contact</h1>
            <p className="helptextinline">Set the review contact here. The review contact is the person to whom correopndence about the review will be sent, and will receive email notifications (along with the review contact) when substantial changes are mdade to the PROSPERO record.</p>

            <div className={"radiobox"}>
                <h2>Select the contact</h2>

                <Form.Group className="mb-5" as={Col} md="3">
                    <Form.Control
                        key={"sel1"}
                        as="select"
                        type="select"
                        name="guarantorlist"
                        defaultValue={data.content[0].tags.contact === undefined ? "" : data.content[0].tags.contact}
                        onChange={onChangeContact}
                    >
                        {populatePersonList(data.content[0].lists.list1, "contact")}
                    </Form.Control>
                </Form.Group>

                <Button variant="primary" onClick={() => setContact(false)}>Set contact</Button>
                <Button variant="warning" onClick={() => setContact(false)}>Cancel</Button>
            </div>
        </>

    }


    // ========================================================================
    // return the content
    // ========================================================================
    return (<>
        {loading && <LoadingSpinner />}
        {error !== null && <RecordError errormessage={error.errormessage} />}
        {!showpreview && !loading && (<div id={"scrollto_" + fname} className="page-inner-restrict pb-3 recordfields">
            {error === null && oldversion === null && (
                <><RecordIdentifier variant={data.RecordTemplateVariant} />
                    <RecordCaption2
                        caption={data.caption}
                        variant={data.RecordTemplateVariant}
                        fname={fname}
                        helpmedecide={"help1"}
                    />


                    <div className={"radiobox"}>
                        <h2>Review guarantor</h2>
                        {populateGuarantorContact(data.content[0].lists.list1, data.content[0].tags)}

                    </div>


                    <div className={"radiobox"}>
                        <h2 id={"scrollto_" + fname}>Review team members <RecordSectionRequired /></h2>
                        <p className="helptextinline">There must be at least two team members and one must be a guarantor. There must also be a review contact (who can be the same person) entered in the Named Contact section. You may not remove a member from the team if they are the review guarantor. Allocate that role to another team member first.</p>
                        <p className="mt-3"><RecordHelp className="smalltext" fname={fname} helpsection="help2" label="What does it mean to be a team member?" /></p>
                        <Button className="mb-5 mt-3" variant="primary" onClick={() => addTeamMember()}>Add a team member</Button>
                        {data.content[0].lists.list1.length === 0 && <div className="mt-3">
                            <p className="helptextinline">There are currently no team members. Please add all key team members here.</p>
                            <p className="helptextinline">The owner of this record is the person able to edit the registration record. If contact details or information about potential conflicts of interest is incorrect for any team member (or if they would like any other amendments to the record) they should ask the record owner to do this on their behalf.</p>
                            <p className="helptextinline">One team member must be the <b>nominated guarantor</b>. The review contact and the guarantor may be the same person.</p>
                            <p className="helptextinline">All team members will receive an email from the PROSPERO system requiring them to approve their participation and record content. The record will not be published on PROSSPERO until all team members have approved. The review contact is responsible for ensuring that all team members complete this task (this is manged from the contact user account).</p>
                            <p className="helptextinline">Each team member is responsible for the accuracy of their own personal information and potential conflicts or perceived conflicts of interest related to this review.</p>
                        </div>
                        }
                        {data.content[0].lists.list1.length > 0 && <>


                            <ListGroup
                                activeKey={opentabs}
                            >
                                {populateAccordion(data.content[0].lists.list1)}
                            </ListGroup>

                            {deletecontact !== undefined && deletecontact !== null &&
                                <div onClick={(e) => e.stopPropagation()}>
                                    <Modal
                                        show={deletecontact !== undefined && deletecontact !== null}
                                        onHide={() => setDeleteContact(null)}
                                        backdrop="static"
                                        keyboard={false}
                                    >
                                        <Modal.Header closeButton>
                                            <Modal.Title>Delete team member</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>Are you sure you want to delete team member {(deletecontact.firstname !== "" ? deletecontact.firstname + " " : "") + (deletecontact.lastname !== "" ? deletecontact.lastname + " " : "Unknown")}?
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={deletePerson}>
                                                Yes
                                            </Button>
                                            <Button variant="primary" onClick={() => setDeleteContact(null)}>No</Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                            }
                        </>
                        }
                    </div>
                </>)}


            {oldversion !== null && <RecordOldversion oldversion={oldversion} newversion={data} useoldversion={useoldversion} />}
            <RecordNavControls data={data} back={back} preview={setShowPreview} dirty={dirty} cancomplete={checked} setshowerrors={setShowErrors} showerrors={showerrors} error={error === null && oldversion === null ? null : "error"} />

        </div>)
        }
        {
            showpreview && (<div className="page-inner-restrict pb-3 publicdocument">
                <h1>{data.caption}</h1>
                <div key={getKey()} dangerouslySetInnerHTML={{ __html: data.preview }} />
                <div key={getKey()} ><p key={getKey()} ><br /></p>
                    <p key={getKey()} ><br /></p>
                </div>
                <Button variant="primary" onClick={() => setShowPreview(false)}>Close preview</Button>
            </div>)
        }


    </>
    )

}

export default TemplateReviewTeam;