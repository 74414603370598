import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../TemplateUtils/Loading';
import { Form, Button } from 'react-bootstrap';
import { UseAppContext } from '../../AppContextProvider';
import RecordNavControls from '../TemplateUtils/RecordNavControls';
import RecordIdentifier from '../TemplateUtils/RecordIdentifier';
import RecordCaption from '../TemplateUtils/RecordCaption';
import RecordTimebomb from '../TemplateUtils/RecordTimebomb';
import MEditor from '../TemplateUtils/MEditor';
import { getPreview } from '../TemplateUtils/utils.js';
import { useNavigate } from 'react-router-dom';
import { MdOpenInFull } from 'react-icons/md';


const fname = "TemplatePreview";
const TemplatePreview = props => {
    // ========================================================================
    // the context data store
    // ========================================================================
    const {
        recordData: {
            record
        }
    } = UseAppContext();


    const getLivePreview = async () => {
        //        const data = await getPreview(record.recordversion);
        //        setRecordTemplate(props.preview);
    }

    // ========================================================================
    // Initialsise on first call
    // ========================================================================
    useEffect(() => {
        //        getLivePreview();
        setRecordTemplate(props.preview);
    }, [props.preview]);


    // ========================================================================
    // state variables
    // ========================================================================
    const [recordtemplate, setRecordTemplate] = useState([]);
    const [error, setError] = useState(null);                   // true if there is an error
    const [loading, setLoading] = useState(true);               // true if loding data

    const nav = useNavigate();

    // ========================================================================
    // Handle going back without saving changes
    // =======================================================================
    const back = () => {
        props.back("list");
        setTimeout(() => {
            window.history.back();
        }, 100)
    }

    const populatePreview = () => {
        if (recordtemplate === null || recordtemplate.length === 0) {
            return null;
        }
        let ret = recordtemplate.map((item, i) => {
            const content = <ListItems source={item.blockcontent} />;
            if(content === "") {
                return null;
            }
            return <div key={"div-" + i} className={i === 0 ? "boxme" : "section"}>
                {item.TemplateBlockCaption !== "" && <h1 key={"divh-" + i}>{item.TemplateBlockCaption}</h1>}
                {content}    
            </div>
        });
        return ret;
    }


    const setSection = (section) => {
        nav("/register/" + section);
    }

    // ========================================================================
    // Generate the list of sections
    // ========================================================================
    const ListItems = (props) => {
        let ret = [];

        if (props.source === undefined) {
            return null;
        }
        var comps = props.source;

        for (var i = 0; i < comps.length; i++) {
            const comp = comps[i];
            const compname = comp.TemplateSection;
            const caption = comp.caption;

            if(comp.PreviewHTML === "") {
                continue;
            }

            var now = comp.FieldStatus;
            var errors = comp.ErrorCount;
            if (comp.Mandatory === 1) {
                ret.push(<div key={"div-" + i}><h2 className={"preview-nav"} key={"li_" + i} onClick={() => setSection(compname)} >{caption}</h2>
                    <div key={"lix_" + i} dangerouslySetInnerHTML={{ __html: comp.PreviewHTML }}></div>
                </div>)
            }
            else {
                ret.push(<div key={"div-" + i}><h2 className={""} key={"li_" + i} >{caption}</h2>
                    <div dangerouslySetInnerHTML={{ __html: comp.PreviewHTML }}></div>
                </div>)

            }

        }

        return ret;
    }

    const publish = () => {

    }

    // ========================================================================
    // return the content
    // ========================================================================
    return (<>
        {props.publish !== undefined &&
            <Button className="mb-3y mt-3 no-print" variant="primary" onClick={() => publish()}>Publish this version now</Button>
        }
        <Button className="mb-3y mt-3 no-print" variant="primary" onClick={() => window.print()}>Print</Button>
        {props.purpose !== "live" && <Button className="mb-3y mt-3 no-print" variant="primary" onClick={() => back()}>Close preview</Button>}
        <div className="page-inner-restrict publicdocument">
            {//previewdata !== null && <div dangerouslySetInnerHTML={{ __html: previewdata }}></div>
            }
            {props.purpose !== "live" && <div id="watermark">PREVIEW</div>}
            {props.purpose === "live" && props.latest !== undefined && props.latest === 'Not latest' && <div className="notlatest">This is not the latest version of this record. Please see the citation for the latest version.</div>}

            {populatePreview()}
        </div>
        {props.purpose !== "live" && <div><p><br /></p>
            <Button className="no-print" variant="primary" onClick={() => back()}>Close preview</Button>
            <p><br /></p>
        </div>
        }

    </>
    )

}


export default TemplatePreview;