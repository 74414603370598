import React, { useEffect, useState } from 'react';

import { approvePublication, rejectPublication, getAuthorApproval, formatName, getPublicView } from '../Template/TemplateUtils/utils.js';
import { UseAppContext } from '../AppContextProvider';


import { prosperoClient } from '../api/axios';
import { Button, Table, Row, Container, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Header from '../header/header';
import HomeBanner from '../home/homebanner';
import RecordTriage from './recordtriage';
import { useParams } from "react-router-dom"
import RecordError from '../Template/TemplateUtils/RecordError';
import TemplateLive from '../Template/TemplateItem/TemplateLive';


const PROSPERORecord = () => {
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [approval, setApproval] = useState();
    const [preview, setPreview] = useState();
    const [recordtemplate, setRecordTemplate] = useState([]);
    const [recordrecord, setRecordRecord] = useState();
    const [decided, setDecided] = useState();

    // ========================================================================
    // the context data store
    // ========================================================================

    let windowparams = useParams()


    const getrt = async () => {
        const rt = await getPublicView(windowparams);
        setRecordTemplate(rt.json);
        setRecordRecord(rt.record);
    }

    // ========================================================================
    // helper function to load or create the data
    // ========================================================================
    useEffect(() => {
        getrt();
    }, []);

    return <Container className="page-outer recorddashboard">
        <TemplateLive key={"preview"} back={setPreview} purpose={"live"} windowparams={windowparams} preview={recordtemplate} latest={recordrecord !== undefined && recordrecord.Latest !== undefined ?recordrecord.Latest : ""}/>
    </Container>;
}

export default PROSPERORecord;