import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';


const RecordSectionRequired = props => {       
    
    const rendertooltip = props => (<Tooltip {...props}>This field is mandatory. You will not be able to submit your record until this field is completed.</Tooltip>);


    return  ( <OverlayTrigger placement="bottom" overlay={rendertooltip}><span className="required"> (* required)</span>
        </OverlayTrigger>
    
    )

}

export default RecordSectionRequired;