import React, { useEffect, useState, useRef } from 'react';


/*
Expecting data =

{
    "authors" : "",
   "title" : "",
    "journal_title" : "",
    "year" : "",
    "volume" : "",
    "issue" : "",
    "start_page" : "",
    "end_page" : "",
    "doi" : "",
    "pubmed_id" : ""
}


*/

const fname = "Citation";
const Citation = props => {
    const [dataz, setData] = useState({});
    let data = props.data;

    // ========================================================================
    // helper function to load or create the data
    // for any rerender we set the dirty flag to indicate we need to save
    // ========================================================================
    useEffect(() => {
        data = props.data;
    }, []);

    // ========================================================================
    // format the citation as AMA format
    // ========================================================================
    const formatCitation = () => {
        let ret = [];

        if (data !== undefined && data !== null && Object.keys(data).length > 0 ) {
            // strategy for author parsing is:  if any firstname in the array is "X." then assume iniitials plus punc
            //                                  if any firstname in the array is "X" then assume iniitials without punc
            //                                  else assume firstname is not initials
            if (data.authors !== undefined && data.authors !== null && data.authors.length > 0) {
                let format = 0; // 0=full firstname
                for (let i = 0; i < data.authors.length; i++) {
                    let auth = data.authors[i];
                    let rev = auth.firstname.split('').reverse().join('');
                    if (rev.length === 2 && rev[0] === '.') {
                        format = 1; // 1=init + punc
                        break;
                    }
                    else if (rev.length === 1) {
                        format = 2; //2=init only
                        break;
                    }
                }
                // now compbine authors
                let authstring = "";
                for (let i = 0; i < data.authors.length; i++) {
                    let auth = data.authors[i];
                    if (i > 0) {
                        authstring = authstring + ", ";
                    }
                    switch (format) {
                        case 0:
                            authstring = authstring + auth.firstname + " " + auth.lastname;
                            break;
                        case 1:
                            authstring = authstring + auth.lastname + " " + auth.firstname;
                            break;
                        case 2:
                            authstring = authstring + auth.lastname + " " + auth.firstname;
                            break;
                    }
                }
                format !== 1 ? authstring = authstring + ". " : " ";

                ret.push(<span key="citation_1" className="citation_authors">{authstring}</span>);

            }


            data.title !== undefined && data.title !== null && data.title !== "" ?
                ret.push(<span key="citation_2" className="citation_title">{data.title +
                    (
                        data.title.split('').reverse().join('')[0] === '?'
                            || data.title.split('').reverse().join('')[0] === '!'
                            || data.title.split('').reverse().join('')[0] === ')'
                            ? ' ' : '. ')
                }</span>) : null;
            data.journal_title !== undefined && data.journal_title !== null && data.journal_title !== "" ? ret.push(<span  key="citation_3" className="citation_journal_title">{data.journal_title}. </span>) : null;
            data.year !== undefined && data.year !== null && data.year !== "" ? ret.push(<span key="citation_4" className="citation_year">{data.year}</span>) : null;
            (data.volume !== undefined && data.volume !== null && data.volume !== "") || (data.issue !== undefined && data.issue !== null && data.issue !== "" )? ret.push(":") : null;
            data.volume !== undefined && data.volume !== null && data.volume !== "" ? ret.push(<span key="citation_5" className="citation_volume">{data.volume}</span>) : null;
            data.issue !== undefined && data.issue !== null && data.issue !== "" ? ret.push(<span key="citation_6" className="citation_volume">({data.issue})</span>) : null;
            (data.start_page !== undefined && data.start_page !== null && data.start_page !== "") || (data.end_page !== undefined && data.end_page !== null && data.end_page !== "") ? ret.push(":") : null;
            data.start_page !== undefined && data.start_page !== null && data.start_page !== "" ? ret.push(<span key="citation_7" className="citation_start_page">{data.start_page}</span>) : null;
            data.end_page !== undefined && data.end_page !== null && data.end_page !== "" ? ret.push(<span key="citation_8" className="citation_end_page">-{data.end_page}</span>) : null;
            ret.push(". ");
            data.doi !== undefined && data.doi !== null && data.doi !== "" ? ret.push(
                props.linktodoi !== "link" ? <span key="citation_9" className="citation_doi"> doi:{data.doi}.</span>
                                            : <span key="citation_9" className="citation_doi"> doi:<a target="_blank"  rel="noreferrer" href={"https://dx.doi.org/" + data.doi}>{data.doi}</a></span>
            ) : null;
        }

        return ret;
    }


    return <>
        <div className={formatCitation().length > 0 ? props.format : "hide"}>{formatCitation()}</div>
    </>

}

export default Citation;

