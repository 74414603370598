import React, {useState} from 'react';
import SearchBar from './SearchBar';
import SearchFilters from './SearchFilters';
import SearchResults from './SearchResults';
import {Container, Row, Col } from "react-bootstrap";
import { UseAppContext } from '../AppContextProvider';
import RecordHelp from '../Template/TemplateUtils/RecordHelp';
import { FaSearchPlus } from "react-icons/fa";
import {MdHistory} from "react-icons/md";

import HistoryResults from './HistoryResults';

const SearchHistory =  () => {
    const [error, setError] = useState(false);
    const {
        userSearchData: {
            userSearch, setUserSearch
        }
    } = UseAppContext();
    


    const setSearchHistory = async (search, history, data) => {
        setUserSearch({...userSearch, "history": history, "filter": {}});
    }
    
    const setSearchResults = (search, data) => {
        let total = 0;
        // get the total results
        if(data.results !== undefined && data.results.hits !== undefined && data.results.hits.total !== undefined && data.results.hits.total.value !== undefined)
            total = data.results.hits.total.value;
        setUserSearch({...userSearch, "search": search, "results": data.results, "total": total,"tab": "simple", "filter": {}});
    }

    return (
        <div className="mt-3 px-3">
            <Row>
            <SearchBar setSearch={setSearchHistory} />
            </Row>        
            <Row className="mt-2">    
                {(userSearch.history === undefined)
                ?  <>
                        <Col className="col-centre pt-2 search-info">
                            <MdHistory id="searchIcon" />
                            <div id="searchWelcome">
                                <p>Information about the search history in Prospero</p>
                                <p>First time? If you would like help running a search, you can use the <RecordHelp className="smalltext" fname={"SearchHistory"} helpsection="help1" label="search help guide" /></p>
                                <p>Or please visit our online documentation for further guidance</p>
                            </div>
                        </Col>                    
                    </>
                :  <HistoryResults setSearch={setSearchHistory} setSearchResults={setSearchResults} />
                }
            </Row>

        </div>
    );  
}

export default SearchHistory;