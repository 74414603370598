import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import { UseAppContext } from '../AppContextProvider';
import { Button, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import {defs}  from '../api/siteDefinitions';

const HomeBanner = (props) => {
    const [rerender, SetRerender] = useState(0);


    const {
        messageData: {
            lastmessage, setLastMessage
        } 
    } = UseAppContext();


    // ========================================================================
    // the context data store
    // ========================================================================
    let messages = [];
    let lastseen = lastmessage;
    let nextmessage = null;
    if (sessionStorage.getItem('messages')) {
        messages = JSON.parse(sessionStorage.getItem('messages'));
    }

    if (sessionStorage.getItem('lastseen')) {
        lastseen = sessionStorage.getItem('lastseen');
    }

    const closeMessage = (id) => {
        sessionStorage.setItem('lastseen', "" + id);
        setTimeout(() =>{
            setLastMessage(id);
        }, 100)

        nextMessage();
        SetRerender(rerender + 1);
    }

    const nextMessage = () => {
        for (let i = 0; i < messages.length; i++) {
            if (messages[i].MessageID > lastseen) {
                nextmessage = messages[i];
                break;
            }
        }
    }
    nextMessage();

    return <div className="home-banner no-print">
        <div className="home-banner-tag">{props.tag}</div>
        <img className="home-banner-img" src={defs.publicURL + [props.img]} alt="home image" />
        {nextmessage !== null &&
            <Alert key={"alert-1"} id={rerender} variant="danger" >
                <div className="dashboardwarningleft">
                {nextmessage.Message}
                </div>
                <div className="dashboardwarningright">
                    <Button  onClick={(e) => closeMessage(nextmessage.MessageID)} variant="outline-danger" size="small">
                        Ok
                    </Button>
                </div>
                <div className="clear"/>
            </Alert>
        }
    </div>
}

export default HomeBanner;

/*  */