let prosperoURL = '';
let publicURL = '';
let publicHelp = '';
let baseURL = '';
let feURL = '';

if (window.location.hostname === "localhost") { // local site
    prosperoURL = 'http://localhost:9000/';        
    baseURL = '/';
    publicURL = '/images/';
    publicHelp = '/helpdocs/';
    feURL = "http://localhost:3000/";
// } else if (window.location.hostname.includes("www.metaxis.com")) { // live site go to metaxis-service
//     prosperoURL = 'http://10.0.0.15:8001';        
// } else { // all other sites go to metaxis-search
//     prosperoURL = 'http://10.0.0.21:8001';          
}
else if (window.location.hostname === "www.metaxis.com") { // local site
    prosperoURL = 'https://test.metaxis.com:9000/';
    baseURL = '/';
    publicURL = baseURL + 'images/';
    publicHelp = baseURL + 'helpdocs/';
    feURL = "https://www.metaxis.com/PROSPERO2/";    
}

else { // demo site
    prosperoURL = 'https://test.metaxis.com:9000/';
    baseURL = '/';
    publicURL = baseURL + 'images/';
    publicHelp = baseURL + 'helpdocs/';
    feURL = "https://screening.metaxis.com/";    
}

const defs = {prosperoURL, publicURL, publicHelp, baseURL, feURL};

export { defs };