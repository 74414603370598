import React, { useState, useEffect, lazy } from 'react';
import { UseAppContext } from '../../AppContextProvider';
import { Button, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { confirm } from 'react-bootstrap-confirmation';
import LoadingSpinner from './Loading';
import RecordError from './RecordError';
import { checkTempateItem, Log, putRecordSection } from '../TemplateUtils/utils';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Modal from 'react-bootstrap/Modal';


const RecordNavControls = props => {
    const [dirty, setDirty] = useState(false);
    const [error, setError] = useState(null);
    const [cancomplete, setCanComplete] = useState({});
    const [saving, setSaving] = useState(false);
    const [complete, setComplete] = useState(null);
    const [goBack, setGoBack] = useState(false);
    const [scrollto, setScrollTo] = useState();

    // ========================================================================
    // the context data store
    // ========================================================================
    const {
        recordData: {
            record, setRecord, recordfields, setRecordFields, recordsave, setRecordSave, recordaccordionkeys
        }
    } = UseAppContext();


    // ========================================================================
    // helper function to set completed level
    // ========================================================================
    useEffect(() => {
        if (complete !== null) {
            setSaving(true);
        }
    }, [complete]);

    // reset on page load
    useEffect(() => {
        setError(null);
    }, []);


    // ========================================================================
    // Initialsise on first call
    // ========================================================================
    useEffect(() => {
        let top = localStorage.getItem("top") || 0;
        let behaviour = "instant";
        setTimeout(() => {
            var topOfElement = 0;
            let elem = document.getElementById('starttext');
            if (top === 0 && elem !== null) {
                top = elem.offsetTop;
                behaviour = "smooth";
            }
            window.scroll({ top: top, behavior: behaviour });
        }, 1);
    }, []);



    const options = {
        title: 'Go back',
        message: 'The contents of this field have changed, are you sure you want to abandon those changes?',
        buttons: [
            {
                label: 'Yes',
                onClick: props.back
            },
            {
                label: 'No',
            }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
        keyCodeForClose: [8, 32],
        willUnmount: () => { },
        afterClose: () => { },
        onClickOutside: () => { },
        onKeypress: () => { },
        onKeypressEscape: () => { },
        overlayClassName: ""
    };


    const saveData = async (tempdata) => {
        tempdata.status = complete;
        return await putRecordSection(record.recordversion, tempdata)
            .then((resp) => {
                Log(resp);
                return true;
            })
            .catch((e) => {
                Log(e);
                if (e.code != undefined) {
                    const em = { errormessage: e.message, errorcode: e.code, data: e.data };
                    setError(em);
                }
                else {
                    setError(null);
                }
                return false;
            })

        //        setTimeout(() => {
        //          setRecordFields(tempdata);
        //        setRecordSave(recordsave + 1);
        //  }, 100)

    }

    if (saving) {
        setSaving(false);
        saveData(props.data)
            .then(ret => {
                if (ret) {
                    localStorage.removeItem(record.recordversion + "_" + props.data.TemplateSection);
                    setTimeout(() => {
                        props.back();
                    }, 100);
                }
                else {
                    // save in local storage for later
                    localStorage.setItem(record.recordversion + "_" + props.data.TemplateSection, JSON.stringify(props.data));
                }

            });

    }


    /*
        if (saving) {
            setSaving(false);
            var data = props.data;
            data.status = complete;
            var tempdata = recordfields;
            tempdata[props.data.section] = data;
            saveData(tempdata);
            setTimeout(() => {
                props.back();
            }, 100)
        }
    */

    const setScroll = () => {
        localStorage.setItem("top", (window.pageYOffset || document.documentElement.scrollTop));
        props.preview(true);
    }

    const overlaycomplete = props => (<Tooltip {...props}>Mark this section as complete. All sections muct be completed before submission.</Tooltip>);
    const overlaysave = props => (<Tooltip {...props}>Save this section for later but do not mark it as complete.</Tooltip>);
    const overlayback = props => (<Tooltip {...props}>Cancel any changes you have made and go back to the overview.</Tooltip>);
    const overlaypreview = props => (<Tooltip {...props}>Show how this section will appear in the PROSPERO record.</Tooltip>);

    return (<div className="recordnav">
        <>
            <OverlayTrigger delay={{ show: 1000, hide: 200 }} placement="top" overlay={overlaysave}>
                <Button variant="primary" onClick={() => setComplete(50)} disabled={error === null && (props.error === null ? false : true) || props.loading && true}>Save for later</Button>
            </OverlayTrigger>
            <OverlayTrigger delay={{ show: 1000, hide: 200 }} placement="top" overlay={overlaycomplete}>
                <Button variant="primary" onClick={() => setComplete(100)} disabled={error === null && (props.error === null ? false : true) || props.loading || !props.cancomplete.ok && true}>Mark as complete</Button>
            </OverlayTrigger>
            <OverlayTrigger delay={{ show: 1000, hide: 200 }} placement="top" overlay={overlayback}>
                <>
                    <Button variant="warning" onClick={() => props.dirty === true ? setGoBack(true) : props.back()} >Go back</Button>
                    <Modal
                        show={goBack}
                        onHide={setGoBack}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Go back</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>The contents of this field have changed, do you want to abandon those changes?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={props.back}>
                                Yes
                            </Button>
                            <Button variant="primary" onClick={() => setGoBack(false)}>No</Button>
                        </Modal.Footer>
                    </Modal>
                </>

            </OverlayTrigger>
            {props.preview != null ? <OverlayTrigger delay={{ show: 1000, hide: 200 }} placement="top" overlay={overlaypreview}>
                <Button variant="outline-primary" onClick={() => setScroll()} disabled={error === null && props.error === null ? false : true || props.loading && true}>Preview</Button></OverlayTrigger> : null}
            {!props.cancomplete.ok && error === null && props.error === null &&
                <div className="nocomplete">You will not be able to mark this section as complete until all mandatory fields are completed.
                    <span className={"internal-link" + (props.cancomplete.errors.length > 0 ? " words-too-many " : "") } onClick={() => props.setshowerrors(!props.showerrors)}>{props.showerrors ? " Hide errors" : " Show errors"}</span></div>
            }
            {props.cancomplete.ok && error === null && props.error === null &&
                <div className="nocomplete">&nbsp;</div>
            }
            {saving && <LoadingSpinner text="Saving..." />}
            {error !== null && <RecordError errormessage={error} />}

        </>
    </div>
    )

}

export default RecordNavControls;