import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../TemplateUtils/Loading';
import RecordSectionRequired from '../TemplateUtils/RecordSectionRequired';
import { Form, Button } from 'react-bootstrap';
import { UseAppContext } from '../../AppContextProvider';
import RecordNavControls from '../TemplateUtils/RecordNavControls';
import RecordIdentifier from '../TemplateUtils/RecordIdentifier';
import RecordCaption2 from '../TemplateUtils/RecordCaption2';
import TypeAhead from '../TemplateUtils/TypeAhead';
import RecordError from '../TemplateUtils/RecordError';
import RecordOldversion from '../TemplateUtils/RecordOldversion';
import { getKey, Log, getOldVersion, RecordSectionPurposeEdit, getRecordSection, scrollIntoView, showError, checkTempateItem, buildpreview } from '../TemplateUtils/utils.js';
import {defs}  from '../../api/siteDefinitions';

const fname = "TemplateSearchLanguage";
const TemplateSearchLanguage = props => {
    // ========================================================================
    // the context data store
    // ========================================================================
    const {
        recordData: {
            record
        }
    } = UseAppContext();

    // ========================================================================
    // state variables
    // ========================================================================
    const [data, setData] = useState();                         // the actual section data
    const [showpreview, setShowPreview] = useState(false);      // toggle show/hide the preview
    const [dirty, setDirty] = useState(false);                  // true if anything has changed
    const [error, setError] = useState(null);                   // true if there is an error
    const [checked, setCheck] = useState({});                   // result of checking the content locally (overridden on the server)
    const [showerrors, setShowErrors] = useState(false);        // toggle showing any errors reported by check()
    const [loading, setLoading] = useState(true);               // true if loding data
    const [rerender, setRerender] = useState(0);                // helper to force a rerender
    const [oldversion, setOldversion] = useState(null);         // placeholder for any version that has not been saved 
    const [showlist, setShowList] = useState(false);


    // ========================================================================
    // Helper function to get the data for this section from the server
    // ========================================================================
    const getData = async (recordversion, fname) => {
        await getRecordSection(recordversion, fname, RecordSectionPurposeEdit)
            .then((resp) => {
                Log(resp);
                if (resp.status === "error") {
                    setError(resp);
                    setLoading(false);
                }
                else {
                    if(resp.FieldStatus < 100) {
                        buildpreview(resp);
                    }
                    setData(resp);
                    setCheck(checkTempateItem(resp));
                    setLoading(false);
                    if (resp.content[0].lists.list1.length > 0) {
                        setShowList(true);
                    }
                }
            });
    }


    // ========================================================================
    // Initialsise on first call
    // ========================================================================
    useEffect(() => {
        getOldVersion(record.recordversion, fname);
        getData(record.recordversion, fname);
    }, []);


    // ========================================================================
    // Handle the browser back button
    // ========================================================================
    useEffect(() => {
        const beforeUnLoad = (e) => {
            if (dirty) {
                e.preventDefault();
                e.stopPropagation();
                e.returnValue = "Are you sure you want to leave? Changes will not be saved";
                return "Are you sure you want to leave? Changes will not be saved";
            }
        }


        window.addEventListener('beforeunload', beforeUnLoad);
        return () => {
            window.removeEventListener("beforeunload", beforeUnLoad);
        }
    }, [dirty]);

    // ========================================================================
    // helper function to load or create the data, and perform initial check
    // ========================================================================
    useEffect(() => {
        if (!loading) {
            setDirty(true);
        }
        setCheck(checkTempateItem(data));
    }, [rerender]);


    // ========================================================================
    // Handle going back without saving changes
    // =======================================================================
    const back = () => {
        setDirty(false);
        props.back("list");
        setTimeout(() => {
            window.history.back();
        }, 100)
    }



    const notify = (selected) => {
        var state = data;
        let content = state.content;
        content[0].lists.list1 = selected;

        state.content = content;
        setDirty(true);
        buildpreview(state);
        setData(state);
        setRerender(rerender + 1);

    }

    // ========================================================================
    // Check if an object element has a particular value
    // ========================================================================
    const isSelected = (key, item) => {
        let tempdata = data;
        let tags = tempdata.content[0].tags;
        if (tags[key] === item) {
            return "checked";
        }
        return null;
    }


    // ========================================================================
    // get the value of an object element
    // ========================================================================
    const getTagValue = (tag) => {
        let tempdata = data;
        let tags = tempdata.content[0].tags;
        return tags[tag];
    }


    // ========================================================================
    // set the value of an object element
    // ========================================================================
    const setTagValue = (e) => {
        var tempdata = data;
        var mytags = tempdata.content[0].tags;
        let name = e.currentTarget.name;
        let value = e.currentTarget.value;

        if (e.currentTarget.checked) {
            mytags[name] = value;
        }
        else {
            mytags[name] = "";
        }


        let content = tempdata.content;

        // value-specific changes
        if (mytags.searchlanguagerestriction === "nosearchlanguagerestriction") {
            content[0].lists.list1 = [];
            setShowList(false);
        }
        else {
            setShowList(true);
        }

        content[0].tags = mytags;
        tempdata.content = content;
        buildpreview(tempdata);
        setData(tempdata);
        setRerender(rerender + 1);
    }


    // ========================================================================
    // handle already editing data
    // ========================================================================
    const useoldversion = (option) => {
        if (option) {
            let tempdata = JSON.parse(oldversion);
            tempdata.EditingToken = data.EditingToken;
            setOldversion(null);
            setData(tempdata);
        }
        else {
            setOldversion(null);
        }
    }

    // ========================================================================
    // return the content
    // ========================================================================
    return (<>
        {loading && <LoadingSpinner />}
        {error !== null && <RecordError errormessage={error} />}
        {!showpreview && !loading && (<div id={"scrollto_" + fname} className="page-inner-restrict pb-3 recordfields">
            {error === null && oldversion === null && (
                <><RecordIdentifier variant={data.RecordTemplateVariant} />
                    <RecordCaption2
                        caption={data.caption}
                        variant={data.RecordTemplateVariant}
                        fname={fname}
                    />
                    <div className={"radiobox"}>
                        <h2>Will you restrict searching to specific languages? <RecordSectionRequired /></h2>
                        <Form.Group >
                            <Form.Check type={"radio"} id={getKey()} >
                                <Form.Check.Input type={"radio"} checked={isSelected("searchlanguagerestriction", "nosearchlanguagerestriction") == null ? false : true} name="searchlanguagerestriction" value="nosearchlanguagerestriction" onChange={(e) => setTagValue(e)} />
                                <Form.Check.Label>No search language restrictions</Form.Check.Label>
                            </Form.Check>


                            <Form.Check type={"radio"} id={getKey()} >
                                <Form.Check.Input type={"radio"} checked={isSelected("searchlanguagerestriction", "searchlanguagerestriction") == null ? false : true} name="searchlanguagerestriction" value="searchlanguagerestriction" onChange={(e) => setTagValue(e)} />
                                <Form.Check.Label>The review will only include studies published in specified languages</Form.Check.Label>
                            </Form.Check>
                        </Form.Group>
                        {showError(fname + "_mustchoose", checked, showerrors) && <div id={fname + "_mustchoose"} className="formerror">You must select one option above</div>}
                    </div>


                    {showlist && <>

                        <div className={"radiobox"}>
                            <h2>Which languages will you restrict searches to? <RecordSectionRequired /></h2>

                            <TypeAhead
                                id={"inputid-2"}
                                baseurl={defs.prosperoURL + "record/languagesearch?count=500&q="}
                                ismultiple={true}
                                notify={notify}
                                selectdata={data.content[0].lists.list1}
                                inputProps={{ className: "notranslate" }}
                                className={"notranslate mt-5"}
                            />
                            {showError(data.section + "_musthavecontent", checked, showerrors) && <div id={data.section + "_musthavecontent"} className="formerror">You must select at least one language</div>}
                        </div>
                    </>
                    }







                </>)}
            {oldversion !== null && <RecordOldversion oldversion={oldversion} newversion={data} useoldversion={useoldversion} />}
            <RecordNavControls data={data} back={back} preview={setShowPreview} dirty={dirty} cancomplete={checked} setshowerrors={setShowErrors} showerrors={showerrors} error={error === null && oldversion === null ? null : "error"} />
        </div>)}
        {showpreview && (<div className="page-inner-restrict publicdocument">
            <h1>{data.caption}</h1>
            <div dangerouslySetInnerHTML={{ __html: data.preview }} />
            <div><p><br /></p>
                <p><br /></p>
            </div>
            <Button variant="primary" onClick={() => setShowPreview(false)}>Close preview</Button>
        </div>)}


    </>
    )

}

export default TemplateSearchLanguage;