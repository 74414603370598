import React, { useState, useEffect } from 'react';
import LoadingSpinner from "../TemplateUtils/Loading";
import RecordSectionRequired from '../TemplateUtils/RecordSectionRequired';
import RecordSectionOptional from '../TemplateUtils/RecordSectionOptional';
import { Form, Button } from 'react-bootstrap';
import { UseAppContext } from '../../AppContextProvider';
import TypeAhead from '../TemplateUtils/TypeAhead';
import RecordNavControls from '../TemplateUtils/RecordNavControls';
import RecordIdentifier from '../TemplateUtils/RecordIdentifier';
import RecordCaption2 from '../TemplateUtils/RecordCaption2';
import RecordError from '../TemplateUtils/RecordError';
import RecordOldversion from '../TemplateUtils/RecordOldversion';
import MEditor from '../TemplateUtils/MEditor';
import RecordHelp from '../TemplateUtils/RecordHelp';
import { Log, getOldVersion, RecordSectionPurposeEdit, getRecordSection, scrollIntoView, showError, cleanHTML, getEditorChecks, checkTempateItem, buildpreview } from '../TemplateUtils/utils.js';

const fname = "TemplateCondition";
const TemplateCondition = props => {
    // ========================================================================
    // the context data store
    // ========================================================================
    const {
        recordData: {
            record
        }
    } = UseAppContext();

    // ========================================================================
    // state variables
    // ========================================================================
    const [data, setData] = useState();                         // the actual section data
    const [showpreview, setShowPreview] = useState(false);      // toggle show/hide the preview
    const [dirty, setDirty] = useState(false);                  // true if anything has changed
    const [error, setError] = useState(null);                   // true if there is an error
    const [checked, setCheck] = useState({});                   // result of checking the content locally (overridden on the server)
    const [showerrors, setShowErrors] = useState(false);        // toggle showing any errors reported by check()
    const [loading, setLoading] = useState(true);               // true if loding data
    const [rerender, setRerender] = useState(0);                // helper to force a rerender
    const [oldversion, setOldversion] = useState(null);         // placeholder for any version that has not been saved 


    // ========================================================================
    // Helper function to get the data for this section from the server
    // ========================================================================
    const getData = async (recordversion, fname) => {
        await getRecordSection(recordversion, fname, RecordSectionPurposeEdit)
            .then((resp) => {
                Log(resp);
                if (resp.status === "error") {
                    setError(resp);
                    setLoading(false);
                }
                else {
                    if (resp.FieldStatus < 100) {
                        buildpreview(resp);
                    }
                    if (resp.FieldStatus < 100) {
                        buildpreview(resp);
                    }
                    setData(resp);
                    setCheck(checkTempateItem(resp));
                    setLoading(false);
                }
            });
    }


    // ========================================================================
    // Initialsise on first call
    // ========================================================================
    useEffect(() => {
        getOldVersion(record.recordversion, fname);
        getData(record.recordversion, fname);
    }, []);



    // ========================================================================
    // Handle the browser back button
    // ========================================================================

    useEffect(() => {
        const beforeUnLoad = (e) => {
            if (dirty) {
                e.preventDefault();
                e.stopPropagation();
                e.returnValue = "Are you sure you want to leave? Changes will not be saved";
                return "Are you sure you want to leave? Changes will not be saved";
            }
        }


        window.addEventListener('beforeunload', beforeUnLoad);
        return () => {
            window.removeEventListener("beforeunload", beforeUnLoad);
        }
    }, [dirty]);



    // ========================================================================
    // helper function to load or create the data
    // ========================================================================
    useEffect(() => {
        if (!loading) {
            setDirty(true);
        }
        // check the status of everything after wach rerender
        setCheck(checkTempateItem(data));
    }, [rerender]);


    // ========================================================================
    // Handle going back without saving changes
    // =======================================================================
    const back = () => {
        setDirty(false);
        props.back("list");
        setTimeout(() => {
            window.history.back();
        }, 100)
    }


    // ========================================================================
    // callback from the editor onblur
    // ========================================================================
    const notifytext = (editorname, editor) => {
        var state = data;
        let content = state.content;
        let c0 = content[0];

        const clean = cleanHTML(editor.datahtml);
        state.preview = clean;
        editor.datahtml = clean;
        c0.editors[editorname] = editor;
        content[0] = c0;
        state.content = content;

        setDirty(true);
        buildpreview(state);
        setData(state);
        setRerender(rerender + 1);
    }

    // ========================================================================
    // get the editor data for the editor
    // ========================================================================
    const getEditorData = (editorname) => {
        if (data != undefined && data !== null) {
            return data.content[0].editors[editorname];
        }
        return null;
    }


    // ========================================================================
    // get the new selection list
    // ========================================================================

    const notify = (selected, list) => {
        let content = data.content;
        let c0 = content[0];
        c0.lists[list] = selected;
        content[0] = c0;
        buildpreview(data);
        setData({ ...data, content: content });
        setRerender(rerender + 1);
    }



    // ========================================================================
    // handle already editing data
    // ========================================================================
    const useoldversion = (option) => {
        if (option) {
            let tempdata = JSON.parse(oldversion);
            tempdata.EditingToken = data.EditingToken;
            setOldversion(null);
            setData(tempdata);
        }
        else {
            setOldversion(null);
        }
    }




    return (<>
        {loading && <LoadingSpinner />}
        {error !== null && <RecordError errormessage={error} />}
        {!showpreview && !loading && (<div id={"scrollto_" + fname} className="page-inner-restrict pb-3 recordfields">
            {error === null && oldversion === null && (<><RecordIdentifier variant={data.RecordTemplateVariant} />
                <RecordCaption2
                    caption={data.caption}
                    variant={data.RecordTemplateVariant}
                    fname={fname}
                />
                <div className="radiobox">
                    <h2>Enter tags that best describe the condition or domain being studied <RecordSectionRequired /></h2>
                    <p className="helptextinline">Inclusion criteria may be specified either by PICO tags or by text. If you choose PICO tags you do not need to add additional text, but if it helps then please do so.</p>
                    <h3>PICO tags <RecordHelp className="smalltext" fname={fname} helpsection="help1" label="(what are PICO tags?)" /></h3>
                    <p className="helptextinline">Please choose as many PICO tags as you need to define the condition or domain being studied.</p>

                    <TypeAhead
                        id={"inputid-2"}
                        clean={true}
                        baseurl={"https://data.cochrane.org/search-n/concepts?fields=search&amp;pageSize=100&amp;filters=http://data.cochrane.org/ontologies/core/Condition,http://data.cochrane.org/ontologies/core/Drug,http://data.cochrane.org/ontologies/core/Event,http://data.cochrane.org/ontologies/core/DrugCategory,http://data.cochrane.org/ontologies/core/Procedure,http://data.cochrane.org/ontologies/core/Investigation&amp;filtersOp=should&q="}
                        ismultiple={true}
                        notify={notify}
                        selectdata={data.content[0].lists.list1}
                        inputProps={{ className: "notranslate" }}
                        className={"notranslate"}
                        listname="list1"
                    />
                </div>
                <div className="radiobox">
                    <h3>Use this section to say more about healthcare condition or domain if clarification is needed <RecordSectionOptional /></h3>
                    <MEditor rerender={rerender} key={"editor1"} editorname={"editor1"} className={"visible"} notify={notifytext} editordata={getEditorData("editor1")} editorchecks={() => getEditorChecks(data.section, "editor1", data)} />
                    {showError(fname + "_musthavecontent1", checked, showerrors) && <div className="formerror">You must select from the drop down list or enter text above (or both).</div>}
                </div>


            </>)}
            {oldversion !== null && <RecordOldversion oldversion={oldversion} newversion={data} useoldversion={useoldversion} />}
            <RecordNavControls data={data} back={back} preview={setShowPreview} dirty={dirty} cancomplete={checked} setshowerrors={setShowErrors} showerrors={showerrors} error={error === null && oldversion === null ? null : "error"} />        </div>)
        }
        {showpreview && (<div className="page-inner-restrict publicdocument">
            <h1>{data.caption}</h1>
            <div dangerouslySetInnerHTML={{ __html: data.preview }} />
            <div><p><br /></p>
                <p><br /></p>
            </div>
            <Button variant="primary" onClick={() => setShowPreview(false)}>Close preview</Button>
        </div>)}


    </>
    )

}


export default TemplateCondition;