import React from 'react';


const RecordIsTranslated = props => {            

    // ========================================================================
    // if the suggested text != the existing text, dis[play]
    // ========================================================================    

    return  ( <>
                {document.getElementsByTagName('html')[0].classList.contains("translated-ltr") && <div className="textwarning">This page has been automatically translated. Please remember that your PROPSERO record must be in English.</div>}
            </>
    )

}

export default RecordIsTranslated;